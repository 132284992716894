<template>
  <ModalContainer
    popup
    :padding="false"
    height="h-auto"
    width="max-w-full"
    extra-class="rounded-xl"
    @toggle="$emit('close', $event)"
    @on-back-button="$emit('close', $event)"
  >
    <div class="flex relative overflow-hidden">
      <Gradient />
      <div class="absolute w-full z-10">
        <p class="text-center font-extrabold text-3xl text-custom-purple-500 p-6 pt-14">
          Oh snap! <br>
          This is a premium thing.
        </p>
        <p class="px-10 text-center text-base font-semibold">
          {{ title }}
          <!-- To do this you need to be premium. Interact more or buy your premium subscription. -->
        </p>
      </div>
    </div>
    <div class="flex items-center justify-center my-8">
      <RouterLink :to="{ name: 'premium' }">
        <button class="px-6 py-3 cursor-pointer font-semibold bg-custom-orange-400 text-white rounded-xl">
          {{ $t('Get premium now!') }}
        </button>
      </RouterLink>
    </div>
  </ModalContainer>
</template>

<script>
import { i18n } from '@/js/language/'
import ModalContainer from '@/modules/modal'

export default {
  name: 'BecomePremiumUser',
  components: {
    ModalContainer
  },
  props: {
    title: { type: String, default: i18n.t('Become Premium member to avail all features of Eucaps.') }
  }
}
</script>
