
import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('pinAds')

export default {
  state: {
    loadingPinAds: false,
    pinAds: [],
    nextPageUrl: null
  },
  getters: {
    pinAds: state => state.pinAds,
    loadingPinAds: state => state.loadingPinAds

  },
  mutations: {
    SET_LOADING_ADS (state, boolean) {
      state.loading = boolean
    },

    SET_PIN_ADS (state, ads) {
      state.pinAds = ads
    },
    SET_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    }
  },
  actions: {
    async fetch ({ commit, state }) {
      commit('SET_LOADING_ADS', true)
      const response = await repository.pinAdsFetch()
      commit('SET_LOADING_ADS', false)

      if (response.status === 200 && response.data && response.data.data) {
        commit('SET_PIN_ADS', response.data.data)
        commit('SET_PAGE_URL', response.data.next_page_url)
      }
    }
  },
  namespaced: true
}
