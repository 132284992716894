<template>
  <button
    class="focus:outline-none"
    :disabled="locked"
    @click="$emit('click',$event)"
  >
    <Icon
      :name="horizontal ? 'dots-horizontal-rounded' : 'dots-vertical-rounded'"
      class="text-2xl dark:text-custom-purple-100"
    />
  </button>
</template>

<script>

export default {
  name: 'DotMenu',
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
