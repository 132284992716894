import { io } from 'socket.io-client'
import store from '@/js/store'

const URL = `${process.env.VUE_APP_CHAT_URL}`
const socket = io(URL, { autoConnect: false, secure: true })

socket.on('user-online', data => {
  store.dispatch('chats/activeUsers', data)
})

socket.on('join-chats', data => {
  // console.log('JOIN CHATS')
  store.dispatch('users/setOnline')
  store.dispatch('chats/joinChats')
})

socket.on('new-message', (payload) => {
  store.dispatch('chats/receiveMessage', payload)
})

socket.on('update-bubbles', () => {
  store.dispatch('users/fetch')
})

socket.on('delete-message', (payload) => {
  store.commit('chats/DELETE_MESSAGE', payload)
})

socket.on('renew-message', (payload) => {
  store.dispatch('chats/receiveMessage', payload)
})

socket.on('new-notification', (payload) => {
  store.dispatch('notifications/newNotification', payload)
})

socket.on('seen-by', data => {
  store.dispatch('chats/setSeenBy', data)
})

socket.on('new-achievement', data => {
  store.dispatch('achievements/openAchievement', JSON.parse(data))
})

export default socket
