<template>
  <div>
    <div class="w-full xs:w-72 sm:w-96 h-96 overflow-hidden overflow-y-auto z-70 px-2 bg-white text-custom-purple-500 dark:text-custom-purple-100 dark:bg-custom-purple-500 px-0 pb-4 pt-0 absolute top-10 right-0 sm:right-32 rounded-xl shadow-sm dark:shadow-sm-dark">
      <div>
        <Title class="px-2 py-4">
          Notifications
        </Title>
        <Icon
          name="x"
          class="text-3xl absolute top-1 right-2 p-2 cursor-pointer"
          @click="$emit('menu-clicked', $event)"
        />
      </div>
      <NotificationsContainer />
    </div>
    <TransparentBackgroundForMenus @click="$emit('menu-clicked', $event)" />
  </div>
</template>

<script>
import NotificationsContainer from '@/modules/notifications'

export default {
  name: 'NotificationsMenu',
  components: {
    NotificationsContainer
  }
}
</script>
