import axios from 'axios'
import store from '@/js/store'

export default {
  token: null,
  dsecret: null,
  storeDSecret (dsecret) {
    if (dsecret) {
      localStorage.setItem('dsecretV2', dsecret)
      this.setDSecretHeader(dsecret)
      return true
    }
    return false
  },
  storeToken (token) {
    // console.log('STORE TOKEN', token)
    if (token !== undefined) {
      localStorage.setItem('accessToken', token)
      this.setAuthorizationHeader(token)
      return true
    }
    return false
  },
  storeTemporaryToken (token) {
    // console.log('STORE TEMP TOKEN', token)
    if (token !== undefined) {
      this.token = token
      this.setAuthorizationHeader(token)
      return true
    }
    return false
  },
  isAuthenticated () {
    const token = this.getAccessToken()
    return token != null && token !== undefined && token !== 'undefined'
  },
  hasDSecret () {
    return this.getDSecret() !== null
  },
  getAccessToken () {
    // console.log('GET ACCESS TOKEN')
    // console.log('local', localStorage.getItem('accessToken'))
    // console.log('session', sessionStorage.getItem('accessToken'))
    if (localStorage.getItem('accessToken') !== '' && localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== undefined) {
      return localStorage.getItem('accessToken')
    } else {
      if (!sessionStorage.getItem('accessToken')) {
        // console.log('SESSION EMPTY, CHECK TOKEN', this.token)
        return this.token
      } else {
        return sessionStorage.getItem('accessToken')
      }
    }
  },
  getDSecret () {
    const dsecret = (localStorage.getItem('dsecretV2') !== '' && localStorage.getItem('dsecretV2') !== null && localStorage.getItem('dsecretV2') !== undefined) ? localStorage.getItem('dsecretV2') : null
    return dsecret
  },
  setAuthorizationHeader (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },
  clearAuthorizationHeader () {
    axios.defaults.headers.common['Authorization'] = ``
  },
  setDSecretHeader (dsecret) {
    store.dispatch('users/setDSecret', dsecret)

    axios.defaults.headers.common['DSecret'] = `${dsecret}`
    axios.defaults.headers.common['PlatformKey'] = store.getters['app/platformKey']
  },
  clearToken () {
    // console.log('CLEAR TOKEN OMS')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    sessionStorage.removeItem('onboarding')
    this.clearAuthorizationHeader()
  },
  refreshHeader () {
    this.setDSecretHeader(this.getDSecret())
    if (this.isAuthenticated()) {
      this.setAuthorizationHeader(this.getAccessToken())
    }
  }
}
