<template>
  <TopCompanySearch
    :value="search"
    :show-search="showSearch"
    :is-mobile="isMobile"
    :show-search-input="showSearchInput"
    :loading-companies="loadingCompanies"
    :show-results="showResults"
    :companies="filterCompanies"
    @search="searchCompany"
    @go-to-company="goToCompany"
    @go-to-screener="goToScreener"
    @toggle-search-box="onShowSearch"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import TopCompanySearch from './_components/TopCompanySearch'
import { isMobile } from '@/js/helpers/mobile'

export default {
  name: 'TopCompanySearchModule',
  components: {
    TopCompanySearch
  },
  data () {
    return {
      search: '',
      showSearch: false,
      showSearchBox: false
    }
  },
  computed: {
    ...mapGetters({
      companies: 'companies/companies',
      loadingCompanies: 'companies/loadingCompanies'
    }),
    isMobile () {
      return isMobile()
    },
    showSearchInput () {
      if (!isMobile()) {
        return true
      }

      return this.showSearch
    },
    filterCompanies () {
      if (!this.showSearch) {
        return []
      }
      if (this.companies.length === 0) {
        return []
      }

      return Object.values(this.companies).filter(company => {
        return ((company.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1))
      })
    },
    showResults () {
      return (!!this.search)
    }
  },
  methods: {
    onShowSearch (event) {
      this.search = ''
      this.showSearch = !this.showSearch
    },
    searchCompany (search) {
      this.search = search
      this.$store.dispatch('companies/setLoadingCompanies', true)
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.refetch(this.search)
      }, 500)
    },
    refetch (search) {
      if (search !== '') {
        this.$store.dispatch('companies/fetchAll', { search })
      } else {
        this.$store.dispatch('companies/setLoadingCompanies', false)
      }
    },
    goToCompany (slugUrl) {
      this.$router.push({ name: 'companyOverview', params: { id: slugUrl } })
      this.search = ''
      this.showSearch = false
    },
    async goToScreener () {
      this.$router.push({ name: 'companyScreener' })
      const search = this.search
      setTimeout(() => {
        this.$store.dispatch('screeners/setSearchCompaniesSortable', {
          searchCompaniesPhrase: search
        })

        this.$store.dispatch('companies/searchCompanies')
      }, 500)

      this.showSearch = false
    }
  }
}
</script>
