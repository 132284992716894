import { get, post, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const getBusinessDetailsList = async () => get(`${url}/business-details`)
const newDocumentListFile = async (businessDetailId, data) => post(`${url}/business-details/${businessDetailId}/document-list`, data)
const deleteDocumentListFile = async (businessDetailId, id) => destroy(`${url}/business-details/${businessDetailId}/document-list/${id}`)
const deleteLink = async (businessDetailId, id) => destroy(`${url}/business-details/${businessDetailId}/links/${id}`)
export default {
  getBusinessDetailsList,
  newDocumentListFile,
  deleteDocumentListFile,
  deleteLink
}
