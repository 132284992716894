<template>
  <FrontFooter
    :is-logged-in="isLoggedIn"
    :use-footer="useFooter"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import FrontFooter from './_components/FrontFooter'

export default {
  name: 'FrontFooterModule',
  components: {
    FrontFooter
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      useFooter: 'app/useFooter'
    }),
    isLoggedIn () {
      return this.user && this.user.id && this.user.profile_type !== 'App\\Models\\GuestUser'
    }
  }
}
</script>
