<template>
  <header
    class="bg-custom-purple-500 dark:bg-custom-purple-800 dark:text-primary-dark w-full z-20 py-4"
    :class="useMenu ? 'z-60' : ''"
  >
    <Container
      wide
      :padding="iosPadding ? 'pt-0 pb-4 px-2' : 'py-0'"
    >
      <nav class="relative flex items-center justify-between py-2 mx-auto">
        <div
          class="flex items-center"
          :class="!useMenu ? '' : 'w-full justify-between'"
        >
          <Icon
            v-if="useMenu"
            name="left-arrow-alt"
            class="text-2xl flex cursor-pointer pr-2 text-white cursor-pointer z-60"
            @click.native="$router.back()"
          />
          <LogoContainer
            eucaps
            class="mr-12 lg:mr-8"
          />
          <TopCompanySearchContainer />
        </div>
        <template v-if="!useMenu">
          <div class="flex items-center text-white font-medium">
            <RouterLink
              :to="{ name: 'generalfeed' }"
              class="mr-2 lg:mr-6 flex items-center"
              @click.native="$emit('reload-data')"
            >
              <Icon
                name="home-alt"
                class="text-xl mr-2 home-icon"
                :class="$route.name === 'generalfeed' ? 'text-custom-orange-400' : ''"
              />
              <span class="hidden lg:block">Talk & News</span>
            </RouterLink>
            <RouterLink
              :to="{ name: 'companyScreener' }"
              class="mr-0 lg:mr-6 flex items-center"
            >
              <Icon
                name="compass"
                :class="$route.fullPath.includes('/explore') ? 'text-custom-orange-400' : ''"
                class="text-2xl mr-2"
              />
              <span class="hidden lg:block">Explore</span>
            </RouterLink>
            <div
              class="relative p-1 w-10 h-10 flex justify-center items-center rounded-full mr-2 lg:mr-4 cursor-pointer hover:text-custom-orange-400"
              :class="showNotificationsMenu ? 'text-custom-orange-400' : 'text-white'"
            >
              <BubbleContainer
                type="global_menu_notification"
                font-size="text-3xs"
                class="font-semibold absolute top-0 left-0"
              />
              <Icon
                name="bell"
                class="text-2xl"
                @click="$emit('toggle-notifications-menu')"
              />
            </div>
            <RouterLink
              :to="{ name: 'myEucapsOverview' }"
              class="flex items-center"
            >
              <Avatar
                :size="30"
                :stock-user="user.profile"
                :use-premium="false"
                small
                :class="$route.fullPath.includes('/my-eucaps') ? 'border-2 border-custom-orange-400 rounded-full' : ''"
              />
            </RouterLink>
            <div
              class="relative p-1 w-10 h-10 flex justify-center items-center rounded-full cursor-pointer hover:bg-white dark:hover:bg-custom-purple-400 hover:text-custom-purple-500"
              :class="showExtendedMenu ? 'bg-white text-custom-purple-500' : 'bg-transparent'"
              @click="$emit('toggle-extended-menu')"
            >
              <Bubble
                v-if="!isVerified"
                small
                class="absolute top-0 left-0 bg-custom-orange-400"
              />
              <DotMenu
                horizontal
              />
            </div>
            <NotificationsMenu
              v-if="showNotificationsMenu"
              @menu-clicked="$emit('toggle-notifications-menu')"
            />
          </div>
        </template>
        <!-- <MenuContainer class="w-10/12" /> -->
      </nav>
    </Container>
    <MyEucapsMenuModalContainer
      v-if="showExtendedMenu"
      :show-my-eucaps-menu="showExtendedMenu"
      @toggle-my-eucaps-menu="$emit('toggle-extended-menu')"
    />
  </header>
</template>

<script>

import LogoContainer from '@/modules/logo'
import TopCompanySearchContainer from '@/modules/topCompanySearch'
// import AppsMenu from './AppsMenu'
import MyEucapsMenuModalContainer from '@/modules/myEucapsMenuModal'
import NotificationsMenu from './NotificationsMenu'
import BubbleContainer from '@/modules/bubble'

// import MenuContainer from '@/modules/menu'

export default {
  name: 'HeaderInternal',
  components: {
    LogoContainer,
    TopCompanySearchContainer,
    MyEucapsMenuModalContainer,
    NotificationsMenu,
    BubbleContainer
  },
  props: {
    iosPadding: { type: Boolean, default: false },
    isLoggedIn: { type: Boolean, default: false },
    isVerified: { type: Boolean, default: false },
    menu: { type: Boolean, default: false },
    useMenu: { type: Boolean, default: false },
    showExtendedMenu: { type: Boolean, default: false },
    showNotificationsMenu: { type: Boolean, default: false },
    unreadMessages: { type: Number, default: 0 },
    user: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      showMyEucapsMenu: false
    }
  },
  mounted () {
    // console.log(this.iosPadding + '-slide-fade')
  }
}
</script>

<style lang="scss">
.home-icon {
  margin-top: -3px;
}

.slide-fade-enter-active {
transition: all .2s ease;
}

.slide-fade-leave-active {
transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
{
transform: translateY(75px);
}

.ios-slide-fade-enter-active {
transition: all .2s ease;
}

.ios-slide-fade-leave-active {
transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.ios-slide-fade-enter,
.ios-slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
{
transform: translateY(111px);
}
</style>
