var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'flex space-x-4': _vm.variant === 'horizontal',
  }},[_c('ul',{staticClass:"list-none border-custom-purple-300 text-sm border-b-2 text-center overflow-auto whitespace-nowrap",class:{
      'flex items-center mb-6': _vm.variant === 'vertical',
    }},_vm._l((_vm.tabList),function(tab,index){return _c('li',{key:index,staticClass:"w-full py-1",class:{
        'text-white font-semibold bg-custom-purple-300': index + 1 === _vm.activeTab,
        '': index + 1 !== _vm.activeTab,
      },on:{"click":function($event){return _vm.$emit('fetch-linkables', tab)}}},[_c('label',{staticClass:"cursor-pointer block",attrs:{"for":("" + _vm._uid + index)},domProps:{"textContent":_vm._s(tab)}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeTab),expression:"activeTab"}],staticClass:"hidden",attrs:{"id":("" + _vm._uid + index),"type":"radio","name":(_vm._uid + "-tab")},domProps:{"value":index + 1,"checked":_vm._q(_vm.activeTab,index + 1)},on:{"change":function($event){_vm.activeTab=index + 1}}})])}),0),_vm._l((_vm.tabList),function(tab,index){return [(index + 1 === _vm.activeTab)?_c('div',{key:index,staticClass:"flex-grow bg-white rounded-lg shadow-xl p-4"},[_vm._t(("tabPanel-" + (index + 1)))],2):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }