<template>
  <BaseButton
    class="rounded-xl text-custom-purple-500 border-2 px-4 py-1 cursor-pointer"
    :class="editMode ? 'text-custom-yellow-300 bg-custom-purple-500 border-custom-purple-500' : 'bg-custom-yellow-300 text-custom-purple-500 border-custom-purple-500'"
    :no-padding="true"
    no-shadow
    @click.native="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'EditButton',
  props: {
    noPadding: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false }
  }
}
</script>
