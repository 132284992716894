import { render, staticRenderFns } from "./CompanyName.vue?vue&type=template&id=3556a2fb&"
import script from "./CompanyName.vue?vue&type=script&lang=js&"
export * from "./CompanyName.vue?vue&type=script&lang=js&"
import style0 from "./CompanyName.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports