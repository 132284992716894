import { RepositoryFactory } from '@/api/repositoryFactory'
import { i18n } from '../js/language'
const repository = RepositoryFactory.get('notifications')

export default {
  state: {
    notifications: [],
    loading: false,
    defaultNotificationSettings: [],
    userNotificationSettings: {},
    totalPublicNotificationSettings: 0,
    nextPageUrl: null,
    scrollLoading: false
  },
  getters: {
    list: state => state.notifications,
    loading: state => state.loading,
    defaultNotificationSettings: state => state.defaultNotificationSettings,
    userNotificationSettings: state => state.userNotificationSettings,
    totalPublicNotificationSettings: state => state.totalPublicNotificationSettings,
    nextPageUrl: state => state.nextPageUrl,
    scrollLoading: state => state.scrollLoading
  },
  mutations: {
    SET_NOTIFICATIONS (state, payload) {
      state.notifications = [...payload]
    },
    RESET_NOTIFICATIONS (state) {
      state.notifications = []
    },
    REMOVE_NOTIFICATION (state, id) {
      const idx = state.notifications.find(notification => Number(notification.id) === Number(id))
      state.notifications.splice(state.notifications.indexOf(idx), 1)
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_DEFAULT_NOTIFICATION_SETTINGS (state, payload) {
      state.defaultNotificationSettings = payload
    },
    SET_USER_NOTIFICATION_SETTINGS (state, payload) {
      state.userNotificationSettings = payload
    },
    SET_TOTAL_PUBLIC_NOTIFICATION_SETTINGS (state, payload) {
      state.totalPublicNotificationSettings = payload
    },
    RESET_NOTIFICATION_COUNTER (state) {
      state.notifications.forEach(notification => {
        if (notification.pivot.seen === 0) {

        }
      })
    },
    UNSHIFT_NOTIFICATIONS (state, payload) {
      if (state.notifications.length === 0) {
        state.notifications = [...payload]
        state.unreadNotifications = payload.length
      } else {
        if (payload.length > 0) {
          payload.forEach(data => {
            const findIndex = state.notifications.findIndex(notification => Number(notification.id) === Number(data.id))
            if (findIndex === -1) {
              state.notifications.unshift(data)
              state.unreadNotifications += 1
            }
          })
        }
      }

      state.notifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
    PUSH_NOTIFICATIONS (state, payload) {
      if (state.notifications.length === 0) {
        state.notifications = [...payload]
      } else {
        if (payload.length > 0) {
          payload.forEach(data => {
            const findIndex = state.notifications.findIndex(notification => Number(notification.id) === Number(data.id))
            if (findIndex === -1) {
              state.notifications.push(data)
            }
          })
        }
      }
      state.notifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
    NEXT_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    SCROLL_LOADING (state, boolean) {
      state.scrollLoading = boolean
    },
    HIDE_NOTIFICATION (state, payload) {
      if (state.notifications.length > 0) {
        const findIndex = state.notifications.findIndex(notification => Number(notification.id) === Number(payload.id))
        if (findIndex > -1) {
          state.notifications.splice(findIndex, 1)
        }
      }
    },
    READ_NOTIFICATION (state, id) {
      if (state.notifications.length > 0) {
        const findIndex = state.notifications.findIndex(notification => notification.id === id)
        if (findIndex > -1) {
          state.notifications[findIndex].pivot.read = 1
        }
      }
      state.notifications = [ ...state.notifications ]
    }
  },
  actions: {
    async inAppPushNotification ({ dispatch }, payload) {
      dispatch('snackbar/snack', {
        type: 'notification',
        show: true,
        autoclose: true,
        title: payload.title,
        body: payload.body,
        link: payload.data.link.replace('axyer://', '/')
      }, { root: true })
    },
    async fetch ({ commit, state, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.list()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('PUSH_NOTIFICATIONS', response.data.data)
          commit('NEXT_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        console.log('Error : ' + e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    clearCounter ({ state, dispatch }) {
      try {
        let unseenNotifications = []
        state.notifications.forEach(notification => {
          if (notification.pivot.seen === 0) {
            unseenNotifications.push(notification.id)
            notification.pivot.seen = 1
          }
        })
        state.notifications = [ ...state.notifications ]
        if (unseenNotifications.length > 0) {
          const response = repository.notificationSeen({ notifications: unseenNotifications })
          if (response.status === 204) {
            // console.log('notification seen')
          }
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    newNotification ({ commit, dispatch }, payload) {
      // console.log('WOHO NOTIFICATION WORKS')
      commit('UNSHIFT_NOTIFICATIONS', payload)
      dispatch('snackbar/snack', { text: i18n.t('Got new notifications'), type: 'success', show: true, autoclose: true }, { root: true })
    },
    async read ({ commit }, id) {
      commit('READ_NOTIFICATION', id)
      const response = await repository.read(id)
      if (response.status === 204) {
        // console.log('notification status updated')
      }
    },
    async hide ({ commit }, payload) {
      commit('HIDE_NOTIFICATION', payload)
      const response = await repository.hide(payload.id)
      if (response.status === 204) {
        // console.log('notification status updated')
      }
    },
    async hideAll ({ commit }, payload) {
      commit('SET_LOADING', true)
      const response = await repository.removeAll()
      commit('SET_LOADING', false)
      if (response.status === 204) {
        commit('RESET_NOTIFICATIONS')
        // console.log('notification status updated')
      }
    },
    async remove ({ commit }, payload) {
      commit('REMOVE_NOTIFICATION', payload.id)
      const response = await repository.remove(payload.id)
      if ((response.status === 204) && response.data) {
        // console.log('Notification removed successfully')
      }
    },
    async fetchSettings ({ commit }) {
      commit('SET_LOADING', true)
      const response = await repository.fetchSettings()
      commit('SET_LOADING', false)
      if ((response.status === 200) && response.data) {
        let totalPublicNotifications = 0
        for (let i = 0; i < response.data.length; i++) {
          const setting = response.data[i]
          if (setting.isPrivate !== true) {
            totalPublicNotifications += 1
          }
        }
        commit('SET_TOTAL_PUBLIC_NOTIFICATION_SETTINGS', totalPublicNotifications)
        commit('SET_DEFAULT_NOTIFICATION_SETTINGS', response.data)
      }
    },
    setSettings ({ state, commit }, payload) {
      let settings = []
      state.defaultNotificationSettings.forEach(setting => {
        if (payload.id === setting.id) {
          settings.push(payload)
        } else {
          settings.push(setting)
        }
      })
      commit('SET_DEFAULT_NOTIFICATION_SETTINGS', settings)
    },
    async changeSettings ({ state }, payload) {
      await repository.setNotificationSettings({
        settings: state.defaultNotificationSettings,
        entity: payload
      })
    },
    async fetchUserNotificationSettings ({ state, commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchNotificationSettings(payload)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_USER_NOTIFICATION_SETTINGS', response.data)
          if (response.data.length !== 0) {
            let notificationList = []
            state.defaultNotificationSettings.forEach(defaultNotification => {
              response.data.forEach(userNotification => {
                if (userNotification.notification_setting_type === defaultNotification.type) {
                  defaultNotification.isEnabled = userNotification.isEnabled
                }
              })
              notificationList.push(defaultNotification)
            })
            commit('SET_DEFAULT_NOTIFICATION_SETTINGS', notificationList)
          }
        }
      } catch (e) {
        console.log(e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async scroll ({ commit, state, dispatch }) {
      try {
        if (state.nextPageUrl === null) {
          commit('SCROLL_LOADING', false)
        } else if (state.nextPageUrl !== null && !state.loading) {
          commit('SCROLL_LOADING', true)
          const response = await repository.scroll(state.nextPageUrl)
          commit('SCROLL_LOADING', false)

          if ((response.status === 200) && response.data && response.data.data) {
            commit('PUSH_NOTIFICATIONS', response.data.data)
            // payload.infiniteLoader.loaded()
            commit('NEXT_PAGE_URL', response.data.next_page_url)
          }
        }
      } catch (e) {
        // commit('SET_LOADING_NEW', false)
        console.log('Error Occured : ' + e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.notifications = [...[]]
    }
  },
  namespaced: true
}
