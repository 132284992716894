<template>
  <MediaPreview
    v-if="previewOnly"
    :media-previews="galleryPreviews"
    @remove-media="removeMedia"
  />
  <div v-else>
    <AttachMediaMenuMinified
      v-if="minify"
      :company-attachament="companyAttachament"
      @on-media-change="onMediaChange"
      @on-user-tag="onUserTag"
      @on-company-tag="onCompanyTag"
      @on-company-data="onCompanyData"
    />
    <AttachMediaMenu
      v-else
      :company-attachament="companyAttachament"
      @on-media-change="onMediaChange"
      @on-user-tag="onUserTag"
      @on-company-tag="onCompanyTag"
      @on-company-data="onCompanyData"
    >
      <MediaPreview
        v-if="previewInSlot"
        :media-previews="galleryPreviews"
        @remove-media="removeMedia"
      />
    </AttachMediaMenu>
  </div>
</template>

<script>
import AttachMediaMenu from './_components/AttachMediaMenu'
import AttachMediaMenuMinified from './_components/AttachMediaMenuMinified'
import MediaPreview from './_components/MediaPreview'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'
import eventBus from '../../js/eventbus'

export default {
  name: 'AttachMediaMenuModule',
  components: {
    AttachMediaMenu,
    AttachMediaMenuMinified,
    MediaPreview
  },
  props: {
    minify: {
      type: Boolean,
      default: false
    },
    previewInSlot: {
      type: Boolean,
      default: false
    },
    previewOnly: {
      type: Boolean,
      default: false
    },
    galleryKey: {
      type: String,
      default: 'general'
    },
    useEventBus: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default: ''
    },
    editables: {
      type: Array,
      default: () => []
    },
    companyAttachament: {
      type: Boolean,
      default: true
    },
    attachmentType: {
      type: String,
      default: 'post'
    },
    postId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      galleryFiles: 'gallery/selectedGalleryFiles',
      galleryPreviews: 'gallery/selectedGalleryPreviews'
    }),
    existingMediaFiles () {
      return this.galleryFiles.map(file => file.filename)
    }
  },
  created () {
    this.$store.dispatch('gallery/select', this.galleryKey)
  },
  mounted () {
    if (this.useEventBus) {
      eventBus.$on('on-image-paste', this.onImagePaste)
    }

    if (this.editables) {
      this.addEditables()
    }
  },
  destroyed () {
    if (this.useEventBus) {
      eventBus.$off('on-image-paste', this.onImagePaste)
    }
  },
  methods: {
    async addEditables () {
      await Promise.all(
        this.editables.map(async (media) => {
          if (!this.existingMediaFiles.includes(media.src)) {
            const data = await fetch(media.src)
            const blob = await data.blob()

            const uuid = uuidv4()

            const gallery = this.getGalleryObjects(media.src, uuid, new File([blob], { type: media.type }), media.type, true)
            this.$store.dispatch('gallery/set', gallery)
          }
        })
      )
    },
    removeMedia (key) {
      this.$store.dispatch('gallery/remove', key)
    },
    onUserTag () {
      eventBus.$emit('on-user-tag', { type: this.attachmentType, postId: this.postId })
    },
    onCompanyTag () {
      eventBus.$emit('on-company-tag', { type: this.attachmentType, postId: this.postId })
    },
    onCompanyData () {
      this.$emit('on-company-data')
    },
    getGalleryObjects (filename, uuid, blob, type, exist = false) {
      return {
        media: {
          filename,
          type,
          uuid,
          data: blob,
          exist
        },
        preview: {
          file: URL.createObjectURL(blob),
          type: type,
          name: blob.name.split(' ').join('_')
        }
      }
    },
    publishToGallery (blob, type) {
      const uuid = uuidv4()

      this.$store.dispatch('gallery/set', this.getGalleryObjects(this.path + uuid + '/' + blob.name.split(' ').join('_'), uuid, blob, type))
    },
    onImagePaste (blob) {
      // console.log('onImagePaste')
      this.$store.dispatch('gallery/select', this.galleryKey)

      this.publishToGallery(blob, 'image')
    },
    onMediaChange (e) {
      // console.log('ON MEDIA CHANGE?')
      let file = e.target.files

      for (let i = 0; i < file.length; i++) {
        let type = 'document'
        if (file[i].type.match(/image/g)) {
          type = 'image'
        } else if (file[i].type.match(/video/g)) {
          type = 'video'
        } else if (file[i].type.match(/audio/g)) {
          type = 'audio'
        }
        this.publishToGallery(file[i], type)
      }

      this.$emit('on-media-change')
    }
  }
}
</script>
