import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('sectors')

export default {
  state: {
    topSectors: [],
    loading: false
  },
  getters: {
    topSectors: state => state.topSectors,
    loading: state => state.loading
  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_TOP_SECTORS (state, payload) {
      state.topSectors = payload
    }
  },
  actions: {
    async fetchTopSectors ({ commit, dispatch }) {
      try {
        const response = await repository.fetchTopSectors()
        if (response.status === 200 && response.data) {
          commit('SET_TOP_SECTORS', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
