<template>
  <div
    class="fixed top-0 left-0 inset-0 z-60"
    @click="$emit('click', $event)"
  />
</template>

<script>

export default {
  name: 'TransparentBackgroundForMenus',
  props: {
  }
}
</script>
