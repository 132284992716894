<template>
  <div
    id="app"
    class="flex flex-1 flex-col h-full bg-main-background dark:bg-custom-purple-500"
    :class="theme"
  >
    <NetworkContainer />
    <template v-if="dsecret && user">
      <!-- <LoggedInLayout v-else-if="userStatus === 'verifiedUser'" /> -->

      <FrontLayout v-if="isFrontLayout" />
      <SignInLayout v-else-if="isSignInLayout" />
      <SignupSection
        v-else-if="userStatus === 'emailVerificationNeeded'"
        :class="'flex flex-grow flex-shrink-0 flex-col bg-custom-purple-500'"
      />
      <LoggedInLayout v-else-if="userStatus === 'emailVerifiedUser'" />
      <StandardLayout v-else />
    </template>
    <div
      v-else
      class="flex justify-center mt-8 p-4"
    >
      <Loader />
    </div>
    <SnackBar />
    <SnackBarNotification />
    <!-- <AchievementModal /> -->
    <UserBlockedModal />
    <WarningModal />
    <MaintenanceModal />
    <ForcedUpgradeModalContainer />
    <BecomePremiumModalContainer />
    <BecomeVerifiedUser
      v-if="showVerificationModal"
      @toggle="closeVerificationModal"
    />
    <!-- <div
      v-if="webShareApiSupported"
      class="fixed z-60 cursor-pointer"
      :class="useMenu ? 'bottom-24 left-4' : 'bottom-32 left-10'"
    >
      <div
        class="flex relative justify-center items-center w-14 h-14 rounded-full bg-white hover:bg-custom-purple-400 hover:text-white cursor-pointer"
        @click="shareViaWebShare"
      >
        <Icon name="share-alt" />
      </div>

      <PremiumNotepadContainer :type="$route.name" />
    </div> -->
  </div>
</template>

<script>
import NetworkContainer from '@/modules/network'
import StandardLayout from '@/layout/StandardLayout'
import FrontLayout from '@/layout/FrontLayout'
import LoggedInLayout from '@/layout/LoggedInLayout'
import WarningModal from '@/modules/warning'
import MaintenanceModal from '@/modules/maintenanceModal'
import ForcedUpgradeModalContainer from '@/modules/forcedUpgradeModal'
import BecomePremiumModalContainer from '@/modules/becomePremiumModal'
// import PremiumNotepadContainer from '@/modules/premiumNotepad'
import SignInLayout from '@/layout/SignInLayout'
import SignupSection from '@/views/Signup'
// import StandardDevLayout from '@/layout/StandardDevLayout'
import { mapGetters } from 'vuex'
import 'boxicons/css/boxicons.min.css'
// import EmailVerification from '@/views/EmailVerification'
import { App as CapacitorApp } from '@capacitor/app'
// import auth from '@/js/helpers/auth'

import { isMobile } from '@/js/helpers/mobile'
import usersMixin from '@/mixins/usersMixin'

require('animate.css/animate.min.css')

export default {
  name: 'App',
  components: {
    // PremiumNotepadContainer,
    NetworkContainer,
    FrontLayout,
    StandardLayout,
    LoggedInLayout,
    WarningModal,
    MaintenanceModal,
    BecomePremiumModalContainer,
    ForcedUpgradeModalContainer,
    SignInLayout,
    SignupSection
  },
  mixins: [usersMixin],
  metaInfo: {
    title: 'Eucaps',
    // titleTemplate: '%s | vue-meta Example App',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'An example Vue application with vue-meta.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  async beforeRouteEnter () {
    await this.$store.dispatch('users/dsecret')
  },
  computed: {
    ...mapGetters({
      theme: 'users/activeTheme',
      isLoggedin: 'users/isLoggedin',
      user: 'users/user',
      useMenu: 'app/useMenu',
      dsecret: 'users/dsecret',
      isPremium: 'users/isPremium',
      loading: 'users/loading',
      showVerificationModal: 'users/showVerificationModal'
    }),
    isSignInLayout () {
      if (this.$route.meta && this.$route.meta.layout) {
        return this.$route.meta.layout === 'SignInLayout'
      }
      return false
    },
    isFrontLayout () {
      if (this.$route.meta && this.$route.meta.layout) {
        return this.$route.meta.layout === 'FrontLayout'
      }

      return false
    },
    userStatus () {
      if (this.isGuest) return 'guest'
      else if (this.user && this.user.id && !this.user.email_verified_at) return 'emailVerificationNeeded'
      else if (this.user && this.user.id && this.user.email_verified_at) return 'emailVerifiedUser'
      else return false
    },
    showTodo () {
      return process.env.VUE_APP_SHOW_TODO !== 'false'
    }
  },
  created () {
    if (this.$store.getters['app/isNative'] || isMobile()) {
      this.$store.dispatch('app/useMenu', true)
    }
    // const userAgent = navigator.userAgent || navigator.vendor || window.opera
    // if (!this.$store.getters['app/isNative']) {
    //   if (/android/i.test(userAgent)) {
    //     window.location.href = 'https://play.google.com/store/apps/details?id=com.stock.market.discussion.axyer'
    //   } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //     window.location.href = 'https://apps.apple.com/us/app/axyer/id1598555414'
    //   }
    // }
    const self = this
    CapacitorApp.addListener('appUrlOpen', (event) => {
      let url = event.url.replace('axyer://', '/')
      if (self.$router.history.current.path.substring(1) !== url) {
        self.$router.push(url)
      }
    })
  },
  async mounted () {
    // console.log('--------G MOUNTED')
    await this.$store.dispatch('users/updateFCMToken')
    await this.$store.dispatch('app/getLatestVersion')
  },
  async beforeCreate () {
    await this.$store.dispatch('users/dsecret')
    await this.$store.dispatch('users/guest')
    this.$store.dispatch('users/profile')
    this.$store.dispatch('languages/init')
    this.$store.dispatch('users/initTheme')
    this.$store.dispatch('notifications/fetchSettings')
  },
  destroyed () {
    this.$store.dispatch('socket/clean')
  },
  methods: {
    togglePremium () {
      this.$store.dispatch('users/setIsPremium', !this.isPremium)
    },
    closeVerificationModal () {
      this.$store.dispatch('users/setShowVerificationModal', false)
    }
  }
}
</script>

<style lang="scss">
h1,
h2,
h3,
h4,
strong,
b {
  @apply font-header;
  font-weight: 700 !important;
}
input, select, textarea {
  font-size: 16px !important;
}
</style>
