<template>
  <NeutralButton
    secondary
    class="w-full"
    @click.native="$emit('click', $event)"
  >
    <div class="flex justify-center items-center pr-2">
      <img
        class="w-12 pr-2"
        src="/img/bankid.svg"
      >
      <span class="text-xs">{{ $t('Verify your identity with BankID') }}</span>
    </div>
  </NeutralButton>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BankIDButton',
  computed: {
    ...mapGetters({
      activeTheme: 'users/activeTheme'
    })
  }
}
</script>
