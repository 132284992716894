<template>
  <div
    v-if="notification.notifiable"
    @click="$parent.redirect({notificationId: notification.id, name: 'userFlow', id: notification.notifiable.stock_user.id})"
  >
    <Notification
      :pivot="notification.pivot"
      @remove="$emit('remove', { id: notification.id })"
    >
      <AvatarSlot
        :stock-user="notification.notifiable.stock_user"
      >
        <div class="text-xs font-medium mr-6">
          <!-- <RouterLink :to="{name: 'userFlow', params: {id: user.id}}"> -->
          <span class="font-bold">
            {{ notification.notifiable.stock_user.username }}
          </span>
          <span v-if="notification.total>1"> {{ $t('and') }}  {{ notification.total - 1 }} {{ $t('people') }}</span>
          {{ $t('started following you') }}
          <!-- </RouterLink> -->
          <div>{{ time }}</div>
        </div>
      </AvatarSlot>
    </Notification>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationUserFollowers',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  }
}
</script>
