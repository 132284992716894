// import { allTypes } from '@/enums/mediaTypes'

export default {
  state: {
    filters: {
      by_all: 1,
      by_image: 1,
      by_video: 1,
      by_audio: 1,
      by_document: 1
      // types: allTypes
    }
  },
  getters: {
    filters: state => state.filters
  },
  mutations: {
    INIT_FILTERS (state) {
      if (localStorage.filters) {
        state.filters = { ...JSON.parse(localStorage.filters) }
      }
    },
    SET_FILTERS (state, payload) {
      state.filters = { ...state.filters, ...payload }
      localStorage.filters = JSON.stringify(state.filters)
    },
    SET_ALLOWED_TYPES (state, payload) {
      state.types = [...payload]
    }
  },
  actions: {
    async init ({ commit }) {
      commit('INIT_FILTERS')
    },
    async setFilter ({ commit }, payload) {
      commit('SET_FILTERS', payload)
    }
  },
  namespaced: true
}
