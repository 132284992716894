import { get, post } from '../helpers/axiosHelper'
const url = `${process.env.VUE_APP_API_URL}/incidents`
const fetch = async () => get(`${url}`)
const fetchIncident = async (id) => get(`${url}/${id}`)
const takeResponsibilityForIncident = async (id) => post(`${url}/${id}/responsible`)
const setActionToUser = async (id, action) => post(`${url}/${id}/action`, action)
export default {
  fetch,
  fetchIncident,
  takeResponsibilityForIncident,
  setActionToUser
}
