import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('verifications')

export default {
  state: {
    loading: false,
    data: null
  },
  getters: {
    loading: state => state.loading,
    status: state => state.data && state.data.status ? state.data.status : 'wait',
    redirectUrl: state => state.data && state.data.url ? state.data.url : null,
    data: state => state.data
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    RESET_DATA (state) {
      state.data = null
    },
    SET_STATUS (state, payload) {
      console.log('NEW STATUS OMS', payload)
      state.data = { ...state.data, status: payload.status }
    },
    SET_DATA (state, data) {
      state.data = { ...data }
    }
  },
  actions: {
    test ({ rootGetters, rootState }) {
      // console.log(rootGetters['app/platform'])
      // console.log(rootState)
    },
    async external ({ commit, rootGetters }, solution) {
      commit('RESET_DATA')

      commit('SET_LOADING', true)
      const response = await repository.authenticate({ external: 1, native: 0, platform: rootGetters['app/platform'], solution, browser: rootGetters['app/browser'] })
      commit('SET_LOADING', false)

      if ((response.status === 201) && response.data) {
        commit('SET_DATA', response.data)
      }
    },
    async internal ({ commit, rootGetters }, solution) {
      commit('RESET_DATA')

      commit('SET_LOADING', true)
      const response = await repository.authenticate({ external: 0, native: 1, platform: rootGetters['app/platform'], solution, browser: rootGetters['app/browser'] })
      commit('SET_LOADING', false)
      if ((response.status === 201) && response.data) {
        commit('SET_DATA', response.data)
      }
    },
    async setSession ({ commit }, session) {
      commit('SET_DATA', { session })
    },
    async setStatus ({ commit }, status) {
      commit('SET_DATA', { status })
    },
    // async status ({ commit }, isExternal = false) {
    //   commit('RESET_SWEDEN')

    //   commit('SET_LOADING', true)
    //   const response = await repository.statusInitiate({ external: (isExternal) ? 1 : 0 })
    //   commit('SET_LOADING', false)
    //   if ((response.status === 201) && response.data) {
    //     commit('SET_SWEDEN', response.data)
    //   }
    // },
    async verify ({ state, commit, rootGetters }, solution) {
      if (state.data) {
        const data = { reference: state.data.session, platform_key: rootGetters['app/platformKey'], solution }
        const response = await repository.verify(data)

        if ((response.status === 200) && response.data) {
          console.log('VERIFY', response.data)
          commit('SET_STATUS', response.data)
        }
      }
    }
  },
  namespaced: true
}
