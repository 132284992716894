import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('companies')

export default {
  state: {
    selectedCompanyId: null,
    companyAssets: {},
    loading: false,
    newLoading: false,
    editableId: null,
    alert: {
      title: '',
      create: false,
      body: '',
      type: '',
      showConfirmButton: false
    }
  },
  getters: {
    loading: state => state.loading,
    editableId: state => state.editableId,
    assets (state) {
      return state.companyAssets[state.selectedCompanyId]
    },
    newLoading: state => state.newLoading,
    alert: state => state.alert
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_NEW_LOADING (state, boolean) {
      state.newLoading = boolean
    },
    SET_LIST (state, payload) {
      state.companyAssets = { ...state.companyAssets, [state.selectedCompanyId]: payload }
    },
    APPEND_ASSETS (state, payload) {
      let assets = state.companyAssets[state.selectedCompanyId]
      payload.find(response => {
        const flag = assets.findIndex(asset => {
          return (response.id === asset.id)
        })
        if (flag > -1) {
          assets[flag] = response
        } else {
          assets.push(response)
        }
      })
      state.companyAssets = { ...state.companyAssets }
    },
    SET_EDITABLE_ID (state, id) {
      state.editableId = id
    },
    SET_ALERT (state, payload) {
      state.alert.title = payload.title
      state.alert.body = payload.body
      state.alert.create = payload.create
      state.alert.type = payload.type
      state.alert.showConfirmButton = payload.showConfirmButton
    },
    RESET_ALERT (state) {
      state.alert = {
        title: '',
        create: false,
        body: '',
        type: '',
        showConfirmButton: false
      }
    },
    PUSH_ASSETS (state, payload) {
      state.companyAssets = { ...state.companyAssets, [payload[0].company.id]: payload }
    },
    DELETE_ASSETS (state, id) {
      const index = state.companyAssets[state.selectedCompanyId].findIndex(asset => asset.id === id)
      state.companyAssets[state.selectedCompanyId].splice(index, 1)
      state.companyAssets = { ...state.companyAssets }
    }
  },
  actions: {
    async fetch ({ commit, dispatch, state, rootState }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        if (state.companyAssets[rootState.companies.selectedCompanyId] === undefined) {
          commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
          commit('SET_LOADING', true)
          const response = await repository.assetList(rootState.companies.selectedCompanySN)
          commit('SET_LOADING', false)
          if ((response.status === 200) && response.data) {
            commit('SET_LIST', response.data)
          }
        } else {
          dispatch('refetch')
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async save ({ commit, rootState, dispatch }, assetId) {
      try {
        const response = await repository.saveAsset(rootState.companies.selectedCompanyId, assetId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Asset added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pushAssets ({ commit, state }, payload) {
      commit('PUSH_ASSETS', [payload])
    },
    async refetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_NEW_LOADING', true)
        const response = await repository.assetList(rootState.companies.selectedCompanySN)
        commit('SET_NEW_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_NEW_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteRequest ({ state, rootState, dispatch }, assetId) {
      try {
        const response = await repository.assetDeleteRequest(rootState.companies.selectedCompanyId, assetId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Delete request added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    resetAlert ({ commit }) {
      commit('RESET_ALERT')
    },
    setAlert ({ commit }, payload) {
      commit('SET_ALERT', payload)
    },
    async delete ({ state, rootState, dispatch, commit }, assetId) {
      try {
        const response = await repository.assetDelete(rootState.companies.selectedCompanyId, assetId)
        if (response.status === 204) {
          commit('DELETE_ASSETS', assetId)
          // dispatch('snackbar/snack', { text: 'Delete request generated successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editAsset ({ commit, state, dispatch }, payload) {
      try {
        const companyId = payload.companyId ? payload.companyId : state.selectedCompanyId
        commit('SET_EDITABLE_ID', payload.assetId)
        const response = await repository.editAsset(companyId, payload.assetId, payload)
        if (response.status === 200) {
          commit('APPEND_ASSETS', [response.data])
          commit('SET_EDITABLE_ID', null)
        }
      } catch (e) {
        commit('SET_EDITABLE_ID', null)
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addAsset ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_NEW_LOADING', true)
        const response = await repository.addAsset(state.selectedCompanyId, payload)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Asset added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.companyAssets = {}
    }
  },
  namespaced: true
}
