<template>
  <div>
    <ModalContainer
      v-if="showModal"
      popup
      :padding="false"
      extra-class="rounded-lg shadow-none bg-white p-0 m-0"
    >
      <div class="h-144 flex flex-col space-y-10 ">
        <div class=" h-60">
          <div
            v-if="currentStep === 0"
            class="premium"
          >
            <div class="absolute flex items-center justify-center h-full w-full">
              <span class="z-50 text-3xl text-center text-white font-extrabold px-16">
                Hey! Welcome to Eucaps!
              </span>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-col items-center justify-center h-full w-full space-y-5 mb-2">
              <span class="text-3xl text-center text-custom-purple-500 font-extrabold px-16 mt-10">
                {{ steps[currentStep].name }}
              </span>
              <img
                :src="`/svg/intro-modal/${currentStep}.svg`"
              >
            </div>
          </div>
        </div>
        <div>
          <p class=" text-custom-purple-800 text-base font-semibold text-center px-20 ">
            {{ steps[currentStep].description }}
          </p>
        </div>
        <div class="flex flex-col justify-between items-center">
          <ActiveButton
            class="bg-custom-orange-400 rounded-xl mt-6 py-3 px-4"
            :class="currentStep === 0 ? '' : 'w-48'"
            @click="$emit('continue', $event)"
          >
            <span class="text-white font-semibold text-sm">
              {{ currentStep === 0 ? "Let's get to know the platform" : currentStep === 5? "Let's start exploring" : "Next step" }}
            </span>
          </ActiveButton>
          <span
            class=" font-semibold mt-3"
            :class="currentStep === 0 ? 'text-white' : ' text-gray-400'"
          >
            {{ currentStep }}/5
          </span>
        </div>
        <div class="bg-custom-purple-50 h-16 flex justify-center items-center border-t-2">
          <BaseButton
            class="text-sm font-bold text-custom-lightPurple-400 cursor-pointer"
            @click="$emit('skip', $event)"
          >
            Skip Please
          </BaseButton>
        </div>
      </div>
    </ModalContainer>
  </div>
</template>

<script>
import ModalContainer from '@/modules/modal'

export default {
  name: 'UsageModal',
  components: {
    ModalContainer
  },
  props: {
    currentStep: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: () => []
    },
    showModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.premium {
  overflow: hidden;
  background: transparent;
  background: linear-gradient(90deg, #280538 30%, #4a2e45 60%);
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}
.premium:before,
.premium:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}
.premium:before {
  background: transparent;
  box-shadow: 0 0 160px 180px #24684f;
  z-index: 0;
  top: -100%;
  opacity: 0.8;
  left: -72%;
  opacity: 50%;
}
</style>
