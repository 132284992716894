import { RepositoryFactory } from '@/api/repositoryFactory'
// import { i18n } from '@/js/language/'
const repository = RepositoryFactory.get('featuredvideos')

export default {
  state: {
    loading: false,
    loadingVideo: false,
    videos: [],
    loadingVideoDetails: false,
    nextPageUrl: null,
    addingVideo: false,
    selectedVideo: null,
    scrollLoading: false
  },
  getters: {
    loading: state => state.loading,
    loadingVideo: state => state.loadingVideo,
    addingVideo: state => state.addingVideo,
    videos: state => state.videos,
    loadingVideoDetails: state => state.loadingVideoDetails,
    nextPageUrl: state => state.nextPageUrl,
    selectedVideo: state => state.selectedVideo,
    scrollLoading: state => state.scrollLoading
  },
  mutations: {
    SET_LOADING_VIDEO_DETAILS (state, boolean) {
      state.loadingVideoDetails = boolean
    },
    LOADING_VIDEO (state, boolean) {
      state.loading = boolean
    },
    ADDING_VIDEO (state, boolean) {
      state.addingVideo = boolean
    },
    NEXT_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    SET_LOADING_VIDEO (state, boolean) {
      state.loadingVideo = boolean
    },
    SET_SELECTED_VIDEO (state, payload) {
      state.selectedVideo = payload
    },
    SET_LOADING_NEW (state, boolean) {
      state.scrollLoading = boolean
    },
    SET_FEATURED_VIDEOS (state, payload) {
      state.videos = payload
    },
    APPEND_FEATURED_VIDEOS (state, payload) {
      if (state.videos.length === 0) {
        state.videos = payload
      } else {
        payload.find(response => {
          const flag = state.videos.findIndex(video => {
            return (response.id === video.id)
          })
          if (flag === -1) {
            state.videos.push(response)
          } else {
            state.videos[flag] = response
          }
        })
      }

      state.videos = [ ...state.videos ]
    }
  },
  actions: {
    async scroll ({ commit, state, dispatch }) {
      try {
        if (state.nextPageUrl !== null && !state.scrollLoading) {
          commit('SET_LOADING_NEW', true)
          const response = await repository.scroll(state.nextPageUrl)
          if ((response.status === 200) && response.data) {
            commit('APPEND_FEATURED_VIDEOS', response.data.data)
            commit('NEXT_PAGE_URL', response.data.next_page_url)
          }
          commit('SET_LOADING_NEW', false)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    selectVideo ({ commit }, payload) {
      commit('SET_SELECTED_VIDEO', payload)
    },
    async getVideoDetails ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING_VIDEO_DETAILS', true)
        const response = await repository.fetchVideoDetails(payload)
        return response.data
      } catch (e) {
        commit('SET_LOADING_VIDEO_DETAILS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchVideos ({ commit, dispatch }) {
      try {
        commit('SET_LOADING_VIDEO', true)
        const response = await repository.fetchVideos()
        if (response.status === 200 && response.data) {
          commit('SET_FEATURED_VIDEOS', response.data.data)
          commit('NEXT_PAGE_URL', response.data.next_page_url)
        }
        commit('SET_LOADING_VIDEO', false)
      } catch (e) {
        commit('SET_LOADING_VIDEO', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addVideo ({ commit, dispatch, rootState }, payload) {
      try {
        commit('ADDING_VIDEO', true)
        const response = await repository.addVideo(rootState.companies.selectedCompanySN, payload)
        if (response.status === 200 && response.data) {
          dispatch('companies/appendFeaturedVideos', response.data, { root: true })
          commit('ADDING_VIDEO', false)
          return response.data
        }
      } catch (e) {
        commit('LOADING_VIDEO', false)
        commit('ADDING_VIDEO', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editVideo ({ commit, dispatch, rootState }, payload) {
      try {
        commit('ADDING_VIDEO', true)
        const response = await repository.editVideo(payload.video.id, payload)
        if (response.status === 200 && response.data) {
          dispatch('companies/updateFeaturedVideos', payload, { root: true })
          commit('ADDING_VIDEO', false)

          return response.data
        }
      } catch (e) {
        commit('LOADING_VIDEO', false)
        commit('ADDING_VIDEO', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteVideo ({ commit, dispatch, rootState }, payload) {
      try {
        commit('SET_LOADING_VIDEO', true)
        const response = await repository.deleteVideo(payload.id)
        if (response.status === 204) {
          dispatch('companies/deleteFeaturedVideos', payload, { root: true })
          commit('SET_LOADING_VIDEO', false)
          return response.data
        }
      } catch (e) {
        commit('SET_LOADING_VIDEO', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
