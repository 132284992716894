<template>
  <VersionBanner
    v-show="!maintenance && newVersionAvailable"
    :padding="globalPadding"
    @click="goToAxyer"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import VersionBanner from './_components/VersionBanner'
import headerPaddingMixin from '@/mixins/headerPaddingMixin'

export default {
  name: 'VersionBannerModule',
  components: {
    VersionBanner
  },
  mixins: [headerPaddingMixin],
  props: {
    padding: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      newVersionAvailable: 'app/newVersionAvailable',
      maintenance: 'app/maintenance'
    }),
    globalPadding () {
      return this.platformPadding
    }
  },
  methods: {
    goToAxyer () {
      window.open('https://get.axyer.com')
    }
  }
}
</script>
