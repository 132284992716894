import usersRepository from './real/users'
import postsRepository from './real/posts'
import notificationsRepository from './real/notifications'
import companiesRepository from './real/companies'
import chatRepository from './real/chats'
import highscoresRepository from './real/highscores'
import insidersRepository from './real/insiders'
import verificationsRepository from './real/verifications'
import messagesRepository from './real/messages'
import questionsRepository from './real/questions'
import pressReleaseRepository from './real/pressRelease'
import achievementsRepository from './real/achievements'
import helpCenterRepository from './real/helpcenter'
import metaRepository from './real/meta'
import incidentsRepository from './real/incidents'
import appRepository from './real/app'
import likesRepository from './real/likes'
import strategiesRepository from './real/strategies'
import socialRepository from './real/social'
import businessDetailsRepository from './real/businessDetails'
import countriesRepository from './real/countries'
import advertisesRepository from './real/advertises'
import premiumsRepository from './real/premiums'
import holdersRepository from './real/holders'
import comparablesRepository from './real/comparables'
import featuredvideosRepository from './real/featuredvideos'
import blogsRepository from './real/blogs'
import listsRepository from './real/lists'
import mentionsRepository from './real/mentions'
import screenersRepository from './real/screeners'
import exchangesRepository from './real/exchanges'
import financialsRepository from './real/financials'
import heyEucapsRepository from './mock/heyEucaps'
import notepadRepository from './real/notepad'
import devcodeRepository from './real/devcode'
import truliooRepository from './real/trulioo'
import paymentsRepository from './real/payments'
import questionAnswersRepository from './real/questionAnswers'
import pinAdsRepository from './real/pinads'
import pollsRepository from './real/polls'
import contactRepository from './mock/contact'
import sectorsRepository from './real/sectors'
import creditCheckRepository from './real/creditCheck'
import allowedRoutesRepository from './real/allowedRoutes'
import eventsCalenderRepository from './real/eventsCalender'

const repositories = {
  trulioo: truliooRepository,
  heyEucaps: heyEucapsRepository,
  users: usersRepository,
  polls: pollsRepository,
  devcode: devcodeRepository,
  notepad: notepadRepository,
  exchanges: exchangesRepository,
  pinAds: pinAdsRepository,
  screeners: screenersRepository,
  mentions: mentionsRepository,
  lists: listsRepository,
  app: appRepository,
  likes: likesRepository,
  strategies: strategiesRepository,
  businessDetails: businessDetailsRepository,
  social: socialRepository,
  posts: postsRepository,
  companies: companiesRepository,
  notifications: notificationsRepository,
  chats: chatRepository,
  verifications: verificationsRepository,
  pressRelease: pressReleaseRepository,
  highscores: highscoresRepository,
  insiders: insidersRepository,
  messages: messagesRepository,
  questions: questionsRepository,
  achievements: achievementsRepository,
  helpcenter: helpCenterRepository,
  meta: metaRepository,
  incidents: incidentsRepository,
  advertises: advertisesRepository,
  featuredvideos: featuredvideosRepository,
  blogs: blogsRepository,
  premiums: premiumsRepository,
  comparables: comparablesRepository,
  holders: holdersRepository,
  countries: countriesRepository,
  financials: financialsRepository,
  sectors: sectorsRepository,
  payments: paymentsRepository,
  questionAnswers: questionAnswersRepository,
  creditCheck: creditCheckRepository,
  contact: contactRepository,
  allowedRoutes: allowedRoutesRepository,
  eventsCalender: eventsCalenderRepository
}

export const RepositoryFactory = {
  get: (name) => repositories[name]
}

export default RepositoryFactory
