var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.position,
    _vm.orange ? 'bg-custom-orange-400' : '',
    _vm.green ? 'bg-custom-green-100' : '',
    _vm.greenLight ? 'bg-custom-green-300' : '',
    _vm.purple ? 'bg-custom-purple-500' : '',
    _vm.yellow ? 'bg-custom-yellow-300' : '',
    _vm.purpleMedium ? 'bg-custom-purple-300' : '',
    _vm.purpleLight ? 'bg-custom-purple-200' : '',
    _vm.purpleLighter ? 'bg-custom-purple-100' : '',
    _vm.plum ? 'bg-custom-purple-500' : '',
    _vm.plumLight ? 'bg-custom-purple-400' : '',
    _vm.col ? 'col-span-'+_vm.col : '',
    _vm.row ? 'row-span-'+_vm.row : '' ]},[(_vm.src)?_c('div',{staticClass:"absolute inset-0 bg-cover bg-center",style:('background-image: url(' + _vm.src + ')')}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }