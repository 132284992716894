<template>
  <portal
    v-if="visible"
    to="dialog"
  >
    <FadeTransition
      :key="title"
      :duration="50"
    >
      <div
        v-show="visible"
        @toggle="toggle"
      >
        <div
          class="modal-overlay absolute w-full h-full bg-white dark:bg-custom-purple-600"
          @click="toggle"
        />

        <div

          class="modal-container relative dark:bg-custom-purple-500 dark:text-primary-dark bg-white w-full md:max-w-md mx-auto shadow-lg dark:shadow-lg-dark z-50 h-full overflow-y-auto"
        >
          <div
            v-if="!hideClose"
            class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
            @click="toggle"
          >
            <svg
              class="fill-current text-custom-purple-300"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
            <span class="text-sm text-custom-purple-300">{{ $t('(Esc)') }}</span>
          </div>
          <SlideYDownTransition
            tag="div"
            class="absolute inset-0 flex justify-center items-top z-10"
            group
            :duration="200"
            @click.native="toggle"
          >
            <!-- Add margin if you want to see some of the overlay behind the modal-->
            <div
              v-show="show"
              key="modal-content"
              class="modal-content w-full py-4 text-left pt-16 px-6"
              @click.stop
            >
              <!--Title-->
              <div class="flex justify-between items-center pb-3">
                <slot name="title">
                  <p class="text-2xl font-bold">
                    {{ title }}
                  </p>
                </slot>
              </div>

              <!--Body-->
              <slot>
                <p>{{ $t("Modal content can go here") }}</p>
                <p>...</p>
                <p>...</p>
                <p>...</p>
                <p>...</p>
              </slot>
              <div class="flex justify-end pt-2">
                <slot name="footer" />
              </div>
            </div>
          </SlideYDownTransition>
        </div>
      </div>
    </FadeTransition>
  </portal>
</template>

<script>

import { SlideYDownTransition, FadeTransition } from 'vue2-transitions'

import hashPushMixin from '@/mixins/hashPushMixin'

export default {
  name: 'ModalFullSize',
  components: {
    SlideYDownTransition,
    FadeTransition
  },
  mixins: [
    hashPushMixin
  ],
  props: {
    title: { type: String, default: '' },
    hideClose: { type: Boolean, default: false },
    show: { type: Boolean, default: false }
  },
  watch: {
    show (newVal) {
      if (newVal) {
        this.$emit('focus')
        this.pushRandomHashUrl()
      } else {
        console.log('FULLSIZE SHOW FALSE')
        this.resetRandomHashUrl()
      }
    }
  },
  mounted () {
  },
  methods: {
    toggle () {
      this.$emit('toggle')
    }
  }
}
</script>
