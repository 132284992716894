import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      company: 'companies/selectedCompany',
      user: 'users/user'
    }),
    isGuest () {
      return (!this.user || !this.user.profile || this.user.profile_type === 'App\\Models\\GuestUser' || this.user.profile.profile_type === 'App\\Models\\GuestUser')
    },
    isVerified () {
      return (!this.isGuest && this.user && this.user.profile && this.user.profile.is_verified)
    },
    isModerator () {
      if (!this.user.profile) return false
      if (!this.user.profile.role_list) return false
      return this.user.profile.role_list.includes('moderator')
    },
    isAdmin () {
      if (!this.user) return false
      if (!this.user.role_list) return false
      return this.user.role_list.includes('admin')
    },
    isCompanyUser () {
      if (!this.user) return false
      if (!this.user.role_list) return false
      if (!this.company) return false
      return this.user.role_list.includes(this.company.slug_url.toLowerCase())
    },
    isMarketer () {
      if (!this.user) return false
      if (!this.user.role_list) return false
      return this.user.role_list.includes('marketer')
    }
  }
}
