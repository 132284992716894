export default {
  linkable (text) {
    if (!text) return { links: [], newText: null }

    const regex = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
    const links = text.match(regex)

    const newText = text.replace(regex, function (url) {
      return '<a target="_blank" class="text-custom-orange-400 break-all" href="' + url + '">' + url + '</a>'
    })

    return {
      links,
      newText
    }
  }
}
