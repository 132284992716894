<template>
  <ModalContainer
    v-if="emoji"
    only-back-with-toggle
    @toggle="$emit('toggle')"
    @on-back-button="$emit('on-back-button')"
  >
    <Picker
      title="Pick your emoji..."
      :data="emojiIndex"
      emoji="point_up"
      :emoji-size="36"
      class="dimension"
      set="apple"
      @select="$emit('add-emoji',$event)"
    />
  </ModalContainer>
</template>

<script>
import data from 'emoji-mart-vue-fast/data/all.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import ModalContainer from '@/modules/modal'

let emojiIndex = new EmojiIndex(data)

export default {
  name: 'EmojiPicker',
  components: {
    Picker,
    ModalContainer
  },
  props: {
    emoji: { type: Boolean, default: false }
  },
  data () {
    return {
      emojiIndex: emojiIndex
    }
  }
}
</script>

<style lang="scss">
  .dimension {
    width: 100% !important;
    height: 550px !important;
  }
  .row { display: flex; }
  .row > * { margin: auto; }

.dark .emoji-mart {
  @apply bg-custom-purple-500 border-custom-purple-500;

  .emoji-mart-bar {
    @apply border-custom-purple-500;
  }

  input {
    @apply bg-custom-purple-500 border-custom-purple-400;
  }

  .emoji-mart-category-label h3 {
    @apply bg-custom-purple-500 border-custom-purple-400 text-primary-dark;
  }
}
</style>
