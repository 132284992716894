<template>
  <button
    class="flex items-center bg-none font-semibold focus:outline-none"
    @click="$emit('follow')"
  >
    <p
      v-if="text"
      class="text-xs flex dark:text-custom-purple-100 flex-col flex-shrink-0 text-right"
    >
      <span class="text-2xs">{{ isFollowed ? $t('Unfollow') : $t('Follow') }}</span>
    </p>
    <Icon
      class="ml-1 text-xl dark:text-custom-purple-100"
      :class="margins ? 'mr-2' : ''"
      :solid="isFollowed"
      name="heart"
    />
  </button>
</template>

<script>

export default {
  name: 'CompanyFollow',
  props: {
    isFollowed: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    margins: {
      type: Boolean,
      default: false
    }
  }
}
</script>
