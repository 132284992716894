<template>
  <ListItem class="mt-4">
    <ListItemContent
      v-for="menu in menus"
      :key="menu.id"
    >
      <button
        :disabled="locked"
        class="focus:outline-none"
        @click="$emit('selected-menu', { action: menu.action })"
      >
        {{ menu.name }}
      </button>
    </ListItemContent>
    <slot />
  </ListItem>
</template>

<script>

export default {
  name: 'ListMenu',
  props: {
    menus: { type: Array, default: () => [] },
    locked: { type: Boolean, default: false }
  }
}
</script>
