import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('financials')

export default {
  state: {
    selectedMnemonics: {
      1: [
        'IQ_TOTAL_REV',
        'IQ_NI',
        'FORMULA_EBITDA_YOY'
      ],
      3: [
        'IQ_TOTAL_RECEIV',
        'IQ_TOTAL_ASSETS',
        'IQ_TOTAL_CL',
        'IQ_TOTAL_LIAB',
        'IQ_TOTAL_DEBT',
        'IQ_TOTAL_EQUITY',
        'IQ_TOTAL_LIAB_EQUITY'
      ],
      5: [
        'IQ_CASH_OPER',
        'IQ_CASH_INVEST',
        'IQ_CASH_FINAN',
        'IQ_NET_CHANGE'
      ]
    },
    loading: false,
    filterList: [],
    filterListLoading: false,
    selectedGroupsKey: 1,
    selectedPeriodCode: 'annual',
    groupList: {
      1: [1, 2],
      3: [3, 4],
      5: [5]
    },
    periods: [
      {
        code: 'annual'
      },
      {
        code: 'quarterly'
      }
    ]
  },
  getters: {
    filterList: state => state.filterList,
    filterListLoading: state => state.filterListLoading,
    selectedMnemonics (state) {
      let selectedMnemonics = [...state.selectedMnemonics[state.selectedGroupsKey]]

      return selectedMnemonics
    },
    selectedGroups (state) {
      let groupList = [...state.groupList[state.selectedGroupsKey]]

      return groupList
    },
    loading: state => state.loading,
    periods: state => state.periods,
    selectedPeriodCode: state => state.selectedPeriodCode
  },
  mutations: {
    SET_FILTER_LIST (state, payload) {
      state.filterList = payload
    },
    SET_FILTER_LIST_LOADING (state, boolean) {
      state.filterListLoading = boolean
    },
    SET_DATA (state, selectedMnemonics) {
      state.selectedMnemonics[state.selectedGroupsKey] = [...selectedMnemonics]
      localStorage.selectedMnemonics = JSON.stringify(selectedMnemonics)
      state.selectedMnemonics = { ...state.selectedMnemonics }
    },
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    SELECT_GROUP (state, groupId) {
      localStorage.selectedGroupsKey = groupId
      state.selectedGroupsKey = groupId
      localStorage.selectedMnemonics = JSON.stringify(state.selectedMnemonics[state.selectedGroupsKey])
    },
    SELECT_PERIOD (state, periodCode) {
      localStorage.selectedPeriodCode = periodCode
      state.selectedPeriodCode = periodCode
    }
  },
  actions: {
    async setMyFilters ({ commit, dispatch }, payload) {
      console.log('payload: ', payload)
      const selectedGroups = JSON.parse(payload.financial_groups)
      const selectedLabels = JSON.parse(payload.financial_labels)

      console.log('selected group : ', selectedGroups[0])
      console.log('selected lables : ', selectedLabels)

      await commit('SELECT_GROUP', selectedGroups[0])
      await commit('SET_DATA', selectedLabels)
    },
    async fetchCompanyFilters ({ commit, dispatch }) {
      try {
        const response = await repository.fetchFilters()
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async saveFilters ({ commit, dispatch, getters }, payload) {
      try {
        const response = await repository.saveFilters(payload)
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
          dispatch('snackbar/snack', { type: 'success', text: 'filters saved successfully', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteFilter ({ commit, dispatch }, filterId) {
      try {
        commit('SET_FILTER_LIST_LOADING', true)
        const response = await repository.deleteFilter(filterId)
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
        }
        commit('SET_FILTER_LIST_LOADING', false)
      } catch (e) {
        commit('SET_FILTER_LIST_LOADING', false)

        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async updateFilters ({ commit, dispatch, getters }, payload) {
      try {
        const response = await repository.updateFilters(payload.filterId, payload)
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
          dispatch('snackbar/snack', { type: 'success', text: 'filters saved successfully', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async setSelectedMnemonics ({ state, commit }, data) {
      commit('SET_DATA', data)
    },
    async selectGroup ({ state, commit }, groupId) {
      commit('SELECT_GROUP', groupId)
    },
    async selectPeriod ({ state, commit }, periodCode) {
      commit('SELECT_PERIOD', periodCode)
    },
    async toggleSelectedMnemonics ({ state, commit }, payload) {
      let data = state.selectedMnemonics[state.selectedGroupsKey]
      const index = data.findIndex(data => data === payload)
      if (index > -1) {
        data.splice(index, 1)
      } else {
        data.push(payload)
      }
      commit('SET_DATA', data)
    },
    async downloadFile ({ dispatch }, payload) {
      try {
        const fileExtension = payload.fileName.slice(payload.fileName.lastIndexOf('.') + 1)
        const response = await repository.downloadDocument(payload.fileName)
        if (response.data && fileExtension === 'pdf') {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${payload.fileName}`
          link.click()
          link.remove()
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
