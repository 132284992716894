import { initializeApp } from 'firebase/app'
// import { getAuth, onAuthStateChanged } from 'firebase/auth'
// import { getMessaging, getToken, onMessage } from 'firebase/messaging'

// import { FirebaseAuthentication } from '@robingenz/capacitor-firebase-authentication'
import PushNotifications from './firebase-native'
import store from './store'
// import { Capacitor } from '@capacitor/core'
// import store from '@/js/store'
// const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications')

const firebaseConfig = {
  apiKey: 'AIzaSyCnlvfcFQfY-UOta_8zoKOobkVHtbBNvbI',
  authDomain: 'axyer-c30d7.firebaseapp.com',
  projectId: 'axyer-c30d7',
  storageBucket: 'axyer-c30d7.appspot.com',
  messagingSenderId: '1052147864723',
  appId: '1:1052147864723:web:3d4fc452310b9a756e2966',
  measurementId: 'G-M4YSV9ZESY'
}

const firebase = initializeApp(firebaseConfig)

// const auth = getAuth()
// TODO FIX MESSAGES
console.log('---------------firebase 1---------------')

// const publicKey = 'BFgvcVcWO7lqubKIhhyx4ck7M1j9e_1vLt_hd-VO8kSTcv3u4nU3CvzQhqqvOzEjUMPg2-5slvYkBavanZokJtw'

async function setTokenAtLocalStorage () {
  if (store.getters['app/isNative']) {
    PushNotifications.checkPermissions()
  }
  // if (isPushNotificationsAvailable) {
  // onMessage(messaging, (payload) => {
  //   const noteTitle = payload.notification.title
  //   const noteOptions = {
  //     body: payload.notification.body,
  //     icon: payload.notification.icon
  //   }
  //   console.log(payload)

  //   Notification(noteTitle, noteOptions)
  // })
  // }

  // export const googleFirebaseLogin = async () => {
  //   let provider = new GoogleAuthProvider()

  //   let info = await Device.getInfo()

  //   if (info.platform === 'web') {
  //     return signInWithPopup(auth, provider)
  //       .then((result) => {
  //         console.log(result)
  //       }).catch((error) => {
  //         // Handle Errors here.
  //         console.log('error', error)
  //         const credential = GoogleAuthProvider.credentialFromError(error)
  //         console.log('error credential', credential)
  //       })
  //   } else {
  //     return FirebaseAuthentication.signInWithGoogle().then((result) => {
  //       console.log(result)
  //       result.user.photoURL = result.user.photoUrl
  //       registerFirebaseUser(result.user)
  //     }).catch((error) => {
  //       // Handle Errors here.
  //       console.log('signInWithGoogle error', error)
  //     })
  //   }
  // }

  // export const facebookFirebaseLogin = async () => {
  //   let provider = new FacebookAuthProvider()
  //   let info = await Device.getInfo()
  //   console.log('info.platform', info.platform)
  //   if (info.platform === 'web') {
  //     return signInWithPopup(auth, provider)
  //       .then((result) => {
  //         console.log(result)
  //       }).catch((error) => {
  //         // Handle Errors here.
  //         console.log('error', error)
  //         const credential = FacebookAuthProvider.credentialFromError(error)
  //         console.log('error credential', credential)
  //       })
  //   } else {
  //     console.log('v signInWithFacebook')

  //     return FirebaseAuthentication.signInWithFacebook().then((result) => {
  //       console.log(result)
  //       result.user.photoURL = result.user.photoUrl
  //       registerFirebaseUser(result.user)
  //     }).catch((error) => {
  //       // Handle Errors here.
  //       console.log('signInWithFacebook error', error)
  //     })
  //   }
  // }
  // export const firebaseLogout = () => {
  //   return signOut(auth)
  //     .then((result) => {
  //       console.log(result)
  //     }).catch((error) => {
  //       console.log('error', error)
  //       const credential = FacebookAuthProvider.credentialFromError(error)
  //       console.log('error credential', credential)
  //     })
  // }
}

setTokenAtLocalStorage()

export default { firebase }
