<template>
  <BaseButton
    class="rounded-xl bg-green-300 text-gray-900 text-xs p-4"
    :no-padding="true"
    :no-shadow="true"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'BuyButton'
}
</script>
