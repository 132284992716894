import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('payments')

export default {
  state: {
    loading: false,
    paymentMethods: [],
    subscriptions: [],
    loadingSubscriptions: false,
    selectedSubscription: null
  },
  getters: {
    paymentMethods: (state) => state.paymentMethods,
    loading: (state) => state.loading,
    subscriptions: (state) => state.subscriptions,
    companySubscriptions: (state) => {
      if (!state.subscriptions || state.subscriptions.length === 0) {
        return []
      }
      let payload = [...state.subscriptions]
      payload.forEach(sub => {
        if (state.paymentMethods.length > 0) {
          const findPaymentMethod = state.paymentMethods.find(payment => payment.id === sub.default_payment_method)
          if (findPaymentMethod) {
            sub.payment_method = findPaymentMethod
          } else {
            sub.payment_method = null
          }
        } else {
          sub.payment_method = null
        }
      })
      return payload
    },
    loadingSubscriptions: (state) => state.loadingSubscriptions,
    selectedSubscription: (state) => state.selectedSubscription
  },
  mutations: {
    SET_SELECTED_SUBSCRIPTION (state, payload) {
      state.selectedSubscription = payload
    },
    SET_SUBSCRIPTION_LIST (state, payload) {
      if (payload.length === 0) {
        state.subscriptions = payload
      } else {
        payload.forEach(data => {
          // if (state.paymentMethods.length > 0) {
          //   const findPaymentMethod = state.paymentMethods.find(payment => payment.id === data.default_payment_method)
          //   if (findPaymentMethod) {
          //     data.payment_method = findPaymentMethod
          //   }
          // } else {
          //   data.payment_method = null
          // }
          data.show_pupup = false
        })
        state.subscriptions = payload
      }
    },
    UPDATE_SUBSCRIPTION (state, payload) {
      // if (state.paymentMethods.length > 0) {
      //   const findPaymentMethod = state.paymentMethods.find(payment => payment.id === payload.default_payment_method)
      //   if (findPaymentMethod) {
      //     payload.payment_method = findPaymentMethod
      //   }
      // } else {
      //   payload.payment_method = null
      // }
      const index = state.subscriptions.findIndex(s => s.id === payload.id)
      if (index > -1) {
        payload.show_popup = (payload.show_popup !== undefined) ? !payload.show_popup : false
        state.subscriptions[index] = payload
      } else {
        payload.show_popup = false
        state.subscriptions.push(payload)
      }

      state.subscriptions = [...state.subscriptions]
    },
    SET_LIST (state, payload) {
      state.paymentMethods = payload
    },
    SET_LOADING_SUBSCRIPTIONS (state, boolean) {
      state.loadingSubscriptions = boolean
    },
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    APPEND_LIST (state, payload) {
      state.paymentMethods.push(payload)
    }
  },
  actions: {
    async fetchSubscriptions ({ commit, dispatch }, companyId) {
      try {
        commit('SET_LOADING_SUBSCRIPTIONS', true)
        const response = await repository.fetchSubscriptions(companyId)
        commit('SET_LOADING_SUBSCRIPTIONS', false)
        commit('SET_SUBSCRIPTION_LIST', response.data)
      } catch (e) {
        commit('SET_LOADING_SUBSCRIPTIONS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async setDefaultPaymentMethod ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.setDefaultPaymentMethod(payload.subscriptionId, { payment_method_id: payload.paymentMethodId })
        commit('SET_LOADING', false)
        commit('UPDATE_SUBSCRIPTION', response.data)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    setSelectedSubscription ({ commit }, payload) {
      commit('SET_SELECTED_SUBSCRIPTION', payload)
    },
    async cancelCompanySubscription ({ commit, dispatch }, subscription) {
      try {
        commit('SET_LOADING_SUBSCRIPTIONS', true)
        const response = await repository.cancelCompanySubscription(subscription.id)
        commit('SET_LOADING_SUBSCRIPTIONS', false)
        commit('UPDATE_SUBSCRIPTION', response.data)
      } catch (e) {
        commit('SET_LOADING_SUBSCRIPTIONS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    showPopups ({ commit, dispatch }, payload) {
      commit('UPDATE_SUBSCRIPTION', payload)
    },
    async fetchPaymentMethods ({ commit, dispatch }) {
      try {
        // commit('SET_LOADING', true)
        const response = await repository.getPaymentMethods()
        // commit('SET_LOADING', false)
        commit('SET_LIST', response.data)
      } catch (e) {
        // commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchCompanyPaymentMethods ({ commit, dispatch, getters }, id) {
      try {
        commit('SET_LOADING_SUBSCRIPTIONS', true)
        const response = await repository.getCompanyPaymentMethods(id)
        commit('SET_LIST', response.data)
        commit('SET_LOADING_SUBSCRIPTIONS', false)
      } catch (e) {
        commit('SET_LOADING_SUBSCRIPTIONS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
