import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('meta')

export default {
  state: {
    links: {}
  },
  getters: {
    links: state => state.links
  },
  mutations: {
    SET_LINK (state, payload) {
      state.links = { ...state.links, [payload.link]: { ...payload.data[payload.link], loading: false } }
    },
    SET_LOADING (state, payload) {
      state.links = { ...state.links, [payload.link]: { ...payload.link, loading: payload.loading } }
    }
  },
  actions: {
    async check ({ commit }, url) {
      // commit('SET_LINK', { link: url })
      commit('SET_LOADING', { link: url, loading: true })

      const response = await repository.check({ links: [url] })

      commit('SET_LOADING', { link: url, loading: false })
      if ((response.status === 200) && response.data) {
        commit('SET_LINK', { link: url, data: response.data })
      }
    }
  },
  namespaced: true
}
