import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('exchanges')

export default {
  state: {
    exchanges: [],
    loading: false
  },
  getters: {
    loading: state => state.loading,
    exchanges: state => state.exchanges
  },
  mutations: {
    SET_EXCHANGES (state, payload) {
      state.exchanges = [...payload]
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    }
  },
  actions: {
    async fetch ({ commit, dispatch, state }) {
      try {
        if (state.exchanges.length === 0) {
          commit('SET_LOADING', true)
          const response = await repository.fetch()
          commit('SET_LOADING', false)
          if ((response.status === 200) && response.data) {
            commit('SET_EXCHANGES', response.data)
          }
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
