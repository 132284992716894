<template>
  <portal
    v-if="visible"
    to="dialog"
  >
    <ModalOuter
      :key="'modal' + modalHashId"
      :right="right"
      @toggle="toggle"
    >
      <ModalOverlay
        :theme="theme"
        @click="toggle"
      />
      <div
        v-if="!fullSize"
        class="w-full absolute bottom-0 flex justify-end"
        :class="getHeight()"
      >
        <Esc
          :hide-close="hideClose"
          position="-mt-12"
          @toggle="toggle"
        />
      </div>
      <ModalContainer
        :height="getHeight()"
        :width="fullWidth ? 'w-full' : 'w-full md:max-w-md'"
        :position="position"
        :extra-class="extraClass"
        :class="platformPadding"
      >
        <Esc
          v-if="fullSize"
          hide-text
          :hide-close="hideClose"
          position="right-0 mr-4 fullsize-esc"
          @toggle="toggle"
        />
        <ModalContent
          :padding="padding"
          :modal-class="modalContentClass"
        >
          <ModalHeader :title="title" />
          <slot />
          <ModalFooter
            v-if="useFooter"
            :padding="padding"
          />
        </ModalContent>
      </ModalContainer>
    </ModalOuter>
  </portal>
</template>

<script>

import ModalOuter from './_components/ModalOuter'
import ModalOverlay from './_components/ModalOverlay'
import ModalContainer from './_components/ModalContainer'
import ModalContent from './_components/ModalContent'
import ModalHeader from './_components/ModalHeader'
import ModalFooter from './_components/ModalFooter'
import Esc from './_components/Esc'
// import random from '@/js/helpers/password'
import { mapGetters } from 'vuex'
// import eventBus from '../../js/eventbus'

import headerPaddingMixin from '@/mixins/headerPaddingMixin'

export default {
  name: 'ModalModule',
  components: {
    ModalOuter,
    ModalOverlay,
    ModalContainer,
    ModalContent,
    ModalHeader,
    ModalFooter,
    Esc
  },
  mixins: [headerPaddingMixin],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    fullSize: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    profileImgHeight: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Boolean,
      default: true
    },
    modalContentClass: {
      type: String,
      default: ''
    },
    extraClass: {
      type: String,
      default: ''
    },
    popup: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    onlyBackWithToggle: {
      type: Boolean,
      default: false
    },
    disableClose: {
      type: Boolean,
      default: false
    },
    useFooter: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modalHashId: '',
      visibleUrl: this.url,
      toggleClicked: false,
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      activeModals: 'modals/active',
      fixedHash: 'app/fixedHash',
      theme: 'users/activeTheme',
      showVerificationModal: 'users/showVerificationModal'
    }),
    // visible () {
    //   return this.activeModals.filter(activeUrls => activeUrls === this.visibleUrl).length > 0
    // },
    position () {
      if (this.popup) {
        return ''
      }
      return 'absolute bottom-0'
    }
  },
  mounted () {
    this.$emit('focus')
    this.temporaryFixWithoutHash()
    let body = document.body
    body.classList.add('overflow-hidden')
    // eventBus.$on('on-go-back', () => {
    //   // console.log('CAN WE GO BACK FROM MODAL?')
    //   this.$store.dispatch('modals/removeLatestModal')
    //   this.$emit('on-back-button')
    // })

    // eventBus.$on('disable-back-button', () => {
    //   console.log('DISABLE GO BACK')
    //   // console.log('CAN WE GO BACK FROM MODAL?')
    //   this.$store.dispatch('modals/removeLatestModal')
    // })

    // RULE: ALL TOGGLE v-if SHALL BE OUTSIDE, THEN WE DONT NEED show INSIDE
    // console.log('MODAL CONTAINER MOUNTED')
    // this.pushRandomHashUrl()
  },
  beforeDestroy () {
    // console.log('MODAL CONTAINER DESTROY')
    let body = document.body
    body.classList.remove('overflow-hidden')
    // if (this.onlyBackWithToggle) {
    //   console.log('ONLY BACK ON TOGGLE')
    //   if (this.toggleClicked) {
    //     console.log('TOGGLE CLICKED TRUE')
    //     this.resetRandomHashUrl()
    //   }
    // } else {
    //   console.log('NO TOGGELING')
    //   if (this.toggleClicked) {
    //   } else {
    //     this.resetRandomHashUrl()
    //   }
    // }
  },
  methods: {
    temporaryFixWithoutHash () {
      this.visible = true
      this.modalHashId = this.fixedHash + this.random(30)
    },
    toggle () {
      document.body.classList.remove('overflow-hidden')

      if (this.disableClose) return
      this.visible = false

      this.toggleClicked = true
      this.$emit('toggle')
    },
    getHeight () {
      if (this.popup) {
        return ''
      }
      if (this.fullSize) {
        return 'h-full'
      }
      if (this.profileImgHeight) {
        return 'h-auto'
      }

      return 'h-3/4'
    },
    pushRandomHashUrl () {
      if (!this.url) {
        this.modalHashId = this.fixedHash + this.random(30)
        this.visibleUrl = this.$route.path + '/' + this.modalHashId
      }

      this.$emit('visible-url', this.visibleUrl)
      this.$store.dispatch('modals/newModal', this.visibleUrl)
      history.pushState({}, null, this.visibleUrl)
    },
    resetRandomHashUrl () {
      if (this.visible) {
        // console.log('YEAH IT IS VISIBLE, GO BACK')
        history.go(-1)
      }
    },
    random (size = 12, characters = 'a-z,A-Z,0-9') {
      let charactersArray = characters.split(',')
      let characterSet = ''
      let password = ''

      if (charactersArray.indexOf('a-z') >= 0) {
        characterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        characterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        characterSet += '0123456789'
      }
      if (charactersArray.indexOf('#') >= 0) {
        characterSet += '![]{}()%&*$#^<>~@|'
      }

      for (let i = 0; i < size; i++) {
        password += characterSet.charAt(Math.floor(Math.random() * characterSet.length))
      }

      return password
    }
  }
}
</script>

<style lang="scss">
.fullsize-esc {
  margin-top: 5px;
}

.ios-padding {
  padding-top: 36px !important;
}
</style>
