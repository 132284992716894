import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('companies')

export default {
  state: {
    selectedCompanyId: null,
    companyTriggers: {},
    loading: false,
    editableId: null,
    loadingNew: false,
    alert: {
      title: '',
      create: false,
      body: '',
      type: '',
      showConfirmButton: false
    }
  },
  getters: {
    loading: state => state.loading,
    editableId: state => state.editableId,
    triggers (state) {
      return state.companyTriggers[state.selectedCompanyId]
    },
    loadingNew: state => state.loadingNew,
    alert: state => state.alert
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LOADING_NEW (state, boolean) {
      state.loadingNew = boolean
    },
    SET_ALERT (state, payload) {
      state.alert.title = payload.title
      state.alert.body = payload.body
      state.alert.create = payload.create
      state.alert.type = payload.type
      state.alert.showConfirmButton = payload.showConfirmButton
    },
    RESET_ALERT (state) {
      state.alert = {
        title: '',
        create: false,
        body: '',
        type: '',
        showConfirmButton: false
      }
    },
    SET_EDITABLE_ID (state, id) {
      state.editableId = id
    },
    SET_LIST (state, payload) {
      state.companyTriggers = { ...state.companyTriggers, [state.selectedCompanyId]: payload }
    },
    APPEND_TRIGGERS (state, payload) {
      let triggers = state.companyTriggers[state.selectedCompanyId]
      payload.find(response => {
        const flag = triggers.findIndex(trigger => {
          return (response.id === trigger.id)
        })
        if (flag > -1) {
          triggers[flag] = response
        } else {
          triggers.push(response)
        }
      })
      state.companyTriggers = { ...state.companyTriggers }
    },
    SELECT_TRIGGER (state, triggerId) {
      state.selectedTriggerId = triggerId
    },
    PUSH_TRIGGER (state, payload) {
      state.companyTriggers = { ...state.companyTriggers, [payload[0].company_id]: payload }
    },
    DELETE_TRIGGER (state, id) {
      const index = state.companyTriggers[state.selectedCompanyId].findIndex(trigger => trigger.id === id)
      state.companyTriggers[state.selectedCompanyId].splice(index, 1)
      state.companyTriggers = { ...state.companyTriggers }
    }
  },
  actions: {
    async fetch ({ commit, rootState, state, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        if (state.companyTriggers[rootState.companies.selectedCompanyId] === undefined) {
          commit('SET_LOADING', true)
          const response = await repository.triggerList(rootState.companies.selectedCompanySN)
          commit('SET_LOADING', false)
          if ((response.status === 200) && response.data) {
            commit('SET_LIST', response.data)
          }
        } else {
          dispatch('refetch')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async refetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_LOADING_NEW', true)
        const response = await repository.triggerList(rootState.companies.selectedCompanySN)
        commit('SET_LOADING_NEW', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async save ({ commit, rootState, dispatch }, triggerId) {
      try {
        const response = await repository.saveTrigger(rootState.companies.selectedCompanyId, triggerId)
        if (response.status === 204) {
          console.log('Trigger saved succefully!')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async agree ({ commit, state, rootState, dispatch }, triggerId) {
      try {
        const companyTriggers = state.companyTriggers[rootState.companies.selectedCompanyId]
        const triggerIndex = companyTriggers.findIndex(trigger => trigger.id === triggerId)
        const agreeIndex = companyTriggers[triggerIndex].validation_trigger_agree.findIndex(agree => agree.stock_user_id === rootState.users.user.profile_id)
        if (agreeIndex > -1) {
          companyTriggers[triggerIndex].validation_trigger_agree.splice(agreeIndex, 1)
          companyTriggers[triggerIndex].validation -= 1
        } else {
          const disagreeIndex = companyTriggers[triggerIndex].validation_trigger_disagree.findIndex(disagree => disagree.stock_user_id === rootState.users.user.profile_id)
          if (disagreeIndex > -1) {
            companyTriggers[triggerIndex].validation_trigger_disagree.splice(disagreeIndex, 1)
            companyTriggers[triggerIndex].validation += 1
          }
          companyTriggers[triggerIndex].validation += 1
          companyTriggers[triggerIndex].validation_trigger_agree.push({
            company_trigger_id: triggerId,
            status: 'agree',
            stock_user_id: rootState.users.user.profile_id
          })
        }
        const response = await repository.agree(rootState.companies.selectedCompanyId, triggerId)
        if (response.status === 204) {
          console.log('User agrees')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async disagree ({ commit, state, rootState, dispatch }, triggerId) {
      try {
        const companyTriggers = state.companyTriggers[rootState.companies.selectedCompanyId]
        const triggerIndex = companyTriggers.findIndex(trigger => trigger.id === triggerId)
        const disagreeIndex = companyTriggers[triggerIndex].validation_trigger_disagree.findIndex(disagree => disagree.stock_user_id === rootState.users.user.profile_id)
        if (disagreeIndex > -1) {
          companyTriggers[triggerIndex].validation_trigger_disagree.splice(disagreeIndex, 1)
          companyTriggers[triggerIndex].validation += 1
        } else {
          const agreeIndex = companyTriggers[triggerIndex].validation_trigger_agree.findIndex(agree => agree.stock_user_id === rootState.users.user.profile_id)
          if (agreeIndex > -1) {
            companyTriggers[triggerIndex].validation_trigger_agree.splice(agreeIndex, 1)
            companyTriggers[triggerIndex].validation -= 1
          }
          companyTriggers[triggerIndex].validation -= 1
          companyTriggers[triggerIndex].validation_trigger_disagree.push({
            company_trigger_id: triggerId,
            status: 'disagree',
            stock_user_id: rootState.users.user.profile_id
          })
        }
        const response = await repository.disagree(rootState.companies.selectedCompanyId, triggerId)
        if (response.status === 204) {
          console.log('User disagrees')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteRequest ({ state, rootState, dispatch }, triggerId) {
      try {
        const response = await repository.triggerDeleteRequest(rootState.companies.selectedCompanyId, triggerId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Delete request generated successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    resetAlert ({ commit }) {
      commit('RESET_ALERT')
    },
    setAlert ({ commit }, payload) {
      commit('SET_ALERT', payload)
    },
    // async report ({ commit, rootState, dispatch }, id) {
    //   try {
    //     const response = await repository.reportTrigger(rootState.companies.selectedCompanyId, id)
    //     if (response.status === 200) {
    //       dispatch('snackbar/snack', { text: 'Trigger reported successfully', type: 'success', show: true, autoclose: true }, { root: true })
    //     } else {
    //       dispatch('snackbar/snack', { text: 'Trigger already reported', type: 'info', show: true, autoclose: true }, { root: true })
    //     }
    //   } catch (e) {
    //     dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
    //   }
    // },
    async delete ({ state, rootState, dispatch, commit }, triggerId) {
      try {
        const response = await repository.triggerDelete(rootState.companies.selectedCompanyId, triggerId)
        if (response.status === 204) {
          commit('DELETE_TRIGGER', triggerId)
          // dispatch('snackbar/snack', { text: 'Delete request generated successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithAll ({ commit, rootState, dispatch }, payload) {
      try {
        const response = await repository.shareTriggerWithAll(rootState.companies.selectedCompanyId, payload.trigger_id, { text: payload.message })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Trigger shared successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithCompany ({ commit, dispatch }, payload) {
      try {
        const response = await repository.shareTriggerWithCompany(payload.company_id, payload.trigger_id, { text: payload.message })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Trigger shared successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pushTrigger ({ commit, state }, payload) {
      commit('PUSH_TRIGGER', [payload])
    },
    async editTrigger ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_EDITABLE_ID', payload.triggerId)
        const response = await repository.editTrigger(state.selectedCompanyId, payload.triggerId, payload)
        if (response.status === 200) {
          commit('APPEND_TRIGGERS', [response.data])
          commit('SET_EDITABLE_ID', null)
        }
      } catch (e) {
        commit('SET_EDITABLE_ID', null)
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addTrigger ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING_NEW', true)
        const response = await repository.addTrigger(state.selectedCompanyId, payload)
        if (response.status === 204) {
          console.log('Trigger added successfully')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    updateTrigger ({ commit, state }, trigger) {
      const triggerIndex = state.companyTriggers[state.selectedCompanyId].findIndex(t => Number(t.id) === Number(trigger.id))
      if (triggerIndex > -1) {
        trigger.selected = true
        state.companyTriggers[state.selectedCompanyId][triggerIndex] = trigger
        state.companyTriggers[state.selectedCompanyId] = [...state.companyTriggers[state.selectedCompanyId]]
      }
    },
    internalUpdate ({ commit, state }, payload) {
      state.companyTriggers[state.selectedCompanyId][payload.index] = payload.trigger
      state.companyTriggers[state.selectedCompanyId] = [...state.companyTriggers[state.selectedCompanyId]]
    },
    reset ({ state }) {
      state.companyTriggers = {}
    }
  },
  namespaced: true
}
