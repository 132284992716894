import { RepositoryFactory } from '@/api/repositoryFactory'
import mentions from '@/js/helpers/mentions'
const repository = RepositoryFactory.get('companies')

export default {
  state: {
    selectedCompanyId: null,
    media: {},
    loading: false,
    loadingNewMedia: false,
    addMediaLoading: false,
    pollLoading: false,
    selectedMedia: '',
    nextPageUrl: '',
    editableId: null,
    alert: {
      title: '',
      create: false,
      body: '',
      type: '',
      showConfirmButton: false
    }
  },
  getters: {
    loading: state => state.loading,
    media (state, _getters, rootState) {
      if (!rootState.companies.selectedCompanyId || !state.media[rootState.companies.selectedCompanyId]) {
        return []
      }
      return state.media[rootState.companies.selectedCompanyId]
    },
    loadingNewMedia: state => state.loadingNewMedia,
    addMediaLoading: state => state.addMediaLoading,
    pollLoading: state => state.pollLoading,
    nextPageUrl: state => state.nextPageUrl,
    alert: state => state.alert,
    editableId: state => state.editableId
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_ALERT (state, payload) {
      state.alert.title = payload.title
      state.alert.body = payload.body
      state.alert.create = payload.create
      state.alert.type = payload.type
      state.alert.showConfirmButton = payload.showConfirmButton
    },
    RESET_ALERT (state) {
      state.alert = {
        title: '',
        create: false,
        body: '',
        type: '',
        showConfirmButton: false
      }
    },
    SET_LOADING_NEW (state, boolean) {
      state.loadingNewMedia = boolean
    },
    ADD_MEDIA_LOADING (state, boolean) {
      state.addMediaLoading = boolean
    },
    SET_EDITABLE_ID (state, id) {
      state.editableId = id
    },
    SET_MEDIA (state, payload) {
      payload.filter(media => {
        if (media.mediable === null) return
        if (media.mediable.message && media.mediable.message.match(mentions.MATCHING)) {
          media.mediable.message = mentions.parse(media.mediable.message, media.message.mentions)
        }
      })
      state.media = { ...state.media, [payload.selectedCompanyId]: payload }
    },
    APPEND_MEDIA (state, payload) {
      payload.media.filter(media => {
        if (media.mediable === null) return
        if (media.mediable.message && media.mediable.message.match(mentions.MATCHING)) {
          media.mediable.message = mentions.parse(media.mediable.message, media.message.mentions)
        }
      })
      if (state.media[payload.selectedCompanyId] === undefined) {
        state.media = { ...state.media, [payload.selectedCompanyId]: payload.media }
      } else {
        payload.media.find(response => {
          const flag = state.media[payload.selectedCompanyId].findIndex(media => {
            return (response.id === media.id)
          })
          if (flag > -1) {
            state.media[payload.selectedCompanyId][flag] = response
          } else {
            state.media[payload.selectedCompanyId].push(response)
          }
        })
      }

      state.media = { ...state.media }
    },
    SET_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    DELETE_MEDIA (state, payload) {
      state.media[payload.companyId].splice(state.media[payload.companyId].findIndex(data => data.id === payload.id), 1)
      state.media = { ...state.media }
    }
  },
  actions: {
    async fetch ({ commit, rootState, state, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.listMedia(rootState.companies.selectedCompanySN, rootState.mediaFilters.filters)
        commit('SET_LOADING', false)
        if ((response.status === 200)) {
          response.data.data.selectedCompanyId = rootState.companies.selectedCompanyId
          commit('SET_MEDIA', response.data.data)
          commit('SET_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    mediaLoading ({ commit }, boolean) {
      commit('ADD_MEDIA_LOADING', boolean)
    },
    resetAlert ({ commit }) {
      commit('RESET_ALERT')
    },
    setAlert ({ commit }, payload) {
      console.log(payload)
      commit('SET_ALERT', payload)
    },
    async report ({ commit, rootState, dispatch }, id) {
      try {
        const response = await repository.reportMedia(id, rootState.companies.selectedCompanyId)
        if (response.status === 200) {
          dispatch('snackbar/snack', { text: 'Media reported successfully', type: 'success', show: true, autoclose: true }, { root: true })
        } else {
          dispatch('snackbar/snack', { text: 'Media already reported', type: 'info', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteRequest ({ commit, dispatch, rootState }, id) {
      try {
        const response = await repository.mediaDeleteRequest(id, rootState.companies.selectedCompanyId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'media deleted request added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async delete ({ state, rootState, dispatch, commit }, Id) {
      try {
        const response = await repository.deleteMedia(Id, rootState.companies.selectedCompanyId)
        if (response.status === 204) {
          commit('DELETE_MEDIA', { companyId: rootState.companies.selectedCompanyId, id: Id })
          dispatch('snackbar/snack', { text: 'media deleted successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async refetch ({ commit, rootState, state, dispatch }) {
      try {
        if (!state.loading) {
          commit('ADD_MEDIA_LOADING', true)
          const response = await repository.listMedia(rootState.companies.selectedCompanySN, rootState.mediaFilters.filters)
          commit('ADD_MEDIA_LOADING', false)
          if ((response.status === 200) && response.data) {
            response.data.data.selectedCompanyId = rootState.companies.selectedCompanyId
            commit('SET_MEDIA', response.data.data)
            commit('SET_PAGE_URL', response.data.next_page_url)
          }
        }
      } catch (e) {
        commit('ADD_MEDIA_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async like ({ commit, state, rootState, dispatch }, payload) {
      try {
        const media = state.media[rootState.companies.selectedCompanyId].find(media => media.id === payload.media_id)

        const likeIndex = media.likes.findIndex(like => (like.stock_user.id === rootState.users.user.profile_id && like.reaction === payload.reaction))

        if (likeIndex > -1) {
          media.likes.splice(likeIndex, 1)
        } else {
          media.likes.push({
            likeable_id: payload.media_id,
            reaction: payload.reaction,
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile.id
          })
        }

        const response = await repository.mediaLike(payload.media_id, rootState.companies.selectedCompanyId, {
          reaction: payload.reaction
        })
        if (response.status === 204) {
          // dispatch('snackbar/snack', { text: 'media added to liked list', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithGeneral ({ commit, dispatch, rootState }, payload) {
      try {
        const response = await repository.shareWithGeneral(payload.media_id, {
          message: payload.message
        })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Media shared.', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchMedia ({ commit, dispatch, rootState }, mediaId) {
      try {
        commit('ADD_MEDIA_LOADING', true)
        const response = await repository.fetchMedia(mediaId)
        if (response.status === 200 && response.data) {
          commit('APPEND_MEDIA', { selectedCompanyId: response.data.company.id, media: [response.data] })
          commit('ADD_MEDIA_LOADING', false)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pushMedia ({ commit, dispatch, rootState }, media) {
      try {
        commit('APPEND_MEDIA', { selectedCompanyId: media.company_id, media: [media] })
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithCompany ({ commit, dispatch }, payload) {
      try {
        const response = await repository.shareWithCompany(payload.media_id, payload.company_id, {
          message: payload.message
        })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Media shared.', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    updateInternalMedia ({ commit, state, rootState }, payload) {
      state.media[rootState.companies.selectedCompanyId][payload.index] = payload.media
      state.media[rootState.companies.selectedCompanyId] = [...state.media[rootState.companies.selectedCompanyId]]
    },
    async scroll ({ commit, rootState, state, dispatch }) {
      try {
        if (state.nextPageUrl !== null && !state.loadingNewMedia) {
          commit('SET_LOADING_NEW', true)
          const response = await repository.scrollMedia(state.nextPageUrl, rootState.mediaFilters.filters)
          commit('SET_LOADING_NEW', false)
          if ((response.status === 200) && response.data) {
            commit('APPEND_MEDIA', { selectedCompanyId: rootState.companies.selectedCompanyId, media: response.data.data })
            commit('SET_PAGE_URL', response.data.next_page_url)
          }
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addMedia ({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.store(payload, rootState.companies.selectedCompanyId)
        commit('SET_LOADING', false)
        if (response.status === 201) {
          commit('APPEND_MEDIA', { selectedCompanyId: rootState.companies.selectedCompanyId, media: [response.data] })
          await dispatch('snackbar/snack', { text: ' Media created successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editMedia ({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_EDITABLE_ID', payload.mediaId)
        const response = await repository.updateMedia(payload.formData, rootState.companies.selectedCompanyId, payload.mediaId)
        if (response.status === 200) {
          commit('APPEND_MEDIA', { selectedCompanyId: rootState.companies.selectedCompanyId, media: [response.data] })
          commit('SET_EDITABLE_ID', null)
          await dispatch('snackbar/snack', { text: ' Media updated successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_EDITABLE_ID', null)
        if (e.response) {
          await dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
        } else {
          await dispatch('snackbar/snack', { text: e, type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    reset ({ state }) {
      state.media = []
    }
  },
  namespaced: true
}
