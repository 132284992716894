<template>
  <div
    :style="colorBackground"
    class="relative rounded-xl flex items-center justify-center flex-shrink-0"
    :class="company.color ? '' : 'bg-custom-purple-500'"
  >
    <div class="flex w-full text-gray-200 justify-center p-2">
      <RectangleImage
        v-if="company.logo"
        :src="company.logo"
        :big="big"
        class="relative"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompanyLogoModule',
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    big: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colorBackground () {
      if (!this.company) {
        return null
      }

      if (this.company.color) {
        return 'background-color: ' + this.company.color
      }

      return null
    }
  }
}
</script>

<style scoped>
</style>
