import {
  RepositoryFactory
} from '@/api/repositoryFactory'
import {
  ALL_TYPES
} from '@/enums/highscoreTypes'
const repository = RepositoryFactory.get('highscores')
export default {
  state: {
    companyAsFollowers: {},
    companyAsDiscussions: {},
    loadingCompany: false,

    userAsFollowers: {},
    userAsEngaged: {},
    userAsReputation: {},
    loadingUser: false
  },
  getters: {
    companyAsFollowers: state => state.companyAsFollowers,
    companyAsDiscussions: state => state.companyAsDiscussions,
    loadingCompany: state => state.loadingCompany,

    userAsFollowers: state => state.userAsFollowers,
    userAsEngaged: state => state.userAsEngaged,
    userAsReputation: state => state.userAsReputation,
    loadingUser: state => state.loadingUser
  },
  mutations: {

    SET_COMPANIES_AS_FOLLOWERS (state, payload) {
      state.companyAsFollowers = payload
    },
    SET_COMPANIES_AS_DISCUSSIONS (state, payload) {
      state.companyAsDiscussions = payload
    },
    SET_LOADING_COMPANY (state, boolean) {
      state.loadingCompany = boolean
    },

    SET_USER_AS_FOLLOWERS (state, payload) {
      state.userAsFollowers = payload
    },
    SET_USER_AS_ENGAGED (state, payload) {
      state.userAsEngaged = payload
    },
    SET_USER_AS_REPUTATION (state, payload) {
      state.userAsReputation = payload
    },
    SET_LOADING_USER (state, boolean) {
      state.loadingUser = boolean
    }
  },
  actions: {
    async fetchCompanies ({
      commit,
      state,
      dispatch
    }, payload) {
      try {
        commit('SET_LOADING_COMPANY', true)
        const response = await repository.getCompanies(payload)
        if ((response.status === 200) && response.data) {
          if (response.data.length === 0) return

          if (payload.dataFor === ALL_TYPES.COMPANY_FOLLOWERS) {
            response.data.forEach(company => {
              company.subtitle = company.totalFollowers + ' followers in ' + payload.duration
            })
            commit('SET_COMPANIES_AS_FOLLOWERS', response.data)
          } else if (payload.dataFor === ALL_TYPES.COMPANY_DISCUSSIONS) {
            response.data.forEach(company => {
              company.subtitle = company.totalMessages + ' discussions in ' + payload.duration
            })
            commit('SET_COMPANIES_AS_DISCUSSIONS', response.data)
          }
        }
        commit('SET_LOADING_COMPANY', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING_COMPANY', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },

    async fetchUsers ({
      commit,
      state,
      dispatch
    }, payload) {
      try {
        commit('SET_LOADING_USER', true)
        const response = await repository.getStockUsers(payload)
        if ((response.status === 200) && response.data) {
          if (response.data.length === 0) return

          if (payload.dataFor === ALL_TYPES.USER_FOLLOWERS) {
            response.data.forEach(user => {
              user.subtitle = user.totalFollowers + ' followers in ' + payload.duration
            })
            commit('SET_USER_AS_FOLLOWERS', response.data)
          } else if (payload.dataFor === ALL_TYPES.USER_ENGAGED) {
            response.data.forEach(user => {
              user.subtitle = user.totalMessages + ' discussions in ' + payload.duration
            })
            commit('SET_USER_AS_ENGAGED', response.data)
          } else if (payload.dataFor === ALL_TYPES.USER_REPUTATION) {
            response.data.forEach(user => {
              if (user.reputation === null || user.reputation === 'null') user.reputation = 0

              user.subtitle = user.reputation + ' points in ' + payload.duration
            })
            commit('SET_USER_AS_REPUTATION', response.data)
          }
        }
        commit('SET_LOADING_USER', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING_USER', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
