<template>
  <ModalContainer
    v-if="maintenance"
    :hide-close="true"
    full-size
    class="z-70"
  >
    <MaintenanceModal
      :maintenance-to="maintenanceTo"
    />
  </ModalContainer>
</template>

<script>

import MaintenanceModal from './_components/MaintenanceModal'
import ModalContainer from '@/modules/modal'
import { mapGetters } from 'vuex'

export default {
  name: 'MaintenanceModalModule',
  components: {
    MaintenanceModal,
    ModalContainer
  },
  data () {
    return {
      maintenanceTimer: null
    }
  },
  computed: {
    ...mapGetters({
      maintenance: 'app/maintenance',
      maintenanceTo: 'app/maintenanceTo'
    })
  },
  mounted () {
    this.getMaintenance()

    this.maintenanceTimer = setInterval(() => {
      this.getMaintenance()
    }, 5 * 60 * 1000)
  },
  beforeDestroy () {
    clearInterval(this.maintenanceTimer)
  },
  methods: {
    async getMaintenance () {
      await this.$store.dispatch('app/getMaintenance')
    }
  }
}
</script>
