import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('achievements')
export default {
  state: {
    achievements: [],
    userAchievements: [],
    userActivityCount: [],
    loading: false,
    achievementModal: [],
    showAchievement: false
  },
  getters: {
    loading: state => state.loading,
    achievements: state => state.achievements,
    userAchievements: state => state.userAchievements,
    userActivityCount: state => state.userActivityCount,
    achievementModal: state => state.achievementModal,
    showAchievement: state => state.showAchievement
  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_USER_ACHIEVEMENTS (state, userAchievements) {
      state.userAchievements = userAchievements
    },
    SET_ACHIEVEMENTS (state, achievements) {
      state.achievements = achievements
    },
    SET_USER_ACTIVITY_COUNT (state, userActivityCount) {
      state.userActivityCount = userActivityCount
    },
    SET_ACHIEVEMENT_MODAL (state, achievementModal) {
      state.achievementModal = achievementModal
    },
    SET_SHOW_ACHIEVEMENT (state, showAchievement) {
      state.showAchievement = showAchievement
    }
  },
  actions: {
    async fetchAchievements ({
      commit, dispatch
    }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchAll()
        if ((response.status === 200) && response.data) {
          commit('SET_USER_ACHIEVEMENTS', response.data.userAchievements)
          commit('SET_ACHIEVEMENTS', response.data.achievements)
          commit('SET_USER_ACTIVITY_COUNT', response.data.userActivityCount)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async openAchievement ({ commit, dispatch }, payload) {
      try {
        commit('SET_ACHIEVEMENT_MODAL', payload['achievement'])
        dispatch('setShowAchievementModal', true)
      } catch (e) {
        console.log(e)
      }
    },
    setShowAchievementModal ({ commit }, boolean) {
      try {
        commit('SET_SHOW_ACHIEVEMENT', boolean)
      } catch (e) {
        console.log(e)
      }
    }
  },
  namespaced: true
}
