<template>
  <div class="relative flex w-full flex-wrap items-stretch">
    <!-- LANGUAGE -->
    <input
      :type="type"
      :placeholder="placeholder"
      :value="value"
      class="w-full py-2 px-6 focus:outline-none rounded-lg shadow-lg dark:shadow-lg-dark border border-gray-100 dark:bg-custom-purple-500 dark:border-custom-purple-400"
      @input="$emit('input', $event)"
    >
    <span class="z-10 h-full leading-snug font-normal absolute text-center text-custom-light-header dark:text-custom-dark-header absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
      <FontAwesomeIcon icon="link" />
    </span>
  </div>
</template>

<script>

export default {
  name: 'LinkInput',
  props: {
    type: { type: String, default: 'text' },
    value: { type: String, default: '' },
    placeholder: { type: String, default: 'link' }
  }
}
</script>
