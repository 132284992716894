<template>
  <button
    class="inline-block flex justify-end font-medium text-sm leading-6 focus:outline-none dark:text-custom-purple-100"
    :class="[size, disabled ? 'text-gray-300' : '']"
    :disabled="disabled"
  >
    <img
      class="share-icon ml-1 h-5 w-5"
      src="/svg/share.svg"
    >
    <!-- <div class="ml-1 mt-1">
      {{ '10K' }}
    </div> -->
  </button>
</template>

<script>

export default {
  name: 'ShareButton',
  props: {
    size: { type: String, default: 'ml-4 text-right' },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.light .share-icon {
  filter: invert(50%) sepia(9%) saturate(654%) hue-rotate(182deg) brightness(86%) contrast(83%);
  }
  .dark .share-icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(176deg) brightness(104%) contrast(106%);
  }
</style>
