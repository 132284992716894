<template>
  <BaseCard class="mx-2 my-4">
    <template #body>
      <div class="grid grid-cols-2 gap-6 m-3">
        <BaseCard :background="'bg-gray-100 rounded-md border-gray-100 dark:bg-custom-purple-500'">
          <template #body>
            <Title
              class="text-center uppercase"
              :size="'sm:text-md md:text-lg'"
            >
              <Icon
                name="star"
                class="text-3xl mb-1 justify-center text-custom-light-header dark:text-custom-dark-header"
              />
              <div class="dark:text-white w-full">
                {{ $t('Reputation') }}
              </div>
              <div class=" w-full">
                {{ reputation }}
              </div>
            </Title>
          </template>
        </BaseCard>
        <BaseCard
          :background="'bg-gray-100 rounded-md border-gray-100 dark:bg-custom-purple-500'"
          @click.native="$emit('click', $event)"
        >
          <template #body>
            <Title
              class="text-center uppercase"
              :size="'sm:text-md md:text-lg'"
            >
              <Icon
                name="comment"
                class="text-4xl mb-1 justify-center text-custom-light-header dark:text-custom-dark-header"
              />

              <div class="dark:text-white w-full">
                {{ $t('Feed') }}
              </div>
              <div class=" w-full">
                {{ feed }}
              </div>
            </Title>
          </template>
        </BaseCard>
        <BaseCard :background="'bg-gray-100 rounded-md border-gray-100 dark:bg-custom-purple-500'">
          <template #body>
            <Title
              class="text-center uppercase"
              :size="'sm:text-md md:text-lg'"
            >
              <Icon
                name="briefcase"
                class="text-4xl mb-1 justify-center text-custom-light-header dark:text-custom-dark-header"
              />

              <div class="dark:text-white w-full">
                {{ $t('Companies') }}
              </div>
              <div class=" w-full">
                {{ companies }}
              </div>
            </Title>
          </template>
        </BaseCard>
        <BaseCard :background="'bg-gray-100 border-gray-100 rounded-md dark:bg-custom-purple-500'">
          <template #body>
            <Title
              class="text-center uppercase"
              :size="'sm:text-md md:text-lg'"
            >
              <Icon
                name="calendar-check"
                class="text-4xl mb-1 justify-center text-custom-light-header dark:text-custom-dark-header"
              />
              <div class="dark:text-white w-full">
                {{ $t('Member') }}
              </div>
              <div class=" w-full">
                {{ member }}
              </div>
            </Title>
          </template>
        </BaseCard>
      </div>
    </template>
  </BaseCard>
</template>
<script>
export default {
  name: 'ProfileSection',
  props: {
    reputation: { type: Number, default: 0 },
    feed: { type: Number, default: 0 },
    companies: { type: Number, default: 0 },
    member: { type: String, default: '' }
  }
}
</script>
