<template>
  <div class="w-full bg-custom-purple-500 text-white dark:text-white dark:bg-custom-purple-900 dark:text-primary-dark px-0 pb-4 pt-0 absolute bottom-16 right-0">
    <Container padding="p-0">
      <div class="p-2 border-b border-dashed border-gray-300 dark:border-custom-purple-600 mb-2">
        <Title color="text-white">
          {{ $t('Apps') }}
        </Title>
      </div>
      <div class="w-full flex flex-wrap-reverse">
        <div class="w-1/2">
          <div class="mx-1">
            <RouterLink
              class="flex flex-col items-center justify-center p-4 hover:bg-custom-purple-300 focus:bg-custom-purple-300 dark:focus:bg-custom-purple-500 dark:hover:bg-custom-purple-500 bg-custom-purple-400 dark:bg-custom-purple-400 mr-2 mb-2 rounded-md w-full h-20"
              :to="{name:'brokerstats'}"
              @click.native="$emit('menu-clicked')"
            >
              <Icon
                name="bar-chart"
                class="text-3xl dark:text-custom-dark-header m-0"
              />
              <span class="text-2xs">{{ $t('Broker stats') }}</span>
            </RouterLink>
          </div>
        </div>
        <div class="w-1/2">
          <div class="mx-1">
            <RouterLink
              class="flex flex-col items-center justify-center p-4 hover:bg-custom-purple-300 focus:bg-custom-purple-300 dark:focus:bg-custom-purple-500 dark:hover:bg-custom-purple-500 bg-custom-purple-400 dark:bg-custom-purple-400 mr-2 mb-2 rounded-md w-full h-20"
              :to="{name:'generalfeed'}"
              @click.native="$emit('menu-clicked')"
            >
              <Icon
                name="conversation"
                class="text-3xl dark:text-custom-purple-200 m-0"
              />
              <span class="text-2xs dark:text-custom-purple-200">{{ $t('Talk & News') }}</span>
            </RouterLink>
          </div>
        </div>
        <div class="w-1/2">
          <div class="mx-1">
            <RouterLink
              class="flex flex-col items-center justify-center p-4 hover:bg-custom-purple-300 focus:bg-custom-purple-300 dark:focus:bg-custom-purple-500 dark:hover:bg-custom-purple-500 bg-custom-purple-400 dark:bg-custom-purple-400 mr-2 mb-2 rounded-md w-full h-20"
              :to="{name:'listScreener'}"
              @click.native="$emit('menu-clicked')"
            >
              <Icon
                name="list-ul"
                class="text-3xl dark:text-custom-purple-200 m-0"
              />
              <span class="text-2xs dark:text-custom-purple-200">{{ $t('Lists') }}</span>
            </RouterLink>
          </div>
        </div>
        <div class="w-1/2">
          <div class="mx-1">
            <RouterLink
              class="flex flex-col items-center justify-center p-4 hover:bg-custom-purple-300 focus:bg-custom-purple-300 dark:focus:bg-custom-purple-500 dark:hover:bg-custom-purple-500 bg-custom-purple-400 dark:bg-custom-purple-400 mr-2 mb-2 rounded-md w-full h-20"
              :to="{name:'companyScreener'}"
              @click.native="$emit('menu-clicked')"
            >
              <Icon
                name="search"
                class="text-3xl dark:text-custom-dark-header m-0"
              />
              <span class="text-2xs">{{ $t('Company Screener') }}</span>
            </RouterLink>
          </div>
        </div>
        <div class="w-1/2">
          <div class="mx-1">
            <RouterLink
              class="flex flex-col items-center justify-center p-4 hover:bg-custom-purple-300 focus:bg-custom-purple-300 dark:focus:bg-custom-purple-500 dark:hover:bg-custom-purple-500 bg-custom-purple-400 dark:bg-custom-purple-400 mr-2 mb-2 rounded-md w-full h-20"
              :to="{name:'members'}"
              @click.native="$emit('menu-clicked')"
            >
              <Icon
                name="male-female"
                class="text-3xl dark:text-custom-dark-header m-0"
              />
              <span class="text-2xs">{{ $t('Members') }}</span>
            </RouterLink>
          </div>
        </div>
        <div class="w-1/2">
          <div class="mx-1">
            <RouterLink
              class="flex flex-col items-center justify-center p-4 hover:bg-custom-purple-300 focus:bg-custom-purple-300 dark:focus:bg-custom-purple-500 dark:hover:bg-custom-purple-500 bg-custom-purple-400 dark:bg-custom-purple-400 mr-2 mb-2 rounded-md w-full h-20"
              :to="{name:'insider'}"
              @click.native="$emit('menu-clicked')"
            >
              <Icon
                name="user-check"
                class="text-3xl dark:text-custom-dark-header m-0 -mr-3"
              />
              <span class="text-2xs">{{ $t('Insider trades') }}</span>
            </RouterLink>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>

export default {
  name: 'AppsMenu',
  props: {
    unreadMessages: { type: Number, default: 0 }
  },
  mounted () {
    this.$store.dispatch('users/seenBubbleType', { tag: 'new_feature_apps', type: 'new_feature_apps' })
  }
}
</script>
