<template>
  <div>
    <BaseCard class="mt-8 h-full">
      <template #body>
        <div>
          <p class="w-full mb-4 text-left text-custom-light-header dark:text-custom-purple-100">
            {{ $t('My Filters') }}
          </p>
          <div
            v-if="filterListLoading"
            class="flex justify-center p-8"
          >
            <Loader />
          </div>
          <div v-else-if="filterList.length>0">
            <BaseCard
              v-for="filter in filterList"
              :key="filter.id"
              :padding="'px-4 py-1 mb-4'"
              @click="$emit('update-filter', filter)"
            >
              <template #body>
                <div class="flex justify-between item-center">
                  <div
                    class="cursor-pointer"
                    @click="$emit('set-filter', filter)"
                  >
                    {{ filter.name }}
                  </div>
                  <FontAwesomeIcon
                    icon="times-circle"
                    class="mt-1 cursor-pointer"
                    @click="$emit('delete-list-filter', filter.id)"
                  />
                </div>
              </template>
            </BaseCard>
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ $t('no data to show.') }}
          </div>
        </div>
      </template>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: 'FilterList',
  props: {
    filterList: { type: Array, default: () => [] },
    filterListLoading: { type: Boolean, default: false }
  }
}
</script>
