import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('companies')

export default {
  state: {
    selectedCompanyId: null,
    companyData: {
      '1': {
        id: 1,
        followers: '30670',
        valuation: '389 MSEK',
        isin: ' SE004550213',
        short_name: 'SMOL',
        pe: '-31',
        direct_return: '0,000',
        ps: '769,79'
      }
    },
    loading: false
  },
  getters: {
    loading: state => state.loading,
    companyData (state) {
      return state.companyData[state.selectedCompanyId]
    }
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LIST (state, payload) {
      state.companyData = { ...state.companyData, [state.selectedCompanyId]: payload }
    }
  },
  actions: {
    async fetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_LOADING', true)
        const response = await repository.dataList(rootState.companies.selectedCompanySN)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
