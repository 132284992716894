<template>
  <SlideXRightTransition
    :duration="500"
    :delay="100"
    class="fixed w-full bottom-16 left-0 z-70"
  >
    <div v-show="internalSnack.show">
      <div
        v-if="internalSnack.type === 'info'"
        class="bg-blue-500 text-white px-4 py-2 rounded-sm text-xs flex justify-center"
      >
        <div>{{ internalSnack.text }}</div>
        <button
          class="ml-8 absolute bottom-0 right-5 focus:outline-none"
          @click="close"
        >
          <Icon
            name="x"
            class="text-xl mb-px"
          />
        </button>
      </div>
      <div
        v-if="internalSnack.type === 'success'"
        class="bg-green-500 text-white px-4 py-2 rounded-sm text-xs flex justify-center"
      >
        <div>{{ internalSnack.text }}</div>
        <button
          class="ml-8 absolute bottom-0 right-5 focus:outline-none"
          @click="close"
        >
          <Icon
            name="x"
            class="text-xl mb-px"
          />
        </button>
      </div>
      <div
        v-if="internalSnack.type === 'error'"
        class="bg-pink-700 text-white px-4 py-2 rounded-sm text-xs flex justify-center"
      >
        <div>{{ internalSnack.text }}</div>
        <button
          class="ml-8 absolute bottom-0 right-5 focus:outline-none"
          @click="close"
        >
          <Icon
            name="x"
            class="text-xl mb-px"
          />
        </button>
      </div>
    </div>
  </SlideXRightTransition>
</template>

<script>
import { SlideXRightTransition } from 'vue2-transitions'
import { mapGetters } from 'vuex'
export default {
  name: 'SnackBar',
  components: {
    SlideXRightTransition
  },
  computed: {
    ...mapGetters({
      snack: 'snackbar/snack'
    }),
    internalSnack () {
      if (this.snack.type !== 'notification') {
        return this.snack
      }

      return { show: false }
    }
  },
  methods: {
    close () {
      this.$store.dispatch('snackbar/close')
    }
  }
}
</script>
