<template>
  <SlideYUpTransition
    :duration="500"
    :delay="100"
    class="fixed w-full top-0 left-0 z-70"
  >
    <div v-show="internalSnack.show">
      <div
        class="bg-gray-900 text-white rounded-sm text-xs flex flex-col justify-center cursor-pointer"
        @click="link(internalSnack.link)"
      >
        <Container class="relative">
          <div class="font-medium">
            {{ internalSnack.title }}
          </div>
          <div>{{ internalSnack.body }}</div>
          <button
            class="ml-8 absolute rounded-full bg-white top-2 w-6  h-6 right-0 flex items-center justify-center focus:outline-none"
            @click="close"
          >
            <Icon
              name="x"
              class="text-xl mb-px text-custom-orange-400"
            />
          </button>
        </Container>
      </div>
    </div>
  </SlideYUpTransition>
</template>

<script>
import { SlideYUpTransition } from 'vue2-transitions'
import { mapGetters } from 'vuex'
export default {
  name: 'SnackBar',
  components: {
    SlideYUpTransition
  },
  computed: {
    ...mapGetters({
      snack: 'snackbar/snack'
    }),
    internalSnack () {
      if (this.snack.type === 'notification') {
        return this.snack
      }

      return { show: false }
    }
  },
  methods: {
    close (event) {
      event.stopPropagation()
      this.$store.dispatch('snackbar/close')
    },
    link (link) {
      this.$router.push(link)
      this.$store.dispatch('snackbar/close')
    }
  }
}
</script>
