import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('premiums')

export default {
  state: {
    loading: false,
    premium: {}
  },
  getters: {
    premium: state => state.premium
  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_PREMIUM (state, premium) {
      state.premium = premium
    }
  },
  actions: {
    async fetch ({ commit, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchPremium()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_PREMIUM', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
