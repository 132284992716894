import { get, post, upload } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/chats`

const list = async () => get(`${url}`)
const singleChat = async (id) => get(`${url}/${id}`)
const createChat = async (data) => post(`${url}`, data)
const fetchAllUsers = async (payload) => get(`${process.env.VUE_APP_API_URL}/users/fetch`, payload)
const saveMessage = async (id, room, payload) => upload(`${url}/${id}/rooms/${room}/save-message`, payload)
const addMember = async (chatId, payload) => post(`${url}/${chatId}/members`, payload)
const removeMember = async (chatId, payload) => post(`${url}/${chatId}/remove-members`, payload)
const fetchMessages = async (id) => get(`${url}/${id}/messages`)
const fetchCompanyChat = async (id) => get(`${process.env.VUE_APP_API_URL}/companies/${id}/chats`)
const joinCompanyChat = async (id) => post(`${url}/${id}/join-chat`)
const like = async (chatId, messageId, data) => post(`${url}/${chatId}/message/${messageId}/likes`, data)
const scroll = async (link) => get(`${link}`)
const report = async (id) => post(`${url}/message/${id}/report`)
const messagesSeen = async (id, data) => post(`${url}/${id}/messages-seen`, data)
const deleteMessage = async (chatId, messageId) => post(`${url}/${chatId}/message/${messageId}/delete`)
const editMessage = async (chatId, messageId, payload) => upload(`${url}/${chatId}/message/${messageId}/update`, payload)

export default {
  list,
  singleChat,
  createChat,
  saveMessage,
  fetchAllUsers,
  addMember,
  fetchMessages,
  fetchCompanyChat,
  joinCompanyChat,
  like,
  scroll,
  report,
  messagesSeen,
  deleteMessage,
  editMessage,
  removeMember
}
