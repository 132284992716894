import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('heyEucaps')
export default {
  state: {
    loading: false,
    eucapsBoardTeam: [],
    stockEvolutionTeam: []
  },
  getters: {
    loading: state => state.loading,
    eucapsBoardTeam: state => state.eucapsBoardTeam,
    stockEvolutionTeam: state => state.stockEvolutionTeam

  },
  mutations: {
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_EUCAPS_BOARD (state, payload) {
      state.eucapsBoardTeam = [...payload]
    },
    SET_STOCK_EVOLUTION (state, payload) {
      state.stockEvolutionTeam = [...payload]
    }
  },
  actions: {
    async fetchEucapsBoard ({ commit }) {
      commit('SET_LOADING', true)
      const response = await repository.getEucapsBoardTeam()
      commit('SET_EUCAPS_BOARD', response.data)
      commit('SET_LOADING', false)
    },
    async fetchStockEvolution ({ commit }) {
      commit('SET_LOADING', true)
      const response = await repository.getStockEvolutionTeam()
      commit('SET_STOCK_EVOLUTION', response.data)
      commit('SET_LOADING', false)
    }
  },
  namespaced: true
}
