import { RepositoryFactory } from '@/api/repositoryFactory'
import { i18n } from '@/js/language/'
import router from '@/js/router'
const repository = RepositoryFactory.get('blogs')
const _ = require('lodash')
export default {
  state: {
    loading: false,
    slug: '',
    blogsList: []
  },
  getters: {
    blogsList: (state) => state.blogsList,
    loading: (state) => state.loading,
    currentBlog: (state) => {
      let currentBlog = state.blogsList.find(blog => blog.slug_url === state.slug)
      if (currentBlog) { currentBlog.blog_blocks = _.orderBy(currentBlog.blog_blocks, 'sorting', 'asc') }
      return currentBlog
    }
  },
  mutations: {
    SET_SLUG (state, payload) {
      state.slug = payload
    },
    SET_BLOGS_LIST (state, payload) {
      state.blogsList = [...payload]
    },
    SET_BLOG (state, blog) {
      const blogsList = state.blogsList

      const blogIndex = blogsList.findIndex(b => Number(b.id) === Number(blog.id))
      if (blogIndex > -1) {
        blogsList[blogIndex] = blog
        state.blogsList = [...blogsList]
      } else {
        state.blogsList.push(blog)
      }
    },
    SET_EDITED_BLOGS (state, payload) {
      const clonedState = JSON.parse(JSON.stringify(state.blogsList))

      clonedState.forEach((item) => {
        if (item.slug_url === payload.slug) {
          item.blog_blocks.forEach((block, index) => {
            payload.blocks.forEach((editedItem, i) => {
              if (item.blog_blocks[index].id === editedItem.id) {
                if (item.blog_blocks[index].blockable_type === 'App\\Models\\BlockTitle') {
                  item.blog_blocks[index].blockable.title = editedItem.title
                }
                if (item.blog_blocks[index].blockable_type === 'App\\Models\\BlockContent') {
                  item.blog_blocks[index].blockable.content = editedItem.content
                }
                if (item.blog_blocks[index].blockable_type === 'App\\Models\\BlockBanner' || item.blog_blocks[index].blockable_type === 'App\\Models\\BlockImage' || item.blog_blocks[index].blockable_type === 'App\\Models\\BlockVideo') {
                  item.blog_blocks[index].blockable.src = editedItem.src
                }
              }
            })
          })
        }
      })
      state.blogsList = [...clonedState]
    },
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    APPEND_BLOGS_LIST (state, payload) {
      state.blogsList.push(payload)
    }
  },
  actions: {
    async fetchBlog ({ state, commit, dispatch }, slugUrl) {
      try {
        commit('SET_LOADING', true)

        const blogIndex = state.blogsList.findIndex(b => b.slug === slugUrl)
        if (blogIndex === -1) {
          const response = await repository.fetchBlog(slugUrl)
          commit('SET_LOADING', false)
          if ((response.status === 200) && response.data) {
            commit('SET_BLOG', response.data)
          }
        }
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchBlogs ({ commit, dispatch }) {
      try {
        commit('SET_LOADING', true)

        const response = await repository.fetchBlogs()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_BLOGS_LIST', response.data)
        }
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async updateBlog ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.updateBlog(payload)
        if (response.status === 201) {
          dispatch('snackbar/snack', { type: 'success', text: i18n.t('The Blog has been edited'), show: true, autoclose: true }, { root: true })
          router.push({ name: 'blogs' })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteBlog ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.deleteBlog(payload)
        if (response.status === 201) {
          dispatch('snackbar/snack', { type: 'success', text: i18n.t('The Blog has been deleted'), show: true, autoclose: true }, { root: true })
          router.push({ name: 'blogs' })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },

    async setSlug ({ commit }, payload) {
      commit('SET_SLUG', payload)
    },
    async setLoading ({ commit }, payload) {
      commit('SET_LOADING', payload)
    },

    async createBlog ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.createBlog(payload.blocks)
        if (response.status === 200) {
          dispatch('snackbar/snack', { type: 'success', text: i18n.t('The Blog has been created successfully'), show: true, autoclose: true }, { root: true })
          router.push({ name: 'blogs' })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.error(e)

        commit('SET_LOADING', false)
        if ('response' in e && e.response.status === 409) {
          dispatch('snackbar/snack', { type: 'error', text: i18n.t('Blog title already exists'), show: true, autoclose: true }, { root: true })
        } else {
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    }
  },
  namespaced: true
}
