import { RepositoryFactory } from '@/api/repositoryFactory'
const companyRepository = RepositoryFactory.get('companies')
const userRepository = RepositoryFactory.get('users')

export default {
  state: {
    selectedCompanyId: null,
    companyMembers: {},
    userList: [],
    loading: false,
    nextPageUrl: '',
    scrollLoading: false,
    canScrollMore: false
  },
  getters: {
    loading: state => state.loading,
    members (state) {
      return state.companyMembers[state.selectedCompanyId]
    },
    users (state) {
      return state.userList
    },
    scrollLoading: state => state.scrollLoading,
    canScrollMore: state => state.canScrollMore,
    nextPageUrl: state => state.nextPageUrl
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SCROLL_LOADING (state, boolean) {
      state.scrollLoading = boolean
    },
    CAN_SCROLL_MORE (state, boolean) {
      state.canScrollMore = boolean
    },
    SET_LIST (state, payload) {
      state.companyMembers = { ...state.companyMembers, [state.selectedCompanyId]: payload }
    },
    SET_USER_LIST (state, payload) {
      state.userList = [...payload]
    },
    APPEND_LIST (state, payload) {
      payload.map(member => {
        state.companyMembers[state.selectedCompanyId].push(member)
      })
    },
    NEXT_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    }
  },
  actions: {
    async fetch ({ commit, rootState, dispatch }, isCompany = true) {
      try {
        let response
        if (isCompany) {
          commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
          commit('SET_LOADING', true)
          response = await companyRepository.memberList(rootState.companies.selectedCompanySN)
          commit('SET_LOADING', false)
          if ((response.status === 200) && response.data) {
            commit('SET_LIST', response.data.data)
            commit('NEXT_PAGE_URL', response.data.next_page_url)
          }
        } else {
          commit('SET_LOADING', true)
          response = await userRepository.memberList()
          if ((response.status === 200) && response.data) {
            commit('SET_USER_LIST', response.data.data)
          }
          commit('SET_LOADING', false)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async scroll ({ commit, rootState, state, dispatch }) {
      try {
        if (state.nextPageUrl !== null && !state.loading) {
          commit('SCROLL_LOADING', true)
          const response = await companyRepository.scroll(state.nextPageUrl)
          if ((response.status === 200) && response.data) {
            commit('APPEND_LIST', response.data.data)
            commit('NEXT_PAGE_URL', response.data.next_page_url)
            commit('SCROLL_LOADING', false)
          }
        }
      } catch (e) {
        commit('SCROLL_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.companyMembers = {}
    }
  },
  namespaced: true
}
