<template>
  <BaseButton
    :class="isSecondary ? 'border border-primary rounded-sm hover:border-gray-800 text-primary hover:text-gray-800' : 'bg-primary rounded-sm hover:bg-gray-800 text-white'"
    :no-padding="noPadding"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'PositiveButton',
  props: {
    noPadding: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false }
  },
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
