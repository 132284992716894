import { render, staticRenderFns } from "./SmallLogo.vue?vue&type=template&id=d678ee56&scoped=true&"
import script from "./SmallLogo.vue?vue&type=script&lang=js&"
export * from "./SmallLogo.vue?vue&type=script&lang=js&"
import style0 from "./SmallLogo.vue?vue&type=style&index=0&id=d678ee56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d678ee56",
  null
  
)

export default component.exports