<template>
  <transition
    v-show="useFooter"
    :name="(iosPadding) ? 'ios-slide-fade' : 'slide-fade'"
  >
    <footer
      v-show="menu"
      class="animated bg-custom-purple-500 text-white dark:bg-custom-purple-800 dark:shadow-sm-dark z-20 fixed bottom-0 w-full"
    >
      <Container :padding="iosPadding ? 'pt-0 pb-4 px-2' : 'py-0'">
        <nav class="flex items-center justify-between flex-wrap py-2 mx-auto dark:text-white">
          <!-- <LogoContainer class="w-2/12" /> -->
          <MenuContainer class="w-10/12" />
        </nav>
      </Container>
    </footer>
  </transition>
</template>

<script>

// import LogoContainer from '@/modules/logo'
import MenuContainer from '@/modules/menu'

export default {
  name: 'Footer',
  components: {
    // LogoContainer,
    MenuContainer
  },
  props: {
    iosPadding: { type: Boolean, default: false },
    isLoggedIn: { type: Boolean, default: false },
    useFooter: { type: Boolean, default: false },
    menu: { type: Boolean, default: false }
  },
  mounted () {
    // console.log(this.iosPadding + '-slide-fade')
  }
}
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(75px);
}

.ios-slide-fade-enter-active {
  transition: all .2s ease;
}
.ios-slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.ios-slide-fade-enter, .ios-slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(111px);
}
</style>
