<template>
  <nav class="flex items-center justify-between z-10 py-6 mx-auto px-8">
    <div class="flex items-center">
      <LogoContainer class="mr-4" />
    </div>
    <DesktopMenu
      :open="open"
      :loaded="loaded"
      @toggle-navbar="$emit('toggle-navbar', $event)"
    />
    <MobileMenu
      :open="open"
      :loaded="loaded"
      @toggle-navbar="$emit('toggle-navbar', $event)"
    />
  </nav>
</template>
<script>
import LogoContainer from '@/modules/logo'
import MobileMenu from './MobileMenu.vue'
import DesktopMenu from './DesktopMenu.vue'

export default {
  name: 'FrontHeaderInternal',
  components: {
    LogoContainer,
    MobileMenu,
    DesktopMenu
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply border-custom-green-500;
}
.menu-slider {
  transform: translateX(100%);
}
.slide-in {
  animation: slide-in 0.5s forwards;
}
.slide-out {
  animation: slide-out 0.9s backwards;
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(-50%);
  }
  100% {
    width: translateX(100%);
  }
}
</style>
