import socket from '@/js/socket'

export default {
  actions: {
    connect ({ rootState }) {
      if (!socket.connected) {
        if (rootState.users.user && rootState.users.user.profile) {
          socket.auth = { userId: rootState.users.user.profile.id }

          socket.connect()
          socket.on('connect_error', (err) => {
            if (err.message === 'invalid username') {
              console.log('Invalid user name')
            }
          })
        } else {
          console.log('not logged in yet')
        }
      } else {
        console.log('already connected, do nothing')
      }
    },
    disconnect () {
      console.log('socket disconnect')
      socket.disconnect()
    },
    clean () {
      socket.off('connect_error')
    }
  },
  namespaced: true
}
