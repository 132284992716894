import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('eventsCalender')

export default {
  state: {
    loader: false,
    eventsList: {},
    selectedQuarter: null
  },
  getters: {
    loader: state => state.loader,
    eventsList: state => state.eventsList,
    selectedQuarter: state => state.selectedQuarter,
    navigationData: state => {
      const quarterList = Object.keys(state.eventsList)
      const quarterListWithEventNumber = quarterList.map(quarter => {
        const eventNumber = state.eventsList[quarter].length
        return { quarter, eventNumber }
      }
      )
      return quarterListWithEventNumber
    },
    selectedQuarterEvents: state => {
      if (state.selectedQuarter) {
        return state.eventsList[state.selectedQuarter]
      }
      return []
    }
  },
  mutations: {
    SET_LOADER (state, payload) {
      state.loader = payload
    },
    SET_EVENTS_LIST (state, payload) {
      state.eventsList = payload

      if (!Object.keys(payload).includes(state.selectedQuarter)) {
        state.selectedQuarter = Object.keys(payload)[0]
      }
    },
    SET_SELECTED_QUARTER (state, payload) {
      state.selectedQuarter = payload
    }
  },
  actions: {
    selectQuarter ({ commit }, payload) {
      commit('SET_SELECTED_QUARTER', payload)
    },
    async fetchEvents ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADER', true)
        const response = await repository.getEventsQuarterly(payload)
        commit('SET_LOADER', false)
        if (response.status === 200 && response.data) {
          commit('SET_EVENTS_LIST', response.data)
        }
      } catch (err) {
        commit('SET_LOADER', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    },
    async addEvent ({ commit, dispatch, rootGetters }, payload) {
      try {
        commit('SET_LOADER', true)
        const response = await repository.addEvent(payload)
        commit('SET_LOADER', false)
        if (response.status === 201) {
          dispatch('snackbar/snack', { type: 'success', text: 'EVENT ADDED', show: true, autoclose: true }, { root: true })
          dispatch('fetchEvents', { companyId: rootGetters['companies/companyId'] })
        }
      } catch (err) {
        commit('SET_LOADER', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    },
    async updateEvent ({ commit, dispatch, rootGetters }, payload) {
      try {
        commit('SET_LOADER', true)
        const response = await repository.updateEvent(payload)
        commit('SET_LOADER', false)
        if (response.status === 200) {
          dispatch('snackbar/snack', { type: 'success', text: 'EVENT UPDATED', show: true, autoclose: true }, { root: true })
          dispatch('fetchEvents', { companyId: rootGetters['companies/companyId'] })
        }
      } catch (err) {
        commit('SET_LOADER', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteEvent ({ commit, dispatch, rootGetters }, payload) {
      try {
        commit('SET_LOADER', true)
        const response = await repository.deleteEvent(payload)
        commit('SET_LOADER', false)
        if (response.status === 200) {
          dispatch('snackbar/snack', { type: 'success', text: 'EVENT DELETED', show: true, autoclose: true }, { root: true })
          dispatch('fetchEvents', { companyId: rootGetters['companies/companyId'] })
        }
      } catch (err) {
        commit('SET_LOADER', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
