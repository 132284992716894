import {
  RepositoryFactory
} from '@/api/repositoryFactory'
import { i18n } from '@/js/language/'
import router from '@/js/router'
import auth from '@/js/helpers/auth'
import mentions from '@/js/helpers/mentions'
import { differenceInSeconds } from 'date-fns'

// import { cfaSignIn } from 'capacitor-firebase-auth'
const repository = RepositoryFactory.get('users')

export default {
  state: {
    needToVerifyTerms: false,
    selectedUserId: null,
    dsecret: null,
    showBecomePremium: false,
    user: {},
    roles: [],
    followings: {},
    followers: {},
    userList: [],
    filteredUserList: [],
    otherUser: {},
    saved: [],
    selectedOtherUserId: null,
    isRegistered: false,
    alreadyRegistered: false,
    isUpdated: false,
    isLoggedin: false,
    loadingRegistration: false,
    loadingBlock: false,
    loadingUpdate: false,
    loadingUsers: false,
    loadingBubble: false,
    loadingLogin: false,
    loadingOTPCheck: false,
    loadingProfileImage: false,
    isVerified: true,
    loginError: '',
    accessToken: '',
    loading: false,
    showVerificationModal: false,
    multiUsers: [],
    loadingFollowings: false,
    loadingFollowers: false,
    savedLoading: false,
    isFollowed: false,
    maybeLater: false,
    isBlocked: false,
    nextPageUrl: null,
    loadingNew: false,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userMenuReference: null,
    successUrl: 'marketplace',
    create: false,
    title: '',
    body: '',
    locked_at: null,
    locked_to: null,
    userBlocked: false,
    emailSent: false,
    secondaryEmailSent: false,
    setNewPassword: false,
    menu: [
      { slide: 0, name: 'userFlow', label: 'Flow' }
      // { slide: 1, name: 'userMedia', label: 'Media' },
      // { slide: 2, name: 'userCompany', label: 'Company' }
    ],
    FCMToken: null,
    editUserError: '',
    otpConfirmationError: '',
    activeTheme: 'light',
    usersCompany: [],
    currencies: [],
    countries: [],
    isdCodes: [],
    loadingCurrency: false,
    loadingSecondaryEmail: false,
    loadingStripeCheckout: false,
    isCompanyPremium: false,
    investorPremium: null,
    userFollowings: []
  },
  getters: {
    investorPremium: state => state.investorPremium,
    isCompanyPremium: state => state.isCompanyPremium,
    loadingStripeCheckout: state => state.loadingStripeCheckout,
    successUrl: state => state.successUrl,
    showBecomePremium: state => state.showBecomePremium,
    isLoggedin: state => state.isLoggedin,
    isRegistered: state => state.isRegistered,
    alreadyRegistered: state => state.alreadyRegistered,
    isUpdated: state => state.isUpdated,
    loginError: state => state.loginError,
    user: state => state.user,
    dsecret: state => state.dsecret,
    followings: state => state.followings,
    followers: state => state.followers,
    userList: state => state.userList,
    filteredUserList: state => state.filteredUserList,
    companies: state => state.user.profile.companies,
    loadingUsers: state => state.loadingUsers,
    token: state => state.accessToken,
    profile: state => state.user.profile,
    menu: state => state.menu,
    showVerificationModal: state => state.showVerificationModal,
    roles: state => state.roles,
    otherUserProfile: state => state.otherUser,
    loading: state => state.loading,
    isFollowed: state => state.isFollowed,
    isBlocked: state => state.isBlocked,
    multiUsers: state => state.multiUsers,
    create: state => state.create,
    body: state => state.body,
    title: state => state.title,
    loadingOTPCheck: state => state.loadingOTPCheck,
    loadingFollowings: state => state.loadingFollowings,
    loadingFollowers: state => state.loadingFollowers,
    loadingLogin: state => state.loadingLogin,
    loadingProfileImage: state => state.loadingProfileImage,
    loadingRegistration: state => state.loadingRegistration,
    loadingBlock: state => state.loadingBlock,
    saved: state => state.saved,
    savedLoading: state => state.savedLoading,
    loadingNew: state => state.loadingNew,
    FCMToken: state => state.FCMToken,
    editUserError: state => state.editUserError,
    otpConfirmationError: state => state.otpConfirmationError,
    activeTheme: state => state.activeTheme,
    locked_at: state => state.locked_at,
    locked_to: state => state.locked_to,
    userBlocked: state => state.userBlocked,
    emailVerifiedAt: state => state.emailVerifiedAt,
    emailSent: state => state.emailSent,
    secondaryEmailSent: state => state.secondaryEmailSent,
    setNewPassword: state => state.setNewPassword,
    loadingCurrency: state => state.loadingCurrency,
    showVerify (state) {
      if (state.maybeLater) {
        return false
      }
      if (!state.user.profile) {
        return false
      }
      if (state.user.profile_type.indexOf('GuestUser') > -1) {
        return false
      }

      return !state.user.profile.is_verified
    },
    needToVerifyTerms: state => state.needToVerifyTerms,
    usersCompany: state => state.usersCompany,
    currencies: state => {
      if (typeof state.currencies !== 'undefined' && state.currencies.length > 0) {
        state.currencies.unshift('EUR')
      }
      return state.currencies
    },
    countries: state => state.countries,
    isdCodes: state => state.isdCodes,
    loadingSecondaryEmail: state => state.loadingSecondaryEmail,
    userFollowings: state => state.userFollowings
  },
  mutations: {
    SET_IS_COMPANY_PREMIUM (state, boolean) {
      state.isCompanyPremium = !!boolean
    },
    SET_INVESTOR_PREMIUM (state, payload) {
      state.investorPremium = payload
    },
    SET_SHOW_VERIFICATION_MODAL (state, payload) {
      state.showVerificationModal = payload
    },
    SET_SUCCESS_URL (state, payload) {
      state.successUrl = payload
    },
    SET_OTP_CONFIRMATION_ERROR (state, boolean) {
      state.otpConfirmationError = boolean
    },
    SET_EDIT_USER_ERROR (state, payload) {
      state.editUserError = payload
    },
    SET_USER_ROLES (state, payload) {
      if (payload !== '' && payload !== 'undefined' && payload !== undefined) {
        localStorage.roles = payload
        state.roles = JSON.parse(payload)
      }
    },
    SET_MULTI_USERS (state, payload) {
      if (payload !== '' && payload !== 'undefined' && payload !== undefined) {
        state.multiUsers = payload
      }
    },
    REMOVE_SAVED_DATA (state, id) {
      state.saved.splice(state.saved.findIndex(data => data.id === id), 1)
    },
    SET_USERS_COMPANY_LIST (state, payload) {
      state.usersCompany = payload
    },
    UPDATE_USERS_COMPANY (state, payload) {
      const index = state.usersCompany.findIndex(company => company.slug_url === payload.slug_url)
      if (index > -1) {
        state.usersCompany[index].logo = payload.logo
        state.usersCompany[index].color = payload.color
        state.usersCompany = [...state.usersCompany]
      }
    },
    SET_LOADING_NEW (state, boolean) {
      state.loadingNew = boolean
    },
    SET_USER (state, payload) {
      localStorage.user = JSON.stringify(payload)
      state.user = payload
    },
    SET_USER_PROFILE (state, payload) {
      state.user = { ...state.user, profile: payload }
      localStorage.user = JSON.stringify(state.user)
    },
    IS_REGISTERED (state, boolean) {
      state.isRegistered = boolean
    },
    ALREADY_REGISTERED (state, boolean) {
      state.alreadyRegistered = boolean
    },
    IS_UPDATED (state, boolean) {
      state.isUpdated = boolean
    },
    IS_LOGGED_IN (state, boolean) {
      state.isLoggedin = boolean
    },
    SET_BECOME_PREMIUM_SEEN (state, boolean) {
      state.showBecomePremium = boolean
    },
    LOADING_OTP_CHECK (state, boolean) {
      state.loadingOTPCheck = boolean
    },
    LOADING_REGISTRATION (state, boolean) {
      state.loadingRegistration = boolean
    },
    LOADING_BLOCK (state, boolean) {
      state.loadingBlock = boolean
    },
    LOADING_BUBBLE (state, boolean) {
      state.loadingBubble = boolean
    },
    LOADING_UPDATE (state, boolean) {
      state.loadingUpdate = boolean
    },
    LOADING_LOGIN (state, boolean) {
      state.loadingLogin = boolean
    },
    LOADING_PROFILE_IMAGE (state, boolean) {
      state.loadingProfileImage = boolean
    },
    SET_LOCKED_TO (state, data) {
      localStorage.locked_to = data
      state.locked_to = data
      if (differenceInSeconds(new Date(state.locked_to), new Date()) > 0) state.userBlocked = true
    },

    SET_USER_BLOCKED_STATUS (state, boolean) {
      state.userBlocked = boolean
    },
    SET_LOCKED_AT (state, data) {
      state.locked_at = data
    },
    UPDATE_FOLLOW (state, payload) {
      let userIndex = state.followers.findIndex(user => Number(payload.id) === Number(user.id))
      // let company = state.companies[companyIndex]
      state.followers[userIndex].is_follower = payload.is_follower
      // state.followers[userIndex] = company
      state.followers = [...state.followers]
    },
    SET_NEW_PASSWORD (state, boolean) {
      state.setNewPassword = boolean
    },
    SET_TOKEN (state, token) {
      state.accessToken = token
      // console.log('SET_TOKEN', token)
      auth.storeToken(token)
    },
    SET_TEMPORARY_TOKEN (state, token) {
      state.accessToken = token
      auth.storeTemporaryToken(token)
    },
    SET_FOLLOWINGS (state, data) {
      state.followings = data
    },
    SET_FOLLOWERS (state, data) {
      state.followers = data
    },

    SET_LOGIN_ERROR (state, error) {
      state.loginError = error
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_SAVED_LOADING (state, boolean) {
      state.savedLoading = boolean
    },
    RESET_USER_LIST (state) {
      state.userList = []
    },
    SET_USER_LIST (state, payload) {
      if (state.userList.length < 1) {
        state.userList = payload
      } else {
        payload.find(response => {
          const flag = state.userList.find(user => {
            return (response.id === user.id)
          })
          if (!flag) {
            state.userList.push(response)
          }
        })
      }

      // if (state.filteredUserList.length < 0) {
      //   Array.prototype.forEach.call(payload, response => {
      //     state.filteredUserList.push({
      //       id: response.id,
      //       label: `${response.full_name} (user)`,
      //       value: response.username,
      //       type: 'stockUser'
      //     })
      //   })
      // } else {
      //   payload.find(response => {
      //     const temp = state.filteredUserList.find(user => {
      //       return (response.id === user.id)
      //     })
      //     if (!temp) {
      //       state.filteredUserList.push({
      //         id: response.id,
      //         label: `${response.full_name} (user)`,
      //         value: response.username,
      //         type: 'stockUser'
      //       })
      //     }
      //   })
      // }

      if (state.filteredUserList.length < 0) {
        Array.prototype.forEach.call(payload, stockUser => {
          state.filteredUserList.push({
            ...stockUser,
            type: 'stockUser'
          })
        })
      } else {
        payload.find(stockUser => {
          const temp = state.filteredUserList.find(user => {
            return (stockUser.id === user.id)
          })
          if (!temp) {
            state.filteredUserList.push({
              ...stockUser,
              type: 'stockUser'
            })
          }
        })
      }
    },
    SET_EMAIL_SENT (state, boolean) {
      state.emailSent = boolean
    },
    SET_SECONDARY_EMAIL_SENT (state, boolean) {
      state.secondaryEmailSent = boolean
    },
    VERIFY_MAYBE_LATER (state, boolean) {
      state.maybeLater = boolean
    },
    SET_LIST (state, payload) {
      state.otherUser = { ...payload }
    },
    SELECT_OTHER_USER (state, otherUserId) {
      state.selectedOtherUserId = otherUserId
    },
    IS_FOLLOWED (state, boolean) {
      state.isFollowed = boolean
    },
    IS_BLOCKED (state, boolean) {
      state.isBlocked = boolean
    },
    SET_VERIFY_TERMS (state, boolean) {
      state.needToVerifyTerms = boolean
      localStorage.needToVerifyTerms = boolean
    },
    SET_ALERT (state, payload) {
      state.title = payload.title
      state.body = payload.body
      state.create = payload.create
    },
    REMOVE_TOKEN (state, payload) {
      state.accessToken = ''
      state.user = {}
      auth.clearToken()
    },
    SET_LOADING_USERS (state, boolean) {
      state.loadingUsers = boolean
    },
    SET_LOADING_FOLLOWINGS (state, boolean) {
      state.loadingFollowings = boolean
    },
    SET_LOADING_FOLLOWERS (state, boolean) {
      state.loadingFollowers = boolean
    },
    SET_LOADING_STRIPE (state, boolean) {
      state.loadingStripeCheckout = boolean
    },
    SAVED_DATA (state, payload) {
      payload.filter(saved => {
        if (saved.saveable_type === 'App\\Models\\Post') {
          let data = saved.saveable
          if (data.postable.message && data.postable.message.match(mentions.MATCHING)) {
            data.postable.message = mentions.parse(data.postable.message, data.message.mentions)
          }
          // console.log(data)
        }
      })
      state.saved = [...payload]
    },
    SET_PROFILE_IMAGE (state, payload) {
      // state.user.profile.image = payload
      state.user.profile = { ...state.user.profile, image: payload }
      state.user = { ...state.user }
      localStorage.user = JSON.stringify(state.user)
    },
    SET_CURRENCY_LOADING (state, boolean) {
      state.loadingCurrency = boolean
    },
    SAVED_NEXT_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    APPEND_SAVED (state, payload) {
      payload.find(response => {
        const flag = state.saved.find(data => {
          return (response.id === data.id)
        })
        if (!flag || flag === undefined) {
          state.saved.push(response)
        }
      })
      // state.posts = [ ...state.posts, ...payload ]
    },
    APPEND_USERS (state, payload) {
      payload.map(response => {
        const flag = state.userList.find(data => {
          return (response.id === data.id)
        })
        if (!flag || flag === undefined) {
          state.userList.push(response)
        }
      })
    },
    SET_FCM_TOKEN (state, token) {
      localStorage.setItem('FCMToken', token)
      state.FCMToken = token
    },
    SET_THEME (state, payload) {
      localStorage.activeTheme = payload
      state.activeTheme = payload
    },
    SET_DSECRET (state, dsecret) {
      state.dsecret = dsecret
    },
    SET_CURRENCIES (state, currencies) {
      state.currencies = currencies
    },
    SET_COUNTRIES (state, countries) {
      state.countries = countries
    },
    SET_ISD_CODES (state, isdCodes) {
      state.isdCodes = isdCodes
    },
    LOADING_SECONDARY_EMAIL (state, boolean) {
      state.loadingSecondaryEmail = boolean
    },
    SET_USER_FOLLOWINGS (state, payload) {
      state.userFollowings = payload
    }
  },
  actions: {
    async fetchInvestorSubscription ({ commit, dispatch }) {
      try {
        const response = await repository.fetchInvestorSubscription()
        if (response.status === 200 && response.data) {
          commit('SET_INVESTOR_PREMIUM', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async isCompanyPremium ({ commit, dispatch }) {
      try {
        const response = await repository.isCompanyPremium()
        if (response.status === 200 && response.data) {
          commit('SET_IS_COMPANY_PREMIUM', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async saveCurrency ({ commit, dispatch }, payload) {
      try {
        commit('SET_CURRENCY_LOADING', true)
        const response = await repository.saveCurrency(payload)
        if (response.status === 200 && response.data) {
          commit('SET_USER_PROFILE', response.data)
        }
        commit('SET_CURRENCY_LOADING', false)
      } catch (e) {
        commit('SET_CURRENCY_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async setSuccessUrl ({ commit }, payload) {
      await commit('SET_SUCCESS_URL', payload.type)
    },
    async updateSubscription ({ commit }, data) {
      const response = await repository.updateSubscription(data)
      if (response.status === 200 && response.data) {
        commit('SET_USER', response.data)
        router.go(-1)
      }
    },
    async cancelUserSubscription ({ commit, dispatch }) {
      try {
        commit('SET_LOADING_STRIPE', true)
        const response = await repository.cancelUserSubscription()
        if (response.status === 200 && response.data) {
          commit('SET_USER', response.data)
        }
        commit('SET_LOADING_STRIPE', false)
      } catch (e) {
        commit('SET_LOADING_STRIPE', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async createCompanyCheckoutSession ({ commit, dispatch, getters }, companyId) {
      try {
        commit('SET_LOADING', true)
        commit('SET_LOADING_STRIPE', true)
        const response = await repository.createCompanyCheckoutSession(companyId, { return_url: getters.successUrl })
        if (response.status === 200 && response.data) {
          if (response.data.checkoutSessionId !== null) {
            sessionStorage.onboarding = JSON.stringify({})
            await window.Stripe(response.data.stripeKey).redirectToCheckout({ sessionId: response.data.checkoutSessionId })
          } else {
            console.log('something went wrong.')
            // router.go(-1)
          }
        } else if (response.status === 204) {
          dispatch('snackbar/snack', { type: 'info', text: 'Company already have an active subscription or You do not have permissions.', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
        commit('SET_LOADING_STRIPE', false)
      } catch (e) {
        commit('SET_LOADING', false)
        commit('SET_LOADING_STRIPE', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async createCheckoutSession ({ commit, dispatch, getters }) {
      try {
        commit('SET_LOADING', true)
        commit('SET_LOADING_STRIPE', true)
        const response = await repository.createCheckoutSession({ return_url: getters.successUrl })
        if (response.status === 200 && response.data) {
          if (response.data.checkoutSessionId !== null) {
            await window.Stripe(response.data.stripeKey).redirectToCheckout({ sessionId: response.data.checkoutSessionId })
          } else {
            console.log('something went wrong.')
            // router.go(-1)
          }
        } else if (response.status === 204) {
          dispatch('snackbar/snack', { type: 'info', text: 'You already have an active subscription.', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
        commit('SET_LOADING_STRIPE', false)
      } catch (e) {
        commit('SET_LOADING', false)
        commit('SET_LOADING_STRIPE', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async checkSubscription ({ commit, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.checkSubscription()
        if (response.status === 200 && response.data) {
          await commit('SET_USER', response.data.user)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addCompaniesToUser ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.addCompaniesToUser(payload.user.id, { companyList: payload.companyList })
        if (response.status === 200 && !response.data.invalid) {
          commit('SET_USERS_COMPANY_LIST', response.data)
        } else {
          dispatch('snackbar/snack', { text: i18n.t('User does not have permission.'), type: 'error', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async removeCompaniesRole ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.removeCompaniesRole(payload.userId, { role: payload.role })
        if (response.status === 200 && !response.data.invalid) {
          commit('SET_USERS_COMPANY_LIST', response.data)
        } else {
          dispatch('snackbar/snack', { text: i18n.t('User does not have permission.'), type: 'error', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async seenBubbleTag ({ commit, dispatch, state }, payload) {
      const response = await repository.seenBubble({ tag: payload.tag })

      if (response && response.status === 200) {
        if (payload.timer) {
          setTimeout(() => {
            dispatch('fetch')
          }, 5000)
        } else {
          dispatch('fetch')
        }
      }
    },
    async seenBubbleType ({ commit, dispatch, state }, payload) {
      const response = await repository.seenBubble({ tag: payload.tag, type: payload.type })

      if (response && response.status === 200) {
        if (response && response.status === 200) {
          if (payload.timer) {
            setTimeout(() => {
              dispatch('fetch')
            }, 5000)
          } else {
            dispatch('fetch')
          }
        }
      }
    },
    async usersCompanies ({ commit, dispatch, state }, user) {
      try {
        commit('SET_LOADING_USERS', true)
        const response = await repository.usersCompanies(user.id)
        if (response.status === 200 && !response.data.invalid) {
          commit('SET_USERS_COMPANY_LIST', response.data)
        } else {
          dispatch('snackbar/snack', { text: i18n.t('User does not have permission.'), type: 'error', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING_USERS', false)
      } catch (e) {
        commit('SET_LOADING_USERS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    visited ({ state }, toPath) {
      repository.visited({ path: toPath })
    },
    async dsecret ({ commit, state, rootGetters }) {
      return new Promise(async (resolve, reject) => {
        try {
          if (!auth.getDSecret()) {
            const response = await repository.dsecret(rootGetters['app/platformKey'])
            if ((response.status === 200) && response.data) {
              await auth.storeDSecret(response.data.dsecret)
              resolve()
            }
          } else {
            await auth.setDSecretHeader(auth.getDSecret())
            resolve()
          }
        } catch (e) {
          reject(e)
        }
      })
    },
    setDSecret ({ commit }, dsecret) {
      commit('SET_DSECRET', dsecret)
    },
    async one ({ commit, state }, payload) {
      let stockUserId = state.selectedOtherUserId
      if (payload === null || payload === undefined) {
        commit('SET_LOADING', true)
      } else {
        stockUserId = payload
      }

      try {
        const response = await repository.one(stockUserId)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (error) {
        commit('SET_LIST', null)
      }
      commit('SET_LOADING', false)
    },
    async fetchUsers ({ commit, dispatch, state }, payload) {
      try {
        commit('SET_LOADING', true)
        let list = []
        list = payload.filter(userId => (state.userList.findIndex(user => Number(user.id) === Number(userId))) < 0)
        if (list.length > 0) {
          const response = await repository.fetchUserList({ 'users': list })
          if ((response.status === 200) && response.data) {
            commit('SET_USER_LIST', response.data)
          }
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchMultiUsers ({ commit, dispatch, state }, payload) {
      try {
        commit('SET_LOADING', true)

        const response = await repository.fetchMultiUsers()
        if ((response.status === 200) && response.data) {
          commit('SET_MULTI_USERS', response.data)
        }

        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    setUserBlockedStatus ({ commit }, boolean) {
      commit('SET_USER_BLOCKED_STATUS', boolean)
    },
    setUserProfile ({ commit }, payload) {
      commit('SET_USER_PROFILE', payload)
      commit('SET_LOCKED_AT', payload.locked_at)
      commit('SET_LOCKED_TO', payload.locked_to)
    },
    async fetchUserById ({ commit, state }, id) {
      const user = state.userList.find(user => Number(user.id) === Number(id)) ? 1 : 0
      if (user === 0) {
        const response = await repository.one(id)
        if ((response.status === 200) && response.data) {
          commit('SET_USER_LIST', [response.data])
        }
      }
    },
    async removeSavedData ({ commit, dispatch }, id) {
      try {
        const response = await repository.removeSaved(id)
        if (response.status === 204) {
          commit('REMOVE_SAVED_DATA', id)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchSaved ({ commit, dispatch }) {
      try {
        commit('SET_SAVED_LOADING', true)
        const response = await repository.fetchSaved()
        commit('SET_SAVED_LOADING', false)
        if (response.status === 200) {
          commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
          commit('SAVED_DATA', response.data.data)
        }
      } catch (e) {
        commit('SET_SAVED_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    setOnline () {
      const response = repository.setOnline()
      if (response.status === 204) {
        // console.log('user is online')
      }
    },
    async profile ({ commit, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.profile()
        commit('SET_LOADING', false)
        if (response.status === 200) {
          commit('SET_USER_PROFILE', response.data)
        } else {
          // console.log('No response found')
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchStockUserInfo ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING_USERS', true)
        commit('RESET_USER_LIST')
        const response = await repository.fetchStockUserInfo(payload.companyId, payload)
        if ((response.status === 200) && response.data) {
          commit('SET_USER_LIST', response.data.data)
          commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
        }
        commit('SET_LOADING_USERS', false)
      } catch (e) {
        commit('SET_LOADING_USERS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchAll ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING_USERS', true)
        const response = await repository.fetchAllUsers(payload)
        if ((response.status === 200) && response.data) {
          commit('SET_USER_LIST', response.data.data)
          commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
        }
        commit('SET_LOADING_USERS', false)
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async searchAll ({ commit, dispatch }, payload) {
      commit('SET_LOADING_USERS', true)
      commit('RESET_USER_LIST')
      await dispatch('fetchAll', payload)
      // try {
      //   commit('SET_LOADING_USERS', true)
      //   const response = await repository.fetchAllUsers(payload)
      //   commit('SET_LOADING_USERS', false)
      //   if ((response.status === 200) && response.data) {
      //     commit('SET_USER_LIST', response.data.data)
      //     commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
      //   }
      // } catch (e) {
      //   dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      // }
    },
    async fetchFollowings ({ state, commit, dispatch }) {
      if (state.user.profile_type === 'App\\Models\\GuestUser') {
        return
      }

      try {
        commit('SET_LOADING_FOLLOWINGS', true)
        const response = await repository.fetchFollowings(state.user.profile.id)
        commit('SET_LOADING_FOLLOWINGS', false)
        if ((response.status === 200) && response.data) {
          state.user.profile.followings = response.data

          commit('SET_USER_FOLLOWINGS', response.data)

          commit('SET_USER', { ...state.user })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },

    async fetchOtherProfileFollowers ({ state, commit, dispatch }, id) {
      try {
        commit('SET_LOADING_FOLLOWERS', true)
        const response = await repository.fetchFollowers(id)
        commit('SET_LOADING_FOLLOWERS', false)

        if ((response.status === 200) && response.data) {
          commit('SET_FOLLOWERS', response.data)
          commit('SET_USER', state.user)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchOtherProfileFollowings ({ state, commit, dispatch }, id) {
      try {
        commit('SET_LOADING_FOLLOWINGS', true)
        const response = await repository.fetchFollowings(id)
        commit('SET_LOADING_FOLLOWINGS', false)

        if ((response.status === 200) && response.data) {
          commit('SET_FOLLOWINGS', response.data)
          commit('SET_USER', state.user)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async warningSeen ({ commit, dispatch }, payload) {
      try {
        const response = await repository.warningSeen(payload)
        if (response.status === 200 && response.data) {
          commit('SET_USER', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async setShowBecomePremium ({ commit, dispatch }, payload) {
      commit('SET_BECOME_PREMIUM_SEEN', payload)
    },
    selectUser ({ commit, dispatch, state, rootState }, userId) {
      commit('SELECT_OTHER_USER', userId)
    },
    async fetch ({ state, commit, dispatch }) {
      if (auth.getAccessToken()) {
        // console.log('TOKEN TOKEN TOKEN', auth.getAccessToken())

        commit('SET_TOKEN', auth.getAccessToken())
      }

      // console.log('USER FETCH USER FETCH USER FETCH')

      if (localStorage.user !== undefined && localStorage.user !== '') {
        commit('SET_USER', JSON.parse(localStorage.user))
        commit('SET_USER_ROLES', localStorage.roles)
      }
      if (localStorage.getItem('isLoggedIn')) {
        commit('IS_LOGGED_IN', JSON.parse(localStorage.isLoggedIn))
      }

      // console.log('USER TOKEN USER TOKEN USER TOKEN')
      if (state.accessToken) {
        // console.log('USER TOKEN USER TOKEN USER TOKEN')

        try {
          const response = await repository.me()
          if (response.data.username !== 'Guest') {
            // console.log('USER DONE USER DONE USER DONE')
            commit('SET_USER', response.data)

            commit('SET_USER_ROLES', JSON.stringify(response.data.roles))
          }
        } catch (e) {
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    setAccessToken ({
      commit
    }, token) {
      commit('SET_TOKEN', token)
    },
    updateFCMToken ({ dispatch }) {
      if (localStorage.getItem('fcmToken') && auth.getAccessToken()) {
        dispatch('setFirebaseToken', localStorage.getItem('fcmToken'))
      }
    },
    async login ({ commit, dispatch }, payload) {
      try {
        commit('LOADING_LOGIN', true)

        const response = await repository.login(payload)
        commit('LOADING_LOGIN', false)
        if (response.status === 200) {
          if (response.data.token && response.data.token.length > 0) {
            // console.log('login data', response.data.user)
            commit('IS_LOGGED_IN', true)
            dispatch('setFirebaseToken', localStorage.getItem('fcmToken'))
            commit('SET_TOKEN', response.data.token)
            commit('SET_USER', response.data.user)
            commit('SET_LOCKED_AT', response.data.user.profile.locked_at)
            commit('SET_LOCKED_TO', response.data.user.profile.locked_to)
            commit('SET_USER_ROLES', JSON.stringify(response.data.user.roles))
            dispatch('profile')
            router.push({
              name: 'generalfeed'
            })
          }
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })

        commit('LOADING_LOGIN', false)
        // commit('SET_ALERT', { create: true, title: 'Login Error!', body: 'Invalid credentials. please check your Email Id or Password' })
        commit('SET_LOGIN_ERROR', e.response.data.message)
      }
    },
    async sendOtp ({ commit, dispatch }, payload) {
      try {
        commit('LOADING_LOGIN', true)
        const response = await repository.sendOpt(payload)
        if (response.status === 200) {
          commit('LOADING_LOGIN', false)
          commit('SET_EMAIL_SENT', true)
          dispatch('snackbar/snack', { text: i18n.t('Code generated successfully. please check out email for the code'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('LOADING_LOGIN', false)
        if (Number(e.response.status) === 422) {
          dispatch('snackbar/snack', { text: i18n.t('Email does not exists in our record. please enter correct email'), type: 'error', show: true, autoclose: false }, { root: true })
        } else {
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    async sendSecondaryEmailVerification ({ commit, dispatch }, payload) {
      try {
        commit('LOADING_SECONDARY_EMAIL', true)
        const response = await repository.sendSecondaryEmailOTP(payload)
        if (response.status === 200) {
          commit('LOADING_SECONDARY_EMAIL', false)
          commit('SET_SECONDARY_EMAIL_SENT', true)
          dispatch('snackbar/snack', { text: i18n.t('Code generated successfully. please check out email for the code'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('LOADING_LOGIN', false)
        if (Number(e.response.status) === 422) {
          dispatch('snackbar/snack', { text: i18n.t('Email does not exists in our record. please enter correct email'), type: 'error', show: true, autoclose: false }, { root: true })
        } else {
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    async otpVerificationForForgotPassword ({ commit, dispatch, state, rootState }, payload) {
      try {
        commit('LOADING_LOGIN', true)
        const response = await repository.verifyEmailForForgotPassword(payload)
        if (response.status === 200 && response.data) {
          commit('SET_EMAIL_SENT', false)
          commit('SET_NEW_PASSWORD', true)
          commit('LOADING_LOGIN', false)
        }
      } catch (e) {
        commit('LOADING_LOGIN', false)
        if (Number(e.response.status) === 422) {
          dispatch('snackbar/snack', { text: i18n.t('Email does not exists in our record. please enter correct email'), type: 'error', show: true, autoclose: false }, { root: true })
        } else {
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    async resetPassword ({ commit, dispatch, state }, payload) {
      try {
        commit('LOADING_LOGIN', true)
        const response = await repository.resetPassword(payload)
        if (response.status === 200 && response.data) {
          commit('SET_NEW_PASSWORD', false)
          commit('LOADING_LOGIN', false)
          if (response.data.token !== undefined && response.data.token.length > 0) {
            commit('IS_LOGGED_IN', true)
            dispatch('initFirebaseMessagingRegistration')
            commit('SET_TOKEN', response.data.token)
            commit('SET_USER', response.data.user)
            commit('SET_EMAIL_VERIFIED_AT', response.data.user.email_verified_at)
            dispatch('profile')
            router.push({
              name: 'generalfeed'
            })
          }
        }
      } catch (e) {
        commit('LOADING_LOGIN', false)
        commit('SET_NEW_PASSWORD', false)
        if (Number(e.response.status) === 422) {
          dispatch('snackbar/snack', { text: i18n.t('Email does not exists in our record. please enter correct email'), type: 'error', show: true, autoclose: false }, { root: true })
        } else {
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    async sendEmailVerification ({ commit, dispatch, state }) {
      const response = await repository.emailVerificationNeeded(state.user.id)
      if (response.status === 200) {
        // console.log(response.data)
        dispatch('snackbar/snack', { text: i18n.t('Code generated successfully. please check out email for the code'), type: 'success', show: true, autoclose: true }, { root: true })
      }
    },
    async otpVerification ({ commit, dispatch, state, rootState }, payload) {
      try {
        commit('LOADING_OTP_CHECK', true)
        const response = await repository.verifyEmailWithOTP(state.user.id, payload)
        commit('LOADING_OTP_CHECK', false)
        if (response.status === 200 && response.data) {
          if (response.data.status === 'error') {
            dispatch('snackbar/snack', { text: response.data.message, type: 'error', show: true, autoclose: true }, { root: true })
          } else {
            await commit('IS_REGISTERED', true)
            await commit('IS_LOGGED_IN', true)
            await commit('SET_USER', response.data.user)
            dispatch('snackbar/snack', { text: i18n.t('Email verified successfully'), type: 'success', show: true, autoclose: true }, { root: true })
            if (payload.process === 'signupEmailVerification') {
              const name = (payload.userType === 'companyUser') ? 'onboarding-company' : 'onboarding'
              router.push({
                name
              })
            }
          }
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async otpSecondaryEmailVerification ({ commit, dispatch, state, rootState }, payload) {
      try {
        commit('LOADING_OTP_CHECK', true)
        const response = await repository.secondaryEmailverifyOTP(payload)
        commit('LOADING_OTP_CHECK', false)
        if (response.status === 200 && response.data) {
          if (response.data.status === 'error') {
            dispatch('snackbar/snack', { text: response.data.message, type: 'error', show: true, autoclose: true }, { root: true })
          } else {
            await commit('IS_REGISTERED', true)
            await commit('IS_LOGGED_IN', true)
            await commit('SET_USER', response.data.user)
            dispatch('snackbar/snack', { text: i18n.t('Email verified successfully'), type: 'success', show: true, autoclose: true }, { root: true })
          }
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async register ({ commit, state, dispatch, rootGetters }, payload) {
      commit('LOADING_REGISTRATION', true)
      payload.timezone = state.timezone
      payload.platform_key = rootGetters['app/platformKey']
      try {
        const response = await repository.register(payload)
        commit('LOADING_REGISTRATION', false)
        // console.log(response)
        if (response.status === 200) {
          if (response.data && response.data.errors) {
            if (response.data.errors.taken) {
              await commit('ALREADY_REGISTERED', true)
            } else if (response.data.errors.email) {
              await commit('ALREADY_REGISTERED', true)
            }
          } else {
            await commit('IS_REGISTERED', true)
            await commit('IS_LOGGED_IN', true)
            await commit('SET_TOKEN', response.data.token)
            await commit('SET_USER', response.data.user)
            await commit('SET_USER_ROLES', JSON.stringify(response.data.user.roles))

            // if (!response.data.user.email_verified_at) {
            //   dispatch('sendEmailVerification')
            // }

            // const name = (payload.userType === 'companyUser') ? 'onboarding-company' : 'onboarding'
            // router.push({
            //   name
            // })

            setTimeout(() => {
              commit('IS_REGISTERED', false)
            }, 3000)
          }
        } else {
          if (response.data.errors.taken) {
            await commit('ALREADY_REGISTERED', true)
          } else if (response.data.errors.email) {
            await commit('ALREADY_REGISTERED', true)
          }
          commit('ALREADY_REGISTERED', true)
          setTimeout(() => {
            commit('ALREADY_REGISTERED', false)
          }, 5000)
        }
      } catch (e) {
        commit('LOADING_REGISTRATION', false)
        // commit('SET_ALERT', { create: true, title: 'Signup Error!', body: 'Please insert valid information' })
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })

        if (e.response.data.errors.taken) {
          await commit('ALREADY_REGISTERED', true)
        } else if (e.response.data.errors.email) {
          await commit('ALREADY_REGISTERED', true)
        }

        commit('ERROR_REGISTERED', true)
        setTimeout(() => {
          commit('ERROR_REGISTERED', false)
        }, 5000)
      }
    },
    async follow ({ commit, state, rootState, dispatch }, stockUserId = null) {
      try {
        let selectedOtherUserId = state.selectedOtherUserId
        if (stockUserId) {
          selectedOtherUserId = stockUserId
        }

        state.otherUser = { ...state.otherUser, is_follower: !state.otherUser.is_follower }

        const response = await repository.follow(selectedOtherUserId)
        if (response.status === 204) {
          dispatch('one', selectedOtherUserId)
          dispatch('fetchFollowings')
          dispatch('snackbar/snack', { text: i18n.t('User added to your following list'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async followerList ({ commit, state, rootState, dispatch }, id) {
      try {
        const selectedOtherUserId = id
        const userIndex = state.followers.findIndex((stockUser) => (stockUser.id === selectedOtherUserId))
        state.followers[userIndex].is_follower = !state.followers[userIndex].is_follower

        const response = await repository.follow(selectedOtherUserId)
        if (response.status === 204) {
          dispatch('one', selectedOtherUserId)
          dispatch('fetchFollowings')

          dispatch('snackbar/snack', { text: i18n.t('User added to your following list'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async followingList ({ commit, state, rootState, dispatch }, id) {
      try {
        const selectedOtherUserId = id
        const userIndex = state.followings.findIndex((stockUser) => (stockUser.id === selectedOtherUserId))
        state.followings[userIndex].is_follower = !state.followings[userIndex].is_follower

        const response = await repository.follow(selectedOtherUserId)
        if (response.status === 204) {
          dispatch('one', selectedOtherUserId)
          dispatch('fetchFollowings')

          dispatch('snackbar/snack', { text: i18n.t('User added to your following list'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async block ({ commit, state, rootState, dispatch }, payload) {
      try {
        commit('LOADING_BLOCK', true)

        // const stockUser = rootState.users.user.profile
        // const otherUser = state.otherUser

        // const followedIndex = stockUser.blockings.findIndex(user => (user.id === otherUser.profile_id))

        const response = await repository.block(state.selectedOtherUserId, payload)
        commit('LOADING_BLOCK', false)

        if (response.status === 204) {
          dispatch('fetch')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async firebaseRegister ({
      commit,
      state,
      dispatch,
      rootState
    }, payload) {
      commit('LOADING_REGISTRATION', true)
      payload.timezone = state.timezone
      try {
        const response = await repository.firebaseRegister(payload)
        commit('LOADING_REGISTRATION', false)
        commit('LOADING_LOGIN', false)
        commit('LOADING_LOGIN', false)
        if (response.status === 200) {
          commit('IS_REGISTERED', true)
          commit('SET_TOKEN', response.data.token)
          commit('SET_USER', response.data.user)
          commit('SET_USER_ROLES', JSON.stringify(response.data.user.roles))
          router.push({
            name: 'generalfeed'
          })
          setTimeout(() => {
            commit('IS_REGISTERED', false)
          }, 3000)
        } else {
          commit('ERROR_REGISTERED', true)
          setTimeout(() => {
            commit('ERROR_REGISTERED', false)
          }, 5000)
        }
      } catch (e) {
        commit('LOADING_REGISTRATION', false)
        commit('ERROR_REGISTERED', true)
        setTimeout(() => {
          commit('ERROR_REGISTERED', false)
        }, 5000)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    resetRegisterMessage ({ commit }) {
      commit('ALREADY_REGISTERED', false)
    },
    async resetError ({
      commit
    }) {
      commit('SET_LOGIN_ERROR', '')
      commit('SET_EDIT_USER_ERROR', '')
      commit('SET_OTP_CONFIRMATION_ERROR', '')
    },
    async verifyMaybeLater ({ commit }) {
      commit('VERIFY_MAYBE_LATER', true)
    },
    async verifyNow ({ commit }) {
      commit('VERIFY_MAYBE_LATER', false)
    },
    async update ({
      commit,
      state,
      dispatch
    }, payload) {
      try {
        payload.timezone = state.timezone
        payload.name = state.user.profile.name
        commit('LOADING_UPDATE', true)
        const response = await repository.update(payload)
        commit('LOADING_UPDATE', false)
        if (response.status === 200) {
          dispatch('fetch')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })

        commit('LOADING_UPDATE', false)
        if (e.data) commit('SET_LOGIN_ERROR', e.data.message)
        else commit('SET_LOGIN_ERROR', 'Error when trying to save!')
      }
    },
    async profileImage ({ commit, state, dispatch }, payload) {
      try {
        // commit('SET_PROFILE_IMAGE', payload.profile)
        commit('LOADING_PROFILE_IMAGE', true)
        const response = await repository.profileImage(payload)
        if (response.status === 201) {
          // console.log('Image uploaded successfully')
          commit('SET_PROFILE_IMAGE', response.data.image)
        }
        commit('LOADING_PROFILE_IMAGE', false)
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async setLogo ({ commit, state, dispatch }, payload) {
      commit('UPDATE_USERS_COMPANY', payload)
    },
    async setProfileImageLoading ({ commit, state, dispatch }, payload) {
      commit('LOADING_PROFILE_IMAGE', payload)
    },
    async logout ({
      commit,
      state,
      dispatch
    }) {
      try {
        // await firebaseLogout()

        localStorage.isLoggedIn = false

        dispatch('setFirebaseToken', null)

        dispatch('assets/reset', null, { root: true })
        dispatch('boardMembers/reset', null, { root: true })
        dispatch('calendarEvents/reset', null, { root: true })
        dispatch('chats/reset', null, { root: true })
        dispatch('comments/reset', null, { root: true })
        dispatch('companies/reset', null, { root: true })
        dispatch('contacts/reset', null, { root: true })
        dispatch('documents/reset', null, { root: true })
        dispatch('insiders/reset', null, { root: true })
        dispatch('media/reset', null, { root: true })
        dispatch('members/reset', null, { root: true })
        dispatch('notifications/reset', null, { root: true })
        dispatch('owners/reset', null, { root: true })
        dispatch('pressRelease/reset', null, { root: true })
        dispatch('timeline/reset', null, { root: true })
        dispatch('triggers/reset', null, { root: true })

        commit('REMOVE_TOKEN')
        commit('IS_LOGGED_IN', false)
        dispatch('socket/disconnect', null, { root: true })

        router.push({ name: 'login' })
        // router.go()
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async guest ({
      commit,
      dispatch
    }) {
      return new Promise(async (resolve, reject) => {
        if (!auth.getAccessToken()) {
          console.log('TRY GUEST')
          try {
            const response = await repository.guest()
            if ((response.status === 200) && response.data.token !== undefined && response.data.token.length > 0) {
              commit('SET_TEMPORARY_TOKEN', response.data.token)
              dispatch('fetch')
              resolve()
            }
          } catch (e) {
            reject(e)
          }
        } else {
          resolve()
        }
      })
    },
    setVerifyTerms ({ commit }, boolean) {
      commit('SET_VERIFY_TERMS', boolean)
    },
    async frejaLogin ({ state, getters, dispatch, commit, rootGetters }, authCode) {
      try {
        commit('LOADING_LOGIN', true)

        const response = await repository.frejaLogin({ reference: authCode, platform_key: rootGetters['app/platformKey'], native: rootGetters['app/isNative'] })

        commit('LOADING_LOGIN', false)
        if (response.status === 200) {
          console.log('freja login 1')
          if (response.data.token !== undefined && response.data.token.length > 0) {
            console.log('freja login 2')
            console.log('login data', response.data.user)
            commit('IS_LOGGED_IN', true)
            commit('SET_TOKEN', response.data.token)

            console.log('freja login 3')
            if (response.data.status === 'registered') {
              console.log('freja login 4')
              commit('SET_VERIFY_TERMS', true)
            } else {
              console.log('freja login 5')
              if (localStorage.needToVerifyTerms && JSON.parse(localStorage.needToVerifyTerms)) {
                console.log('freja login 6')
                commit('SET_VERIFY_TERMS', true)
              } else {
                console.log('freja login 7')
                commit('SET_USER', response.data.user)
                commit('SET_USER_ROLES', JSON.stringify(response.data.user.roles))
                dispatch('profile')
                router.push({
                  name: 'generalfeed'
                })
              }
            }
          }
        }

        commit('LOADING_LOGIN', false)
      } catch (e) {
        console.log(e)
        commit('LOADING_LOGIN', false)
        // commit('SET_ALERT', { create: true, title: 'Account already Exists!', body: 'Email is already used for facebook login/signup' })
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async googleLogin ({
      state,
      getters,
      dispatch,
      commit
    }) {
      if (getters.isLoggedin && getters.user.profile) {
        dispatch('profile')
        console.log(state.user.profile.full_name + ' is already loggedIn')
        router.push({
          name: 'generalfeed'
        })
      } else {
        commit('LOADING_LOGIN', true)

        try {
          // googleFirebaseLogin()
          // const info = await Device.getInfo()
          // if (info.platform === 'web') {

          // } else if (info.platform === 'android' || info.platform === 'ios') {
          //   // await firebase.auth().signInWithRedirect(provider)
          //   // cfaSignIn('google.com').subscribe(
          //   //   (user) => console.log(user.displayName)
          //   // )
          // }

          // commit('LOADING_LOGIN', false)
        } catch (e) {
          console.log(e)
          commit('LOADING_LOGIN', false)
          // commit('SET_ALERT', { create: true, title: 'Account already Exists!', body: 'Email is already used for facebook login/signup' })
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    async facebookLogin ({
      state,
      getters,
      dispatch,
      commit
    }) {
      if (getters.isLoggedin && getters.user.profile) {
        dispatch('profile')
        console.log(state.user.profile.full_name + ' is already loggedIn')
        router.push({
          name: 'generalfeed'
        })
      } else {
        commit('LOADING_LOGIN', true)

        // const provider = new firebase.auth.FacebookAuthProvider()
        try {
          // facebookFirebaseLogin().then(result => {
          //   console.log('eresult', result)
          // }).catch(e => {
          //   console.log('e', e)
          // })
          // const info = await Device.getInfo()

          // if (info.platform === 'web') {
          //   await firebase.auth().signInWithPopup(provider)
          // } else if (info.platform === 'android' || info.platform === 'ios') {
          //   await firebase.auth().signInWithRedirect(provider)
          // }

          commit('LOADING_LOGIN', false)
        } catch (e) {
          commit('LOADING_LOGIN', false)
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    async scroll ({ commit, rootState, state, dispatch }) {
      try {
        if (state.nextPageUrl !== null && (!state.loadingNewPost || !state.loadingNew)) {
          commit('SET_LOADING_NEW', true)
          const response = await repository.scroll(state.nextPageUrl)
          if ((response.status === 200) && response.data) {
            commit('APPEND_SAVED', response.data.data)
            commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
            commit('SET_LOADING_NEW', false)
          }
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async scrollUsers ({ commit, rootState, state, dispatch }) {
      try {
        if (state.nextPageUrl !== null && !state.loadingNew) {
          commit('SET_LOADING_NEW', true)
          const response = await repository.scroll(state.nextPageUrl)
          if ((response.status === 200) && response.data) {
            commit('APPEND_USERS', response.data.data)
            commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
            commit('SET_LOADING_NEW', false)
          }
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    setFCMToken ({ commit, state, dispatch }, payload) {
      commit('SET_FCM_TOKEN', payload.token)
      dispatch('initFirebaseMessagingRegistration')
    },
    async initFirebaseMessagingRegistration ({ commit, state }) {
      console.log(state.FCMToken)
      await repository.saveFCMToken({ token: state.FCMToken })
    },
    async setFirebaseToken ({ state, rootGetters }, payload) {
      if (payload) {
        const response = await repository.saveFCMToken({ token: payload, platform_key: rootGetters['app/platformKey'] })
        if (response.status === 200) {
          console.log('Token saved successfully')
        }
      }
    },
    async edit ({
      commit,
      state,
      dispatch
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.editRequest(payload)
        commit('SET_LOADING', false)
        if (response.status === 200) {
          dispatch('snackbar/snack', { text: i18n.t('Profile updated successfully'), type: 'success', show: true, autoclose: true }, { root: true })

          if (payload.email !== state.user.email) {
            dispatch('sendEmailVerification')
            commit('SET_USER', response.data)
            commit('SET_USER_PROFILE', response.data.profile)
          }

          if (response.data.status === 'requested') {
            return false
          } else {
            // router.push({
            //   name: 'profile'
            // })
            dispatch('fetch')
            return true
          }
        }
      } catch (e) {
        console.log('Error : ' + e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        if (payload.process === 'verifyingEmail') {
          if (e.response.data.errors.taken) {
            await commit('ALREADY_REGISTERED', true)
          } else if (e.response.data.errors.email) {
            await commit('ALREADY_REGISTERED', true)
          }

          setTimeout(() => {
            commit('ALREADY_REGISTERED', false)
          }, 5000)
        }
        if (e.hasOwnProperty('errors')) commit('SET_EDIT_USER_ERROR', e.errors)
        else if (e.hasOwnProperty('data')) commit('SET_EDIT_USER_ERROR', e.data.message)
        else commit('SET_EDIT_USER_ERROR', 'Error when trying to update!')
        return true
      }
    },
    setEditUserError ({
      commit
    }, payload) {
      commit('SET_EDIT_USER_ERROR', payload)
    },
    setOtpConfirmationError ({
      commit
    }, payload) {
      commit('SET_OTP_CONFIRMATION_ERROR', payload)
    },
    async deleteUser ({
      commit
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.deleteUser(payload)
        commit('SET_LOADING', false)
        if (response.status === 200) return true
        return false
      } catch (e) {
        console.log('Error : ' + e)
        commit('SET_LOADING', false)
        return false
      }
    },
    async editConfirm ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        console.log(payload)
        const response = await repository.editConfirmation(payload)
        commit('SET_LOADING', false)
        if (response.status === 200) {
          router.push({
            name: 'profile'
          })
          dispatch('me')
        }
      } catch (e) {
        console.log('Error : ' + e)
        commit('SET_LOADING', false)
        if (e.response.status === 401) {
          commit('SET_OTP_CONFIRMATION_ERROR', 'Otp does not match.')
        } else if (e.hasOwnProperty('data')) commit('SET_OTP_CONFIRMATION_ERROR', e.data.message)
        else if (e.hasOwnProperty('errors')) commit('SET_OTP_CONFIRMATION_ERROR', e.errors)
        else commit('SET_OTP_CONFIRMATION_ERROR', 'Error when trying to update!')
      }
    },
    initTheme ({ commit }) {
      const setItem = localStorage.activeTheme
      if (setItem) {
        commit('SET_THEME', setItem)
      }
    },
    async setTheme ({ commit }, payload) {
      commit('SET_THEME', payload)
    },
    async setEmailSend ({ commit }, payload) {
      commit('SET_EMAIL_SENT', payload)
    },
    async setNewPassword ({ commit }, payload) {
      commit('SET_NEW_PASSWORD', payload)
    },
    async setShowVerificationModal ({ commit }, payload) {
      commit('SET_SHOW_VERIFICATION_MODAL', payload)
    },
    async removeAllBubbles ({ commit, dispatch }, payload) {
      commit('SET_LOADING', true)
      const response = await repository.removeAllBubbles()

      if (response.status === 204) {
        await dispatch('fetch')
        //  dispatch('snackbar/snack', { text: i18n.t('User added to your following list'), type: 'success', show: true, autoclose: true }, { root: true })
      }
      commit('SET_LOADING', false)
    },
    async fetchCurrencies ({
      state,
      commit,
      dispatch
    }) {
      try {
        const response = await repository.fetchCurrencies()
        commit('SET_CURRENCIES', response.data)
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchCountries ({
      state,
      commit,
      dispatch
    }) {
      try {
        const response = await repository.fetchCountries()
        commit('SET_COUNTRIES', response.data)
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },

    async submitContact ({ commit, dispatch }, payload) {
      commit('SET_LOADING', true)
      try {
        const response = await repository.submitContact(payload)
        if (response.status === 201) {
          dispatch('snackbar/snack', { text: i18n.t('Form submitted successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: error, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchIsdCodes ({
      state,
      commit,
      dispatch
    }) {
      try {
        const response = await repository.fetchIsdCodes()
        commit('SET_ISD_CODES', response.data)
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
