<template>
  <div>
    <div class="py-4 lg:px-12 sm:px-5 max-w-lg mx-auto ">
      <div class="sm:grid-cols-1">
        <div class=" p-3 bg-white dark:bg-custom-purple-500 px-5 py-6">
          <Title class="text-xl mt-10 text-center text-gray-900 font-light">
            {{ $t('Become Premium') }}
          </Title>
          <div class="text-center my-4 w-full flex justify-center">
            <Icon
              name="star"
              solid
              class="mb-3 text-8xl text-yellow-500"
            />
          </div>
          <div class="text-center">
            <p>{{ $t('You need to be premium to use this function.') }}</p>
            <p class="font-bold mt-4">
              {{ $t('Don`t worry, it is free to become premium!') }}
            </p>
          </div>
          <div>
            <slot />
          </div>
          <div class="font-normal my-8 text-center flex flex-col items-center">
            <NeutralButton
              class="z-100"
              secondary
              @click.native="$emit('confirm')"
            >
              {{ $t('Free? Tell me more') }}
            </NeutralButton>
            <NegativeButton
              class="w-24 float-right mt-4 mr-2 focus:outline-none"
              @click.native="$emit('close')"
            >
              {{ $t('CANCEL') }}
            </NegativeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BecomePremiumModal'
}
</script>
