import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('holders')

export default {
  state: {
    holders: [],
    loading: false,
    holderSharesLoading: false,
    company: null,
    selectedHolders: null,
    holderShares: []
  },
  getters: {
    holderSharesLoading: state => state.holderSharesLoading,
    loading: state => state.loading,
    holders (state, getters, rootState) {
      return state.holders[rootState.companies.selectedCompanySN]
    },
    company: state => state.company,
    selectedHolders: state => state.selectedHolders,
    holderShares: state => state.holderShares
  },
  mutations: {
    SET_COMPANY (state, payload) {
      state.company = payload
    },
    SET_SELECTED_HOLDER (state, payload) {
      state.selectedHolders = payload
    },
    SET_HOLDER_SHARES (state, payload) {
      state.holderShares = payload
    },
    SET_HOLDER_SHARES_LOADING (state, boolean) {
      state.holderSharesLoading = boolean
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LIST (state, payload) {
      state.holders[payload.companySN] = [ ...payload.holders ]
      state.holders = { ...state.holders }
    }
  },
  actions: {
    async fetchHolderShares ({ commit, dispatch }, payload) {
      try {
        commit('SET_HOLDER_SHARES_LOADING', true)
        const response = await repository.fetchHolderShares(payload.holderId)
        commit('SET_HOLDER_SHARES_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_HOLDER_SHARES', response.data)
        }
      } catch (e) {
        commit('SET_HOLDER_SHARES_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async setSelectedCompany ({ commit }, payload) {
      await commit('SET_COMPANY', payload)
    },
    async fetchHolder ({ commit, dispatch }, holderId) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchHolder(holderId)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_SELECTED_HOLDER', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetch ({ commit, rootState, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetch(rootState.companies.selectedCompanySN)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', { companySN: rootState.companies.selectedCompanySN, holders: response.data })
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
