import { RepositoryFactory } from '@/api/repositoryFactory'
import router from '@/js/router'
const repository = RepositoryFactory.get('lists')
export default {
  state: {
    listFilters: [
      {
        code: 'sectors',
        group: 'sectors',
        type: 'multiselect',
        data: {},
        selected: null,
        preselect: null
      },
      {
        code: 'countries',
        group: 'countries',
        type: 'multiselect',
        data: {},
        selected: null,
        preselect: null
      },
      {
        code: 'stats_all_time',
        group: 'stats',
        type: 'range',
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0]
      },
      {
        code: 'stats_six_months',
        group: 'stats',
        type: 'range',
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0]
      },
      {
        code: 'followers',
        group: 'stats',
        type: 'range',
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0]
      }
    ],
    lists: [],
    weeklyTopLists: [],
    topFiftyLists: [],
    usersLists: [],
    selectedList: {},
    loading: false,
    loadingNew: false,
    weeklyTopListsLoading: false,
    listIds: [],
    nextPageUrl: null,
    searchListsPhrase: '',
    searchListsSortable: 'title',
    searchListsOrder: 'asc',
    countries: [],
    sectors: [],
    loadingLists: false,
    listsTotal: 0,
    minStatsAllTime: 0,
    maxStatsAllTime: 0,
    minStatsSixMonths: 0,
    maxStatsSixMonths: 0,
    maxFollowers: 0,
    filterList: [],
    filterListLoading: false
  },
  getters: {
    filterListLoading: state => state.filterListLoading,
    filterList: state => state.filterList,
    loadingLists: state => state.loadingLists,
    nextPageUrl: state => state.nextPageUrl,
    lists: state => state.lists,
    weeklyTopLists: state => state.weeklyTopLists,
    topFiftyLists: state => state.topFiftyLists,
    usersLists: state => state.usersLists,
    selectedList: state => state.selectedList,
    loading: state => state.loading,
    loadingNew: state => state.loadingNew,
    sectors: state => state.sectors,
    minStatsAllTime: state => state.minStatsAllTime,
    maxStatsAllTime: state => state.maxStatsAllTime,
    minStatsSixMonths: state => state.minStatsSixMonths,
    maxStatsSixMonths: state => state.maxStatsSixMonths,
    maxFollowers: state => state.maxFollowers,
    listsTotal: state => state.listsTotal,
    countries: state => state.countries,
    weeklyTopListsLoading: state => state.weeklyTopListsLoading,
    listIds: state => state.listIds,
    searchListsPhrase: state => state.searchListsPhrase,
    searchListsDebounce: state => state.searchListsDebounce,
    searchListsSortable: state => state.searchListsSortable,
    searchListsOrder: state => state.searchListsOrder,
    listFilters (state, getters, rootState, rootGetters) {
      const filters = [...state.listFilters]

      filters.map(f => {
        if (f.code === 'sectors') {
          f.data = { ...f.data, list: getters.sectors }
        }

        if (f.code === 'countries') {
          f.data = { ...f.data, list: getters.countries }
        }

        if (f.code === 'stats_all_time') {
          f.data.defaultRange = [getters.minStatsAllTime, getters.maxStatsAllTime]

          if (f.preselect[1] === 0) {
            f.preselect = [getters.minStatsAllTime, getters.maxStatsAllTime]
          }
        }
        if (f.code === 'followers') {
          f.data.defaultRange = [0, getters.maxFollowers]

          if (f.preselect[1] === 0) {
            f.preselect = [0, getters.maxFollowers]
          }
        }
        if (f.code === 'stats_six_months') {
          f.data.defaultRange = [getters.minStatsSixMonths, getters.maxStatsSixMonths]

          if (f.preselect[1] === 0) {
            f.preselect = [getters.minStatsSixMonths, getters.maxStatsSixMonths]
          }
        }
      })

      return filters
    },
    selectedListFilters (state, getters, rootState) {
      const selected = []

      state.listFilters.map(f => {
        if (f.selected) {
          if (f.type === 'multiselect') {
            f.selected.map((listOption) => {
              const filterOption = {
                label: listOption.name,
                group: f.group,
                type: f.type,
                code: listOption.name + '-' + listOption.id,
                data: listOption,
                cleanable: true
              }

              selected.push(filterOption)
            })
          } else if (f.type === 'range') {
            if (f.data.defaultRange[0] !== f.selected[0] || f.data.defaultRange[1] !== f.selected[1]) {
              const filterOption = {
                label: f.code + ' ' + f.selected[0] + ' - ' + f.selected[1],
                group: f.group,
                type: f.type,
                code: f.code,
                data: f.selected,
                cleanable: true
              }

              selected.push(filterOption)
            }
          }
        }
      })

      return selected
    }
  },
  mutations: {
    SET_FILTER_LIST (state, payload) {
      state.filterList = payload
    },
    SET_FILTER_LIST_LOADING (state, boolean) {
      state.filterListLoading = boolean
    },
    SET_LIST_FILTER (state, payload) {
      state.listFilters = [...payload]
    },
    APPEND_LIST (state, payload) {
      if (state.lists.length < 1) {
        state.lists = payload
      } else {
        payload.find(response => {
          const flag = state.lists.find(list => {
            return (Number(response.id) === Number(list.id))
          })
          if (!flag) {
            state.lists.push(response)
          }
        })
      }
    },
    SET_FILTER_DATA (state, payload) {
      state.listFilters = [...payload]
    },
    SET_SEARCH_LISTS_DEBOUNCE (state, payload) {
      state.searchListsDebounce = payload
    },
    SET_SEARCH_LISTS_PHRASE (state, payload) {
      state.searchListsPhrase = payload
    },
    SET_SEARCH_LISTS_SORTABLE (state, payload) {
      state.searchListsSortable = payload
    },
    SET_SEARCH_LISTS_ORDER (state, payload) {
      state.searchListsOrder = payload
    },
    SET_LOADING_NEW (state, boolean) {
      state.loadingNew = boolean
    },
    SET_SELECTED_LIST (state, payload) {
      state.selectedList = payload
    },
    PUSH_DATA_TO_TOP_FIFTY_LIST (state, payload) {
      if (payload) {
        const findIndex = state.topFiftyLists.findIndex(list => list.id === payload.id)
        if (findIndex > -1) {
          state.topFiftyLists[findIndex] = payload
        }
        state.topFiftyLists = [...state.topFiftyLists]
      }
    },
    PUSH_DATA_TO_WEEKLY_TOP_LIST (state, payload) {
      if (payload) {
        const findIndex = state.weeklyTopLists.findIndex(list => list.id === payload.id)
        if (findIndex > -1) {
          state.weeklyTopLists[findIndex] = payload
        }
        state.weeklyTopLists = [...state.weeklyTopLists]
      }
    },
    PUSH_DATA_TO_LIST (state, payload) {
      if (payload) {
        const findIndex = state.lists.findIndex(list => list.id === payload.id)
        if (findIndex > -1) {
          state.lists[findIndex] = payload
        }
        state.lists = [...state.lists]
      }
    },
    SET_TOP_FIFTY_LISTS (state, payload) {
      state.topFiftyLists = payload
    },
    SET_WEEKLY_TOP_LISTS (state, payload) {
      state.weeklyTopLists = payload
    },
    SET_NEXT_PAGE_URL (state, url) {
      state.nextPageUrl = url
    },
    SET_USERS_LIST (state, payload) {
      state.usersLists = payload
    },
    SET_LISTS (state, payload) {
      state.lists = payload
    },
    SET_SECTORS (state, payload) {
      state.sectors = payload
    },
    SET_LOADING_LISTS (state, boolean) {
      state.loadingLists = boolean
    },
    SET_COUNTRIES (state, payload) {
      state.countries = payload
    },
    SET_LIST_TOTAL (state, payload) {
      state.listsTotal = payload
    },
    REMOVE_TAG (state, payload) {
      let selectedFilter = state.listFilters.find(filter => filter.code === payload.group)
      if (selectedFilter === undefined) {
        selectedFilter = state.listFilters.find(filter => (filter.code === payload.code && filter.group === payload.group))
        selectedFilter.selected = null
      } else if (selectedFilter.selected && selectedFilter.selected.length > 0) {
        const findIndex = selectedFilter.selected.findIndex(f => f.id === payload.data.id)
        if (findIndex > -1) {
          selectedFilter.selected.splice(findIndex, 1)
        }
      }

      state.listFilters = [...state.listFilters]
    },
    SET_FILTERS (state, payload) {
      state.sectors = payload.sectors
      state.countries = payload.countries
      state.minStatsAllTime = payload.min_stats_all_time
      state.maxStatsAllTime = payload.max_stats_all_time
      state.minStatsSixMonths = payload.min_stats_six_months
      state.maxStatsSixMonths = payload.max_stats_six_months
      state.maxFollowers = payload.max_followers
    },
    SET_MY_FILTERS (state, payload) {
      state.listFilters.forEach(filter => {
        if (filter.code === 'countries') {
          filter.selected = JSON.parse(payload.countries)
          filter.preselect = JSON.parse(payload.countries)
        } else if (filter.code === 'sectors') {
          filter.selected = JSON.parse(payload.sectors)
          filter.preselect = JSON.parse(payload.sectors)
        } else if (filter.code === 'followers') {
          if (payload.followers !== null) {
            filter.selected = JSON.parse(payload.followers)
            filter.preselect = JSON.parse(payload.followers)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        } else if (filter.code === 'stats_all_time') {
          if (payload.stats_all_time !== null) {
            filter.selected = JSON.parse(payload.stats_all_time)
            filter.preselect = JSON.parse(payload.stats_all_time)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        } else if (filter.code === 'stats_six_months') {
          if (payload.stats_six_months !== null) {
            filter.selected = JSON.parse(payload.stats_six_months)
            filter.preselect = JSON.parse(payload.stats_six_months)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        }
      })
      state.listFilters = [...state.listFilters]
    },
    SET_COUNTRY_FILTERS (state, payload) {
      let filter = state.listFilters.find(filter => filter.code === payload.code)
      filter.selected = [payload.data]
      filter.preselect = [payload.data]
      state.listFilters = [...state.listFilters]
    },
    RESET_FILTERS (state) {
      state.listFilters.forEach(filter => {
        if (filter.type === 'multiselect') {
          filter.selected = null
          filter.preselect = null
        } else if (filter.type === 'range') {
          filter.selected = null
          filter.preselect = [0, 0]
        }
      })
    },
    SET_LIST_IDS (state, payload) {
      state.listIds = payload
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_WEEKLY_TOP_LISTS_LOADING (state, boolean) {
      state.weeklyTopListsLoading = boolean
    }
  },
  actions: {
    async fetchListFilters ({ commit, dispatch }) {
      try {
        const response = await repository.fetchListFilters()
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteListFilter ({ commit, dispatch }, filterId) {
      try {
        commit('SET_FILTER_LIST_LOADING', true)
        const response = await repository.deleteListFilter(filterId)
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
        }
        commit('SET_FILTER_LIST_LOADING', false)
      } catch (e) {
        commit('SET_FILTER_LIST_LOADING', false)

        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async saveFilters ({ commit, dispatch, getters }, payload) {
      try {
        const response = await repository.saveListFilters(payload)
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
          console.log('response : ', response.data)
          dispatch('snackbar/snack', { type: 'success', text: 'filters saved successfully', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async updateFilters ({ commit, dispatch, getters }, payload) {
      try {
        const response = await repository.updateListFilters(payload.filterId, payload)
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
          console.log('response : ', response.data)
          dispatch('snackbar/snack', { type: 'success', text: 'filters saved successfully', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async resetSearchListsSortable ({ state, dispatch }) {
      clearTimeout(state.searchCompaniesDebounce)
      dispatch('setSearchListsSortable', {
        searchListsPhrase: '',
        debounce: null,
        sortable: 'title',
        order: 'asc'
      })
    },
    async removeTag ({ commit, dispatch }, payload) {
      await commit('REMOVE_TAG', payload)
      dispatch('searchLists')
    },
    async setMyFilters ({ commit, dispatch }, payload) {
      await commit('SET_MY_FILTERS', payload)
    },
    resetFilters ({ commit }) {
      commit('RESET_FILTERS')
    },
    async setCountryFilters ({ getters, commit, dispatch }, payload) {
      let country = null
      if (getters.countries.length > 0) {
        country = getters.countries.find(country => country.name === payload.name)
      } else {
        await dispatch('fetchSectorAndCountries')
        country = getters.countries.find(country => country.name === payload.name)
      }
      await commit('SET_COUNTRY_FILTERS', { code: payload.code, data: country })
    },
    async setSectorFilter ({ getters, commit, dispatch }, payload) {
      await commit('SET_COUNTRY_FILTERS', { code: 'sectors', data: payload })
    },
    updateListFilterData ({ state, commit }, payload) {
      const index = state.listFilters.findIndex(f => f.code === payload.listFilter.code)
      let listFilter = { ...state.listFilters[index], preselect: payload.preselect }
      let listFilters = [...state.listFilters]
      listFilters[index] = listFilter
      commit('SET_LIST_FILTER', listFilters)
    },
    async scrollLists ({ commit, getters, rootGetters, state, dispatch }) {
      try {
        if (state.nextPageUrl !== null && !state.loadingNew) {
          commit('SET_LOADING_NEW', true)
          console.log('sortable', getters.searchListsSortable)
          const payload = { filter: getters.selectedListFilters,
            searchListsPhrase: getters.searchListsPhrase,
            orderBy: getters.searchListsSortable,
            order: getters.searchListsOrder
          }
          const response = await repository.scroll(state.nextPageUrl, payload)
          if ((response.status === 200) && response.data) {
            commit('APPEND_LIST', response.data.result.data)
            commit('SET_LIST_TOTAL', response.data.total)
            commit('SET_NEXT_PAGE_URL', response.data.result.next_page_url)
          }
          commit('SET_LOADING_NEW', false)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async delayedSearchLists ({ state, commit, dispatch }, payload) {
      if (payload.searchCompanyPhrase === '') {
        dispatch('resetSearchListsSortable')
      } else {
        clearTimeout(state.searchListsDebounce)
        commit('SET_SEARCH_LISTS_PHRASE', payload.searchListsPhrase)
        state.searchListsDebounce = setTimeout(() => {
          dispatch('searchLists', payload)
        }, 600)
      }
    },
    async searchLists ({ getters, rootGetters, commit, dispatch }) {
      try {
        commit('SET_LOADING_LISTS', true)
        const payload = { filter: getters.selectedListFilters,
          searchListsPhrase: getters.searchListsPhrase,
          orderBy: getters.searchListsSortable,
          order: getters.searchListsOrder
        }

        const response = await repository.fetchAllList(payload)
        commit('SET_LOADING_LISTS', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LISTS', response.data.result.data)
          commit('SET_LIST_TOTAL', response.data.total)
          commit('SET_NEXT_PAGE_URL', response.data.result.next_page_url)
        }
      } catch (e) {
        commit('SET_LOADING_LISTS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addListFilter ({ state, commit }, payload) {
      commit('SET_LIST_FILTER', payload)
    },
    async setSearchListsSortable ({ commit }, payload) {
      if (payload.searchListsPhrase || payload.searchListsPhrase === '') {
        commit('SET_SEARCH_LISTS_PHRASE', payload.searchListsPhrase)
      }
      if (payload.debounce) {
        commit('SET_SEARCH_LISTS_DEBOUNCE', payload.debounce)
      }
      if (payload.sortable) {
        commit('SET_SEARCH_LISTS_SORTABLE', payload.sortable)
      }
      if (payload.order) {
        commit('SET_SEARCH_LISTS_ORDER', payload.order)
      }
    },
    async fetchSectorAndCountries ({ commit, dispatch }) {
      try {
        const response = await repository.fetchSectorAndCountries()
        if (response.status === 200 && response.data) {
          commit('SET_FILTERS', response.data)
          // commit('SET_SECTORS', response.data.sectors)
          // commit('SET_COUNTRIES', response.data.countries)
          // commit('SET_MIN_STATS_ALL_TIME', response.data.max_stats_all_time)
          // commit('SET_MIN_STATS_ALL_TIME', response.min_stats_all_time)
          // commit('SET_MIN_STATS_SIX_MONTHS', response.data.max_stats_six_months)
          // commit('SET_MIN_STATS_SIX_MONTHS', response.min_stats_six_months)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addList ({ commit, dispatch, getters }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.addList(payload)
        if (response.status === 200 && response.data) {
          commit('SET_USERS_LIST', response.data.lists)
          router.push({ name: 'singleList', params: { id: response.data.new_list_id } })
        } else {
          this.$store.dispatch('users/setShowVerificationModal', true) // verification-needed
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async updateList ({ commit, dispatch, getters }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.updateList(payload.id, payload)
        if (response.status === 200 && response.data) {
          commit('SET_SELECTED_LIST', response.data)
        } else {
          this.$store.dispatch('users/setShowVerificationModal', true)// verification-needed
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async changeListStockUser ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.changeListStockUser(payload.list_id, payload)
        if (response.status === 200 && response.data) {
          commit('SET_SELECTED_LIST', response.data)
        } else if (response.status === 201) {
          dispatch('snackbar/snack', { type: 'info', text: "You don't have permission to update list.", show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async removeList ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.removeList(payload.id)
        if (response.status === 200 && response.data) {
          commit('SET_USERS_LIST', response.data)
          this.$store.dispatch('snackbar/snack', { text: 'List deleted successfully.', type: 'success', show: true, autoclose: true })
        } else {
          this.$store.dispatch('users/setShowVerificationModal', true) // verification-needed
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async removeCompanyFromList ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.removeCompanyFromList(payload.listId, { company_id: payload.companyId, type: payload.type })
        if (response.status === 200 && response.data) {
          if (payload.type === 'company') {
            commit('SET_LIST_IDS', response.data)
          } else {
            commit('SET_SELECTED_LIST', response.data)
          }
        } else {
          this.$store.dispatch('users/setShowVerificationModal', true) // verification-needed
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addCompaniesToList ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.addCompaniesToList(payload.listId, { companyList: payload.companyList, type: payload.type })
        if (response.status === 200 && response.data) {
          if (payload.type === 'company') {
            commit('SET_LIST_IDS', response.data)
          } else {
            commit('SET_SELECTED_LIST', response.data)
          }
        } else {
          this.$store.dispatch('users/setShowVerificationModal', true) // verification-needed
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchListCompanies ({ commit, dispatch }, id) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchListCompanies(id)
        if (response.status === 200 && response.data) {
          commit('SET_SELECTED_LIST', response.data)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchFollowingLists ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchFollowingLists(payload)
        if (response.status === 200 && response.data) {
          commit('SET_USERS_LIST', response.data)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchUsersList ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchUsersList(payload)
        if (response.status === 200 && response.data) {
          commit('SET_USERS_LIST', response.data)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchAllList ({ commit, dispatch }, payload) {
      try {
        const response = await repository.fetchAllList(payload)
        if (response.status === 200 && response.data) {
          commit('SET_LISTS', response.data.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchWeeklyTopList ({ commit, dispatch }, payload) {
      commit('SET_WEEKLY_TOP_LISTS_LOADING', true)
      try {
        const response = await repository.fetchAllList(payload)
        if (response.status === 200 && response.data) {
          commit('SET_WEEKLY_TOP_LISTS', response.data.result.data)
          commit('SET_NEXT_PAGE_URL', response.data.result.next_page_url)
        }
        commit('SET_WEEKLY_TOP_LISTS_LOADING', false)
      } catch (e) {
        commit('SET_WEEKLY_TOP_LISTS_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchTopFiftyList ({ commit, dispatch }, payload) {
      commit('SET_LOADING', true)
      try {
        const response = await repository.fetchAllList(payload)
        if (response.status === 200 && response.data) {
          commit('SET_TOP_FIFTY_LISTS', response.data.result.data)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async showMore ({ commit, dispatch, getters }, payload) {
      commit('SET_LOADING_NEW', true)
      try {
        if (!getters.weeklyTopListsLoading && getters.nextPageUrl !== null) {
          const response = await repository.showMore(getters.nextPageUrl, payload)
          if (response.status === 200 && response.data) {
            commit('PUSH_DATA_TO_WEEKLY_TOP_LIST', response.data.data)
            commit('SET_NEXT_PAGE_URL', response.data.next_page_url)
          }
        }
        commit('SET_LOADING_NEW', false)
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async followList ({ commit, dispatch }, payload) {
      try {
        const response = await repository.followList(payload.listId, payload.userId)
        if (response.status === 200 && response.data) {
          if (payload.type === 'allTimeStats') {
            commit('PUSH_DATA_TO_TOP_FIFTY_LIST', response.data)
          } else if (payload.type === 'listScreener') {
            commit('PUSH_DATA_TO_LIST', response.data)
          } else if (payload.type === 'singleList') {
            commit('SET_SELECTED_LIST', response.data)
          } else {
            commit('PUSH_DATA_TO_WEEKLY_TOP_LIST', response.data)
          }
          dispatch('snackbar/snack', { type: 'success', text: 'List followed/unfollowed successfully.', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async unfollowList ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)

        const response = await repository.unfollowList(payload.listId, payload.userId, payload.filters)
        if (response.status === 200 && response.data) {
          if (response.status === 200 && response.data) {
            commit('SET_USERS_LIST', response.data)
          }
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)

        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchListIds ({ commit, dispatch }, payload) {
      try {
        const response = await repository.fetchListIds(payload.companyURL)
        if (response.status === 200 && response.data) {
          commit('SET_LIST_IDS', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
