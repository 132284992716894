/* eslint-disable no-console */

import { register } from 'register-service-worker'

import LATEST_VERSION from './version.json'

// const LATEST_VERSION = version

// if (process.env.NODE_ENV === 'production') {
register(`${process.env.BASE_URL}service-worker.js`, {
  async ready () {
    console.log(
      'App is being served from cache by a service worker.\n'
    )

    console.log(process.env.BASE_URL)
    // console.log('APP VERSION IN STORE: ', JSON.parse(LATEST_VERSION))
    console.log(`%c ${LATEST_VERSION} `, 'color: #0000ff')
    if (caches) {
      let newContent = false
      caches.keys().then((arr) => {
        arr.forEach((key) => {
          caches.open(key).then((cache) => {
            cache.match('version').then((res) => {
              if (!res) {
                cache.put('version', new Response(LATEST_VERSION, { status: 200, statusText: LATEST_VERSION }))
              } else if (res.statusText !== LATEST_VERSION) {
                caches.delete(key).then(() => console.log(`%c Cleared Cache ${LATEST_VERSION}`, 'color: #ff0000'))
                newContent = true
              } else console.log(`%c Great you have the latest version ${LATEST_VERSION}`, 'color: #00ff00')
            })
          })
        })
      })

      if (newContent) {
        window.location.reload(true)
      }
    }
  },
  async registered () {
    console.log('Service worker has been registered.')
    // const response = await fetch('https://qew.se/test.txt', { mode: 'no-cors' })
    // const file = await response.body()

    // fetch('https://api.eucaps.axyer.com/test-json', { mode: 'no-cors', 'Content-Type': 'application/json' })
    //   .then((response) => response.json())
    //   .then((data) =>
    //     console.log('data', data)
    //   )
    // fetch('https://qew.se/test.txt')
    // .then(response => console.log('response test', response.text()))
    // console.log('data', file)
    console.log('Service worker has been registered END.')
  },
  cached () {
    console.log('Content has been cached for offline use.')
  },
  updatefound () {
    console.log('New content is downloading.')
  },
  async updated () {
    // NEW CONTENT, REMOVE CACHE
    console.log('new content, refresh')
    const version = await caches.open('version')
    console.log('VERSION OMS', version)

    if (Number(version) !== Number(LATEST_VERSION)) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name)
      })

      // setTimeout(() => {
      //   window.location.reload(true)
      // }, 700)
    }
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
})
// }
