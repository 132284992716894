<template>
  <Header
    :user="user"
    :is-verified="user && user.profile && user.profile.is_verified"
    :use-menu="useMenu"
    :show-extended-menu="showExtendedMenu"
    :show-notifications-menu="showNotificationsMenu"
    @toggle-extended-menu="showExtendedMenu = !showExtendedMenu"
    @toggle-notifications-menu="showNotificationsMenu = !showNotificationsMenu"
    @reload-data="reloadData"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import Header from './_components/Header'
import { isMobile } from '@/js/helpers/mobile'

export default {
  name: 'HeaderModule',
  components: {
    Header
  },
  data () {
    return {
      showExtendedMenu: false,
      showNotificationsMenu: false
    }
  },
  computed: {
    ...mapGetters({
      allMessages: 'chats/allMessages',
      generalChats: 'chats/generalChats',
      user: 'users/user'
    }),
    useMenu () {
      return isMobile()
    },
    unreadMessages () {
      if (!this.allMessages) return 0
      let unreadMessageArray = 0
      Object.values(this.allMessages).filter(chat => {
        chat.filter(message => {
          if (this.generalChats.find(chat => Number(chat.id) === Number(message.chat_id))) {
            const data = message.seen_by.find(user => Number(user.id) === Number(this.user.profile.id) && user.pivot.seen === 0)
            if (data) {
              unreadMessageArray++
            }
          }
        })
      })
      return unreadMessageArray
    }
  },
  methods: {
    async reloadData () {
      if (this.$route.name === 'generalfeed') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        await this.$store.dispatch('timeline/setType', 'general-feed')
        this.$store.dispatch('timeline/refetch')
      }
    }
  }
}
</script>
