import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/trulioo`

const result = async (experienceTransactionId) => get(`${url}/verification-result/${experienceTransactionId}`)
const save = async (data) => post(`${url}/savedata`, data)

export default {
  result,
  save
}
