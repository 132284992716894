export default {
  state: {
    galleryFiles: {},
    galleryPreviews: {},
    galleryKey: null
  },
  getters: {
    selectedGalleryFiles (state) {
      if (!state.galleryFiles || !state.galleryFiles[state.galleryKey]) {
        return []
      }

      return state.galleryFiles[state.galleryKey]
    },
    selectedGalleryPreviews (state) {
      if (!state.galleryPreviews || !state.galleryPreviews[state.galleryKey]) {
        return []
      }

      return state.galleryPreviews[state.galleryKey]
    }
  },
  mutations: {
    SET_GALLERY (state, data) {
      let files = []
      let previews = []
      if (state.galleryFiles[state.galleryKey]) {
        files = state.galleryFiles[state.galleryKey]
      }

      if (state.galleryPreviews[state.galleryKey]) {
        previews = state.galleryPreviews[state.galleryKey]
      }

      files.push(data.media)
      previews.push(data.preview)

      state.galleryFiles = { ...state.galleryFiles, [state.galleryKey]: files }
      state.galleryPreviews = { ...state.galleryPreviews, [state.galleryKey]: previews }
    },
    CLEAR_GALLERY (state) {
      state.galleryFiles = { ...state.galleryFiles, [state.galleryKey]: [] }
      state.galleryPreviews = { ...state.galleryPreviews, [state.galleryKey]: [] }
    },
    SELECT_GALLERY (state, key) {
      state.galleryKey = key
    }
  },
  actions: {
    set ({ state, commit }, data) {
      // console.log('SET', data)
      commit('SET_GALLERY', data)
    },
    remove ({ state, commit }, key) {
      state.galleryFiles[state.galleryKey].splice(key, 1)
      state.galleryPreviews[state.galleryKey].splice(key, 1)
      state.galleryFiles = { ...state.galleryFiles }
      state.galleryPreviews = { ...state.galleryPreviews }
    },
    select ({ commit }, galleryKey) {
      commit('SELECT_GALLERY', galleryKey)
    },
    clear ({ commit }) {
      commit('CLEAR_GALLERY')
    }
  },
  namespaced: true
}
