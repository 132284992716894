<template>
  <div
    class="flex items-center justify-between px-2 text-base font-semibold"
    :class="[padding]"
  >
    <div class="inline-block">
      <slot />
    </div>
    <button
      class="float-right text-shadow-none focus:outline-none"
      @click="$emit('click')"
    >
      <Icon
        name="pencil"
        solid
        bold
        class="text-base text-xl"
      />
    </button>
  </div>
</template>

<script>

export default {
  name: 'TitleWithEditButton',
  props: {
    padding: { type: String, default: 'mb-4' }
  }
}
</script>
