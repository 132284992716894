<template>
  <div class="bg-white dark:bg-custom-purple-800 border-b dark:border-custom-purple-450 z-60">
    <Container
      padding="p-0"
      wide
    >
      <div
        v-if="company"
        class="flex justify-between items-center"
      >
        <CompanyNameContainer
          :minified="isMobile()"
          @click="$route.name !== 'companyOverview' ? $router.push({name: 'companyOverview', params: {id: company.slug_url }}) : null"
        />
        <div class="flex items-center relative">
          <CompanyFollowContainer
            class="z-20 flex-grow-0 py-2"
            :company="company"
            :text="false"
          />
          <button
            class="cursor-pointer flex items-center bg-none font-semibold focus:outline-none dark:text-custom-purple-100"
            @click="$emit('show-user-lists')"
          >
            <SaveToList
              size="w-5 h-5"
              class="z-20 ml-4"
            />
          </button>
          <button
            v-if="webShareApiSupported"
            class="cursor-pointer flex items-center bg-none font-semibold focus:outline-none dark:text-custom-purple-100"
            @click="shareViaWebShare"
          >
            <Icon
              name="share-alt"
              solid
              class="z-20 ml-4 text-xl"
            />
          </button>
          <div
            v-if="hasEditAccess"
            class="relative ml-4 flex justify-center items-center flex-grow-0 w-10 h-6 bg-gray-200 dark:bg-custom-purple-450 rounded-md p-4"
          >
            <div
              class="cursor-pointer absolute inset-0 flex items-center justify-center"
              @click="$emit('show-menu')"
            >
              <DotMenu
                horizontal
                class=""
              />
            </div>
            <div
              v-if="showMenu"
              class="w-64 z-70 absolute top-4 right-2 rounded-lg shadow-sm dark:shadow-sm-dark border dark:border-custom-purple-400 bg-white dark:bg-custom-purple-450 dark:text-white"
            >
              <div class="relative w-full">
                <div
                  v-if="hasEditAccess"
                  class="flex items-center"
                >
                  <div
                    class="text-sm h-14 cursor-pointer flex items-center z-30 px-4 w-full"
                    :class="editMode ? 'bg-custom-yellow-300 dark:text-custom-purple-500' : 'hover:bg-gray-100 dark:hover:bg-custom-purple-400 text-custom-purple-500 dark:text-custom-purple-100'"
                    @click="$emit('on-edit-mode', $event)"
                  >
                    <div class="flex items-center">
                      <Icon
                        :name="editMode ? 'x' : 'pencil'"
                        class="mr-2 text-gray-600 dark:text-custom-purple-100"
                        :class="editMode ? 'text-2xl' : 'text-lg'"
                        :solid="editMode ? false : true"
                      />
                      <span class="font-medium">{{ $t('Edit') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="hasEditAccess"
                class="px-4 text-md dark:text-custom-purple-100 h-14 cursor-pointer flex items-center z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                @click="$emit('toggle-settings')"
              >
                <Icon
                  name="cog"
                  class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
                  solid
                />
                <span class="font-medium">{{ $t('Settings') }}</span>
              </div>
              <div
                v-if="hasEditAccess && isSinglePressReleasePage"
                class="px-4 text-md dark:text-custom-purple-100 h-14 cursor-pointer flex items-center z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                @click="$emit('toggle-press-image-upload')"
              >
                <Icon
                  name="image"
                  class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
                  solid
                />
                <span class="font-medium">{{ $t('Upload Image') }}</span>
              </div>
            </div>
            <TransparentBackgroundForMenus
              v-if="showMenu"
              @click="$emit('show-menu', $event)"
            />
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import CompanyNameContainer from '@/modules/companyName'
import CompanyFollowContainer from '@/modules/companyFollow'
import { isMobile } from '@/js/helpers/mobile'
export default {
  name: 'CompanyTopNameAndMenu',
  components: {
    CompanyNameContainer,
    CompanyFollowContainer
  },
  props: {
    company: {
      type: Object,
      default: () => { }
    },
    hasEditAccess: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    showMenu: {
      type: Boolean,
      default: false
    },
    widgetAddons: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    webShareApiSupported () {
      return navigator.share
    },
    url () {
      if (process.env.VUE_APP_URL) return process.env.VUE_APP_URL
      else return ''
    },
    isSinglePressReleasePage () {
      return (this.$route.name === 'companyPressRelease')
    }
  },
  async mounted () {
    await this.$store.dispatch('lists/fetchListIds', { companyURL: this.company.slug_url })
  },
  methods: {
    isMobile () {
      return isMobile()
    },
    shareViaWebShare () {
      if (this.company) {
        navigator.share({
          title: `${this.company.name} @ Eucaps.com`,
          text: `At Eucaps you can find decision based information about ${this.company.name}, Data, Financials, Analysis, Holdings and Latest News. \n\n Check out the company here:`,
          url: `${this.url}/${this.company.slug_url}`
        })
      }
    }
  }
}
</script>
