<template>
  <BaseButton
    :class="isSecondary ? 'border border-red-500 hover:border-red-700 text-red-500 hover:text-red-700' : 'bg-none text-custom-purple-500 dark:text-custom-purple-100'"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'NegativeButton',
  props: {
    secondary: { type: Boolean, default: false }
  },
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
