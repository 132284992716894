import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('contact')

export default {
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    async submitForm ({ commit }, payload) {
      await repository.newMessage(payload.name, payload.email, payload.subject, payload.message)
    }
  },
  namespaced: true
}
