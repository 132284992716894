import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('pressRelease')

export default {
  state: {
    selectedCompanyId: null,
    companyPressReleases: {},
    loading: false,
    nextPageUrl: null,
    loadingNew: false
  },
  getters: {
    loading: state => state.loading,
    pressReleases (state) {
      if (!state.companyPressReleases[state.selectedCompanyId]) {
        return []
      }
      return state.companyPressReleases[state.selectedCompanyId]
    },
    nextPageUrl: state => state.nextPageUrl,
    loadingNew: state => state.loadingNew
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = Number(companyId)
    },
    SET_NEW_LOADING (state, boolean) {
      state.loadingNew = boolean
    },
    SET_LOADING (state, bbb) {
      state.loading = bbb
    },
    APPEND_PRESS_RELEASE (state, payload) {
      if (state.companyPressReleases[state.selectedCompanyId] === undefined) {
        state.companyPressReleases[state.selectedCompanyId] = payload
      } else {
        payload.find(response => {
          const flag = state.companyPressReleases[state.selectedCompanyId].findIndex(companyPressRelease => {
            return (response.id === companyPressRelease.id)
          })
          if (flag > -1) {
            state.companyPressReleases[state.selectedCompanyId][flag] = response
          } else {
            state.companyPressReleases[state.selectedCompanyId].push(response)
          }
        })
      }

      state.companyPressReleases = { ...state.companyPressReleases }
    },
    SET_LIST (state, payload) {
      if (payload) {
        payload.filter(pressRelease => {
          if (pressRelease.content.indexOf('<body>') > -1) {
            pressRelease.content = pressRelease.content.replace('<body>', '<div>')
            pressRelease.content = pressRelease.content.replace('</body>', '</div>')
          } else {
            pressRelease.content = `<div>${pressRelease.content}</div>`
          }
        })
        if (!state.companyPressReleases.hasOwnProperty(state.selectedCompanyId)) {
          state.companyPressReleases[state.selectedCompanyId] = []
        }

        state.companyPressReleases = { ...state.companyPressReleases, [state.selectedCompanyId]: payload }
        state.companyPressReleases = { ...state.companyPressReleases }
      }
    },
    SET_ONE (state, pressRelease) {
      if (pressRelease.content.indexOf('<body>') > -1) {
        pressRelease.content = pressRelease.content.replace('<body>', '<div>')
        pressRelease.content = pressRelease.content.replace('</body>', '</div>')
      }

      let pr

      if (state.companyPressReleases[state.selectedCompanyId]) {
        pr = state.companyPressReleases[state.selectedCompanyId].find(pr => Number(pr.id) === Number(pressRelease.id))
      }

      if (!pr) {
        if (!state.companyPressReleases[state.selectedCompanyId]) {
          state.companyPressReleases = { ...state.companyPressReleases, [state.selectedCompanyId]: [] }
        }
        state.companyPressReleases[state.selectedCompanyId].push(pressRelease)
      }
    },
    PUSH_PRESS_RELEASE (state, payload) {
      state.companyPressReleases = { ...state.companyPressReleases, [payload[0].company.id]: payload }
    },
    SAVED_NEXT_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    }
  },
  actions: {
    async one ({ commit, dispatch, state, rootState }, id) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)

        let pressRelease

        if (state.companyPressReleases[Number(rootState.companies.selectedCompanyId)]) {
          pressRelease = state.companyPressReleases[Number(rootState.companies.selectedCompanyId)].find(pressRelease => Number(pressRelease.id) === Number(id))
        }

        if (!pressRelease) {
          commit('SET_LOADING', true)
          const response = await repository.one(rootState.companies.selectedCompanyId, id)
          commit('SET_LOADING', false)
          if ((response.status === 200) && response.data) {
            commit('SET_ONE', response.data)
          }
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetch ({ commit, dispatch, state, rootState }) {
      try {
        commit('SELECT_COMPANY', Number(rootState.companies.selectedCompanyId))

        if (state.companyPressReleases[Number(rootState.companies.selectedCompanyId)] === undefined) {
          commit('SET_LOADING', true)
          const response = await repository.list(rootState.companies.selectedCompanyId)

          commit('SET_LOADING', false)

          if ((response.status === 200) && response.data) {
            commit('SET_LIST', response.data.data)

            commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
          }
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async scroll ({ commit, rootState, state, dispatch }) {
      try {
        if (state.nextPageUrl !== null && !state.loadingNew) {
          commit('SET_NEW_LOADING', true)
          const response = await repository.scroll(state.nextPageUrl)
          if ((response.status === 200) && response.data) {
            commit('APPEND_PRESS_RELEASE', response.data.data)
            commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
            commit('SET_NEW_LOADING', false)
          }
        }
      } catch (e) {
        commit('SET_NEW_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    internalUpdate ({ commit, state, rootState }, payload) {
      state.companyPressReleases[state.selectedCompanyId][payload.index] = payload.pressRelease
      state.companyPressReleases[state.selectedCompanyId] = [...state.companyPressReleases[state.selectedCompanyId]]
    },
    async refetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_NEW_LOADING', true)
        const response = await repository.list(rootState.companies.selectedCompanyId)
        commit('SET_NEW_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_LIST', response.data.data)
        }
      } catch (e) {
        commit('SET_NEW_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async like ({ commit, state, rootState, dispatch }, payload) {
      try {
        const pressRelease = state.companyPressReleases[Number(rootState.companies.selectedCompanyId)]
        const Index = pressRelease.findIndex(pressRelease => Number(pressRelease.id) === Number(payload.pressReleaseId))

        const likeIndex = pressRelease[Index].likes.findIndex(like => (Number(like.stock_user.id) === Number(rootState.users.user.profile_id) && like.reaction === payload.reaction))

        if (likeIndex > -1) {
          pressRelease[Index].likes.splice(likeIndex, 1)
        } else {
          pressRelease[Index].likes.push({
            likeable_id: payload.pressReleaseId,
            reaction: payload.reaction,
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile.id
          })
        }

        const response = await repository.pressReleaseLike(rootState.companies.selectedCompanyId, payload.pressReleaseId, { reaction: payload.reaction })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press release liked successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pushPressRelease ({ commit, state }, payload) {
      commit('PUSH_PRESS_RELEASE', [payload])
    },
    async addPressRelease ({ commit, state, dispatch }, payload) {
      try {
        const response = await repository.add(state.selectedCompanyId, payload)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press Release added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async save ({ commit, rootState, dispatch }, pressReleaseId) {
      try {
        const response = await repository.save(rootState.companies.selectedCompanyId, pressReleaseId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press release saved successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithAll ({ commit, rootState, dispatch }, payload) {
      try {
        const response = await repository.sharePressReleaseWithAll(rootState.companies.selectedCompanyId, payload.press_release_id, { message: payload.message })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press Release shared successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithCompany ({ commit, dispatch }, payload) {
      try {
        const response = await repository.sharePressReleaseWithCompany(payload.company_id, payload.press_release_id, { message: payload.message })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press Release shared successfully', type: 'success', show: true, autoclose: true }, { root: true })
        } else {
          // console.log('failed to share Press Release')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.companyPressReleases = {}
    }
  },
  namespaced: true
}
