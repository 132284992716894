import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const countries = async () => get(`${url}/countries`)
const userCountries = async () => get(`${url}/user-countries`)
const deviceIp = async () => get(`${url}/user-ip`)

const sortCountries = async (id) => post(`${url}/sort-countries`, id)

export default {
  countries,
  sortCountries,
  userCountries,
  deviceIp
}
