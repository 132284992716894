<template>
  <ModalContainer
    v-if="forcedVersionAvailable"
    disable-close
    full-width
    full-size
    hide-close
    :padding="false"
  >
    <ForcedUpgradeMessage />
  </ModalContainer>
</template>

<script>
import ModalContainer from '@/modules/modal'
import ForcedUpgradeMessage from './_components/ForcedUpgradeMessage'
import { mapGetters } from 'vuex'

export default {
  name: 'ForcedUpgradeModalModule',
  components: {
    ModalContainer,
    ForcedUpgradeMessage
  },
  computed: {
    ...mapGetters({
      forcedVersionAvailable: 'app/forcedVersionAvailable'
    })
  }
}
</script>
