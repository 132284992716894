import { RepositoryFactory } from '@/api/repositoryFactory'
import time from '@/js/helpers/time'
import { i18n } from '@/js/language/'
import mentions from '@/js/helpers/mentions'
const repository = RepositoryFactory.get('companies')

export default {
  state: {
    selectedCompanyId: null,
    selectedCompanySN: null,
    scrollLoading: false,
    companies: [],
    instruments: [],
    trendingCompanies: [],
    mostDevelopedCompanies: [],
    instrumentsTotal: 0,
    totalNumberOfCompanies: 2008, // default in case api struggle
    maxValuation: 0,
    sectors: [],
    instrumentsNextPageUrl: null,
    certifiedCompanies: [],
    ipoCompanies: [],
    privateCompanies: [],
    searchedCompanies: [],
    searchedBrokers: [],
    filteredCompanyList: [],
    statistics: {},
    userRequests: {},
    brokerstats: [],
    brokerstatsDate: null,
    brokerStatNextPageUrl: null,
    loading: false,
    loadingCompanies: false,
    loadingTrendingCompanies: false,
    loadingMostDevelopedCompanies: false,
    loadingFinancials: false,
    loadingMarketCap: false,
    loadingCompetitors: false,
    loadingBrokerStats: false,
    loadingStatistics: false,
    loadingAttachment: false,
    loadingLogo: false,
    loadingBurnrate: false,
    loadingIPO: false,
    loadingTrial: false,
    loadingPrivate: false,
    loadingCash: false,
    loadingNotification: false,
    nextPageUrl: null,
    loadingNew: false,
    loadingValuation: false,
    companyMenuReference: null,
    searchCompaniesDebounce: null,
    financials: {},
    marketCap: {},
    companyDiscussions: {},
    notificationSettings: {},
    companyMenu: false,
    attachmentDataList: [],
    attachmentNextPageUrl: null,
    companyUsers: [],
    pendingInvitations: [],
    menu: {
      public: [
        { group: null, name: 'companyOverview', bubbleTags: ['---'], label: i18n.t('Overview') },
        { group: 0, name: 'companyOverview', bubbleTags: ['post_text', 'post_image', 'post_gallery', 'post_audio', 'post_document'], label: i18n.t('Company feed') },
        // { group: 1, name: 'companyChat', room: 'general', label: i18n.t('General') },
        // { group: 1, name: 'companyChat', room: 'stock', label: i18n.t('Stock') },
        // { group: 1, name: 'companyChat', room: 'offtopic', label: i18n.t('Off Topic') },
        { group: 2, name: 'companyDetails', bubbleTags: ['---'], label: i18n.t('About the company') },
        // { group: 0, name: 'companyMembers', label: i18n.t('Members') },
        { group: 2, name: 'companyTriggers', bubbleTags: ['company_trigger'], label: i18n.t('Triggers') },
        { group: 2, name: 'companyBrokerStats', bubbleTags: ['---'], label: i18n.t('Broker stats') },
        { group: 2, name: 'companyInsiderTrade', bubbleTags: ['insider_trade'], label: i18n.t('Insider Trades') },
        { group: 2, name: 'companyEstimates', bubbleTags: ['post_estimates'], label: i18n.t('Estimates') },
        // { group: 2, name: 'companyQuestionDocuments', label: i18n.t('Question Documents') },
        { group: 2, name: 'companyAssets', bubbleTags: ['company_asset'], label: i18n.t('Assets') },
        { group: 2, name: 'companyPressReleases', bubbleTags: ['company_press_release'], label: i18n.t('Press Release') },
        { group: 2, name: 'companyMedia', bubbleTags: ['media'], label: i18n.t('Media') },
        { group: 2, name: 'companyContacts', bubbleTags: ['company_contact'], label: i18n.t('Contacts') },
        { group: 2, name: 'companyBoard', bubbleTags: ['company_board_member'], label: i18n.t('Board') },
        { group: 2, name: 'companyOwners', bubbleTags: ['company_ownership_list'], label: i18n.t('Owners') },
        // { group: 2, name: 'companyCalendar', label: i18n.t('Calendar') },
        { group: 2, name: 'companySettings', bubbleTags: ['---'], label: i18n.t('Settings') }
      ],
      crypto: [
        { group: null, name: 'companyOverview', bubbleTags: ['---'], label: i18n.t('Overview') },
        { group: 0, name: 'companyOverview', bubbleTags: ['---'], label: i18n.t('Crypto feed') },
        // { group: 1, name: 'companyChat', room: 'general', label: i18n.t('General') },
        // { group: 1, name: 'companyChat', room: 'stock', label: i18n.t('Stock') },
        // { group: 1, name: 'companyChat', room: 'offtopic', label: i18n.t('Off Topic') },
        // { group: 0, name: 'companyMembers', label: i18n.t('Members') },
        { group: 2, name: 'companyTriggers', bubbleTags: ['company_trigger'], label: i18n.t('Triggers') },
        { group: 2, name: 'companyMedia', bubbleTags: ['media'], label: i18n.t('Media') },
        { group: 2, name: 'companySettings', bubbleTags: ['---'], label: i18n.t('Settings') }
      ],
      ipo: [
        { group: null, name: 'companyOverview', bubbleTags: ['---'], label: i18n.t('Overview') },
        { group: 0, name: 'companyOverview', bubbleTags: ['---'], label: i18n.t('Company feed') },
        // { group: 1, name: 'companyChat', room: 'general', label: i18n.t('General') },
        // { group: 1, name: 'companyChat', room: 'stock', label: i18n.t('Stock') },
        // { group: 1, name: 'companyChat', room: 'offtopic', label: i18n.t('Off Topic') },
        { group: 2, name: 'companyIPO', bubbleTags: ['---'], label: i18n.t('IPO') },
        { group: 2, name: 'companyDetails', bubbleTags: ['---'], label: i18n.t('About the company') },
        // { group: 0, name: 'companyMembers', label: i18n.t('Members') },
        { group: 2, name: 'companyTriggers', bubbleTags: ['company_trigger'], label: i18n.t('Triggers') },
        // { group: 2, name: 'companyBrokerStats', bubbleTags: ['---'], label: i18n.t('Broker stats') },
        // { group: 2, name: 'companyInsiderTrade', bubbleTags: ['insider_trade'], label: i18n.t('Insider Trades') },
        // { group: 2, name: 'companyEstimates', bubbleTags: ['post_estimates'], label: i18n.t('Estimates') },
        // { group: 2, name: 'companyQuestionDocuments', label: i18n.t('Question Documents') },
        { group: 2, name: 'companyAssets', bubbleTags: ['company_asset'], label: i18n.t('Assets') },
        { group: 2, name: 'companyPressReleases', bubbleTags: ['company_press_release'], label: i18n.t('Press Release') },
        { group: 2, name: 'companyMedia', bubbleTags: ['media'], label: i18n.t('Media') },
        { group: 2, name: 'companyContacts', bubbleTags: ['company_contact'], label: i18n.t('Contacts') },
        { group: 2, name: 'companyBoard', bubbleTags: ['company_board_member'], label: i18n.t('Board') },
        { group: 2, name: 'companyOwners', bubbleTags: ['company_ownership_list'], label: i18n.t('Owners') },
        // { group: 2, name: 'companyCalendar', label: i18n.t('Calendar') },
        { group: 2, name: 'companySettings', bubbleTags: ['---'], label: i18n.t('Settings') }

      ],
      private: [
        { group: null, name: 'companyOverview', bubbleTags: ['---'], label: i18n.t('Overview') },
        { group: 0, name: 'companyOverview', bubbleTags: ['---'], label: i18n.t('Company feed') },
        // { group: 1, name: 'companyChat', room: 'general', label: i18n.t('General') },
        // { group: 1, name: 'companyChat', room: 'stock', label: i18n.t('Stock') },
        // { group: 1, name: 'companyChat', room: 'offtopic', label: i18n.t('Off Topic') },
        { group: 2, name: 'companyPrivate', bubbleTags: ['---'], label: i18n.t('Offers') },
        { group: 2, name: 'companyDetails', bubbleTags: ['---'], label: i18n.t('About the company') },
        // { group: 0, name: 'companyMembers', label: i18n.t('Members') },
        { group: 2, name: 'companyTriggers', bubbleTags: ['company_trigger'], label: i18n.t('Triggers') },
        // { group: 2, name: 'companyBrokerStats', bubbleTags: ['---'], label: i18n.t('Broker stats') },
        // { group: 2, name: 'companyInsiderTrade', bubbleTags: ['insider_trade'], label: i18n.t('Insider Trades') },
        // { group: 2, name: 'companyEstimates', bubbleTags: ['post_estimates'], label: i18n.t('Estimates') },
        // { group: 2, name: 'companyQuestionDocuments', label: i18n.t('Question Documents') },
        { group: 2, name: 'companyAssets', bubbleTags: ['company_asset'], label: i18n.t('Assets') },
        // { group: 2, name: 'companyPressReleases', bubbleTags: ['company_press_release'], label: i18n.t('Press Release') },
        { group: 2, name: 'companyMedia', bubbleTags: ['media'], label: i18n.t('Media') },
        { group: 2, name: 'companyContacts', bubbleTags: ['company_contact'], label: i18n.t('Contacts') },
        { group: 2, name: 'companyBoard', bubbleTags: ['company_board_member'], label: i18n.t('Board') },
        { group: 2, name: 'companyOwners', bubbleTags: ['company_ownership_list'], label: i18n.t('Owners') },
        // { group: 2, name: 'companyCalendar', label: i18n.t('Calendar') },
        { group: 2, name: 'companySettings', bubbleTags: ['---'], label: i18n.t('Settings') }

      ]
    },
    companyDetails: {},
    companyIPO: {},
    companyPrivate: {},
    marketGoals: {},
    competitors: {},
    plans: [],
    loadingCoupons: false,
    coupons: []
  },
  getters: {
    coupons: state => state.coupons,
    loadingCoupons: state => state.loadingCoupons,
    pendingInvitations: state => state.pendingInvitations,
    plans: state => state.plans,
    totalNumberOfCompanies: state => state.totalNumberOfCompanies,
    companyUsers: state => state.companyUsers,
    brokerStatNextPageUrl: state => state.brokerStatNextPageUrl,
    scrollLoading: state => state.scrollLoading,
    loading: state => state.loading,
    loadingNew: state => state.loadingNew,
    loadingValuation: state => state.loadingValuation,
    loadingCompanies: state => state.loadingCompanies,
    loadingTrendingCompanies: state => state.loadingTrendingCompanies,
    loadingMostDevelopedCompanies: state => state.loadingMostDevelopedCompanies,
    loadingCompetitors: state => state.loadingCompetitors,
    loadingFinancials: state => state.loadingFinancials,
    loadingMarketCap: state => state.loadingMarketCap,
    loadingBrokerStats: state => state.loadingBrokerStats,
    loadingStatistics: state => state.loadingStatistics,
    loadingLogo: state => state.loadingLogo,
    loadingAttachment: state => state.loadingAttachment,
    loadingBurnrate: state => state.loadingBurnrate,
    loadingIPO: state => state.loadingIPO,
    loadingTrial: state => state.loadingTrial,
    loadingPrivate: state => state.loadingPrivate,
    loadingCash: state => state.loadingCash,
    loadingNotification: state => state.loadingNotification,
    companies: state => state.companies,
    instruments: state => state.instruments,
    instrumentsTotal: state => state.instrumentsTotal,
    trendingCompanies: state => state.trendingCompanies,
    mostDevelopedCompanies: state => state.mostDevelopedCompanies,
    maxValuation: state => state.maxValuation,
    sectors: state => state.sectors,
    instrumentsNextPageUrl: state => state.instrumentsNextPageUrl,
    certifiedCompanies: state => state.certifiedCompanies,
    ipoCompanies: state => state.ipoCompanies,
    privateCompanies: state => state.privateCompanies,
    searchedCompanies: state => state.searchedCompanies,
    searchedBrokers: state => state.searchedBrokers,
    filteredCompanyList: state => state.filteredCompanyList,
    companyMenu: state => state.companyMenu,
    nextPageUrl: state => state.nextPageUrl,
    attachmentNextPageUrl: state => state.attachmentNextPageUrl,
    attachmentDataList (state) {
      return state.attachmentDataList[state.selectedCompanyId]
    },
    statistics (state) {
      return state.statistics[state.selectedCompanyId]
    },
    financials (state) {
      return state.financials[state.selectedCompanyId]
    },
    marketCap (state) {
      return state.marketCap[state.selectedCompanyId]
    },
    notificationSettings (state) {
      return state.notificationSettings[state.selectedCompanyId]
    },
    selectedCompany (state) {
      return state.companies.find(company => {
        return Number(company.id) === Number(state.selectedCompanyId)
      })
    },
    companyMenuReference: state => state.companyMenuReference,
    menu: state => state.menu,
    companyId: state => state.selectedCompanyId,
    companySN: state => state.selectedCompanySN,
    userRequests (state) {
      return state.userRequests[state.selectedCompanyId]
    },
    companyDiscussions (state) {
      return state.companyDiscussions[state.selectedCompanyId]
    },
    brokerstats: state => state.brokerstats,
    competitors (state) {
      return state.competitors[state.selectedCompanyId]
    },
    companyDetails (state) {
      if (!state.companyDetails[state.selectedCompanyId]) {
        return {}
      }
      return state.companyDetails[state.selectedCompanyId]
    },
    companyIPO (state) {
      if (!state.companyIPO[state.selectedCompanyId]) {
        return []
      }
      return state.companyIPO[state.selectedCompanyId]
    },
    companyPrivate (state) {
      if (!state.companyPrivate[state.selectedCompanyId]) {
        return []
      }
      return state.companyPrivate[state.selectedCompanyId]
    },
    marketGoals (state) {
      return state.marketGoals[state.selectedCompanyId]
    }
  },
  mutations: {
    SET_INVITATIONS (state, payload) {
      state.pendingInvitations = payload
    },
    SET_PLANS (state, payload) {
      state.plans = payload
    },
    SET_BROKER_STATS_NEXT_PAGE_URL (state, url) {
      state.brokerStatNextPageUrl = url
    },
    SET_INSTRUMENTS_NEXT_PAGE_URL (state, url) {
      state.instrumentsNextPageUrl = url
    },
    SET_SCROLL_LOADING (state, boolean) {
      state.scrollLoading = boolean
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_SECTORS (state, payload) {
      state.sectors = payload
    },
    SET_MAX_VALUATION (state, valuation) {
      state.maxValuation = Math.ceil(Number(valuation) / 100) * 100
    },
    SET_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    LOADING_LOGO (state, boolean) {
      state.loadingLogo = boolean
    },
    SET_LOADING_ATTACHMENT (state, boolean) {
      state.loadingAttachment = boolean
    },
    SET_LOADING_BURNRATE (state, boolean) {
      state.loadingBurnrate = boolean
    },
    SET_LOADING_CASH (state, boolean) {
      state.loadingCash = boolean
    },
    SET_LOADING_STATISTICS (state, boolean) {
      state.loadingStatistics = boolean
    },
    SET_LOADING_COMPANIES (state, boolean) {
      state.loadingCompanies = boolean
    },
    SET_LOADING_TRENDING_COMPANIES (state, boolean) {
      state.loadingTrendingCompanies = boolean
    },
    SET_LOADING_MOST_DEVELOPED_COMPANIES (state, boolean) {
      state.loadingMostDevelopedCompanies = boolean
    },
    SET_LOADING_IPO (state, boolean) {
      state.loadingIPO = boolean
    },
    SET_LOADING_TRIAL (state, boolean) {
      state.loadingTrial = boolean
    },
    SET_LOADING_PRIVATE (state, boolean) {
      state.loadingPrivate = boolean
    },
    SET_LOADING_NOTIFICATION (state, boolean) {
      state.loadingNotification = boolean
    },
    SET_LOADING_COMPETITORS (state, boolean) {
      state.loadingCompetitors = boolean
    },
    SET_LOADING_FINANCIALS (state, boolean) {
      state.loadingFinancials = boolean
    },
    SET_LOADING_MARKET_CAP (state, boolean) {
      state.loadingMarketCap = boolean
    },
    SET_LOADING_BROKER_STATS (state, boolean) {
      state.loadingBrokerStats = boolean
    },
    SET_TOTAL_NUMBER_OF_COMPANIES (state, totalNumberOfCompanies) {
      state.totalNumberOfCompanies = Number(totalNumberOfCompanies)
    },
    SET_TRIAL_PERIOD (state, payload) {
      let companyIndex = state.companies.findIndex(company => Number(payload.id) === Number(company.id))
      // let company = state.companies[companyIndex]
      if (companyIndex > -1) {
        state.companies[companyIndex].trial_date_to = payload.trial_date_to
        state.companies[companyIndex].has_trial_account = payload.has_trial_account
        // state.companies[companyIndex] = company
        state.companies = [...state.companies]
      }
    },
    SET_ATTACHMENT_DATALIST (state, payload) {
      state.attachmentDataList = { ...state.attachmentDataList, [state.selectedCompanyId]: payload }
    },
    PUSH_ATTACHMENT_DATALIST (state, payload) {
      payload.find(response => {
        const flag = state.attachmentDataList[state.selectedCompanyId].find(data => {
          return (Number(response.id) === Number(data.id))
        })
        if (!flag) {
          state.attachmentDataList[state.selectedCompanyId].push(response)
        }
      })
      state.attachmentDataList = { ...state.attachmentDataList }
    },
    SET_NEW_BURNRATE (state, payload) {
      let burnrates = state.statistics[state.selectedCompanyId].burnrates
      burnrates.unshift(payload)
      state.statistics = { ...state.statistics }
    },
    SET_NEW_CASH (state, payload) {
      let cash = state.statistics[state.selectedCompanyId].cash
      cash.unshift(payload)
      state.statistics = { ...state.statistics }
    },
    SET_FINANCIALS (state, payload) {
      state.financials = { ...state.financials, [state.selectedCompanyId]: payload }
      state.financials = { ...state.financials }
    },
    SET_MARKET_CAP (state, payload) {
      state.marketCap = { ...state.marketCap, [state.selectedCompanyId]: payload }
      state.marketCap = { ...state.marketCap }
    },
    SET_STATISTICS (state, payload) {
      state.statistics = { ...state.statistics, [state.selectedCompanyId]: payload }
    },
    SET_COMPANY_MENU_VISIBILITY (state, boolean) {
      state.companyMenu = boolean
    },
    UPDATE_FOLLOWERS (state, payload) {
      let companyIndex = state.companies.findIndex(company => Number(payload.company_id) === Number(company.id))
      // let company = state.companies[companyIndex]
      if (companyIndex > -1) {
        state.companies[companyIndex].all_followers = payload.all_followers
        state.companies[companyIndex].is_follower = payload.is_follower
        // state.companies[companyIndex] = company
        state.companies = [...state.companies]
      }

      companyIndex = state.searchedCompanies.findIndex(company => Number(payload.company_id) === Number(company.id))
      // company = state.companies[companyIndex]
      if (companyIndex > -1) {
        state.searchedCompanies[companyIndex].all_followers = payload.all_followers
        state.searchedCompanies[companyIndex].is_follower = payload.is_follower
        // state.companies[companyIndex] = company
        state.searchedCompanies = [...state.searchedCompanies]
      }
    },
    SET_SEARCH (state, payload) {
      state.searchedCompanies = [...payload]
    },

    SET_BROKER_SEARCH (state, payload) {
      state.searchedBrokers = [...payload]
    },
    CLEAR_BROKER_STATS (state, companyId) {
      state.brokerstats = []
    },
    SET_CERTIFIED_LIST (state, payload) {
      state.certifiedCompanies = payload
    },
    SET_IPO_LIST (state, payload) {
      state.ipoCompanies = payload
    },
    SET_PRIVATE_LIST (state, payload) {
      state.privateCompanies = payload
    },
    APPEND_VIDEO (state, payload) {
      let companyIndex = state.companies.findIndex(company => Number(payload.company_id) === Number(company.id))
      state.companies[companyIndex].featured_videos.push(payload)
      state.companies = [ ...state.companies ]
    },
    UPDATE_VIDEO (state, payload) {
      let companyIndex = state.companies.findIndex(company => Number(payload.video.company_id) === Number(company.id))

      let currentVideoIndex = state.companies[companyIndex].featured_videos.findIndex(v => v.id === payload.video.id)
      let currentVideo = { ...state.companies[companyIndex].featured_videos[currentVideoIndex] }
      currentVideo.title = payload.title
      currentVideo.description = payload.description
      state.companies[companyIndex].featured_videos[currentVideoIndex] = currentVideo

      state.companies = [ ...state.companies ]
    },
    DELETE_VIDEO (state, payload) {
      let companyIndex = state.companies.findIndex(company => Number(payload.company_id) === Number(company.id))

      let currentVideoIndex = state.companies[companyIndex].featured_videos.findIndex(v => v.id === payload.id)
      state.companies[companyIndex].featured_videos.splice(currentVideoIndex, 1)

      state.companies = [ ...state.companies ]
    },
    UPDATE_LIST (state, updatedCompany) {
      const flag = state.companies.findIndex(company => {
        return (Number(updatedCompany.id) === Number(company.id))
      })
      state.companies[flag] = { ...updatedCompany }
      state.companies = [ ...state.companies ]
    },
    APPEND_INSTRUMENTS_LIST (state, payload) {
      if (state.instruments.length < 1) {
        state.instruments = payload
      } else {
        payload.find(response => {
          const flag = state.instruments.find(company => {
            return (Number(response.id) === Number(company.id))
          })
          if (!flag) {
            state.instruments.push(response)
          }
        })
      }
    },
    SET_INSTRUMENTS_LIST (state, payload) {
      state.instruments = payload
    },
    SET_INSTRUMENTS_LIST_TOTAL (state, payload) {
      state.instrumentsTotal = payload
    },
    SET_TRENDING_COMPANIES (state, payload) {
      state.trendingCompanies = { ...payload }
    },
    SET_MOST_DEVELOPED_COMPANIES (state, payload) {
      state.mostDevelopedCompanies = [ ...payload ]
    },
    SET_FILTERED_LIST (state, payload) {
      if (state.filteredCompanyList.length < 0) {
        payload.map((response) => {
          state.filteredCompanyList.push({
            ...response,
            type: 'company'
          })
        })
      } else {
        payload.find(response => {
          const temp = state.filteredCompanyList.find(company => {
            return (response.id === company.id)
          })
          if (!temp) {
            state.filteredCompanyList.push({
              ...response,
              type: 'company'
            })
          }
        })
      }
    },
    SET_LIST (state, payload) {
      if (state.companies.length < 1) {
        state.companies = payload
      } else {
        payload.find(response => {
          const flag = state.companies.find(company => {
            return (Number(response.id) === Number(company.id))
          })
          if (!flag) {
            state.companies.push(response)
          }
        })
      }

      if (state.filteredCompanyList.length < 0) {
        Array.prototype.forEach.call(payload, response => {
          state.filteredCompanyList.push({
            ...response,
            type: 'company'
          })
        })
      } else {
        payload.find(response => {
          const temp = state.filteredCompanyList.find(company => {
            return (response.id === company.id)
          })
          if (!temp) {
            state.filteredCompanyList.push({
              ...response,
              type: 'company'
            })
          }
        })
      }
    },
    SET_LOADING_NEW (state, boolean) {
      state.loadingNew = boolean
    },
    SET_LOADING_VALUATION (state, boolean) {
      state.loadingValuation = boolean
    },
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_COMPANY_USER_LIST (state, payload) {
      state.companyUsers = payload
    },
    SELECT_COMPANY_SHORT_NAME (state, companySN) {
      state.selectedCompanySN = companySN
    },
    SET_COMPANY_MENU_REFERENCE (state, reference) {
      state.companyMenuReference = reference
    },
    SET_USER_REQUESTS (state, payload) {
      state.userRequests = { ...state.userRequests, [state.selectedCompanyId]: payload }
    },
    SET_NOTIFICATIONS (state, payload) {
      state.notificationSettings = { ...state.notificationSettings, [state.selectedCompanyId]: payload }
    },
    SET_LOGO (state, payload) {
      let companyIndex = state.companies.findIndex(company => payload.slug_url === company.slug_url)
      if (companyIndex > -1) {
        console.log('UPDATED LOGO OMS')
        state.companies[companyIndex].logo = payload.logo
        state.companies[companyIndex].color = payload.color
        state.companies = [...state.companies]
      }
    },
    RESET_BROKER_STATS (state) {
      state.brokerstats = []
    },
    PUSH_BROKER_STATS (state, payload) {
      if (state.brokerstats.length === 0) {
        state.brokerstats = payload
      } else {
        payload.find(response => {
          const flag = state.brokerstats.find(broker => {
            return (Number(response.insref) === Number(broker.insref))
          })
          if (!flag) {
            state.brokerstats.push(response)
          }
        })
      }
    },
    SET_BROKER_STATS (state, payload) {
      state.brokerstats = payload
    },
    SET_COMPANY_DISCUSSIONS (state, companyDiscussions) {
      for (let i = 0; i < companyDiscussions.length; i++) {
        let intro = (companyDiscussions[i].chatable.message) ? companyDiscussions[i].chatable.message.substring(0, 60) : null
        intro = intro + ((companyDiscussions[i].chatable.message && companyDiscussions[i].chatable.message.length > 57) ? '...' : '')
        companyDiscussions[i].chatable.intro = intro

        if (companyDiscussions[i].message && companyDiscussions[i].message.mentions.length > 0) {
          if (companyDiscussions[i].chatable.message && companyDiscussions[i].chatable.message.match(mentions.MATCHING)) {
            companyDiscussions[i].chatable.message = mentions.parse(companyDiscussions[i].chatable.message, companyDiscussions[i].message.mentions, 'chat')
            companyDiscussions[i].chatable.intro = mentions.parse(companyDiscussions[i].chatable.intro, companyDiscussions[i].message.mentions, 'chat')
          }
        }
        companyDiscussions[i].updated_at = time.diffFromNow(companyDiscussions[i].updated_at)
      }

      state.companyDiscussions = { ...state.companyDiscussions, [state.selectedCompanyId]: companyDiscussions }
    },
    SET_COMPANY_DETAILS (state, details) {
      state.companyDetails = { ...state.companyDetails, [state.selectedCompanyId]: { ...details } }
      state.companyDetails[state.selectedCompanyId] = { ...state.companyDetails[state.selectedCompanyId] }
    },
    SET_IPO (state, ipo) {
      state.companyIPO = { ...state.companyIPO, [state.selectedCompanyId]: { ...ipo } }
      state.companyIPO[state.selectedCompanyId] = { ...state.companyIPO[state.selectedCompanyId] }
    },
    SET_PRIVATE (state, privateData) {
      state.companyPrivate = { ...state.companyPrivate, [state.selectedCompanyId]: { ...privateData } }
      state.companyPrivate[state.selectedCompanyId] = { ...state.companyPrivate[state.selectedCompanyId] }
    },
    SET_MARKET_GOALS (state, goals) {
      state.marketGoals = { ...state.marketGoals, [state.selectedCompanyId]: [...goals] }
    },

    SET_COMPETITORS (state, competitors) {
      state.competitors = { ...state.competitors, [state.selectedCompanyId]: [...competitors] }
    },
    SET_ATTACHMENT_NEXT_PAGE_URL (state, url) {
      state.attachmentNextPageUrl = url
    },
    SET_COUPONS (state, payload) {
      state.coupons = payload
    },
    SET_LOADING_COUPONS (state, boolean) {
      state.loadingCoupons = boolean
    }
  },
  actions: {
    async assignRole ({ commit, dispatch }, companyId) {
      try {
        commit('SET_LOADING_NEW', true)
        const response = await repository.assignRole(companyId)
        if (response.status === 201) {
          commit('SET_LOADING_NEW', false)
          return true
        } else if (response.status === 203) {
          dispatch('snackbar/snack', { text: i18n.t('Invalid access.'), type: 'error', show: true, autoclose: true }, { root: true })
          commit('SET_LOADING_NEW', false)
          return false
        }
        commit('SET_LOADING_NEW', false)
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        return false
      }
    },
    async setDiscountTillDate ({ commit, dispatch }, payload) {
      try {
        const response = await repository.setDiscountTillDate(payload.companyId, { months: payload.discountMonths })
        if ((response.status === 200) && response.data) {
          commit('UPDATE_LIST', response.data)
        } else if (response.status === 203) {
          dispatch('snackbar/snack', { text: i18n.t('User does not have permission.'), type: 'error', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async toggleTrialPeriodOnBoarding ({ commit, dispatch }, companyId) {
      try {
        const response = await repository.toggleTrialPeriodOnBoarding(companyId)
        if ((response.status === 200) && response.data) {
          commit('UPDATE_LIST', response.data)
        } else if (response.status === 203) {
          dispatch('snackbar/snack', { text: i18n.t('User does not have permission.'), type: 'error', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async toggleAgreementRequired ({ commit, dispatch }, companyId) {
      try {
        const response = await repository.toggleAgreementRequired(companyId)
        if ((response.status === 200) && response.data) {
          commit('UPDATE_LIST', response.data)
        } else if (response.status === 203) {
          dispatch('snackbar/snack', { text: i18n.t('User does not have permission.'), type: 'error', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchCoupons ({ commit, dispatch }) {
      try {
        commit('SET_LOADING_COUPONS', true)
        const response = await repository.fetchCoupons()
        if (response.status === 200 && response.data) {
          commit('SET_COUPONS', response.data)
        }
        commit('SET_LOADING_COUPONS', false)
      } catch (e) {
        commit('SET_LOADING_COUPONS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addCouponToSubscription ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING_COUPONS', true)
        const response = await repository.addCouponToSubscription(payload.subscriptionId, { coupon_id: payload.couponId })
        if (response.status === 200 && response.data) {
          // commit('SET_COUPONS', response.data)
        }
        commit('SET_LOADING_COUPONS', false)
      } catch (e) {
        commit('SET_LOADING_COUPONS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async getTotalNumberOfCompanies ({ commit, dispatch }, payload) {
      const response = await repository.totalNumberOfCompanies()
      if ((response.status === 200) && response.data) {
        commit('SET_TOTAL_NUMBER_OF_COMPANIES', response.data)
      }
    },
    async sendInvitation ({ commit, dispatch }, payload) {
      try {
        const response = await repository.sendInvitation(payload.companyId, { email: payload.email })
        if ((response.status === 200) && response.data) {
          commit('SET_INVITATIONS', response.data)
          dispatch('snackbar/snack', { type: 'success', text: 'Invitation sent successfully!', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteInvitation ({ commit, dispatch }, payload) {
      try {
        const response = await repository.deleteInvitation(payload.companyId, payload.id)
        if ((response.status === 200) && response.data) {
          commit('SET_INVITATIONS', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchInvitations ({ commit, dispatch }, companyId) {
      try {
        const response = await repository.fetchInvitations(companyId)
        if ((response.status === 200) && response.data) {
          commit('SET_INVITATIONS', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchCompanyPlans ({ commit, dispatch }, companyId) {
      try {
        commit('SET_LOADING_COUPONS', true)
        const response = await repository.fetchCompanyPlans(companyId)
        if ((response.status === 200) && response.data) {
          commit('SET_PLANS', response.data)
        }
        commit('SET_LOADING_COUPONS', false)
      } catch (e) {
        commit('SET_LOADING_COUPONS', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    setLoadingCompanies ({ commit }, boolean) {
      commit('SET_LOADING_COMPANIES', boolean)
    },
    clearBrokerStats ({ commit }, companyId) {
      commit('CLEAR_BROKER_STATS', companyId)
    },
    async companyUsers ({ commit, dispatch, state }, companySN) {
      try {
        const response = await repository.companyUsers(companySN)
        commit('SET_LOADING_COMPANIES', true)
        if ((response.status === 200) && response.data) {
          commit('SET_COMPANY_USER_LIST', response.data)
        }
        commit('SET_LOADING_COMPANIES', false)
      } catch (e) {
        commit('SET_LOADING_COMPANIES', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addUsersToCompany ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING_NEW', true)
        const response = await repository.addUsersToCompany(payload.company, { userList: payload.userList })
        if (response.status === 200 && !response.data.invalid) {
          commit('SET_COMPANY_USER_LIST', response.data)
        } else {
          dispatch('snackbar/snack', { text: i18n.t('User does not have permission.'), type: 'error', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING_NEW', false)
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async removeUsersRole ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING_NEW', true)
        const response = await repository.removeUsersRole(payload.companyURL, { userId: payload.userId })
        if (response.status === 200 && !response.data.invalid) {
          commit('SET_COMPANY_USER_LIST', response.data)
        } else {
          dispatch('snackbar/snack', { text: i18n.t('User does not have permission.'), type: 'error', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING_NEW', false)
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async one ({ commit, state }) {
      commit('SET_LOADING', true)
      const response = await repository.one(state.selectedCompanySN)
      commit('SET_LOADING', false)
      if ((response.status === 200) && response.data) {
        commit('SET_LIST', [response.data])
        commit('SELECT_COMPANY', response.data.id)
      }
    },
    setLoadingAttachment ({ commit }, boolean) {
      commit('SET_LOADING_ATTACHMENT', boolean)
    },
    async companyDataList ({ commit, state }, payload) {
      commit('SET_LOADING_ATTACHMENT', true)
      const response = await repository.companyDataList(state.selectedCompanySN, payload)
      commit('SET_LOADING_ATTACHMENT', false)
      if (response.status === 200 && response.data) {
        commit('SET_ATTACHMENT_DATALIST', response.data)
      }
    },
    async statistics ({ commit, state }) {
      commit('SET_LOADING_STATISTICS', true)
      const response = await repository.statistics(state.selectedCompanySN)
      commit('SET_LOADING_STATISTICS', false)

      if (response.status === 200 && response.data) {
        commit('SET_STATISTICS', response.data)
      }
    },
    async fetchUserRequests ({ commit, state }, payload) {
      commit('SET_LOADING', true)
      const response = await repository.userRequestList(state.selectedCompanySN)
      commit('SET_LOADING', false)
      if ((response.status === 200) && response.data) {
        commit('SET_USER_REQUESTS', response.data.data)
      }
    },
    async fetchFollowedCompanies ({ commit, dispatch, rootState }) {
      commit('SET_LOADING', true)
      try {
        const response = await repository.fetchFollowedCompanyList(rootState.users.user.profile_id)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data.data)
          commit('SET_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async getTrendingCompanies ({ commit, dispatch, rootState }) {
      commit('SET_LOADING_TRENDING_COMPANIES', true)
      try {
        const response = await repository.getTrendingCompanies()
        commit('SET_LOADING_TRENDING_COMPANIES', false)
        if ((response.status === 200) && response.data) {
          commit('SET_TRENDING_COMPANIES', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async getMostDevelopedCompanies ({ commit, dispatch, rootState }) {
      commit('SET_LOADING_MOST_DEVELOPED_COMPANIES', true)
      try {
        const response = await repository.getMostDevelopedCompanies()
        commit('SET_LOADING_MOST_DEVELOPED_COMPANIES', false)
        if ((response.status === 200) && response.data) {
          commit('SET_MOST_DEVELOPED_COMPANIES', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchAllBroker ({ commit }, payload) {
      commit('SET_LOADING_COMPANIES', true)
      const response = await repository.brokerList(payload)
      commit('SET_LOADING_COMPANIES', false)
      if ((response.status === 200) && response.data) {
        commit('SET_BROKER_SEARCH', response.data)
      }
    },
    async fetchCertifiedCompanies ({ commit, dispatch, rootState }) {
      commit('SET_LOADING', true)
      try {
        const response = await repository.fetchCertifiedCompanyList()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_CERTIFIED_LIST', response.data.data)
          commit('SET_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchIPOCompanies ({ commit, dispatch, rootState }) {
      commit('SET_LOADING', true)
      try {
        const response = await repository.fetchIPOCompanyList()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_IPO_LIST', response.data.data)
          commit('SET_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchPrivateCompanies ({ commit, dispatch, rootState }) {
      commit('SET_LOADING', true)
      try {
        const response = await repository.fetchPrivateCompanyList()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_PRIVATE_LIST', response.data.data)
          commit('SET_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    // async fetch ({ commit, dispatch }, payload) {
    //   commit('SET_LOADING', true)
    //   try {
    //     const response = await repository.list(payload)
    //     commit('SET_LOADING', false)
    //     if ((response.status === 200) && response.data) {
    //       commit('SET_LIST', response.data.data)
    //       commit('SET_PAGE_URL', response.data.next_page_url)
    //     }
    //   } catch (e) {
    //     dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
    //   }
    // },
    async scrollAttachments ({ commit, rootState, state, dispatch }, filter) {
      try {
        if (state.attachmentNextPageUrl !== null && !state.loadingAttachment && !state.loadingNew) {
          commit('SET_LOADING_NEW', true)
          const response = await repository.scroll(state.attachmentNextPageUrl, filter)
          if ((response.status === 200) && response.data) {
            commit('PUSH_ATTACHMENT_DATALIST', response.data.data)
            commit('SET_ATTACHMENT_NEXT_PAGE_URL', response.data.next_page_url)
          }
          commit('SET_LOADING_NEW', false)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async scrollBrokerStats  ({ commit, rootState, state, dispatch }, payload) {
      try {
        if (state.brokerStatNextPageUrl !== null) {
          commit('SET_SCROLL_LOADING', true)
          const response = await repository.scroll(state.brokerStatNextPageUrl, payload)
          if ((response.status === 200) && response.data) {
            commit('PUSH_BROKER_STATS', response.data.data)
            commit('SET_BROKER_STATS_NEXT_PAGE_URL', response.data.next_page_url)
          }
          commit('SET_SCROLL_LOADING', false)
        }
      } catch (e) {
        commit('SET_SCROLL_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async scroll ({ commit, rootState, state, dispatch }) {
      try {
        if (state.nextPageUrl !== null && !state.loadingNew) {
          commit('SET_LOADING_NEW', true)
          const response = await repository.scroll(state.nextPageUrl)
          if ((response.status === 200) && response.data) {
            commit('SET_LIST', response.data.data)
            commit('SET_PAGE_URL', response.data.next_page_url)
          }
          commit('SET_LOADING_NEW', false)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchSectorAndValuation ({ commit, dispatch }) {
      try {
        commit('SET_LOADING_VALUATION', true)
        const response = await repository.fetchSectorAndValuation()
        commit('SET_LOADING_VALUATION', false)
        if (response.status === 200 && response.data) {
          commit('SET_SECTORS', response.data.sectors)
          commit('SET_MAX_VALUATION', response.data.max_valuation)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchAll ({ commit }, payload) {
      commit('SET_LOADING_COMPANIES', true)
      const response = await repository.list(payload)
      commit('SET_LOADING_COMPANIES', false)
      if ((response.status === 200) && response.data) {
        // if (!payload.bubbleSearch) {
        commit('SET_SEARCH', response.data.data)
        commit('SET_LIST', response.data.data)
        // } else {
        // commit('SET_FILTERED_LIST', response.data.data)
        // }
      }
    },
    async scrollInstruments ({ commit, rootState, rootGetters, state, dispatch }) {
      try {
        if (state.instrumentsNextPageUrl !== null && !state.loadingNew) {
          commit('SET_LOADING_NEW', true)
          const payload = { filter: rootGetters['screeners/slimSelectedScreenerFilters'],
            searchCompaniesPhrase: rootGetters['screeners/searchCompaniesPhrase'],
            currency: rootGetters['screeners/selectedCurrency'],
            orderBy: rootGetters['screeners/searchCompaniesSortable'],
            order: rootGetters['screeners/searchCompaniesOrder'],
            inUniverse: rootGetters['screeners/inUniverse'],
            relevant: rootGetters['screeners/relevant']
          }
          const response = await repository.scrollInstruments(state.instrumentsNextPageUrl, payload)
          if ((response.status === 200) && response.data) {
            commit('APPEND_INSTRUMENTS_LIST', response.data.result.data)
            commit('SET_INSTRUMENTS_LIST_TOTAL', response.data.total)
            commit('SET_INSTRUMENTS_NEXT_PAGE_URL', response.data.result.next_page_url)
          }
          commit('SET_LOADING_NEW', false)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async searchCompanies ({ state, rootGetters, commit, dispatch }) {
      try {
        commit('SET_LOADING_COMPANIES', true)
        // RENAME instrument stuff
        const payload = { filter: rootGetters['screeners/slimSelectedScreenerFilters'],
          searchCompaniesPhrase: rootGetters['screeners/searchCompaniesPhrase'],
          currency: rootGetters['screeners/selectedCurrency'],
          orderBy: rootGetters['screeners/searchCompaniesSortable'],
          order: rootGetters['screeners/searchCompaniesOrder'],
          inUniverse: rootGetters['screeners/inUniverse'],
          relevant: rootGetters['screeners/relevant']
        }

        const response = await repository.instrumentsList(payload)
        commit('SET_LOADING_COMPANIES', false)
        if ((response.status === 200) && response.data) {
          commit('SET_INSTRUMENTS_LIST', response.data.result.data)
          commit('SET_INSTRUMENTS_LIST_TOTAL', response.data.total)
          commit('SET_INSTRUMENTS_NEXT_PAGE_URL', response.data.result.next_page_url)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchSelectedCompanies ({ state, rootGetters, commit, dispatch }) {
      try {
        commit('SET_LOADING_COMPANIES', true)
        // RENAME instrument stuff
        const payload = { filter: rootGetters['screeners/slimSelectedScreenerFilters'],
          activeIds: rootGetters['comparables/comparables'],
          currency: rootGetters['screeners/selectedCurrency'],
          orderBy: rootGetters['screeners/searchCompaniesSortable'],
          order: rootGetters['screeners/searchCompaniesOrder']
        }

        const response = await repository.instrumentsList(payload)
        commit('SET_LOADING_COMPANIES', false)
        if ((response.status === 200) && response.data) {
          commit('SET_INSTRUMENTS_LIST', response.data.result.data)
          commit('SET_INSTRUMENTS_LIST_TOTAL', response.data.total)
          commit('SET_INSTRUMENTS_NEXT_PAGE_URL', response.data.result.next_page_url)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    // OBSOLETE, replaces by searchCompanies
    async fetchAllInstruments ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING_COMPANIES', true)
        const response = await repository.instrumentsList(payload)
        commit('SET_LOADING_COMPANIES', false)
        if ((response.status === 200) && response.data) {
          commit('SET_INSTRUMENTS_LIST', response.data.data)
          commit('SET_INSTRUMENTS_NEXT_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    selectCompany ({ commit, dispatch, state, rootState }, companyId) {
      commit('SELECT_COMPANY', companyId)
    },
    selectCompanySN ({ commit, dispatch, state, rootState }, companySN) {
      commit('SELECT_COMPANY_SHORT_NAME', companySN)
    },
    setCompanyMenuReference ({ commit }, reference) {
      commit('SET_COMPANY_MENU_REFERENCE', reference)
    },
    async follow ({ commit, rootState, state, dispatch, getters }, companyId = null) {
      try {
        const selectedCompanyId = companyId || state.selectedCompanyId

        const response = await repository.follow(selectedCompanyId)

        if (response.status === 200) {
          commit('UPDATE_FOLLOWERS', response.data)
          dispatch('users/profile', null, { root: true })
          // dispatch('snackbar/snack', { text: 'Company added to followed list', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async followMultiple ({ commit, dispatch }, companyIds = null) {
      try {
        const response = await repository.followMultiple({ companies: companyIds })

        if (response.status === 200) {
          commit('UPDATE_FOLLOWERS', response.data)
          dispatch('users/profile', null, { root: true })
          // dispatch('snackbar/snack', { text: 'Company added to followed list', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async rejectContact ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.contact_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'reject',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.rejectContact(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          // console.log('Delete request rejected')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async rejectTrigger ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.company_trigger_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'reject',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.rejectTrigger(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          // console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async rejectMediaDeleteRequest ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.company_media_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'reject',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.rejectDeleteMedia(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request rejected successfully')
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async approveMediaDeleteRequest ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.company_media_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'approve',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.approveDeleteMedia(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async approveTrigger ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.company_trigger_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'approve',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.approveTrigger(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async approveContact ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.contact_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'approve',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.approveContact(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async rejectAsset ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.company_asset_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'reject',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.rejectAsset(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async approveAsset ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.company_asset_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'approve',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.approveAsset(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async rejectBoardMember ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.board_member_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'reject',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.rejectBoardMember(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async approveBoardMember ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.board_member_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'approve',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.approveBoardMember(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async rejectOwner ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.owner_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'reject',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.rejectOwner(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async approveOwner ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyUserRequests = state.userRequests[state.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(request => request.deletable.owner_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push(
          {
            status: 'approve',
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile_id
          }
        )
        const response = repository.approveOwner(state.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchLinkables ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING_ATTACHMENT', true)
        const response = await repository.fetchLinkables(payload)
        commit('SET_LOADING_ATTACHMENT', false)
        if (response.status === 200) {
          commit('SET_ATTACHMENT_DATALIST', response.data.data)
          commit('SET_ATTACHMENT_NEXT_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async addCompanyDetails ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)

        const response = await repository.addCompanyDetails(state.selectedCompanyId, payload)
        if (response.status === 200) {
          commit('SET_COMPANY_DETAILS', response.data)

          commit('SET_LOADING', false)
          dispatch('snackbar/snack', { text: 'Company Details added successfully', type: 'success', show: true, autoclose: true }, { root: true })
          return response
        } else {
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async notificationSettings ({ commit, state }) {
      commit('SET_LOADING_NOTIFICATION', true)
      const response = await repository.notificationSettings(state.selectedCompanyId)
      commit('SET_LOADING_NOTIFICATION', false)
      if ((response.status === 200) && response.data) {
        commit('SET_NOTIFICATIONS', response.data)
      }
    },
    async updateNotificationSettings ({ commit, state, getters }, key) {
      commit('SET_LOADING_NOTIFICATION', true)
      const response = await repository.updateNotificationSettings(state.selectedCompanyId, { key })
      commit('SET_LOADING_NOTIFICATION', false)
      if (response.status === 200) {
        commit('SET_NOTIFICATIONS', response.data)
      }
    },
    async fetchCompanyDetails ({ commit, state }) {
      commit('SET_LOADING', true)
      const response = await repository.fetchCompanyDetails(state.selectedCompanySN)
      commit('SET_LOADING', false)
      if ((response.status === 200) && response.data) {
        commit('SET_COMPANY_DETAILS', response.data)
      }
    },
    async addCompanyMarketGoals ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.addCompanyMarketGoals(state.selectedCompanyId, { goals: JSON.stringify(payload.options) })
        if (response.status === 200) {
          commit('SET_LOADING', false)
          commit('SET_MARKET_GOALS', response.data)

          dispatch('snackbar/snack', { text: 'Market Goals added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        } else {
          console.log('Market Goals is not added.')
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async fetchCompanyMarketGoals ({ commit, state }) {
      commit('SET_LOADING', true)
      const response = await repository.fetchCompanyMarketGoals(state.selectedCompanySN)
      commit('SET_LOADING', false)
      if ((response.status === 200) && response.data) {
        commit('SET_MARKET_GOALS', response.data)
      }
    },
    async addCompanyCompetitors ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.addCompanyCompetitors(state.selectedCompanyId, payload.company_id, { link: null, title: null })
        if (response.status === 200) {
          commit('SET_LOADING', false)
          dispatch('fetchCompanyCompetitors')
          dispatch('snackbar/snack', { text: 'Competitors added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        } else {
          console.log('Competitors is not added.')
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async addUnknownCompanyCompetitors ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.addUnknownCompanyCompetitors(state.selectedCompanyId, payload)
        if (response.status === 200) {
          commit('SET_LOADING', false)
          dispatch('fetchCompanyCompetitors')
          dispatch('snackbar/snack', { text: 'Competitors added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        } else {
          console.log('Competitors is not added.')
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async fetchCompanyCompetitors ({ commit, state }) {
      commit('SET_LOADING_COMPETITORS', true)
      const response = await repository.fetchCompanyCompetitors(state.selectedCompanySN)
      commit('SET_LOADING_COMPETITORS', false)
      if ((response.status === 200) && response.data) {
        commit('SET_COMPETITORS', response.data)
      }
    },
    async getBrokerStats ({ commit, state, dispatch }, payload) {
      commit('SET_LOADING_BROKER_STATS', true)
      try {
        const response = await repository.getBrokerStats(payload.companySURL, payload)
        commit('SET_LOADING_BROKER_STATS', false)
        if (response.status === 200) {
          commit('SET_BROKER_STATS', response.data)
        }
      } catch (e) {
        commit('SET_LOADING_BROKER_STATS', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async getBrokerStatsByBroker ({ commit, state, dispatch }, payload) {
      commit('SET_BROKER_STATS_NEXT_PAGE_URL', null)
      commit('SET_LOADING_BROKER_STATS', true)
      try {
        const response = await repository.getBrokerStatsByBroker(payload)
        commit('SET_LOADING_BROKER_STATS', false)
        if (response.status === 200) {
          commit('SET_BROKER_STATS', response.data.data)
          commit('SET_BROKER_STATS_NEXT_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        commit('SET_LOADING_BROKER_STATS', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async saveBurnrate ({ state, commit, dispatch }, payload) {
      commit('SET_LOADING_BURNRATE', true)
      try {
        const response = await repository.saveBurnrate(state.selectedCompanyId, payload)
        commit('SET_LOADING_BURNRATE', false)
        if (response.status === 201) {
          commit('SET_NEW_BURNRATE', response.data)
        }
      } catch (e) {
        commit('SET_LOADING_BURNRATE', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async saveCash ({ state, commit, dispatch }, payload) {
      commit('SET_LOADING_CASH', true)
      try {
        const response = await repository.saveCash(state.selectedCompanyId, payload)
        commit('SET_LOADING_CASH', false)
        if (response.status === 201) {
          commit('SET_NEW_CASH', response.data)
        }
      } catch (e) {
        commit('SET_LOADING_CASH', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    resetBrokerStats ({ commit, state }) {
      commit('RESET_BROKER_STATS')
    },
    async fetchPrivate ({ commit, dispatch, state }) {
      commit('SET_LOADING_PRIVATE', true)
      try {
        const response = await repository.fetchPrivate(state.selectedCompanySN)
        commit('SET_LOADING_PRIVATE', false)
        if (response.status === 200) {
          commit('SET_PRIVATE', response.data)
        }
      } catch (e) {
        commit('SET_LOADING_PRIVATE', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    tempSavePrivate ({ commit, state, getters }, payload) {
      commit('SET_PRIVATE', { ...getters.companyPrivate, ...payload })
    },
    async savePrivate ({ commit, dispatch, state, getters }) {
      commit('SET_LOADING_PRIVATE', true)
      try {
        const response = await repository.savePrivate(state.selectedCompanySN, getters.companyPrivate)
        commit('SET_LOADING_PRIVATE', false)
        if (response.status === 200) {
          commit('SET_PRIVATE', response.data)
        }
      } catch (e) {
        commit('SET_LOADING_PRIVATE', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async fetchIPO ({ commit, dispatch, state }) {
      commit('SET_LOADING_IPO', true)
      try {
        const response = await repository.fetchIPO(state.selectedCompanySN)
        commit('SET_LOADING_IPO', false)
        if (response.status === 200) {
          commit('SET_IPO', response.data)
        }
      } catch (e) {
        commit('SET_LOADING_IPO', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async toggleTrialPeriod ({ commit, dispatch, state }) {
      commit('SET_LOADING_TRIAL', true)
      try {
        const response = await repository.toggleTrialPeriod(state.selectedCompanySN)
        commit('SET_LOADING_TRIAL', false)
        if (response.status === 200) {
          commit('SET_TRIAL_PERIOD', response.data)
        }
      } catch (e) {
        commit('SET_LOADING_IPO', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    tempSaveIPO ({ commit, state, getters }, payload) {
      commit('SET_IPO', { ...getters.companyIPO, ...payload })
    },
    async saveIPO ({ commit, dispatch, state, getters }) {
      commit('SET_LOADING_IPO', true)
      try {
        const response = await repository.saveIPO(state.selectedCompanySN, getters.companyIPO)
        commit('SET_LOADING_IPO', false)
        if (response.status === 200) {
          commit('SET_IPO', response.data)
        }
      } catch (e) {
        commit('SET_LOADING_IPO', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async saveSocialTags ({ commit, dispatch, state, getters }, socialTags) {
      try {
        const tags = socialTags.join(',')
        const response = await repository.saveSocialTags(state.selectedCompanySN, { tags })
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async updateContactInfo ({ commit, dispatch, state, getters }, updateContactInfo) {
      try {
        const response = await repository.updateContactInfo(state.selectedCompanySN, updateContactInfo)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async updateLocations ({ commit, dispatch, state, getters }, updateLocations) {
      try {
        const response = await repository.updateLocations(state.selectedCompanySN, { locations: updateLocations })
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async deleteLocation ({ commit, dispatch, state, getters }, deleteLocation) {
      try {
        const response = await repository.deleteLocation(state.selectedCompanySN, deleteLocation.id)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async toggleCompanyStrategy ({ commit, dispatch, state, getters }, payload) {
      try {
        const response = await repository.toggleCompanyStrategy(state.selectedCompanySN, payload.strategyId)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async updateCompanySocial ({ commit, dispatch, state, getters }, payload) {
      try {
        const response = await repository.updateCompanySocial(state.selectedCompanySN, payload)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async deleteCompanySocial ({ commit, dispatch, state, getters }, id) {
      try {
        const response = await repository.deleteCompanySocial(state.selectedCompanySN, id)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async updateDocumentListFile ({ commit, dispatch, state, getters }, document) {
      try {
        const response = await repository.updateDocumentListFile(state.selectedCompanySN, document)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async deleteDocumentListFile ({ commit, dispatch, state, getters }, document) {
      try {
        const response = await repository.deleteDocumentListFile(state.selectedCompanySN, document.id)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async newBusinessDetail ({ commit, dispatch, state, getters }, newBusinessDetail) {
      try {
        const response = await repository.saveNewBusinessDetail(state.selectedCompanySN, newBusinessDetail)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async updateBusinessDetail ({ commit, dispatch, state, getters }, updateBusinessDetail) {
      try {
        const response = await repository.updateBusinessDetail(state.selectedCompanySN, updateBusinessDetail.id, updateBusinessDetail)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async deleteBusinessDetail ({ commit, dispatch, state, getters }, deleteBusinessDetail) {
      try {
        const response = await repository.deleteBusinessDetail(state.selectedCompanySN, deleteBusinessDetail.id)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async newReasonToInvest ({ commit, dispatch, state, getters }, newReasonToInvest) {
      try {
        const response = await repository.saveNewReasonToInvest(state.selectedCompanySN, newReasonToInvest)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async updateReasonToInvest ({ commit, dispatch, state, getters }, updateReasonToInvest) {
      try {
        const response = await repository.updateReasonToInvest(state.selectedCompanySN, updateReasonToInvest.id, updateReasonToInvest)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async deleteReasonToInvest ({ commit, dispatch, state, getters }, deleteReasonToInvest) {
      try {
        const response = await repository.deleteReasonToInvest(state.selectedCompanySN, deleteReasonToInvest.id)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async newManagement ({ commit, dispatch, state, getters }, newManagement) {
      try {
        const response = await repository.saveNewManagement(state.selectedCompanySN, newManagement)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async appendFeaturedVideos ({ commit, dispatch, state, getters }, payload) {
      commit('APPEND_VIDEO', payload)
    },
    async updateFeaturedVideos ({ commit, dispatch, state, getters }, payload) {
      commit('UPDATE_VIDEO', payload)
    },
    async deleteFeaturedVideos ({ commit, dispatch, state, getters }, payload) {
      commit('DELETE_VIDEO', payload)
    },
    async updateManagement ({ commit, dispatch, state, getters }, updateManagement) {
      try {
        const response = await repository.updateManagement(state.selectedCompanySN, updateManagement.id, updateManagement)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async newDocumentList ({ commit, dispatch, state, getters }, newDocument) {
      try {
        const response = await repository.newDocumentListFile(state.selectedCompanySN, { document: newDocument })
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async toggleWidget ({ commit, dispatch, state, getters }, payload) {
      try {
        const response = await repository.toggleWidget(state.selectedCompanySN, payload)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async deleteManagement ({ commit, dispatch, state, getters }, deleteManagement) {
      try {
        const response = await repository.deleteManagement(state.selectedCompanySN, deleteManagement.id)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async fetchManagement ({ commit, dispatch, state }) {
      // commit('SET_MANAGEMENT_LOADING', true)
      // try {
      //   const response = await repository.fetchManagement(state.selectedCompanySN)
      //   commit('SET_MANAGEMENT_LOADING', false)
      //   if ((response.status === 200) && response.data) {
      //     commit('SET_LIST', response.data.data)
      //     commit('SET_PAGE_URL', response.data.next_page_url)
      //   }
      // } catch (e) {
      //   dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      // }
    },
    async fetchFinancials ({ commit, dispatch, rootGetters, state }) {
      commit('SET_LOADING_FINANCIALS', true)
      try {
        const currency = rootGetters['screeners/selectedCurrency']
        const periodCode = rootGetters['financials/selectedPeriodCode']
        const response = await repository.fetchFinancials(state.selectedCompanySN, currency, periodCode)
        commit('SET_LOADING_FINANCIALS', false)
        if ((response.status === 200) && response.data) {
          commit('SET_FINANCIALS', response.data)
        }
      } catch (e) {
        commit('SET_LOADING_FINANCIALS', false)

        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchMarketCap ({ commit, dispatch, rootGetters, state }) {
      if (!state.loadingMarketCap) {
        commit('SET_LOADING_MARKET_CAP', true)
        try {
          const currency = rootGetters['screeners/selectedCurrency']
          const response = await repository.fetchMarketCap(state.selectedCompanySN, currency)
          commit('SET_LOADING_MARKET_CAP', false)
          if ((response.status === 200) && response.data) {
            commit('SET_MARKET_CAP', response.data)
          }
        } catch (e) {
          commit('SET_LOADING_MARKET_CAP', false)

          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    async uploadLogo ({ commit, state, dispatch }, payload) {
      try {
        commit('LOADING_LOGO', true)
        const response = await repository.uploadLogo(payload.slugUrl, payload)
        if (response.status === 200) {
          // console.log('Image uploaded successfully')
          payload = { logo: response.data.logo, color: response.data.color, slug_url: payload.slugUrl }
          commit('SET_LOGO', payload)
          dispatch('users/setLogo', payload, { root: true })
        }
        commit('LOADING_LOGO', false)
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.companies = [...[]]
      state.userRequests = [...[]]
      state.filteredCompanyList = [...[]]
    },
    async updateHiddenPages ({ commit, state, dispatch }, page) {
      try {
        const response = await repository.saveHiddenPage(state.selectedCompanySN, page)
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async updateCompanyList ({ commit, state, dispatch }, company) {
      try {
        commit('UPDATE_LIST', company)
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
