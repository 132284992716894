<template>
  <Container
    wide
    class="text-sm"
  >
    <p class="mb-3">
      <Title secondary>
        INTEGRITETSPOLICY - INFORMATION OM PERSONUPPGIFTSBEHANDLING OCH ANVÄNDNING AV COOKIES INOM EUCAPS OCH DESS BOLAG SAMT PARTNERS
      </Title>
    </p>
    <p class="mb-3">
      <Title>INLEDNING</Title>
    </p>
    <p class="mb-3">
      När du registrerar dig och använder dig av Eucaps alla plattformar såsom App, hemsida och Pod innebär det att dina personuppgifter behandlas enligt GDPR.
    </p>
    <p class="mb-3">
      Eucaps Holding AB, 559028-9368, (“vi", "vår", "våra" eller “oss") är personuppgiftsansvarig för den behandling av personuppgifter som beskrivs i denna informationstext. Informationstexten beskriver hur och varför vi behandlar dina personuppgifter när du använder Eucaps, vilka cookies som används och varför samt vilka rättigheter du har och hur du använder rättigheterna.
    </p>
    <p class="mb-3">
      Denna allmänna informationstext kan komma att kompletteras av specifik information om viss insamling eller behandling av personuppgifter. Vi kan också komma att be om ditt samtycke för viss behandling. Om specifik information lämnas eller om samtycke efterfrågas gäller detta i tillägg till, och med företräde framför, denna allmänna informationstext.
    </p>
    <p class="mb-3">
      När du använder Eucaps är vi personuppgiftsansvariga. Notera dock att du, genom användandet av Eucaps, kan navigera dig till andra webbplatser och tjänster. Då gäller inte denna informationstext utan istället informationstexten för respektive webbsida/tjänst som du navigerar till.
    </p>
    <p class="mb-3">
      Kontakta gärna oss om du har några frågor om vår personuppgiftsbehandling, våra kontaktuppgifter framgår längst ned i detta dokument.
    </p>
    <p class="mb-3">
      <Title>VILKA PERSONUPPGIFTER BEHANDLAR VI OCH HUR SAMLAR VI IN PERSONUPPGIFTERNA?</Title>
    </p>
    <p class="mb-3">
      1. När du registrerar dig i Eucaps måste du tillhandahålla oss namn, e-postadress, födelseår och välja ett lösenord, annars kan du inte använda Eucaps fullt ut. Om dessa uppgifter ej lämnas kan endast en begränsad del av "Tjänster" och "Applikationer" nyttjas.
    </p>
    <p class="mb-3">
      2. För att få full tillgång till plattformen måste du ha tagit del av denna Integritetspolicy samt Användarvillkor. Detta gör du genom att signera mottagandet av handlingarna med bank-ID. Inga uppgifter sparas från Bank-ID
    </p>
    <p class="mb-3">
      3. Genom användandet av Eucaps skapas även vissa personuppgifter om dig, såsom användar-id, ärendehistorik och i förekommande fall uppgifter om postade inlägg.
    </p>
    <p class="mb-3">
      4. Vi kan också komma att samla in och behandla uppgifter om dig genom cookies och liknande teknologier, såsom IP-nummer och information om hur du använder Eucaps. Vi berättar mer om hur vi använder cookies och liknande tjänster längre ned i denna informationstext.
    </p>
    <p class="mb-3">
      5. Vid kontakt med Eucaps, ledning eller dess kundtjänst måste du kunna uppge vem du är via namn och användarnamn samt e-post adress.
    </p>
    <p class="mb-3">
      <Title>VARFÖR BEHANDLAR VI DINA PERSONUPPGIFTER OCH MED VILKEN RÄTTSLIG GRUND SKER BEHANDLINGEN SAMT HUR LÄNGE SPARAR VI DINA PERSONUPPGIFTER?</Title>
    </p>
    <p class="mb-3">
      Denna tabell nedan gäller för att göra "Tjänsterna", "Applikationen" och "Användargenererat innehåll" (se förklaring sista sidan i detta dokument, ordlista) så korrekt, seriöst och rent från icke tillhörande material.
    </p>
    <p class="mb-3">
      Om du vill ha ytterligare information om de bedömningar som vi har gjort avseende våra berättigade intressen ovan är du välkommen att kontakta oss. Du hittar våra kontaktuppgifter på sista sidan i detta dokument.
    </p>
    <p class="mb-3">
      <Title>Ändamål</Title>
    </p>
    <p class="mb-3">
      <b>Användaradministration, exempelvis för:</b>
    </p>
    <p class="mb-3">
      • att registrera användare;
    </p>
    <p class="mb-3">
      • att tillhandahålla Eucaps;
    </p>
    <p class="mb-3">
      • att tillhandahålla hjälp i ärenden och tillhandahålla support, felsökning och information.
    </p>
    <p class="mb-3">
      • Spara namn, användarnamn, personnummer, adress,
    </p>
    <p class="mb-3">
      <Title>Rättslig grund</Title>
    </p>
    <p class="mb-3">
      Behandlingen är nödvändig fullgöra användaravtalet med dig.
    </p>
    <p class="mb-3">
      <Title>Lagringsperiod</Title>
    </p>
    <p class="mb-3">
      Under den tid som du har ett konto i Eucaps och 12 månader därefter.
    </p>
    <p class="mb-3">
      <Title>Ändamål</Title>
    </p>
    <p class="mb-3">
      <b>Administration av Eucaps exempelvis för:</b>
    </p>
    <p class="mb-3">
      • att uppfylla lagkravgällande vilseledande marknadskommunikation om detta anmäls
    </p>
    <p class="mb-3">
      • att kunna vidta åtgärder om användarkonton missbrukas;
    </p>
    <p class="mb-3">
      • för att fastställa, göra gällande eller försvara oss mot rättsligt anspråk, eller om vi har rättslig skyldighet att göra det.
    </p>
    <p class="mb-3">
      <Title>Rättslig grund</Title>
    </p>
    <p class="mb-3">
      <b>Rättlig förpliktelse</b>
    </p>
    <p class="mb-3">
      enligt 1-5 §§ lagen  2016:1307 om marknadsmanipulation
    </p>
    <p class="mb-3">
      <b>Intresseavvägning</b>
    </p>
    <p class="mb-3">
      där vårt berättigade intresse består i att kunna tillhandahålla, och underhålla Eucaps.
    </p>
    <p class="mb-3">
      <Title>Lagringsperiod</Title>
    </p>
    <p class="mb-3">
      Under den tid som du har ett konto i Eucaps och 12 månader därefter.
    </p>
    <p class="mb-3">
      <Title>Ändamål</Title>
    </p>
    <p class="mb-3">
      Utveckling av Eucaps och andra tjänster, exempelvis genom:
    </p>
    <p class="mb-3">
      • att bedöma och analysera hur Eucaps används för att kunna erbjuda nya tjänster och funktioner;
    </p>
    <p class="mb-3">
      • att genomföra marknads- och kundanalyser;
    </p>
    <p class="mb-3">
      • att genomföra systemutveckling;
    </p>
    <p class="mb-3">
      • att genomföra marknadsföring och kommunikation.
    </p>
    <p class="mb-3">
      <Title>Rättslig grund</Title>
    </p>
    <p class="mb-3">
      Där vårt berättigade intresse består i att kunna utveckla och marknadsföra, Eucaps och andra tjänster.
    </p>
    <p class="mb-3">
      <Title>Lagringsperiod</Title>
    </p>
    <p class="mb-3">
      Under den tid som du har ett konto i Eucaps och 12 månader därefter.
    </p>
    <p class="mb-3">
      <Title>TILL VILKA ANDRA AKTÖRER DELAR VI DINA PERSONUPPGIFTER?</Title>
    </p>
    <p class="mb-3">
      1. För de ändamål som vi har angett ovan kan dina personuppgifter komma att delas till andra aktörer såsom våra IT-leverantörer, myndigheter såsom Finansinspektionen, stat och kommun samt gällande brottsmisstanke. Denna delning göres mycket restriktivt och bara om det finns starka skäl och beslut för utlämning. Detta är nödvändigt för att vi ska kunna tillhandahålla dig Eucaps med en ren och hög kvalité
    </p>
    <p class="mb-3">
      2. Vi kan också komma att dela dina personuppgifter till andra leverantörer och samarbetspartners till oss, exempelvis företag som hjälper oss med marknadsföring. Vi kan också komma att dela dina personuppgifter med myndigheter om det krävs enligt lag.
    </p>
    <p class="mb-3">
      3. Om och när vi delar dina personuppgifter med andra aktörer ser vi alltid till att skydda dina personuppgifter exempelvis genom att ingå nödvändiga avtal såsom personuppgiftbiträdesavtal med relevanta parter. Dina personuppgifter lagras inom EU/EES. Dina personuppgifter kan komma att överföras utanför EU/EES.
    </p>
    <p class="mb-3">
      4. Sådana överföringar är alltid föremål för relevanta skyddsåtgärder från oss, såsom ingående av vid var tid av EU-kommissionen beslutade standardavtalsklausuler (du kan läsa mer om standardavtalsklausulerna och erhålla en kopia på:
    </p>
    <p class="mb-3">
      5.  <a
        class="text-custom-light-header dark:text-custom-dark-header"
        href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_sv"
      >https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_sv</a>
    </p>
    <p class="mb-3">
      <Title>COOKIES</Title>
    </p>
    <p class="mb-3">
      Nedan beskriver vi hur vi använder cookies och liknande tekniker såsom pixeltaggar och Web Beacons (vi kallar fortsättningsvis samlat alla dessa tekniker för "cookies"). Cookies är små textfiler som lagras på din enhet när du använder våra Tjänster.
    </p>
    <p class="mb-3">
      De cookies som vi använder är sessionscookies, som är tillfälliga cookies som endast lagras under den tid som du använder våra Tjänster och de raderas när du loggar ut från våra Tjänster.
    </p>
    <p class="mb-3">
      Vi använder oss endast av s.k. nödvändiga cookies. Dessa cookies är nödvändiga för att du ska kunna använda våra "Tjänster" och alla dess funktioner, exempelvis för att vi ska kunna identifiera begäran från samma webbläsare under en begränsad sessionstid. Vi använder även nödvändiga cookies för att hålla dig som användare inloggad, och för att spara dina tillfälliga inställningar under den tiden du är inloggad. Tredje part har inte tillgång till våra cookies.
    </p>
    <p class="mb-3">
      <Title>HUR KAN DU BEGRÄNSA ELLER RADERA COOKIES?</Title>
    </p>
    <p class="mb-3">
      Du kan radera alla cookies från din enhet och kan ställa in din webbläsare/ändra dina inställningar så att du automatiskt nekar cookies eller informeras om att en webbsida använder cookies.
    </p>
    <p class="mb-3">
      För att få full tillgång till våra Tjänster krävs det viss behandling som sker med hjälp av cookies. Om du väljer att radera eller begränsa användningen av cookies, kommer vissa funktioner inte att fungera och du kommer således inte kunna dra nytta av samtliga funktioner i "Tjänsterna".
    </p>
    <p class="mb-3">
      <Title>VILKA RÄTTIGHETER HAR DU OCH HUR ANVÄNDER DU DIG AV RÄTTIGHETERNA?</Title>
    </p>
    <p class="mb-3">
      Du har följande rättigheter enligt tillämplig dataskyddslagstiftning:
    </p>
    <p class="mb-3">
      1. Du har rätt att få information om huruvida vi behandlar dina personuppgifter och i så fall få tillgång till sådana personuppgifter (s.k. registerutdrag) med ytterligare information om behandlingarna.
    </p>
    <p class="mb-3">
      2. Om du anser att dina personuppgifter är felaktiga eller ofullständiga har du rätt att begära att uppgifterna rättas.
    </p>
    <p class="mb-3">
      3. Under vissa omständigheter kan du begära att dina personuppgifter raderas eller att behandlingen av dem begränsas.
    </p>
    <p class="mb-3">
      4. Du har alltid rätt att återkalla lämnade samtycken för personuppgiftsbehandling.
    </p>
    <p class="mb-3">
      5. När behandling utförs med stöd av intresseavvägning, har du rätt att invända mot sådan behandling. Om invändning görs mot behandling för direktmarknadsföring kommer vi inte längre att behandla dina personuppgifter för det ändamålet.
    </p>
    <p class="mb-3">
      6. På begäran har du även rätt att, under vissa förutsättningar, få ut och ta med dig vissa av dina personuppgifter till annan personuppgiftsansvarig, s.k. dataportabilitet.
    </p>
    <p class="mb-3">
      7. Om du anser att vi behandlat dina personuppgifter felaktigt eller i strid mot tillämplig dataskyddslagstiftning, har du rätt att inge klagomål till Integritetsskyddsmyndigheten, se <a
        href="https://www.imy.se"
        target="_blank"
      >https://www.imy.se</a>
    </p>
    <p class="mb-3">
      <Title>Ändring av Integritetsvillkor/Personuppgiftsbehandling samt Allmänna villkor</Title>
    </p>
    <p class="mb-3">
      Se aktuella uppdateringar på:
    </p>
    <p class="mb-3">
      <RouterLink
        class="text-custom-light-header dark:text-custom-dark-header"
        :to="{name: 'privacy'}"
      >
        Integritetspolicy
      </RouterLink> samt <RouterLink
        class="text-custom-light-header dark:text-custom-dark-header"
        :to="{name: 'terms'}"
      >
        Användarvillkor
      </RouterLink>
    </p>
    <p class="mb-3">
      Eucaps äger rätten att ändra villkoren för Eucaps tjänst, App, web från tid till annan och kommer då att publicera de ändrade Användarvillkoren på Webbplatsen, App, web, brev, mejl eller på annat sätt visats för medlem/kund. Detta gäller även 3:e partsavtal.
    </p>
    <p class="mb-3">
      De ändrade Användarvillkoren blir gällande för nya användare från och med det datum de publiceras på vår webbplats. Redan existerande användare blir bundna av de ändrade Användarvillkoren 10 dagar efter meddelandet visats genom publicering på Eucaps webbplats, e-post eller på annat sätt publicerats.
    </p>
    <p class="mb-3">
      Eucaps kan utan föregående meddelande komma att ändra Applikationens layout eller utformning i övrigt, lägga till eller ta bort funktioner samt göra nödvändiga uppdateringar. Genom att signa upp på dessa villkor godtar du även att du godkänner uppdaterade versioner av Tjänsten/Appen.
    </p>
    <p class="mb-3">
      <Title>EUCAPS'S KONTAKTUPPGIFTER</Title>
    </p>
    <p class="mb-3">
      Tveka inte att kontakta oss, eller vår dataskyddsansvarig, om du vill utöva dina rättigheter enligt ovan eller om du har några andra funderingar kring vår personuppgiftsbehandling.
    </p>
    <p>
      <b>EUCAPS AB</b>
    </p>
    <p>
      Org. nummer: 559296-9579
    </p>
    <p>
      Lindengatan 1 C - 582 53 Linköping
    </p>
    <p class="mb-3">
      <a
        class="text-custom-light-header dark:text-custom-dark-header"
        href="mailto:support@eucaps.com"
      >support@eucaps.com</a><br>
    </p>
    <p class="mb-3">
      <Title>Ordlista:</Title>
    </p>
    <p class="mb-3">
      <b>"Användarvillkor"</b>
    </p>
    <p class="mb-3">
      Är de regler i detta avtal som du som medlem måste följa och rätta dig efter
    </p>
    <p class="mb-3">
      <b>"Applikationen"</b>
    </p>
    <p class="mb-3">
      Är de verktyg som Eucaps tillhandahåller = App, hemsida, Pod och övrigt inklusive dess innehåll
    </p>
    <p class="mb-3">
      <b>"Tjänsterna"</b>
    </p>
    <p class="mb-3">
      Är de möjligheter och tjänster som finns i "Applikationen" och som Eucaps tillhandahåller till dig som godkänd medlem
    </p>
    <p class="mb-3">
      <b>"Integritetspolicyn"</b>
    </p>
    <p class="mb-3">
      Är det separata avtal med dig som medlem som reglerar hur vi på Eucaps behandlar dina personuppgifter
    </p>
    <p class="mb-3">
      <b>"Användargenererat innehåll"</b>
    </p>
    <p class="mb-3">
      Med Användargenererat innehåll avses allt sådant innehåll som du skapar/skriver och/eller lägger upp i Applikationen som t.ex. bilder, texter och information (“Användargenererat innehåll").
    </p>
  </Container>
</template>

<script>

export default {
  name: 'Privacy',
  metaInfo () {
    return {
      title: this.$t('Privacy')
    }
  }
}
</script>
