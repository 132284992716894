<template>
  <button
    class="inline-block text-gray-500 flex items-center justify-center font-medium text-sm leading-6 focus:outline-none dark:text-custom-purple-100"
    :class="size"
  >
    <Icon
      name="message-rounded"
      class="text-xl mt-1 text-gray-500 dark:text-custom-purple-100"
    />
    <div class="ml-1 mt-1 text-sm font-semibold">
      {{ numberOfComments }}
    </div>
  </button>
</template>

<script>

export default {
  name: 'CommentButton',
  props: {
    size: { type: String, default: 'text-center' },
    numberOfComments: { type: Number, default: 0 }
  }
}
</script>
