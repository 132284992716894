<template>
  <RouterLink
    :to="isLoggedIn ? {name: 'generalfeed'} : '/'"
    class="flex flex-col items-center"
  >
    <Logo />
    <!-- <span class="text-white font-extrabold">BETA</span> -->
  </RouterLink>
</template>

<script>

export default {
  name: 'Logo',
  props: {
    color: { default: 'white', type: String },
    isLoggedIn: { default: false, type: Boolean }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  height: 10rem;
}
</style>
