import { RepositoryFactory } from '@/api/repositoryFactory'
import mentions from '@/js/helpers/mentions'
import router from '@/js/router'
import socket from '@/js/socket'
import { i18n } from '../js/language'
const repository = RepositoryFactory.get('chats')

export default {
  state: {
    chatList: [],
    alert: {
      title: '',
      create: false,
      body: '',
      type: '',
      showConfirmButton: false
    },
    chatTitle: 'General Chats',
    activeUsers: [],
    loading: false,
    newLoading: false,
    loadingNewMessages: false,
    loadingMessages: false,
    loadingCompanyChat: true,
    selectedChatId: null,
    selectedRoom: 'general',
    selectedChatUuid: null,
    sendingMessages: false,
    nextPageUrl: null,
    loadingChat: false,
    list: [],
    messages: [],
    seenBy: [],
    filter: {
      byCompany: null
    },
    loadingChatMenu: false,
    editMessageId: null
  },
  getters: {
    selectedRoom: state => state.selectedRoom,
    editMessageId: state => state.editMessageId,
    alert: state => state.alert,
    selectedChatId: state => state.selectedChatId,
    selectedChatUuid: state => state.selectedChatUuid,
    list: state => state.list,
    selectedChat: state => {
      return state.list.find(chat => chat.id === state.selectedChatId)
    },
    selectedChatUser: state => {
      let chat = state.list.find(chat => chat.id === state.selectedChatId)
      return chat.stock_users
    },
    loadingChat: state => state.loadingChat,
    loadingCompanyChat: state => state.loadingCompanyChat,
    loading: state => state.loading,
    newLoading: state => state.newLoading,
    nextPageUrl: state => state.nextPageUrl,
    activeUsers: state => state.activeUsers,
    allMessages: state => state.messages,
    messages (state) {
      if (!state.messages[state.selectedChatId]) {
        return []
      }
      return state.messages[state.selectedChatId].filter(message => message.room === state.selectedRoom)
    },
    seenBy: state => state.seenBy[state.selectedChatId],
    sendingMessages: state => state.sendingMessages,
    loadingMessages: state => state.loadingMessages,
    loadingNewMessages: state => state.loadingNewMessages,
    generalChats: state => {
      return state.list.filter(chat => chat.company === null)
    },
    hasGeneralChats: (_state, getters) => getters.generalChats.length > 0,
    hasCompaniesChats: (state, getters) => [...Object.values(getters.companiesChats)].length > 0 && (state.filter.byCompany === null),
    hasCompanyChats: (_state, getters) => getters.companyChats.length > 0,
    // companiesChat: (state, rootState) => {
    //   return state.list.find(chat => chat.company_id === rootState.companies.selectedCompanyId)
    // },
    companyChats: state => {
      if (state.filter.byCompany === null) return []
      return state.list.filter(chat => {
        if (chat.company === null) return false
        return chat.company.id === state.filter.byCompany
      })
    },
    loadingChatMenu: state => state.loadingChatMenu
  },
  mutations: {
    SET_EDIT_MESSAGE_ID (state, id) {
      state.editMessageId = id
    },
    SET_FILTER (state, payload) {
      state.filter = { ...state.filter, ...payload }
    },
    SET_USERS (state, payload) {
      state.activeUsers = { ...payload }
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_NEW_LOADING (state, boolean) {
      state.newLoading = boolean
    },
    SET_SENDING_MESSAGES (state, boolean) {
      state.sendingMessages = boolean
    },
    SET_SELECTED_ROOM (state, room) {
      state.selectedRoom = room
    },
    SET_CHAT_LIST (state, chatList) {
      // if (state.list.length > 0) {
      //   chatList.find((response, index) => {
      //     const flag = state.list.find(chat => {
      //       return (response.uuid === chat.uuid)
      //     })
      //     if (!flag || flag === undefined) {
      //       state.list.push(response)
      //     } else {
      //       state.list[index] =
      //     }
      //   })
      // } else {
      state.list = [...chatList]
      // }
    },
    PUSH_MESSAGE (state, payload) {
      payload.filter(chatMessage => {
        if (chatMessage.message.mentions.length > 0) {
          if (chatMessage.chatable.message && chatMessage.chatable.message.match(mentions.MATCHING)) {
            chatMessage.chatable.message = mentions.parse(chatMessage.chatable.message, chatMessage.message.mentions, 'chat')
          }
        }
      })

      if (!state.messages || !state.messages[state.selectedChatId]) {
        state.messages = { ...state.messages, [state.selectedChatId]: payload }
      } else {
        payload.find(response => {
          const findIndex = state.messages[state.selectedChatId].findIndex(message => message.id === response.id)
          if (findIndex > -1) {
            state.messages[state.selectedChatId][findIndex] = response
          } else {
            state.messages[state.selectedChatId].push(response)
          }
        })
      }

      state.messages = { ...state.messages }
    },
    UNSHIFT_MESSAGE (state, payload) {
      if (payload.length === 0) {
        return
      }
      const chatId = payload[0].chat_id
      payload.map(chatMessage => {
        if (chatMessage.chatable.message && chatMessage.chatable.message.match(mentions.MATCHING)) {
          chatMessage.chatable.message = mentions.parse(chatMessage.chatable.message, chatMessage.message.mentions, 'chat')
        }
      })

      let chat = state.list.find(chat => chat.id === chatId)
      if (chat) {
        chat.updated_at = payload[0].updated_at
      }

      if (!state.messages || !state.messages[chatId]) {
        state.messages = { ...state.messages, [chatId]: payload }
      } else {
        payload.find(response => {
          const findIndex = state.messages[chatId].findIndex(message => message.id === response.id)
          if (findIndex > -1) {
            let chat = state.messages[chatId]
            let message = chat[findIndex]
            message = response
            chat[findIndex] = message
            state.messages = { ...state.messages, [chatId]: chat }
            // state.messages[chatId][findIndex] = response
          } else {
            state.messages[chatId].unshift(response)
          }
        })
      }

      state.messages = { ...state.messages }
    },
    SET_SELECTED_CHAT_ID (state, id) {
      state.selectedChatId = id
    },
    SET_SELECTED_CHAT_UUID (state, uuid) {
      state.selectedChatUuid = uuid
    },
    SET_LOADING_MESSAGES (state, boolean) {
      state.loadingMessages = boolean
    },
    SET_LOADING_NEW_MESSAGES (state, boolean) {
      state.loadingNewMessages = boolean
    },
    SET_CHAT_LOADING (state, boolean) {
      state.loadingChat = boolean
    },
    SET_ACTIVE_USERS (state, payload) {
      state.activeUsers = payload
    },
    NEXT_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    REMOVE_ACTIVE_USERS (state, payload) {
      const index = state.activeUsers.findIndexOf(data => data === payload)
      state.activeUsers.splice(index, 1)
    },
    SET_SEEN_BY (state, payload) {
      state.seenBy = { ...state.seenBy, [state.selectedChatId]: payload }
    },
    SET_ALERT (state, payload) {
      state.alert.title = payload.title
      state.alert.body = payload.body
      state.alert.create = payload.create
      state.alert.type = payload.type
      state.alert.showConfirmButton = payload.showConfirmButton
    },
    SET_COMPANY_CHAT_LOADING (state, boolean) {
      state.loadingCompanyChat = boolean
    },
    SET_LOADING_CHAT_MENU (state, boolean) {
      state.loadingChatMenu = boolean
    },
    DELETE_MESSAGE (state, payload) {
      state.messages[payload.chatId].splice(state.messages[payload.chatId].findIndex(message => message.id === payload.messageId), 1)
    },
    RESET_ALERT (state) {
      state.alert = {
        title: '',
        create: false,
        body: '',
        type: '',
        showConfirmButton: false
      }
    }
  },
  actions: {
    setEditMessageId ({ commit }, id) {
      commit('SET_EDIT_MESSAGE_ID', id)
    },
    async fetch ({ commit, state, dispatch }) {
      try {
        if (state.list.length > 0) {
          commit('SET_NEW_LOADING', true)
        } else {
          commit('SET_LOADING', true)
        }
        const response = await repository.list()
        commit('SET_LOADING', false)
        commit('SET_NEW_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_CHAT_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchChatMessages ({ commit, state, rootState, dispatch }, uuid) {
      // DISABLE FOR NOW, DUE TO PAYLOAD STUFF
      const response = await repository.fetchMessages(uuid)
      if (response.status === 200 && response.data && response.data.data) {
        commit('UNSHIFT_MESSAGE', response.data.data)
      }
    },
    async joinChats ({ commit, state, dispatch, rootState }) {
      await dispatch('fetch')
      if (state.list.length > 0) {
        const email = rootState.users.user.email
        state.list.forEach(chat => {
          // DISABLE COMPANY CHATS FOR NOW
          if (chat.company) {
            return
          }

          dispatch('fetchChatMessages', chat.uuid)

          let rooms = [
            '-general'
          ]

          if (chat.company) {
            rooms.push('-stock')
            rooms.push('-analysis')
            rooms.push('-offtopic')
          }
          rooms.map(room => {
            // console.log('JOIN ROOM ' + chat.id.toString() + room)
            socket.emit('join-room', chat.id.toString() + room, email, (data) => {
              // socket.emit('join-room', chat.id.toString(), email, (data) => {
              if (data !== null) {
                dispatch('setSeenBy', data)
              }
            })
          })
        })
      }
    },
    async scroll ({ commit, state }) {
      commit('SET_LOADING_NEW_MESSAGES', true)
      const response = await repository.scroll(state.nextPageUrl)
      commit('SET_LOADING_NEW_MESSAGES', false)
      if (response.status === 200 && response.data.data) {
        await commit('PUSH_MESSAGE', response.data.data)
        await commit('NEXT_PAGE_URL', response.data.next_page_url)
      }
    },
    async joinCompanyChat ({ commit, dispatch, rootState }, id) {
      try {
        // console.log('JOIN COMPANY CHAT')

        const response = await repository.joinCompanyChat(id)
        // console.log('JOIN COMPANY CHAT 2')
        if (response.status === 200) {
          let rooms = [
            '-general'
          ]

          rooms.push('-stock')
          rooms.push('-analysis')
          rooms.push('-offtopic')
          rooms.map(room => {
            socket.emit('join-room', response.data.id.toString() + room, rootState.users.user.email, (data) => {
              if (data !== null) {
                dispatch('setSeenBy', data)
              }
            })
          })
        }
      } catch (e) {
        console.log('Error occured: ' + e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    updatemessageSeen ({ commit, state, rootState }, data) {
      if (data.length > 0) {
        data.forEach(messageid => {
          state.messages[state.selectedChatId].find(message => {
            if (message.id === messageid) {
              message.seen_by.find(user => {
                if (user.id === rootState.users.user.profile.id) {
                  user.pivot.seen = 1
                }
              })
            }
          })
        })
        state.messages = { ...state.messages }
      }
      commit('SET_SELECTED_CHAT_UUID', null)
      commit('SET_SELECTED_CHAT_ID', null)
    },
    async messagesSeen ({ commit, state, dispatch }, data) {
      try {
        const response = await repository.messagesSeen(state.selectedChatUuid, { chat_messages: data })
        if (response.status === 204) {
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchMessages ({ commit, state, rootState, dispatch }) {
      try {
        if (state.messages[state.selectedChatId] === undefined) {
          commit('SET_LOADING_MESSAGES', true)
        }
        const response = await repository.fetchMessages(state.selectedChatUuid)
        commit('SET_LOADING_MESSAGES', false)
        if (response.status === 200 && response.data && response.data.data) {
          commit('UNSHIFT_MESSAGE', response.data.data)
          commit('NEXT_PAGE_URL', response.data.next_page_url)

          if (response.data.data && response.data.data.length > 0) {
            socket.emit('message-seen', { userId: rootState.users.user.profile.id, chatId: state.selectedChatId, room: state.selectedRoom, messageId: response.data.data[0].id })
          }
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    activeUsers ({ commit }, payload) {
      commit('SET_ACTIVE_USERS', payload)
    },
    userOffline ({ commit }, payload) {
      commit('REMOVE_ACTIVE_USERS', payload)
    },
    async one ({ commit, dispatch }, chatUuid) {
      try {
        const response = await repository.singleChat(chatUuid)
        if (response.status === 200 && response.data) {
          commit('SET_CHAT_LIST', [response.data])
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    setSeenBy ({ commit }, data) {
      commit('SET_SEEN_BY', data)
    },
    async like ({ commit, state, dispatch }, payload) {
      try {
        const response = await repository.like(state.selectedChatUuid, payload.messageId, { reaction: payload.reaction })
        if (response.status === 200 && response.data) {
          commit('PUSH_MESSAGE', [response.data])
          socket.emit('resend-message', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    resetAlert ({ commit }) {
      commit('RESET_ALERT')
    },
    setAlert ({ commit }, payload) {
      commit('SET_ALERT', payload)
    },
    async create ({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_NEW_LOADING', true)
        const response = await repository.createChat(payload)
        commit('SET_NEW_LOADING', false)

        if (response.status === 201 && response.data) {
          commit('SET_CHAT_LIST', [response.data])
          commit('SET_CHAT_LOADING', false)
          socket.emit('join-room', response.data.id.toString(), rootState.users.user.email, (data) => {
            if (data !== null) {
              dispatch('setSeenBy', data)
            }
          })

          router.push({ name: 'chat', params: { id: response.data.uuid } })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async selectChat ({ commit, state, dispatch }, uuid) {
      if (state.list.length === 0) {
        await dispatch('one', uuid)
      }

      const chat = state.list.find(chats => chats.uuid === uuid)
      if (chat) {
        commit('SET_SELECTED_CHAT_ID', chat.id)
        commit('SET_SELECTED_CHAT_UUID', chat.uuid)
      } else {
        router.push({ name: 'chats' })
      }
    },
    async setSelectedRoom ({ commit }, room) {
      commit('SET_SELECTED_ROOM', room)
    },
    async setSelectedCompanyChat ({ commit, state, rootState, dispatch }) {
      commit('SET_COMPANY_CHAT_LOADING', true)
      const chat = state.list.find(chats => chats.company_id === rootState.companies.selectedCompanyId)
      if (chat) {
        await commit('SET_SELECTED_CHAT_UUID', chat.uuid)
        await commit('SET_SELECTED_CHAT_ID', chat.id)
        commit('SET_COMPANY_CHAT_LOADING', false)
      } else {
        await dispatch('fetchCompanyChat')
        commit('SET_COMPANY_CHAT_LOADING', false)
      }
    },
    async fetchCompanyChat ({ commit, rootState, dispatch }) {
      try {
        const response = await repository.fetchCompanyChat(rootState.companies.selectedCompanyId)
        if (response.status === 200 && response.data) {
          commit('SET_CHAT_LIST', [response.data])
          await commit('SET_SELECTED_CHAT_ID', response.data.id)
          await commit('SET_SELECTED_CHAT_UUID', response.data.uuid)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async startChat ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_CHAT_LOADING', true)
        let findChat = null
        if (state.list.length > 0) {
          findChat = state.list.find(chat => {
            if (chat.stock_users.length !== 2) return null

            return chat.stock_users.find(user => user.id === payload.id)
          })
        }
        if (findChat === null) {
          const users = {
            stock_users: []
          }
          users.stock_users.push(payload.id)
          await dispatch('create', users)
        } else {
          commit('SET_CHAT_LOADING', false)
          router.push({ name: 'chat', params: { id: findChat.id } })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    setMessages ({ commit, state, rootState }, payload) {
      commit('PUSH_MESSAGE', payload)
      if (payload.messages.length > 0) {
        socket.emit('message-seen', { userId: rootState.users.user.profile.id, chatId: state.selectedChatId, room: state.selectedRoom, messageId: payload.messages[payload.messages.length - 1].id })
      }
    },
    async addMember ({ commit, state, dispatch }, user) {
      try {
        const chat = state.list.find(chat => chat.id === state.selectedChatId)

        let isUserAlreadyExist = false
        for (let i = 0; i < chat.stock_users.length; i++) {
          const chatUser = chat.stock_users[i]
          if (chatUser.id === user.id) {
            isUserAlreadyExist = true
            break
          }
        }
        if (isUserAlreadyExist) {
          dispatch('snackbar/snack', { text: i18n.t('Member already added.'), type: 'info', show: true, autoclose: true }, { root: true })
        } else {
          chat.stock_users.push({
            pivot: {
              chat_id: state.selectedChatId,
              stock_user_id: user.id
            },
            companies_count: user.companies_count,
            created_at: user.created_at,
            username: user.username,
            full_name: user.full_name,
            id: user.id,
            image: user.image,
            posts_count: user.posts_count,
            reputation: user.reputation
          })
          const payload = {
            stock_users: []
          }
          payload.stock_users.push(user.id)
          const response = await repository.addMember(state.selectedChatUuid, payload)
          if (response.status === 204) {
            dispatch('snackbar/snack', { text: i18n.t('Member added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
          // commit('SET_ALERT', { create: true, title: 'Success!', body: 'Member added successfully.' })
          }
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async removeMember ({ commit, state, dispatch }, userIds) {
      try {
        const chat = state.list.find(chat => chat.id === state.selectedChatId)
        userIds.forEach(userId => {
          let chatUserIndex = chat.stock_users.findIndex(chatUser => { return chatUser.id === parseInt(userId) })
          chat.stock_users.splice(chatUserIndex, 1)
        })

        const response = await repository.removeMember(state.selectedChatUuid, { stock_users: userIds })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: i18n.t('Member removed successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        console.log(e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async sendMessage ({ commit, dispatch, state, rootState }, payload) {
      try {
        commit('SET_SENDING_MESSAGES', true)
        if (payload.unreadMessages.length > 0) {
          payload.unreadMessages.forEach(messageid => {
            state.messages[state.selectedChatId].find(message => {
              if (message.id === messageid) {
                message.seen_by.find(user => {
                  if (user.id === rootState.users.user.profile.id) {
                    user.pivot.seen = 1
                  }
                })
              }
            })
          })
          state.messages = { ...state.messages }
        }
        const response = await repository.saveMessage(state.selectedChatUuid, state.selectedRoom, payload.formData)
        commit('SET_SENDING_MESSAGES', false)
        if (response.status === 200 && response.data) {
          commit('UNSHIFT_MESSAGE', [response.data])
          socket.emit('send-message', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editMessage ({ commit, dispatch, state, rootState }, payload) {
      try {
        const response = await repository.editMessage(state.selectedChatUuid, payload.messageId, payload.formData)
        commit('SET_EDIT_MESSAGE_ID', null)
        if (response.status === 200 && response.data) {
          commit('UNSHIFT_MESSAGE', [response.data])
          socket.emit('resend-message', response.data)
        }
      } catch (e) {
        commit('SET_EDIT_MESSAGE_ID', null)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    receiveMessage ({ commit }, payload) {
      commit('UNSHIFT_MESSAGE', [payload])
    },
    async report ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING_CHAT_MENU', true)
        const response = await repository.report(payload)

        commit('SET_LOADING_CHAT_MENU', false)
        if (response.status === 200) {
          dispatch('snackbar/snack', { text: 'Message reported successfully', type: 'success', show: true, autoclose: true }, { root: true })
        } else {
          dispatch('snackbar/snack', { text: 'Message already reported', type: 'info', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_LOADING_CHAT_MENU', false)
        console.log('Error occured : ' + e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })

        return {
          status: 'false'
        }
      }
    },
    async deleteMessage ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING_CHAT_MENU', true)
        const response = await repository.deleteMessage(state.selectedChatUuid, payload)
        commit('SET_LOADING_CHAT_MENU', false)
        if (response.status === 204) {
          socket.emit('delete-message', { chatId: state.selectedChatId, room: state.selectedRoom, messageId: payload })
          commit('DELETE_MESSAGE', { chatId: state.selectedChatId, messageId: payload })
          dispatch('snackbar/snack', { text: 'Message deleted successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_LOADING_CHAT_MENU', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.chatList = [...[]]
      state.list = [...[]]
      state.messages = [...[]]
      state.activeUsers = [...[]]
    }
  },
  namespaced: true
}
