<template>
  <div
    class="rounded-full w-8 h-8 flex justify-center items-center p-1 cursor-pointer"
    :class="color"
    @click="$emit('click','clicked')"
  >
    <Icon
      :name="red ? 'pencil' : 'edit'"
      class="text-lg"
      solid
    />
  </div>
</template>

<script>

export default {
  name: 'EditButtonCircle',
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    red: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    color () {
      if (this.red) {
        if (this.edit) {
          return 'text-custom-orange-400 bg-custom-purple-500'
        } else {
          return 'bg-custom-orange-400 hover:bg-custom-orange-300 text-custom-purple-500'
        }
      } else {
        if (this.edit) {
          return 'text-custom-yellow-300 bg-custom-purple-500'
        } else {
          return 'bg-custom-yellow-300 text-custom-purple-500'
        }
      }
    }
  }
}
</script>
