import Vue from 'vue'
import App from './App.vue'
import router from './js/router'
import { i18n } from '@/js/language/'
import store from './js/store'
import '@/assets/css/tailwind.scss'
import './js/autoload'
import './js/firebase'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { library, config } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './registerServiceWorker'
import Vue2TouchEvents from 'vue2-touch-events'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'

import TextareaAutosize from 'vue-textarea-autosize'
import { StatusBar, Animation } from '@capacitor/status-bar'
import Multiselect from 'vue-multiselect'

import Highcharts from 'highcharts'
import Stock from 'highcharts/modules/stock'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsVue from 'highcharts-vue'
import Flag from 'vue-flagpack'

StatusBar.setStyle({ animation: Animation.SLIDE })
StatusBar.setBackgroundColor({ color: '#543db2' })

Stock(Highcharts)
HighchartsExporting(Highcharts)
HighchartsMore(Highcharts)
Vue.use(HighchartsVue)
Vue.use(TextareaAutosize)
Vue.use(VueMeta)
Vue.use(PortalVue)
Vue.use(Vue2TouchEvents)

Vue.use(Flag, {
  name: 'Flag'
})
library.add(far, fas, fab)
config.searchPseudoElements = true
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('Multiselect', Multiselect)
Vue.config.productionTip = false
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
