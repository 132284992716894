<template>
  <div
    class="flex flex-shrink-0 justify-center items-center relative"
    :class="[defaultClass,margins]"
  >
    <PremiumIcon
      v-if="usePremium"
      :is-premium="stockUser && stockUser.is_premium"
      :small="small"
      class="absolute bottom-0 -right-1 z-20"
    />
    <div
      v-if="avatar && avatar.image && avatar.image !== ''"
      class="bg-gray-400 absolute inset-0 bg-center rounded-full bg-no-repeat bg-cover"
      :style="'background-image: url(\'' + avatar.image + '\');'"
    />
    <AvatarInitials
      v-else
      :name="fullName"
      :class="defaultClass"
      :size="size"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Avatar',
  props: {
    stockUser: {
      type: Object,
      default: () => {}
    },
    company: {
      type: Object,
      default: () => {}
    },
    rounded: {
      type: Boolean,
      default: true
    },
    usePremium: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: true
    },
    defaultClass: {
      type: String,
      default: 'w-10 h-10'
    },
    margins: {
      type: String,
      default: 'mr-2'
    },
    size: {
      type: Number,
      default: 40
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user'
    }),
    avatar () {
      if (this.stockUser) {
        return this.stockUser
      }

      if (this.company) {
        return this.company
      }

      return this.user.profile
    },
    fullName () {
      if (!this.avatar) {
        return ''
      }

      if (this.company) {
        return this.avatar.name
      }

      if (this.avatar.user) {
        if (this.avatar.user.profile_type === 'App\\Models\\GuestUser') {
          return this.avatar.name
        }
      }

      return this.avatar.username
    }
  }
}
</script>

<style lang="scss">
  .star-margin {
    padding-top: 2px;
  }
</style>
