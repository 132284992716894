<template>
  <div class="flex items-center hidden md:block text-white text-sm font-medium">
    <RouterLink
      :to="{name: 'heyCompany'}"
      exact
      class="mr-6 lg:mr-14 border-b-2 border-transparent py-2"
    >
      Hey company
    </RouterLink>
    <RouterLink
      exact
      :to="{name: 'heyInvestor'}"
      class="mr-6 lg:mr-14 border-b-2 border-transparent py-2"
    >
      Hey investor
    </RouterLink>
    <RouterLink
      exact
      :to="{name: 'heyEucaps'}"
      class="mr-6 lg:mr-14 border-b-2 border-transparent py-2"
    >
      Hey Eucaps
    </RouterLink>
    <RouterLink
      :to="{name: 'companyScreener'}"
      exact
      class="mr-6 lg:mr-14 border-b-2 border-transparent py-2"
    >
      Explore
    </RouterLink>
    <RouterLink
      exact
      :to="{name: 'login'}"
      class="mr-6 lg:mr-14 border-b-2 border-transparent py-2"
    >
      Sign in
    </RouterLink>
    <RouterLink
      exact
      :to="{name: 'signup'}"
    >
      <button class="bg-custom-orange-400 py-2 px-6 rounded-lg ">
        Sign up
      </button>
    </RouterLink>
  </div>
</template>
<script>
export default {
  name: 'DesktopMenu',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply border-custom-green-500;
}
.menu-slider {
  transform: translateX(100%);
}
.slide-in {
  animation: slide-in 0.5s forwards;
}
.slide-out {
  animation: slide-out 0.9s backwards;
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(-50%);
  }
  100% {
    width: translateX(100%);
  }
}
</style>
