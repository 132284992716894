<template>
  <NeutralButton
    secondary
    class="w-full bg-freja text-white"
    @click.native="$emit('click', $event)"
  >
    <div class="flex justify-center items-center pr-2 py-1">
      <span
        v-if="login"
        class="text-xs"
      >{{ $t('Continue with') }}</span>
      <span
        v-else
        class="text-xs"
      >{{ $t('Verify your identity with') }}</span>
      <img
        class="w-20 pl-1"
        src="/img/freja.svg"
      >
    </div>
  </NeutralButton>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FrejaeIDButton',
  props: {
    login: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      activeTheme: 'users/activeTheme'
    })
  }
}
</script>
