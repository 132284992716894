<template>
  <div class="flex md:hidden items-center text-white text-sm font-medium">
    <button
      id="menu-btn"
      class="block text-3xl focus:outline-none ml-3 mt-1"
      :class="open && 'open'"
      @click="$emit('toggle-navbar', $event)"
    >
      <Icon name="menu" />
    </button>
    <div
      id="menu"
      :class=" loaded ? open ? 'slide-in right-0' : 'slide-out' : '' "
      class="fixed block md:hidden menu-slider h-full w-4/5 flex flex-col items-start p-8 mt-2 space-y-6 font-semibold text-xl text-white bg-custom-purple-500 top-0 z-20"
    >
      <button
        class="text-3xl"
        @click="$emit('toggle-navbar', $event)"
      >
        <Icon name="x" />
      </button>
      <RouterLink
        exact
        :to="{name: 'heyCompany'}"
        class="mr-6 lg:mr-14 border-b-2 border-transparent py-2"
      >
        Hey company
      </RouterLink>
      <RouterLink
        exact
        :to="{name: 'heyInvestor'}"
        class="mr-6 lg:mr-14 border-b-2 border-transparent py-2"
      >
        Hey investor
      </RouterLink>
      <RouterLink
        exact
        :to="{name: 'heyEucaps'}"
        class="mr-6 lg:mr-14 border-b-2 border-transparent py-2"
      >
        About eucaps
      </RouterLink>
      <RouterLink
        exact
        :to="{name: 'login'}"
        class="mr-6 lg:mr-14 border-b-2 border-transparent py-2"
      >
        Sign in
      </RouterLink>
      <RouterLink
        exact
        :to="{name: 'signup'}"
      >
        <button class="bg-custom-orange-400 py-2 px-6 rounded-lg ">
          Sign up
        </button>
      </RouterLink>
    </div>
  </div>
</template>
<script>

export default {
  name: 'MobileMenu',
  components: {
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply border-custom-green-500;
}
.menu-slider {
  transform: translateX(100%);
}
.slide-in {
  animation: slide-in 0.5s forwards;
}
.slide-out {
  animation: slide-out 0.9s backwards;
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(-50%);
  }
  100% {
    width: translateX(100%);
  }
}
</style>
