import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('polls')

export default {
  state: {
    loading: false,
    loadingCreate: false,
    loadingDelete: false,
    poll: null,
    polls: []
  },
  getters: {
    loading: (state) => state.loading,
    loadingCreate: (state) => state.loadingCreate,
    loadingDelete: (state) => state.loadingDelete,
    polls: (state) => state.polls,
    poll: (state) => state.poll
  },
  mutations: {
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_CREATE_LOADING (state, payload) {
      state.loadingCreate = payload
    },
    SET_DELETE_LOADING (state, payload) {
      state.loadingDelete = payload
    },
    SET_POLL (state, payload) {
      state.poll = payload
    },
    SET_POLLS (state, payload) {
      state.polls = [...payload]
    }
  },
  actions: {
    async fetchPoll ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.getNextPoll(payload)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_POLL', response.data)
        } else {
          commit('SET_POLL', null)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'POLL DATA ARE NOT FETCH', e }, { root: true })
        console.log(e)
      }
    },
    async fetch ({ commit, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetch()
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_POLLS', response.data.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'POLL DATA ARE NOT FETCH', e }, { root: true })
        console.log(e)
      }
    },
    async create ({ commit, dispatch }, payload) {
      try {
        commit('SET_CREATE_LOADING', true)
        const response = await repository.create(payload)
        commit('SET_CREATE_LOADING', false)
        if (response.status === 200) {
          dispatch('fetch')
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'POLL DATA ARE NOT FETCH', e }, { root: true })
        console.log(e)
      }
    },
    async delete ({ commit, dispatch }, payload) {
      try {
        commit('SET_DELETE_LOADING', true)
        const response = await repository.deletePoll(payload)
        commit('SET_DELETE_LOADING', false)
        if (response.status === 200) {
          dispatch('fetch')
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'POLL DATA ARE NOT FETCH', e }, { root: true })
        console.log(e)
      }
    },
    async updatePoll ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        await repository.answer(payload)
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'POLL DATA ARE NOT FETCH', e }, { root: true })
        console.log(e)
      }
    }
  },
  namespaced: true
}
