<template>
  <footer
    v-show="useFooter"
    class="py-20 bg-custom-purple-500"
  >
    <div class="flex lg:flex-row flex-col items-center justify-center text-white font-medium py-16 ">
      <RouterLink
        :to="{name: 'home'}"
        class="lg:mx-6 mr-0 lg:mb-0 mb-4"
      >
        Home
      </RouterLink>
      <RouterLink
        :to="{name: 'heyCompany'}"
        class="lg:mx-6 mr-0 lg:mb-0 mb-4"
      >
        Hey company
      </RouterLink>
      <RouterLink
        :to="{name: 'heyInvestor'}"
        class="lg:mx-6 mr-0 lg:mb-0 mb-4"
      >
        Hey investor
      </RouterLink>
      <RouterLink
        :to="{name: 'heyEucaps'}"
        class="lg:mx-6 mr-0 lg:mb-0 mb-4"
      >
        Hey Eucaps
      </RouterLink>
      <RouterLink
        :to="{name: 'blogs'}"
        class="lg:mx-6 mr-0 lg:mb-0 mb-4"
      >
        {{ $t('Articles') }}
      </RouterLink>
      <RouterLink
        v-if="!isLoggedIn"
        :to="{name: 'signup'}"
        class="lg:mx-6 mr-0 lg:mb-0 mb-4 text-custom-orange-400"
      >
        Sign up
      </RouterLink>
      <RouterLink
        v-if="!isLoggedIn"
        :to="{name: 'login'}"
        class="lg:mx-6 mr-0 lg:mb-0 mb-4 text-custom-orange-400 rounded-lg"
      >
        Sign in
      </RouterLink>
      <RouterLink
        v-else
        :to="{name: 'myEucapsOverview'}"
        class="lg:mx-6 mr-0 lg:mb-0 mb-4 text-custom-orange-400 rounded-lg"
      >
        My Home
      </RouterLink>
    </div>
    <div>
      <div class="flex items-center justify-center text-white text-sm">
        <p class="lg:mr-8 mr-8">
          Stockholm
        </p>
        <p>Zürich</p>
      </div>
      <div class="flex justify-center items-center">
        <LogoContainer class="py-6" />
      </div>
      <div class="flex justify-center items-center">
        <p class="text-white text-sm">
          <span class="mr-4">© 2022 Eucaps</span>
          <RouterLink :to="{name: 'terms'}">
            Terms and Conditions
          </RouterLink>
        </p>
      </div>
    </div>
  </footer>
</template>
<script>

import LogoContainer from '@/modules/logo'

export default {
  name: 'FrontFooter',
  components: {
    LogoContainer
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    useFooter: {
      type: Boolean,
      default: false
    }
  }
}
</script>
