import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('trulioo')

export default {
  state: {
    loading: false,
    result: []
  },
  getters: {
    loading: state => state.loading,
    result: state => state.result
  },
  mutations: {
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_RESULTS (state, payload) {
      state.result = payload
    }
  },
  actions: {
    async result ({ commit }, experienceTransactionId) {
      commit('SET_LOADING', true)
      const response = await repository.result(experienceTransactionId)

      commit('SET_RESULTS', response.data)
      commit('SET_LOADING', false)

      return response.data
    }
  },
  namespaced: true
}
