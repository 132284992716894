import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('social')

export default {
  state: {
    socialChannels: [],
    loading: false
  },
  getters: {
    loading: state => state.loading,
    socialChannels (state) {
      return state.socialChannels
    }
  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LIST (state, payload) {
      state.socialChannels = [ ...payload ]
    }
  },
  actions: {
    async fetch ({ commit, rootState, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetch()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
