<template>
  <div class="page flex flex-grow flex-shrink-0 flex-col bg-main-background dark:bg-custom-purple-500">
    <HeaderContainer />
    <main class="flex flex-col flex-grow flex-shrink-0 z-10">
      <RouterView :key="$route.fullPath" />
    </main>
    <FooterContainer v-if="$route.meta.needLogin !== false" />
    <FrontFooterContainer />
    <!-- <FairUsageModalContainer v-if="((user && user.id && user.profile_type === 'App\\Models\\GuestUser' || !user || !user.id) && $route.meta.needLogin !== false) || (!(user && user.id && user.profile_type !== 'App\\Models\\GuestUser') && $route.meta.needLogin !== false)" /> -->
    <InitialUserWizardModalContainer v-if="hasIntroBubble" />
    <PortalTarget
      name="dialog"
      multiple
    />
  </div>
</template>

<script>

import HeaderContainer from '@/modules/header'
import FooterContainer from '@/modules/footer'
// import FairUsageModalContainer from '@/modules/fairUsageModal'
import InitialUserWizardModalContainer from '@/modules/initialSwiperWizardModal'
import FrontFooterContainer from '@/modules/frontFooter'
import { PortalTarget } from 'portal-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'StandardLayout',
  components: {
    HeaderContainer,
    FooterContainer,
    FrontFooterContainer,
    PortalTarget,
    // FairUsageModalContainer,
    InitialUserWizardModalContainer
  },
  data () {
    return {
      introModalBubble: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user'
    }),
    isFrontLayout () {
      if (this.$route.meta && this.$route.meta.layout) {
        return this.$route.meta.layout === 'FrontLayout'
      }

      return false
    },
    hasIntroBubble () {
      if (!localStorage.introBubbleSeen) {
        if (!this.user || !this.user.profile || !this.user.profile.bubbles || this.user.profile.bubbles.length === 0) {
          return false
        }

        let hasIntroBubble = false

        this.user.profile.bubbles.map(b => {
          if (b.type === 'initial_user_wizard') {
            hasIntroBubble = true
          }
        })

        return hasIntroBubble
      }

      return false
    }
  }
}
</script>
