const eucapsBoard = [
  {
    id: 1,
    name: 'Henrik Wagenius',
    src: '/img/team/HW.jpg'
  },
  {
    id: 2,
    name: 'Robin Henriksson',
    src: '/img/team/RH.jpg'
  },
  {
    id: 3,
    name: 'Christer Wagenius',
    src: '/img/team/Christer.png'
  },
  {
    id: 4,
    name: 'Anders Rynnel',
    src: '/img/team/Andres-Rynnel.png'
  },
  {
    id: 5,
    name: 'Anders Bruzelius',
    src: '/img/team/Anders.jpg'
  },
  {
    id: 6,
    name: 'Pontus Rosén',
    src: '/img/team/Pontus.png'
  }
]

const stockEvolution = [
  {
    id: 1,
    name: 'Henrik Wagenius',
    title: 'Founder & CEO',
    src: '/img/team/HW.jpg'
  },
  {
    id: 2,
    name: 'Robin Henriksson',
    title: 'Co-founder & CTO',
    src: '/img/team/RH.jpg'
  },
  {
    id: 3,
    name: 'Kristof De Buysere',
    title: 'Co-founder Head of legal & Compliance',
    src: '/img/team/KB.jpg'
  },
  {
    id: 5,
    name: 'Johan Hedenskog',
    title: 'COO',
    src: '/img/team/JH.jpg'
  },
  {
    id: 6,
    name: 'Deblina Ray',
    title: 'Project Manager',
    src: '/img/team/DB.jpg'
  },
  {
    id: 9,
    name: 'Carl Berglund',
    title: 'Partner & Key Account',
    src: '/img/team/CB.jpg'
  }
]

const getEucapsBoardTeam = async () => {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: eucapsBoard
        })
      }, 2000)
    } catch (e) {
      reject(e)
    }
  })
}

const getStockEvolutionTeam = async () => {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: stockEvolution
        })
      }, 2000)
    } catch (e) {
      reject(e)
    }
  })
}

export default {
  getEucapsBoardTeam,
  getStockEvolutionTeam
}
