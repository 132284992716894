<template>
  <div />
</template>

<script>

export default {
  name: 'Todo',
  data () {
    return {
      enabled: process.env.VUE_APP_SHOW_TODO
    }
  },
  mounted () {
    if (this.enabled) {
      this.$store.dispatch('todos/add', { id: this._uid, route: this.$route.path, text: this.$slots.default[0].text, inComponent: this.$parent.$options._componentTag })
    }
  }
}
</script>
