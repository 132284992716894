<template>
  <div class="dark:text-custom-purple-100">
    <div
      v-if="loading"
      class="flex justify-center p-8"
    >
      <Loader />
    </div>
    <div v-else-if="notifications && notifications.length > 0">
      <div class="flex justify-end">
        <span
          class="inline-block text-xs p-2 border bg-white dark:bg-custom-purple-300 dark:text-white dark:border-custom-purple-600 cursor-pointer rounded-sm border-gray-900 my-4 font-normal text-gray-900"
          @click="clearAll"
        >
          {{ $t('Clear all notifications') }}
        </span>
      </div>
      <div
        v-for="(notification, index) in notifications"
        :key="('not' + notification.id + index)"
      >
        <div
          v-if="notification.pivot.show === 1"
          class="mb-1"
        >
          <Component
            :is="notifiableType(notification.notifiable_type)"
            :notification="notification"
            :time="diffFromNow(notification.created_at)"
            @remove="remove"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full text-center dark:text-custom-purple-100 p-8"
    >
      {{ $t('No notifications to show') }}
    </div>
    <div
      v-if="scrollLoading"
      class="flex justify-center p-8"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import time from '@/js/helpers/time'
import types from '@/enums/notificationTypes'

export default {
  name: 'NotificationsModule',
  components: {
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/list',
      user: 'users/user',
      loading: 'notifications/loading',
      scrollLoading: 'notifications/scrollLoading'
    }),
    internalNotifications () {
      let n = []

      n.push({ notifiable_type: 'testable' })

      n = [...n, ...this.notifications]

      return n
    }
  },
  mounted () {
    this.$store.dispatch('notifications/fetch')
    this.scroll()
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('notifications/clearCounter')
      this.$store.dispatch('users/seenBubbleType', { tag: 'global_menu_notification', type: 'global_menu_notification' })
    }, 500)
  },
  beforeDestroy () {
    this.$store.dispatch('notifications/clearCounter')
  },
  methods: {
    diffFromNow (datetime) {
      return time.diffFromNow(datetime)
    },
    clearAll () {
      const confirmation = confirm(this.$t('Do you really want to clear all notifications?'))

      if (confirmation) {
        this.$store.dispatch('notifications/hideAll')
      }
    },
    remove (payload) {
      this.$store.dispatch('notifications/hide', payload)
    },
    validate (type, key) {
      return type === types[key]
    },
    notifiableType (notifiableType) {
      const type = notifiableType.replace('App\\Models\\', '')
      return type
    },
    async redirectPost (payload) {
      this.$store.dispatch('notifications/read', payload.notificationId)
      // await this.$store.dispatch('timeline/pushPost', payload.post)
      this.$router.push({ name: 'posts', params: { id: payload.postId } })
    },
    async redirectComment (payload) {
      this.$store.dispatch('notifications/read', payload.notificationId)
      await this.$store.dispatch('comments/pushComment', payload.comment)
      // this.$store.dispatch('timeline/fetchPost', payload.comment.commentable_id)
      if (payload.comment.commentable_type === 'App\\Models\\Post') {
        this.$router.push({ name: 'posts', params: { id: payload.comment.commentable_id } })
      }

      if (payload.comment.commentable_type === 'App\\Models\\Comment') {
        this.$router.push({ name: 'comment', params: { id: payload.comment.post_id, commentId: payload.commentable_id } })
      }
    },
    redirect (payload) {
      this.$store.dispatch('notifications/read', payload.notificationId)
      this.$router.push({ name: payload.name, params: { id: payload.id } })
    },
    redirectPressRelease (payload) {
      this.$store.dispatch('notifications/read', payload.notificationId)
      this.$router.push({ name: payload.name, params: { id: payload.company.slug_url, pressReleaseId: payload.pressReleaseId } })
    },
    redirectTrigger (payload) {
      this.$store.dispatch('notifications/read', payload.notificationId)
      this.$store.dispatch('triggers/pushTrigger', payload.trigger)
      this.$router.push({ name: 'companyTriggers', params: { id: payload.company.slug_url } })
    },
    redirectCompany (payload) {
      this.$store.dispatch('notifications/read', payload.notificationId)
      this.$router.push({ name: payload.page, params: { id: payload.company.slug_url } })
    },
    redirectChat (payload) {
      this.$store.dispatch('notifications/read', payload.notificationId)
      this.$router.push({ name: 'companyChat', params: { id: payload.company.slug_url, room: payload.room } })
    },
    scroll () {
      window.onscroll = () => {
        let route = this.$router.currentRoute.name
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= (document.body.scrollHeight) * (0.70)
        if (bottomOfWindow && !this.scrollLoading && !this.loading && route === 'notifications') {
          this.$store.dispatch('notifications/scroll')
        }
      }
    }
  }
}
</script>
