import { Capacitor } from '@capacitor/core'
import { mapGetters } from 'vuex'

import { isIphone } from '@/js/helpers/mobile'

export default {
  computed: {
    ...mapGetters({
      useMenu: 'app/useMenu',
      insideCompanyPage: 'app/insideCompanyPage',
      insidePrivateChat: 'app/insidePrivateChat'
    }),
    platformPadding () {
      if ((Capacitor.getPlatform() === 'ios') || isIphone()) {
        return 'ios-padding'
      }

      return ''
    }
  }
}
