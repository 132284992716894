<template>
  <Footer
    :is-logged-in="isLoggedin"
    :menu="useMenu"
    :use-footer="useFooter"
    :ios-padding="iosPadding"
  />
</template>

<script>

import Footer from './_components/Footer'
import { mapGetters } from 'vuex'

export default {
  name: 'FooterModule',
  components: {
    Footer
  },
  props: {
    padding: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      isLoggedin: 'users/isLoggedin',
      useFooter: 'app/useFooter',
      useMenu: 'app/useMenu'
    }),
    iosPadding () {
      return (this.padding !== '')
    }
  }
}
</script>
