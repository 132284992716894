import { specificLanguage, i18n } from '@/js/language'

export default {
  state: {
    active: 'en',
    list: [
      'en'
    ]
  },
  getters: {
    active: state => state.active,
    list: state => state.list
  },
  mutations: {
    SET_LANGUAGE (state, language) {
      state.active = language
      localStorage.language = language
      i18n.locale = state.active
    }
  },
  actions: {
    init ({ commit }) {
      // if (localStorage.language) {
      // commit('SET_LANGUAGE', localStorage.language)
      // } else {
      commit('SET_LANGUAGE', specificLanguage)
      // }
    },
    async set ({ commit }, language) {
      commit('SET_LANGUAGE', language)
    }
  },
  namespaced: true
}
