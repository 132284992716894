import { RepositoryFactory } from '@/api/repositoryFactory'
import mentions from '../js/helpers/mentions'
const repository = RepositoryFactory.get('messages')

export default {
  state: {
    loading: false,
    messages: [],
    nextPageUrl: '',
    scrollLoading: false,
    canScrollMore: false
  },
  getters: {
    loading: state => state.loading,
    messages: state => state.messages,
    nextPageUrl: state => state.nextPageUrl,
    scrollLoading: state => state.scrollLoading,
    canScrollMore: state => state.canScrollMore
  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_MESSAGES (state, messages) {
      let messageList = []
      messages.forEach(message => {
        if (message.messagable_type === 'App\\Models\\Post') {
          if (message.messagable.postable.message && (typeof message.messagable.postable.message === 'string' || message.messagable.postable.message instanceof String) && message.messagable.postable.message.match(mentions.MATCHING)) {
            message.messagable.postable.message = mentions.parse(message.messagable.postable.message, message.mentions)
          }
          messageList.push(message)
        }

        if (message.messagable_type === 'App\\Models\\Comment') {
          message.messagable.text = mentions.parse(message.messagable.text, message.mentions)

          messageList.push(message)
        }

        if (message.messagable_type === 'App\\Models\\ChatMessage') {
          if (message.messagable.chatable.message && message.messagable.chatable.message.match(mentions.MATCHING)) {
            message.messagable.chatable.message = mentions.parse(message.messagable.chatable.message, message.messagable.message.mentions, 'chat')
          }

          messageList.push(message)
        }
      })
      state.messages = messageList
    },
    NEXT_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    APPEND_MESSAGE (state, payload) {
      payload.map(message => {
        state.messages.push(message)
      })
    },
    SCROLL_LOADING (state, boolean) {
      state.scrollLoading = boolean
    },
    CAN_SCROLL_MORE (state, boolean) {
      state.canScrollMore = boolean
    }
  },
  actions: {
    async fetchMessages ({ commit }, userId) {
      try {
        commit('SET_MESSAGES', [])

        commit('SET_LOADING', true)
        commit('CAN_SCROLL_MORE', true)

        const response = await repository.fetchMessages(userId)
        if (response.status === 200 && response.data) {
          commit('SET_LOADING', false)
          commit('SET_MESSAGES', response.data.data)
          commit('NEXT_PAGE_URL', response.data.next_page_url)
        }
      } catch (e) {
        console.log('Error occured : ' + e)
      }
    },
    async scroll ({ commit, rootState, state, dispatch }) {
      try {
        if (state.nextPageUrl === null) {
          commit('SCROLL_LOADING', false)
          commit('CAN_SCROLL_MORE', false)
        } else if (state.nextPageUrl !== null && !state.loading) {
          commit('SCROLL_LOADING', true)
          const response = await repository.scroll(state.nextPageUrl)
          commit('SCROLL_LOADING', false)

          if ((response.status === 200) && response.data && response.data.data) {
            commit('APPEND_MESSAGE', response.data.data)
            commit('NEXT_PAGE_URL', response.data.next_page_url)
            commit('SCROLL_LOADING', false)
          }
        }
      } catch (e) {
        commit('SCROLL_LOADING', false)
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
