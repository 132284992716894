import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('countries')

export default {
  state: {
    selectedCompanyId: null,
    countries: [],
    userCountries: [],
    loading: false,
    ip: ''
  },
  getters: {
    loading: state => state.loading,
    countries: state => state.countries,
    userCountries: state => state.userCountries,
    ip: state => state.ip
  },
  mutations: {

    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LIST (state, payload) {
      state.countries = payload
    },
    SET_IP (state, payload) {
      state.ip = payload
    },
    FILTER_LIST (state, payload) {
      state.countries = payload
    },
    SET_USER_COUNTRIES (state, payload) {
      state.userCountries = payload
    }
  },
  actions: {
    async fetch ({ commit, rootState, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.countries()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchIp ({ commit, rootState, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.deviceIp()
        commit('SET_LOADING', false)
        if ((response.status === 200 && response.data)) {
          commit('SET_IP', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },

    async fetchUserCountries ({ commit, rootState, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.userCountries()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_USER_COUNTRIES', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async sort ({ commit, rootState, dispatch }, id) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.sortCountries(id)
        commit('SET_LOADING', false)
        if ((response.status === 201) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
