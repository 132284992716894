import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('helpcenter')

export default {
  state: {
    loading: false,
    helps: [],
    questionGroups: []
  },
  getters: {
    loading: state => state.loading,
    helps: state => state.helps,
    questionGroups: state => state.questionGroups
  },
  mutations: {
    SET_HELPS (state, helps) {
      state.helps = helps
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_QUESTION_GROUPS (state, questionGroups) {
      state.questionGroups = questionGroups
    }
  },
  actions: {
    async fetch ({ commit, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetch()
        commit('SET_LOADING', false)
        if ((response.status === 200)) {
          dispatch('filterHelpsByLanguage', response.data.helps)
          commit('SET_QUESTION_GROUPS', response.data.questionGroups)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        console.log(e)
      }
    },

    filterHelpsByLanguage ({ commit, rootState }, helps) {
      let helpList = []

      for (let i = 0; i < helps.length; i++) {
        const answers = helps[i].answer_helps

        let answer
        let question

        for (let j = 0; j < answers.length; j++) {
          if (answers[j].language === rootState.languages.active) {
            answer = answers[j]
            break
          }
        }

        const questions = helps[i].question_helps
        for (let j = 0; j < questions.length; j++) {
          if (questions[j].language === rootState.languages.active) {
            question = questions[j]
            break
          }
        }
        helpList.push({
          'question': question,
          'answer': answer
        })
      }
      commit('SET_HELPS', helpList)
    }
  },
  namespaced: true
}
