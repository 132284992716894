<template>
  <div v-if="notification.notifiable">
    <Notification
      :pivot="notification.pivot"
      @remove="$emit('remove', { id: notification.id })"
    >
      <div
        class="cursor-pointer"
        @click="$parent.redirectPost({postId: notification.notifiable.post_id, notificationId: notification.id})"
      >
        <AvatarSlot
          :stock-user="notification.notifiable.stock_user"
        >
          <div class="text-xs font-medium mr-6">
            <span class="font-bold">
              <RouterLink :to="{name: 'userFlow', params: {id: notification.notifiable.stock_user.id}}">{{ notification.notifiable.stock_user.username }}</RouterLink>
            </span> {{ $t(' shared a post') }}
            <span
              v-if="notification.notifiable.company"
              class="whitespace-nowrap"
            >
              {{ $t('for') }}
              <RouterLink
                class="font-bold"
                :to="{name: 'companyOverview', params: {id: notification.notifiable.company.slug_url}}"
              > {{ notification.notifiable.company.name }}</RouterLink>
            </span>
            <div>{{ time }}</div>
          </div>
        </AvatarSlot>
      </div>
    </Notification>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationNewShare',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  }
}
</script>
