<template>
  <ul class="list-reset flex justify-between flex-1 items-center">
    <li
      v-for="menu in nav"
      :key="menu.link.name"
      class="text-sm relative font-semibold"
      :class="activeMenu && activeMenu.link === menu.link ? 'active-menu' : ''"
    >
      <RouterLink
        v-if="menu.icon === 'altered-avatar'"
        :to="menu.link"
        class="cursor-pointer"
      >
        <Avatar
          :size="30"
          :use-premium="false"
          small
          :stock-user="user.profile"
        />
      </RouterLink>

      <component
        :is="menu.type === 'link' ? 'RouterLink' : 'span'"
        v-else
        class="block p-2 relative text-primary-light"
        exact
        :to="menu.link"
        @click.native="$emit('reload', menu)"
        @click="$emit('reload', menu)"
      >
        <Bubble
          v-if="menu.link === 'extended-menu' && unreadMessages > 0"
          small
          background="bg-custom-orange-400"
          color="text-white"
          font-size="text-3xs"
          class="absolute rounded-full top-0 right-0 font-semibold"
        >
          {{ unreadMessages }}
        </Bubble>
        <Icon
          class="text-2xl"
          :name="menu.icon"
          :solid="activeMenu && activeMenu.hasSolid && activeMenu.link === menu.link"
        />
      </component>
      <BubbleContainer
        v-if="menu.icon === 'category'"
        type="new_feature_apps"
        tooltip
        font-size="text-3xs"
        class="font-semibold"
      >
        <span class="block">Nyhet! Appmenyn!</span>
      </BubbleContainer>
      <BubbleContainer
        v-if="menu.icon === 'bell'"
        type="global_menu_notification"
        font-size="text-3xs"
        class="absolute rounded-full top-0 right-0 -mr-1 font-semibold"
      />
      <BubbleContainer
        v-if="menu.icon === 'buildings'"
        type="global_menu_companies"
        font-size="text-3xs"
        class="absolute rounded-full top-0 right-0 -mr-1 font-semibold"
      />
      <!-- <Bubble
        v-if="menu.icon === 'bell' && unreadNotifications > 0"
        small
        background="bg-custom-orange-400"
        color="text-white"
        font-size="text-3xs"
        class="absolute rounded-full top-0 right-0 font-semibold"
      >
        {{ unreadNotifications }}
      </Bubble> -->
    </li>
  </ul>
</template>

<script>

import BubbleContainer from '@/modules/bubble'

export default {
  name: 'Menu',
  components: {
    BubbleContainer
  },
  props: {
    nav: { default: () => [], type: Array },
    activeMenu: { type: Object, default: () => {} },
    user: { type: Object, default: () => {} },
    unreadMessages: { type: Number, default: 0 }
  }
}
</script>

<style lang="scss" scoped>
.active-menu .router-link-active, .active-menu span {
  @apply text-custom-orange-400 dark:text-custom-dark-header;
}
</style>
