
export default {
  state: {
    list: []
  },
  getters: {
    get: state => state.list
  },
  mutations: {
    RESET (state) {
      state.list = []
    },
    ADD (state, todo) {
      state.list = [...state.list, todo]
    }
  },
  actions: {
    reset ({ commit }) {
      commit('RESET')
    },
    add ({ commit, state }, payload) {
      const todoAlreadyAdded = state.list.find(todo => todo.text === payload.text)

      if (todoAlreadyAdded === undefined) {
        commit('ADD', payload)
      }
    }
  },
  namespaced: true
}
