import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('mentions')

export default {
  state: {
    loading: false,
    topMentions: [],
    topReactions: [],
    sentiments: []
  },
  getters: {
    topMentions: state => state.topMentions,
    topReactions: state => state.topReactions,
    sentiments: state => state.sentiments,
    loading: state => state.loading
  },
  mutations: {
    SET_TOP_MENTIONS (state, payload) {
      state.topMentions = [...payload]
    },
    SET_TOP_REACTIONS (state, payload) {
      state.topReactions = [...payload]
    },
    SET_SENTIMENTS (state, payload) {
      state.sentiments = [...[
        {
          name: 'Unknown',
          data: [payload.neutral],
          color: '#AAA1AF'
        },
        {
          name: 'Negative',
          data: [payload.negative],
          color: '#311C3B'
        },
        {
          name: 'Positive',
          data: [payload.positive],
          color: '#0EDA80'
        }
      ]]
    },
    SET_LOADING (state, payload) {
      state.loading = payload
    }
  },
  actions: {
    async fetchMentions ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.getTopMentions(payload)
        commit('SET_TOP_MENTIONS', response.data.topMentions)
        commit('SET_TOP_REACTIONS', response.data.topReactions)
        commit('SET_SENTIMENTS', response.data.sentiments)
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'MENTIONS NOT FETCHED', e }, { root: true })
        console.log(e)
      }
    }
  },
  namespaced: true
}
