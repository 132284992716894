import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('questionAnswers')

export default {
  state: {
    loading: false,
    questionAnswers: [],
    filteredQnas: []
  },
  getters: {
    loading: (state) => state.loading,
    questionAnswers: (state) => state.questionAnswers,
    filteredQnas: (state) => state.filteredQnas
  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_QUESTION_ANSWERS (state, questionAnswers) {
      state.questionAnswers = [...questionAnswers]
    },
    SET_FILTERED_QNAS (state, filteredQnas) {
      state.filteredQnas = [...filteredQnas]
    }
  },
  actions: {
    async getQuestionAnswers ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchQuestionAnswers(payload)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_QUESTION_ANSWERS', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async createQuestion ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.createQuestion(payload.company_id, payload.data)
        commit('SET_LOADING', false)
        if (response.status === 201 && response.data) {
          dispatch(
            'snackbar/snack',
            { type: 'success', message: 'Question created', show: true, autoclose: true },
            { root: true }
          )
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async publishQuestion ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.publishQuestion(payload.company_id, payload.question_id, payload.data)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          dispatch(
            'snackbar/snack',
            { type: 'success', message: 'Question published', show: true, autoclose: true },
            { root: true }
          )
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async destroyQuestion ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.destroyQuestion(payload.company_id, payload.question_id)
        commit('SET_LOADING', false)
        if (response.status === 204 && response.data) {
          dispatch(
            'snackbar/snack',
            { type: 'success', message: 'Question deleted', show: true, autoclose: true },
            { root: true }
          )
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async getFilteredQuestionAnswers ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchFilteredQuestionAnswers(payload.company_id, payload.query)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_FILTERED_QNAS', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async toggleQuestionStatus ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.toggleQuestionStatus(payload.company_id, payload.question_id)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          dispatch(
            'snackbar/snack',
            { type: 'success', message: 'Question status updated successfully', show: true, autoclose: true },
            { root: true }
          )
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
