// import { Capacitor } from '@capacitor/core'

import { PushNotifications } from '@capacitor/push-notifications'
import store from '@/js/store'
import router from '@/js/router'

if (store.getters['app/isNative']) {
  PushNotifications.checkPermissions().then((res) => {
    if (res.receive !== 'granted') {
      console.log('---------------firebasenative 2---------------')

      PushNotifications.requestPermissions().then((res) => {
        if (res.receive === 'denied') {
          // console.log('Push Notification permission denied')
        } else {
          // console.log('Push Notification permission granted')
          register()
        }
      })
    } else {
      register()
    }
  })

  const register = () => {
    // console.log('Initializing HomePage')

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register()

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token) => {
      // console.log('Push registration success')
      // console.log(token)
      // console.log('token', token.value)
      store.dispatch('users/setFCMToken', { token: token.value })
      localStorage.setItem('fcmToken', token.value)
    }
    )

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error) => {
        alert('Error on registration: ' + JSON.stringify(error))
      }
    )

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification) => {
        store.dispatch('notifications/inAppPushNotification', notification)
      }
    )

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification) => {
        if (notification.notification.data.link) {
          let url = notification.notification.data.link.replace('axyer://', '/')
          // check it we are not in same url
          if (router.history.current.path.substring(1) !== url) {
            router.push(url)
          }
        }
      }
    )
  }
}

export default PushNotifications
