<template>
  <div
    class="mx-auto w-full cursor-pointer "
    :class="[border, mini ? 'py-1' : 'py-3 px-2 border-b border-gray-200 dark:border-custom-purple-300']"
    @click="$emit('click', company.slug_url)"
  >
    <div class="w-full">
      <div class="flex items-center">
        <CountryFlag
          :company="company"
        />
        <NameSubTitle
          :name="company.name"
          :subtitle="withoutSubtitle ? '' : company.all_followers + ' ' + $t('followers')"
          :text-size="big ? 'text-lg dark:text-custom-purple-100' : 'text-xs dark:text-custom-purple-100'"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompanyListItem',
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    border: {
      type: String,
      default: 'border-b border-gray-200 dark:border-custom-purple-500'
    },
    mini: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    },
    withoutSubtitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>
