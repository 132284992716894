import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('screeners')

export default {
  state: {
    selectedCurrency: 'LOCAL',
    relevant: true,
    inUniverse: 'in-universe',
    screenerFilterDefault: [
      {
        code: 'sectors',
        group: 'sectors',
        type: 'multiselect',
        data: {},
        selected: null,
        preselect: null,
        suffix: false
      },
      {
        code: 'countries',
        group: 'countries',
        type: 'multiselect',
        data: {},
        selected: null,
        preselect: null,
        suffix: false
      },
      {
        code: 'marketcap',
        group: 'financials',
        type: 'range',
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
        suffix: 'M€'
      },
      {
        code: 'price',
        group: 'financials',
        type: 'range',
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
        suffix: '€'
      },
      {
        code: 'shares',
        group: 'financials',
        type: 'range',
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
        suffix: 'M'
      }
    ],
    screenerFilters: [],
    sectors: [],
    countries: [],
    marketCapMax: 0,
    priceMax: 0,
    sharesMax: 0,
    showFilter: false,
    loadingFilterData: false,
    searchCompaniesPhrase: '',
    searchCompaniesSortable: 'default',
    searchCompaniesOrder: 'asc',
    filterList: [],
    filterListLoading: false
  },
  getters: {
    relevant: state => state.relevant,
    inUniverse: state => state.inUniverse,
    filterList: state => state.filterList,
    filterListLoading: state => state.filterListLoading,
    screenerFilterDefault: state => state.screenerFilterDefault,
    screenerFilters (state, getters, rootState, rootGetters) {
      const filters = state.screenerFilterDefault.map(filterDefault => {
        const index = state.screenerFilters.findIndex(f => f.code === filterDefault.code)
        return index > -1 ? state.screenerFilters[index] : filterDefault
      })

      filters.map(f => {
        if (f.code === 'sectors') {
          f.data = { ...f.data, list: getters.sectors }
        }

        if (f.code === 'countries') {
          f.data = { ...f.data, list: getters.countries }
        }

        if (f.code === 'marketcap') {
          f.data.defaultRange = [0, getters.marketCapMax]
          if (f.preselect[1] === 0) {
            f.preselect = [0, getters.marketCapMax]
          }
        }
        if (f.code === 'shares') {
          f.data.defaultRange = [0, getters.sharesMax]
          if (f.preselect[1] === 0) {
            f.preselect = [0, getters.sharesMax]
          }
        }
        if (f.code === 'price') {
          f.data.defaultRange = [0, getters.priceMax]
          if (f.preselect[1] === 0) {
            f.preselect = [0, getters.priceMax]
          }
        }
      })

      return filters
    },
    selectedScreenerFilters (state, getters, rootState) {
      const selected = []

      getters.screenerFilters.map(f => {
        if (f.selected) {
          if (f.type === 'multiselect') {
            f.selected.map((listOption) => {
              const filterOption = {
                label: listOption.name,
                group: f.group,
                type: f.type,
                code: listOption.name + '-' + listOption.id,
                pics: f.pics_code,
                data: listOption,
                default: f,
                cleanable: true
              }

              selected.push(filterOption)
            })
          } else if (f.type === 'range') {
            if (f.data.defaultRange[0] !== f.selected[0] || f.data.defaultRange[1] !== f.selected[1]) {
              const filterOption = {
                label: f.code + ' ' + f.selected[0] + ' - ' + f.selected[1],
                group: f.group,
                type: f.type,
                suffix: f.suffix,
                code: f.code,
                data: f.selected,
                default: f,
                cleanable: true
              }

              selected.push(filterOption)
            }
          }
        }
      })

      return selected
    },
    // SELECTED FILTERS HAS A DEFAULT KEY WHICH CONTAINS ALL DATA. DO NOT NEED TO SEND
    // THEM WHEN SEARCHING COMPANIES, SO SLIM IT DOWN. IS USED IN COMPANIES STORE
    slimSelectedScreenerFilters (state, getters) {
      const selected = []

      getters.selectedScreenerFilters.map(f => {
        const newFilter = { ...f, default: null }
        selected.push(newFilter)
      })

      return selected
    },
    showFilter: state => state.showFilter,
    loadingFilterData: state => state.loadingFilterData,
    countries: state => state.countries,
    sectors: state => state.sectors,
    marketCapMax: state => state.marketCapMax,
    sharesMax: state => state.sharesMax,
    priceMax: state => state.priceMax,
    selectedCurrency: state => state.selectedCurrency,
    searchCompaniesPhrase: state => state.searchCompaniesPhrase,
    searchCompaniesDebounce: state => state.searchCompaniesDebounce,
    searchCompaniesSortable: state => state.searchCompaniesSortable,
    searchCompaniesOrder: state => state.searchCompaniesOrder
  },
  mutations: {
    INIT_SCREENER_FILTER (state) {
      if (localStorage.inUniverse) {
        state.inUniverse = JSON.parse(localStorage.inUniverse)
      }

      if (localStorage.relevant) {
        state.relevant = JSON.parse(localStorage.relevant)
      }

      if (localStorage.selectedCurrency) {
        state.selectedCurrency = JSON.parse(localStorage.selectedCurrency)
      }
    },
    SET_RELEVANT (state, boolean) {
      state.relevant = boolean
      localStorage.relevant = JSON.stringify(state.relevant)
    },
    SET_UNIVERSE (state, universe) {
      state.inUniverse = universe
      localStorage.inUniverse = JSON.stringify(state.inUniverse)
    },
    SET_FILTER_LIST (state, payload) {
      state.filterList = payload
    },
    SET_FILTER_LIST_LOADING (state, boolean) {
      state.filterListLoading = boolean
    },
    SET_LOADING_FILTER_DATA (state, boolean) {
      state.loadingFilterData = boolean
    },
    SET_MY_FILTERS (state, payload) {
      state.screenerFilters.forEach(filter => {
        if (filter.code === 'countries') {
          filter.selected = JSON.parse(payload.countries)
          filter.preselect = JSON.parse(payload.countries)
        } else if (filter.code === 'sectors') {
          filter.selected = JSON.parse(payload.sectors)
          filter.preselect = JSON.parse(payload.sectors)
        } else if (filter.code === 'shares') {
          if (payload.shares !== null) {
            filter.selected = JSON.parse(payload.shares)
            filter.preselect = JSON.parse(payload.shares)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        } else if (filter.code === 'price') {
          if (payload.price !== null) {
            filter.selected = JSON.parse(payload.price)
            filter.preselect = JSON.parse(payload.price)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        } else if (filter.code === 'marketcap') {
          if (payload.marketcap !== null) {
            filter.selected = JSON.parse(payload.marketcap)
            filter.preselect = JSON.parse(payload.marketcap)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        }
      })
      state.screenerFilters = [...state.screenerFilters]
    },
    SET_SCREENER_FILTER (state, payload) {
      // console.log('update screener filter', payload)

      state.screenerFilters = [...payload]
    },
    SET_FILTER_DATA (state, payload) {
      state.screenerFilters = [...payload]
    },
    SET_SEARCH_COMPANIES_DEBOUNCE (state, payload) {
      state.searchCompaniesDebounce = payload
    },
    SET_SEARCH_COMPANIES_PHRASE (state, payload) {
      state.searchCompaniesPhrase = payload
    },
    SET_COUNTRIES (state, payload) {
      state.countries = payload
    },
    SET_SEARCH_COMPANIES_SORTABLE (state, payload) {
      state.searchCompaniesSortable = payload
    },
    SET_SELECTED_CURRENCY (state, payload) {
      state.selectedCurrency = payload
      localStorage.selectedCurrency = JSON.stringify(state.selectedCurrency)
    },
    TOGGLE_SHOW_FILTER (state, payload) {
      state.showFilter = payload
    },
    SET_SEARCH_COMPANIES_ORDER (state, payload) {
      state.searchCompaniesOrder = payload
    },
    SET_FILTERS (state, payload) {
      state.sectors = payload.sectors
      state.countries = payload.countries
      state.marketCapMax = Math.ceil(Number(payload.max_valuation) / 100) * 100
      state.priceMax = Math.ceil(Number(payload.price) / 100) * 100
      state.sharesMax = Math.ceil(Number(payload.shares) / 100) * 100
    }
  },
  actions: {
    setRelevant ({ commit }, boolean) {
      commit('SET_RELEVANT', boolean)
    },
    setUniverse ({ commit }, universe) {
      commit('SET_UNIVERSE', universe)
    },
    async setMyFilters ({ commit, dispatch }, payload) {
      await commit('SET_MY_FILTERS', payload)
    },
    async fetchCompanyFilters ({ commit, dispatch }) {
      try {
        const response = await repository.fetchCompanyFilters()
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async saveFilters ({ commit, dispatch, getters }, payload) {
      try {
        const response = await repository.saveCompanyFilters(payload)
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
          dispatch('snackbar/snack', { type: 'success', text: 'filters saved successfully', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteFilter ({ commit, dispatch }, filterId) {
      try {
        commit('SET_FILTER_LIST_LOADING', true)
        const response = await repository.deleteFilter(filterId)
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
        }
        commit('SET_FILTER_LIST_LOADING', false)
      } catch (e) {
        commit('SET_FILTER_LIST_LOADING', false)

        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async updateFilters ({ commit, dispatch, getters }, payload) {
      try {
        const response = await repository.updateCompanyFilters(payload.filterId, payload)
        if (response.status === 200 && response.data) {
          commit('SET_FILTER_LIST', response.data)
          dispatch('snackbar/snack', { type: 'success', text: 'filters saved successfully', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    toggleShowFilter ({ commit }, payload) {
      commit('TOGGLE_SHOW_FILTER', payload)
    },
    async fetchFilterData ({ commit, dispatch }) {
      commit('INIT_SCREENER_FILTER')
      commit('SET_LOADING_FILTER_DATA', true)
      try {
        const response = await repository.fetchFilterData()
        if (response.status === 200 && response.data) {
          commit('SET_FILTERS', response.data)
        }
        commit('SET_LOADING_FILTER_DATA', false)
      } catch (e) {
        commit('SET_LOADING_FILTER_DATA', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async resetScreenerFilter ({ state, commit }) {
      commit('SET_SCREENER_FILTER', [])
    },
    async addScreenerFilter ({ state, commit }, payload) {
      commit('SET_SCREENER_FILTER', payload)
    },
    async setSelectedCurrency ({ state, commit }, payload) {
      commit('SET_SELECTED_CURRENCY', payload)
    },
    async resetSearchCompaniesSortable ({ state, dispatch }) {
      clearTimeout(state.searchCompaniesDebounce)
      dispatch('setSearchCompaniesSortable', {
        searchCompaniesPhrase: '',
        debounce: null,
        sortable: 'default',
        order: 'asc'
      })
    },
    async setSearchCompaniesSortable ({ commit }, payload) {
      if (payload.searchCompaniesPhrase || payload.searchCompaniesPhrase === '') {
        commit('SET_SEARCH_COMPANIES_PHRASE', payload.searchCompaniesPhrase)
      }
      if (payload.debounce) {
        commit('SET_SEARCH_COMPANIES_DEBOUNCE', payload.debounce)
      }
      if (payload.sortable) {
        commit('SET_SEARCH_COMPANIES_SORTABLE', payload.sortable)
      }
      if (payload.order) {
        commit('SET_SEARCH_COMPANIES_ORDER', payload.order)
      }
      if (payload.relevant === true || payload.relevant === false) {
        commit('SET_RELEVANT', payload.relevant)
      }
    },
    async updateScreenerFilterData ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          // DEFINE A FILTER OPTION
          let filter = null
          // IF PREVIOUS FILTER EXIST, USE THAT ONE
          let index = state.screenerFilters.findIndex(f => f.code === payload.screenerFilter.code)
          if (index > -1) {
            filter = state.screenerFilters[index]
          } else {
            // ELSE USE THE DEFAULT FILTER OBJECTS. WITHOUT THIS FIRST SELECTION OF A FILTER WILL NOT WORK
            index = state.screenerFilters.length
            const indexDefault = state.screenerFilterDefault.findIndex(f => f.code === payload.screenerFilter.code)
            filter = state.screenerFilterDefault[indexDefault]
          }

          let screenerFilter = { ...filter, preselect: payload.preselect }
          let screenerFilters = [...state.screenerFilters]
          screenerFilters[index] = screenerFilter
          resolve(commit('SET_SCREENER_FILTER', screenerFilters))
        } catch (e) {
          reject(e)
        }
      })
    },
    async delayedSearchCompanies ({ state, commit, dispatch }, payload) {
      if (payload.searchCompanyPhrase === '') {
        dispatch('resetSearchCompaniesSortable')
      } else {
        clearTimeout(state.searchCompaniesDebounce)
        commit('SET_SEARCH_COMPANIES_PHRASE', payload.searchCompaniesPhrase)
        state.searchCompaniesDebounce = setTimeout(() => {
          dispatch('companies/searchCompanies', payload, { root: true })
        }, 600)
      }
    }
  },
  namespaced: true
}
