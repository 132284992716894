<template>
  <div
    class="relative z-60"
    :class="isMobile ? 'right-8' : ''"
  >
    <div
      class="relative cursor-pointer"
    >
      <div
        v-show="showSearch"
        class="fixed lg:absolute left-0 top-0 h-full lg:h-auto lg:rounded-xl bg-white dark:bg-custom-purple-500 shadow-sm dark:shadow-none z-50 w-full lg:w-72"
      >
        <!-- <Icon
          name="search"
          class="absolute left-3 cursor-pointer text-black text-xl lg:text-lg"
          @click="toggleSearchBox"
        /> -->
        <div class="p-4 bg-custom-purple-500 text-white flex lg:hidden justify-between items-center">
          <span>Find companies</span>
          <Icon
            name="x"
            class="text-2xl text-white cursor-pointer"
            @click="$emit('toggle-search-box')"
          />
        </div>
        <div class="relative p-4 lg:p-0">
          <div
            class="text-black cursor-pointer z-20 absolute top-0 inset-y-0 flex items-center"
          >
            <Icon
              name="search"
              class="absolute left-3 cursor-pointer text-xl lg:text-lg dark:text-custom-purple-100"
              @click="$emit('toggle-search-box')"
            />
          </div>
          <input
            ref="searchTop"
            :value="value"
            :placeholder="$t('Find companies')"
            class="p-2 pl-9 w-72 rounded-t-xl border-b focus:outline-none dark:bg-custom-purple-500 dark:text-custom-purple-100 dark:border-custom-purple-500 dark:placeholder-custom-purple-200"
            @input="$emit('search', $event.target.value)"
          >
        </div>
        <div
          v-if="showResults"
          class="px-2 w-full flex flex-col max-h-64 overflow-hidden overflow-y-auto"
        >
          <div
            v-if="loadingCompanies"
            class="flex justify-center py-4"
          >
            <CircleLoader
              small
            />
          </div>
          <template v-else>
            <CompanyListItem
              v-for="company in companies"
              :key="company.short_name"
              :company="company"
              @click="$emit('go-to-company', $event)"
            />
          </template>
          <div
            class="py-4 flex text-sm dark:text-custom-purple-100 cursor-pointer"
            @click="$emit('go-to-screener')"
          >
            <Icon
              name="right-arrow-alt"
              class="mr-1"
            />
            <span>Show all result</span>
          </div>
        </div>
        <div
          v-if="!showResults"
          class="pb-4"
        />
      </div>
      <div
        class="cursor-pointer bg-custom-purple-400 dark:bg-custom-purple-500 hover:bg-custom-purple-300 hidden lg:flex rounded rounded-xl text-white py-2 px-9 w-72"
        @click="$emit('toggle-search-box')"
      >
        {{ $t('Find companies') }}
      </div>

      <div
        class="text-white cursor-pointer z-20 absolute top-0 inset-y-0 flex items-center"
      >
        <Icon
          name="search"
          class="absolute left-3 cursor-pointer text-xl lg:text-lg"
          @click="$emit('toggle-search-box', $event)"
        />
      </div>
      <div
        v-if="showSearch"
        class="fixed inset-0 z-40 cursor-pointer"
        @click="$emit('toggle-search-box')"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopCompanySearch',
  props: {
    value: {
      type: String,
      default: ''
    },
    loadingCompanies: {
      type: Boolean,
      default: false
    },
    showSearchInput: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    showResults: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    companies: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    showSearch (newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.searchTop.focus()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
