<template>
  <div>
    <label id="preview">
      <div class="flex flex-wrap">
        <div
          v-for="(media, key) in mediaPreviews"
          :key="key"
          class="relative p-1 add mr-2 and mb-2 rounded-lg border"
        >
          <div>
            <SquareImage
              v-if="media.type === 'image'"
              :src="media.file"
            />
            <figure
              v-if="media.type === 'audio' || media.type === 'document' || media.type === 'video'"
              class="flex flex-col items-center"
            >
              <ThumbnailVideo v-if="media.type === 'video'" />
              <ThumbnailAudio v-if="media.type === 'audio'" />
              <ThumbnailDocument v-if="media.type === 'document'" />
              <figcaption class="text-xs font-semibold text-center dark:text-custom-purple-100">{{ media.name.substring(0, 10) }}</figcaption>
            </figure>

            <Bubble
              small
              background="bg-white"
              color="text-custom-orange-400 absolute top-0 right-0 -mt-2 -mr-2"
              @click.native="$emit('remove-media', key)"
            >
              <Icon
                name="x-circle"
                class="text-xl"
              />
            </Bubble>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'MediaPreview',
  props: {
    mediaPreviews: { type: Array, default: () => [] }
  }
}
</script>
