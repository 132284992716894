<template>
  <SignupSectionContainer />
</template>

<script>
import SignupSectionContainer from '@/modules/signupSection'

export default {
  name: 'Signup',
  components: {
    SignupSectionContainer
  },
  metaInfo () {
    return {
      title: this.$t('Sign Up')
    }
  }
}
</script>
