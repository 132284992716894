<template>
  <div>
    <portal to="dialog">
      <div>
        <LightBox
          :ref="'lightbox' + reference"
          :show-light-box="false"
          :disable-scroll="false"
          :show-thumbs="true"
          :media="mediaGallery"
        />
      </div>
    </portal>
    <ModalContainer
      v-if="showAudio"
      position="absolute mt-40"
      @toggle="$emit('toggle-audio', !showAudio)"
      @on-back-button="$emit('on-back-button')"
    >
      <BaseAudio :src="audioSource" />
    </ModalContainer>
  </div>
</template>

<script>
import ModalContainer from '@/modules/modal'
import LightBox from 'vue-it-bigger'

require('vue-it-bigger/dist/vue-it-bigger.min.css')

export default {
  name: 'LightBoxExtendedModule',
  components: {
    ModalContainer,
    LightBox
  },
  props: {
    reference: {
      type: String,
      default: ''
    },
    showAudio: {
      type: Boolean,
      default: false
    },
    mediaGallery: {
      type: Array,
      default: () => []
    }
  }
}
</script>
