<template>
  <SlideYUpTransition
    :duration="500"
    :delay="100"
    class="w-full top-0 left-0 z-70"
  >
    <div
      class="flex flex-col w-full top-0 left-0 z-50 cursor-pointer"
      @click="$emit('click')"
    >
      <div
        class="bg-custom-orange-400 font-medium text-white p-2 text-xs flex justify-center"
        :class="padding"
      >
        <div class="w-full flex justify-between items-center px-2">
          <span>{{ $t('New Version Available') }}</span> <div
            class="rounded-sm py-1 px-2 ml-1 bg-custom-orange-400 hover:bg-pink-500 border border-white"
            no-padding
          >
            {{ $t('Click here') }}
          </div>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>

<script>

import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'VersionBanner',
  components: {
    SlideYUpTransition
  },
  props: {
    padding: {
      type: String,
      default: ''
    }
  }
}
</script>
