import { get, post, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/lists`
const addList = async (data) => post(`${url}/add-list`, data)
const fetchUsersList = async (data) => get(`${url}/fetch-users-list`, data)
const fetchFollowingLists = async (data) => get(`${url}/fetch-following-list`, data)
const fetchAllList = async (data) => get(`${url}/fetch-all-list`, data)
const fetchListCompanies = async (id) => get(`${url}/${id}/companies`)
const addCompaniesToList = async (id, data) => post(`${url}/${id}/add-companies`, data)
const removeCompanyFromList = async (id, data) => destroy(`${url}/${id}/remove-companies`, data)
const removeList = async (id) => destroy(`${url}/${id}/remove`)
const followList = async (listId, userId) => post(`${url}/${listId}/users/${userId}/follow`)
const unfollowList = async (listId, userId, data) => destroy(`${url}/${listId}/users/${userId}/unfollow`, data)
const fetchListIds = async (companyId) => get(`${process.env.VUE_APP_API_URL}/companies/${companyId}/lists`)
const showMore = async (link, data) => get(`${link}`, data)
const fetchSectorAndCountries = async () => get(`${url}/sectors-and-countries`)
const scroll = async (link, data) => get(`${link}`, data)
const saveListFilters = async (data) => post(`${process.env.VUE_APP_API_URL}/users/add-list-filters`, data)
const updateListFilters = async (id, data) => post(`${process.env.VUE_APP_API_URL}/users/${id}/update-list-filters`, data)
const fetchListFilters = async () => get(`${process.env.VUE_APP_API_URL}/users/fetch-list-filters`)
const deleteListFilter = async (id) => destroy(`${process.env.VUE_APP_API_URL}/users/${id}/delete-list-filters`)
const updateList = async (id, data) => post(`${url}/${id}/update-list`, data)
const changeListStockUser = async (id, data) => post(`${url}/${id}/update-list-stock-user`, data)

export default {
  changeListStockUser,
  deleteListFilter,
  fetchListFilters,
  saveListFilters,
  updateListFilters,
  updateList,
  addList,
  fetchFollowingLists,
  scroll,
  fetchSectorAndCountries,
  showMore,
  unfollowList,
  fetchAllList,
  followList,
  removeList,
  removeCompanyFromList,
  fetchListCompanies,
  addCompaniesToList,
  fetchUsersList,
  fetchListIds
}
