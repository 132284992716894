import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('insiders')

export default {
  state: {
    insiders: [],
    loading: false,
    loadingMore: false,
    nextPageUrl: ''
  },
  getters: {
    insiders: state => state.insiders,
    loading: state => state.loading,
    loadingMore: state => state.loadingMore,
    nextPageUrl: state => state.nextPageUrl
  },
  mutations: {
    SET_INSIDERS (state, payload) {
      state.insiders = [...payload]
    },
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_LOADING_MORE (state, boolean) {
      state.loadingMore = boolean
    },
    SET_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    APPEND_TRADES (state, payload) {
      payload.map(trade => {
        state.insiders.push(trade)
      })
    }
  },
  actions: {
    async fetch ({ commit }, payload) {
      commit('SET_LOADING', true)
      const response = await repository.list(payload)
      if ((response.status === 200) && response.data) {
        commit('SET_INSIDERS', response.data.data)
        commit('SET_PAGE_URL', response.data.next_page_url)
      }
      commit('SET_LOADING', false)
    },
    async scroll ({ commit, rootState, state, dispatch }, payload) {
      try {
        if (state.nextPageUrl !== null && !state.loadingNewPost) {
          commit('SET_LOADING_MORE', true)
          const response = await repository.scroll(state.nextPageUrl, payload)
          if ((response.status === 200) && response.data) {
            commit('APPEND_TRADES', response.data.data)
            commit('SET_PAGE_URL', response.data.next_page_url)
            commit('SET_LOADING_MORE', false)
          }
        }
      } catch (e) {
        commit('SET_LOADING_MORE', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.insiders = []
    }
  },
  namespaced: true
}
