<template>
  <div class="z-60 -mb-1">
    <BaseCard
      background="w-full bg-white rounded-t-2xl rounded-b-none shadow-none z-20 dark:bg-custom-purple-400 border-gray-200 dark:border-custom-purple-400"
    >
      <template #body>
        <div
          class="flex flex-col justify-center w-full"
        >
          <slot />
          <div class="flex justify-center dark:text-custom-purple-100">
            <label class="w-20 text-center flex flex-col items-center justify-center">
              <Icon
                class="flex items-center justify-center w-10 h-10 text-xl font-semibold text-custom-purple-400 border-2 border-custom-purple-500 dark:border-custom-purple-200 dark:bg-custom-purple-400 dark:text-custom-purple-100 rounded-full"
                name="upload"
                @click="$emit('toggle')"
              />
              <span class="block text-2xs">{{ $t('Upload') }}</span>
              <input
                type="file"
                accept="image/*,video/mp4,audio/*,.doc,.docx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.pdf,.txt,.pptx,.ppt, .csv"
                multiple="multiple"
                class="hidden"
                @change="$emit('on-media-change', $event)"
              >
            </label>
            <label class="w-20 text-center flex flex-col items-center justify-center">
              <Icon
                class="flex items-center justify-center w-10 h-10 text-xl font-semibold text-custom-purple-400 border-2 border-custom-purple-500 dark:border-custom-purple-200 dark:bg-custom-purple-400 dark:text-custom-purple-100 rounded-full"
                name="at"
                @click.native="$emit('on-user-tag', $event)"
              />
              <span class="block text-2xs">{{ $t('Users') }}</span>
            </label>
            <label class="w-20 text-center flex flex-col items-center justify-center">
              <Icon
                class="flex items-center justify-center w-10 h-10 text-xl font-semibold text-custom-purple-400 border-2 border-custom-purple-500 dark:border-custom-purple-200 dark:bg-custom-purple-400 dark:text-custom-purple-100 rounded-full"
                name="dollar"
                @click.native="$emit('on-company-tag', $event)"
              />
              <span class="block text-2xs">{{ $t('Companies') }}</span>
            </label>
            <label
              v-if="companyAttachament"
              class="w-20 text-center flex flex-col items-center justify-center"
            >
              <Icon
                class="flex items-center justify-center w-10 h-10 text-xl font-semibold text-custom-purple-400 border-2 border-custom-purple-500 dark:border-custom-purple-200 dark:bg-custom-purple-400 dark:text-custom-purple-100 rounded-full"
                name="paperclip"
                @click.native="$emit('on-company-data', $event)"
              />
              <span class="block text-2xs">{{ $t('Company data') }}</span>
            </label>
          </div>
        </div>
      </template>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: 'AttachMediaMenu',
  props: {
    companyAttachament: { type: Boolean, default: true }
  }
}
</script>
