<template>
  <div
    class="w-full lg:w-6/12"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'MiddleColumn',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>
