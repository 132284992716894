<template>
  <RouterLink
    class="shadow p-4 bg-white hover:bg-gray-100 rounded-lg dark:hover:bg-custom-purple-600 dark:bg-custom-purple-500 shadow-lg dark:shadow-lg-dark block mt-3"
    :to="to"
    @click.native="$emit('click')"
  >
    <div class="flex items-center flex-wrap justify-between">
      <span class="font-semibold text-sm">
        <slot />
      </span>
      <FontAwesomeIcon
        size="lg"
        icon="angle-right"
      />
    </div>
  </RouterLink>
</template>

<script>

export default {
  name: 'LinkArrowRight',
  props: {
    to: { type: String, default: '' }
  }
}
</script>
