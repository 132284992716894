<template>
  <BaseButton
    class="rounded-xl text-white dark:text-custom-purple-100 text-xs"
    :class="[padding, secondary ? 'bg-custom-purple-800 dark:bg-custom-purple-500 hover:bg-custom-purple-400 dark:hover:bg-custom-purple-800' : 'bg-custom-purple-500 dark:bg-custom-purple-400 hover:bg-custom-purple-400 dark:hover:bg-custom-purple-450']"
    :no-padding="true"
    :no-shadow="true"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'SellButton',
  props: {
    padding: {
      type: String,
      default: 'px-4 py-3'
    },
    secondary: {
      type: Boolean,
      default: false
    }
  }
}
</script>
