import { i18n } from '@/js/language/'
import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('pressRelease')
const postrepository = RepositoryFactory.get('posts')

export default {
  state: {
    selectedCompanyId: null,
    companyPressReleases: {},
    typedCompanyPressReleases: {},
    loading: false,
    loadingTranslate: false,
    nextPageUrl: null,
    loadingNew: false,
    hasError: false
  },
  getters: {
    hasError: state => state.hasError,
    loading: state => state.loading,
    loadingTranslate: state => state.loadingTranslate,
    pressReleases (state) {
      if (!state.companyPressReleases[state.selectedCompanyId]) {
        return []
      }
      return state.companyPressReleases[state.selectedCompanyId]
    },
    typedPressReleases (state) {
      if (!state.typedCompanyPressReleases) {
        return []
      }
      return state.typedCompanyPressReleases
    },
    nextPageUrl: state => state.nextPageUrl,
    loadingNew: state => state.loadingNew
  },
  mutations: {
    SET_HAS_ERROR (state, boolean) {
      state.hasError = boolean
    },
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = Number(companyId)
    },
    SET_NEW_LOADING (state, boolean) {
      state.loadingNew = boolean
    },
    SET_LOADING (state, bbb) {
      state.loading = bbb
    },
    SET_LOADING_TRANSLATE (state, boolean) {
      state.loadingTranslate = boolean
    },
    APPEND_PRESS_RELEASE (state, payload) {
      if (state.companyPressReleases[state.selectedCompanyId] === undefined) {
        state.companyPressReleases[state.selectedCompanyId] = payload
      } else {
        payload.find(response => {
          const flag = state.companyPressReleases[state.selectedCompanyId].findIndex(companyPressRelease => {
            return (response.id === companyPressRelease.id)
          })
          if (flag > -1) {
            state.companyPressReleases[state.selectedCompanyId][flag] = response
          } else {
            state.companyPressReleases[state.selectedCompanyId].push(response)
          }
        })
      }

      state.companyPressReleases = { ...state.companyPressReleases }
    },
    SET_LIST (state, payload) {
      if (payload) {
        payload.filter(pressRelease => {
          if (pressRelease && pressRelease.content) {
            if (pressRelease.content.indexOf('<body>') > -1) {
              pressRelease.content = pressRelease.content.replace('<body>', '<div>')
              pressRelease.content = pressRelease.content.replace('</body>', '</div>')
            } else {
              pressRelease.content = `<div>${pressRelease.content}</div>`
            }
          }
        })
        if (!state.companyPressReleases.hasOwnProperty(state.selectedCompanyId)) {
          state.companyPressReleases[state.selectedCompanyId] = []
        }

        state.companyPressReleases = { ...state.companyPressReleases, [state.selectedCompanyId]: payload }
        state.companyPressReleases = { ...state.companyPressReleases }
      }
    },
    SET_TYPED_LIST (state, payload) {
      if (payload.data) {
        payload.data.filter(pressRelease => {
          if (pressRelease && pressRelease.content) {
            if (pressRelease.content.indexOf('<body>') > -1) {
              pressRelease.content = pressRelease.content.replace('<body>', '<div>')
              pressRelease.content = pressRelease.content.replace('</body>', '</div>')
            } else {
              pressRelease.content = `<div>${pressRelease.content}</div>`
            }
          }
        })
        if (!state.typedCompanyPressReleases[payload.identifier]) {
          state.typedCompanyPressReleases[payload.identifier] = {}
        }
        if (!state.typedCompanyPressReleases[payload.identifier][payload.type]) {
          state.typedCompanyPressReleases[payload.identifier][payload.type] = []
        }

        state.typedCompanyPressReleases = { ...state.typedCompanyPressReleases, [payload.identifier]: { [payload.type]: payload.data } }
        state.typedCompanyPressReleases = { ...state.typedCompanyPressReleases }
      }
    },
    SET_ONE (state, pressRelease) {
      // if (pressRelease && pressRelease.post) {
      //   pressRelease = pressRelease.post
      // }

      if (pressRelease && pressRelease.content) {
        if (pressRelease.content.indexOf('<body>') > -1) {
          pressRelease.content = pressRelease.content.replace('<body>', '<div>')
          pressRelease.content = pressRelease.content.replace('</body>', '</div>')
        }
      }

      let pr

      if (state.companyPressReleases[state.selectedCompanyId]) {
        pr = state.companyPressReleases[state.selectedCompanyId].find(pr => Number(pr.id) === Number(pressRelease.id))
      }

      if (!pr) {
        if (!state.companyPressReleases[state.selectedCompanyId]) {
          state.companyPressReleases = { ...state.companyPressReleases, [state.selectedCompanyId]: [] }
        }
        state.companyPressReleases[state.selectedCompanyId].push(pressRelease)
      }
    },
    SET_ONE_BY_PRESS_RELEASE_ID (state, pressRelease) {
      // if (pressRelease && pressRelease.post) {
      //   pressRelease = pressRelease.post
      // }

      if (pressRelease && pressRelease.content) {
        if (pressRelease.content.indexOf('<body>') > -1) {
          pressRelease.content = pressRelease.content.replace('<body>', '<div>')
          pressRelease.content = pressRelease.content.replace('</body>', '</div>')
        }
      }

      if (state.companyPressReleases[state.selectedCompanyId]) {
        const prPostIndex = state.companyPressReleases[state.selectedCompanyId].findIndex(pr => (pr.postable_type === 'App\\Models\\CompanyPressRelease') && Number(pr.postable_id) === Number(pressRelease.id))
        let prPost = { ...state.companyPressReleases[state.selectedCompanyId][prPostIndex] }

        prPost.postable = pressRelease
        state.companyPressReleases[state.selectedCompanyId][prPostIndex] = prPost
        state.companyPressReleases = { ...state.companyPressReleases }
      }
    },
    PUSH_PRESS_RELEASE (state, payload) {
      state.companyPressReleases = { ...state.companyPressReleases, [payload[0].company.id]: payload }
    },
    SAVED_NEXT_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    SHOW_PRESSRELEASE_MENU (state, payload) {
      let result = state.companyPressReleases[state.selectedCompanyId].findIndex(pressRelease => Number(pressRelease.id) === Number(payload.id))
      state.companyPressReleases[state.selectedCompanyId][result].show = !state.companyPressReleases[state.selectedCompanyId][result].show
      state.companyPressReleases = { ...state.companyPressReleases }
      // let pindex = state.companyPressReleases[payload.companies[0].id].find(pressRelease => pressRelease.id === payload.id)
      // console.log('check : before', pindex)
      // pindex = payload
      // console.log('check : after', pindex)
      // state.companyPressReleases = { ...state.companyPressReleases }
    }
  },
  actions: {
    async translate ({ commit, dispatch, rootState }, id) {
      commit('SET_LOADING_TRANSLATE', true)
      const response = await repository.translate(rootState.companies.selectedCompanySN, id)
      if ((response.status === 200) && response.data) {
        commit('SET_ONE_BY_PRESS_RELEASE_ID', response.data)
      }
      commit('SET_LOADING_TRANSLATE', false)
    },
    showMenu ({ commit }, payload) {
      commit('SHOW_PRESSRELEASE_MENU', payload)
    },
    async one ({ commit, dispatch, state, rootState }, id) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)

        let pressRelease

        if (state.companyPressReleases[Number(rootState.companies.selectedCompanyId)]) {
          pressRelease = state.companyPressReleases[Number(rootState.companies.selectedCompanyId)].find(pressRelease => Number(pressRelease.id) === Number(id))
        }

        if (!pressRelease || !pressRelease.content) {
          commit('SET_LOADING', true)
          const response = await repository.one(rootState.companies.selectedCompanySN, id)
          console.log(response)
          commit('SET_LOADING', false)
          if ((response.status === 200) && response.data) {
            commit('SET_ONE', response.data)
          }
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetch ({ commit, dispatch, state, rootState }, payload = null) {
      try {
        let identifier = 'global'
        if (payload.companyId) {
          identifier = payload.companyId
          commit('SELECT_COMPANY', Number(payload.companyId))
        }

        // if (state.companyPressReleases[Number(rootState.companies.selectedCompanyId)] === undefined) {
        commit('SET_LOADING', true)
        const response = await repository.list(payload)
        commit('SET_LOADING', false)

        if ((response.status === 200) && response.data) {
          commit('SET_TYPED_LIST', { data: response.data.data, identifier, type: payload.type })

          commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
        }
        // }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async scroll ({ commit, rootState, state, dispatch }) {
      try {
        if (state.nextPageUrl !== null && !state.loadingNew) {
          commit('SET_NEW_LOADING', true)
          const response = await repository.scroll(state.nextPageUrl)
          if ((response.status === 200) && response.data) {
            commit('APPEND_PRESS_RELEASE', response.data.data)
            commit('SAVED_NEXT_PAGE_URL', response.data.next_page_url)
            commit('SET_NEW_LOADING', false)
          }
        }
      } catch (e) {
        commit('SET_NEW_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    internalUpdate ({ commit, state, rootState }, payload) {
      state.companyPressReleases[state.selectedCompanyId][payload.index] = payload.pressRelease
      state.companyPressReleases[state.selectedCompanyId] = [...state.companyPressReleases[state.selectedCompanyId]]
    },
    async refetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_NEW_LOADING', true)
        const response = await repository.list(rootState.companies.selectedCompanySN)
        commit('SET_NEW_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_LIST', response.data.data)
        }
      } catch (e) {
        commit('SET_NEW_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async like ({ commit, state, rootState, dispatch }, payload) {
      try {
        const companyId = (payload.companyId) ? payload.companyId : rootState.companies.selectedCompanyId

        if (state.companyPressReleases && state.companyPressReleases[Number(companyId)]) {
          const pressReleases = state.companyPressReleases[Number(companyId)]
          const index = pressReleases.findIndex(pressRelease => {
            return Number(pressRelease.id) === Number(payload.pressReleaseId)
          })
          const likeIndex = pressReleases[index].post.likes.findIndex(like => (Number(like.stock_user_id) === Number(rootState.users.user.profile_id) && like.reaction === payload.reaction))

          if (likeIndex > -1) {
            pressReleases[index].post.likes.splice(likeIndex, 1)
          } else {
            pressReleases[index].post.likes.push({
              likeable_id: payload.postId,
              likeable_type: 'App\\Models\\Post',
              reaction: payload.reaction,
              stock_user: rootState.users.user.profile,
              stock_user_id: rootState.users.user.profile.id
            })
          }
        }

        const response = await postrepository.postLike(payload.postId, { reaction: payload.reaction })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press release liked successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pushPressRelease ({ commit, state }, payload) {
      commit('PUSH_PRESS_RELEASE', [payload])
    },
    async addPressRelease ({ commit, state, dispatch }, payload) {
      try {
        const response = await repository.add(state.selectedCompanyId, payload)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press Release added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async save ({ commit, rootState, dispatch }, pressReleaseId) {
      try {
        const response = await repository.save(rootState.companies.selectedCompanyId, pressReleaseId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press release saved successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithAll ({ commit, rootState, dispatch }, payload) {
      try {
        const response = await repository.sharePressReleaseWithAll(rootState.companies.selectedCompanyId, payload.press_release_id, { message: payload.message })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press Release shared successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithCompany ({ commit, dispatch }, payload) {
      try {
        const response = await repository.sharePressReleaseWithCompany(payload.company_id, payload.press_release_id, { message: payload.message })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Press Release shared successfully', type: 'success', show: true, autoclose: true }, { root: true })
        } else {
          // console.log('failed to share Press Release')
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.companyPressReleases = {}
    },
    setLoading ({ commit }, boolean) {
      commit('SET_LOADING', boolean)
    },
    async uploadImage ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.uploadImage(payload.companyId, payload.pressReleaseId, { image: payload.image })
        console.log('response', response)
        if ((response.status === 200) && response.data) {
          commit('SET_ONE_BY_PRESS_RELEASE_ID', response.data)
          dispatch('snackbar/snack', { text: i18n.t('Image Uploaded Successfully'), type: 'success', show: true, autoclose: true }, { root: true })
          commit('SET_HAS_ERROR', false)
        } else {
          commit('SET_HAS_ERROR', true)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_HAS_ERROR', true)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
