<template>
  <div
    class="mx-auto dark:text-primary-dark px-0 sm:px-0"
    :class="[customClass, padding, bottomPadding ? 'mb-24' : '', width]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    padding: { type: String, default: 'py-6' },
    bottomPadding: { type: Boolean, default: false },
    customClass: { type: String, default: '' },
    full: { type: Boolean, default: false },
    wide: { type: Boolean, default: false }
  },
  computed: {
    width () {
      if (this.full) {
        return 'w-full'
      }

      if (this.wide) {
        return 'w-11/12 2xl:w-8/12'
      }

      return 'w-11/12 sm:w-8/12 md:w-6/12 lg:w-5/12'
    }
  }
}
</script>
