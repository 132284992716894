<template>
  <div
    class="text-custom-purple-500 rounded-xl text-xs py-1 px-3"
    :class="comingSoon ? 'bg-gray-300' : 'bg-custom-green-200'"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'PositiveBadge',
  props: {
    big: {
      type: Boolean,
      default: false
    },
    comingSoon: {
      type: Boolean,
      default: false
    }
  }
}
</script>
