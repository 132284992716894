<template>
  <div
    :class="globalPadding"
    class="page overflow-hidden flex flex-grow flex-shrink-0 flex-col bg-custom-purple-500 dark:bg-custom-purple-500"
  >
    <HeaderContainer
      v-if="user && user.id && user.profile_type !== 'App\\Models\\GuestUser'"
    />
    <FrontHeaderContainer
      v-else
    />
    <main class="flex flex-col flex-grow flex-shrink-0 z-10">
      <RouterView :key="$route.fullPath" />
    </main>
    <FooterContainer
      v-if="user && !isGuest"
      :padding="footerPadding"
    />
    <FrontFooterContainer v-if="$route.meta.needLogin !== false" />
    <!-- <FairUsageModalContainer v-if="((user && user.id && user.profile_type === 'App\\Models\\GuestUser' || !user || !user.id) && $route.meta.needLogin !== false) || (!(user && user.id && user.profile_type !== 'App\\Models\\GuestUser') && $route.meta.needLogin !== false)" /> -->
    <PortalTarget
      name="dialog"
      multiple
    />
  </div>
</template>

<script>

import FrontHeaderContainer from '@/modules/frontHeader'
import HeaderContainer from '@/modules/header'
import FooterContainer from '@/modules/footer'
// import FairUsageModalContainer from '@/modules/fairUsageModal'
import headerPaddingMixin from '@/mixins/headerPaddingMixin'
import usersMixin from '@/mixins/usersMixin'
import FrontFooterContainer from '@/modules/frontFooter'
import { PortalTarget } from 'portal-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FrontLayout',
  components: {
    HeaderContainer,
    FooterContainer,
    FrontHeaderContainer,
    FrontFooterContainer,
    PortalTarget
  },
  mixins: [headerPaddingMixin, usersMixin],
  computed: {
    ...mapGetters({
      newVersionAvailable: 'app/newVersionAvailable',
      user: 'users/user'
    }),
    globalPadding () {
      if (!this.newVersionAvailable) { // shall only change for header
        return this.platformPadding
      }

      return ''
    },
    footerPadding () {
      return this.platformPadding
    }
  }
}
</script>

<style lang="scss">
 .ios-padding {
   padding-top: 36px !important;
 }
</style>
