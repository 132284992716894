
export default {
  state: {
    active: []
  },
  getters: {
    active: state => state.active
  },
  mutations: {
    REMOVE_LATEST_MODAL (state) {
      state.active.splice(state.active.length - 1, 1)
      // console.log('REMOVE LAST ONE PLEASE')
      // console.log('MODAL STATE', state.active)
    },
    NEW_MODAL (state, id) {
      state.active.push(id)
      // console.log('MODAL STATE', state.active)
    }
  },
  actions: {
    newModal ({ commit }, id) {
      commit('NEW_MODAL', id)
    },
    removeLatestModal ({ commit, state }) {
      if (state.active.length > 0) {
        commit('REMOVE_LATEST_MODAL')
      }
    }
  },
  namespaced: true
}
