import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/companies`

const pressReleaseLike = async (companyId, pressReleaseId, data) => post(`${url}/${companyId}/press-releases/${pressReleaseId}/likes`, data)
const list = async (data = null) => get(`${process.env.VUE_APP_API_URL}/press-releases`, data)
const one = async (companyId, pressReleaseId) => get(`${url}/${companyId}/v2/press-releases/${pressReleaseId}`)
const translate = async (companyId, pressReleaseId) => get(`${url}/${companyId}/v2/press-releases/${pressReleaseId}/translate`)
const save = async (companyId, pressReleaseId) => post(`${url}/${companyId}/press-releases/${pressReleaseId}/save`)
const sharePressReleaseWithAll = async (companyId, pressReleaseId, data) => post(`${url}/${companyId}/press-releases/${pressReleaseId}/followers/share`, data)
const sharePressReleaseWithCompany = async (companyId, pressReleaseId, data) => post(`${url}/${companyId}/press-releases/${pressReleaseId}/share`, data)
const scroll = async (link) => get(`${link}`)
const uploadImage = async (companyId, pressReleaseId, data) => post(`${url}/${companyId}/press-releases/${pressReleaseId}/upload-image`, data)
export default {
  pressReleaseLike,
  list,
  sharePressReleaseWithAll,
  sharePressReleaseWithCompany,
  save,
  scroll,
  one,
  translate,
  uploadImage
}
