import { Capacitor } from '@capacitor/core'
import { detectBrowser, isMobile } from '@/js/helpers/mobile'
import { RepositoryFactory } from '@/api/repositoryFactory'
import platform from 'platform'
import LATEST_VERSION from '@/version.json'

const repository = RepositoryFactory.get('app')

export default {
  state: {
    version: LATEST_VERSION,
    latestVersion: '1',
    forcedVersion: '1',
    versionFetched: false,
    menu: false,
    useFooter: true,
    loadingVersion: false,
    loadingMaintenance: false,
    maintenance: false,
    maintenanceTo: null,
    insideCompanyPage: false,
    insidePrivateChat: false,
    fixedHash: 'aurja23987',
    browser: localStorage.browser ? localStorage.browser : detectBrowser,
    myEucapsMenu: [
      {
        routeName: 'myEucapsOverview',
        label: 'My Home'
      },
      {
        routeName: 'myEucapsLists',
        label: 'My Lists'
      },
      {
        routeName: 'myEucapsFavorites',
        label: 'My Favorites'
      },
      {
        routeName: 'myEucapsMarketplace',
        label: 'My Marketplace'
      }
    ]
  },
  getters: {
    myEucapsMenu: (state, _getters, rootState) => {
      const menu = [...state.myEucapsMenu]

      if (rootState.users.user.profile.company_user) {
        menu.push({
          routeName: 'myEucapsMyCompany',
          label: 'My Company'
        })
      }

      return menu
    },
    useMenu: state => state.menu,
    useFooter: state => state.useFooter,
    platformKey: (state, getters) => {
      if (!isMobile()) {
        return (platform.name + platform.version + platform.os).replace(/\s\S/g, '')
      }

      return (platform.product + platform.os).replace(/\s\S/g, '')
    },
    platform: state => Capacitor.getPlatform(),
    isNative: state => Capacitor.isNativePlatform(),
    insideCompanyPage: state => state.insideCompanyPage,
    insidePrivateChat: state => state.insidePrivateChat,
    newVersionAvailable (state, _getters, rootState) {
      if (!state.versionFetched || state.loadingVersion) {
        return false
      }

      if (!rootState.users.user) {
        return false
      }

      const currentVersion = Number(state.version)
      const latestVersion = Number(rootState.users.user.latestVersion)

      return (currentVersion !== latestVersion && currentVersion < latestVersion)
    },
    forcedVersionAvailable (state, _getters, rootState) {
      if (!state.versionFetched || state.loadingVersion) {
        return false
      }

      if (!rootState.users.user) {
        return false
      }

      const currentVersion = Number(state.version)
      const forcedVersion = Number(rootState.users.user.forced_version)

      return (currentVersion < forcedVersion)
    },
    maintenance (state) {
      return state.maintenance
    },
    maintenanceTo (state) {
      return state.maintenanceTo
    },
    loadingVersion: state => state.loadingVersion,
    versionFetched: state => state.versionFetched,
    fixedHash: state => state.fixedHash
  },
  mutations: {
    SET_MENU (state, boolean) {
      state.menu = boolean
    },
    SET_USE_FOOTER (state, boolean) {
      state.useFooter = boolean
    },
    SET_INSIDE_COMPANY_PAGE (state, boolean) {
      state.insideCompanyPage = boolean
    },
    SET_INSIDE_PRIVATE_CHAT (state, boolean) {
      state.insidePrivateChat = boolean
    },
    UPDATE_VERSION (state, payload) {
      state.latestVersion = payload.version
    },
    UPDATE_FORCED_VERSION (state, payload) {
      state.forcedVersion = payload.forced_version ? payload.forced_version : 1
    },
    VERSION_LOADING (state, loading) {
      state.loadingVersion = loading
    },
    VERSION_FETCHED (state, boolean) {
      state.versionFetched = boolean
    },
    UPDATE_MAINTENANCE (state, payload) {
      // console.log('UPDATING MAINTENANCE')
      if (!payload.maintenance || payload.maintenance === false) {
        state.maintenance = false
        state.maintenanceTo = null
      } else {
        state.maintenance = true
        state.maintenanceTo = payload.maintenance_to
      }
    },
    MAINTENANCE_FETCHED (state, isFetched) {
      state.maintenanceFetched = isFetched
    },
    MAINTENANCE_LOADING (state, loading) {
      state.loadingMaintenance = loading
    }
  },
  actions: {
    useMenu ({ commit }, useMenu) {
      commit('SET_MENU', useMenu)
    },
    useFooter ({ commit }, useFooter) {
      commit('SET_USE_FOOTER', useFooter)
    },
    insideCompanyPage ({ commit }, insideCompanyPage) {
      commit('SET_INSIDE_COMPANY_PAGE', insideCompanyPage)
    },
    insidePrivateChat ({ commit }, insidePrivateChat) {
      commit('SET_INSIDE_PRIVATE_CHAT', insidePrivateChat)
    },
    async getLatestVersion ({ commit }) {
      commit('VERSION_FETCHED', false)
      commit('VERSION_LOADING', true)
      const response = await repository.latestVersion()
      commit('VERSION_LOADING', false)
      if (response && response.status === 200) {
        commit('UPDATE_VERSION', response.data)
        commit('UPDATE_FORCED_VERSION', response.data)
        commit('VERSION_FETCHED', true)
      } else {
        commit('VERSION_FETCHED', false)
      }
    },
    async getMaintenance ({ commit }) {
      commit('MAINTENANCE_FETCHED', false)
      commit('MAINTENANCE_LOADING', true)
      const response = await repository.maintenance()
      commit('MAINTENANCE_LOADING', false)
      if (response && response.status === 200) {
        commit('UPDATE_MAINTENANCE', response.data)
        commit('MAINTENANCE_FETCHED', true)
      } else {
        commit('MAINTENANCE_FETCHED', false)
      }
    },
    async lead ({ commit }, payload) {
      repository.lead(payload)
    }
  },
  namespaced: true
}
