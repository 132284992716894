<template>
  <div class="lg:space-y-10 space-y-5">
    <div class=" font-medium sm:text-2xl text-xl ">
      The one-stock-shop for European growth companies
    </div>
    <div class=" xl:text-6xl xl:leading-tight sm:leading-tight leading-tight sm:text-5xl text-4xl font-extrabold pr-10">
      Get your free <span class="text-custom-orange-400">Eucaps</span> account
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserTextSection'
}
</script>
