import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('businessDetails')

export default {
  state: {
    businessDetails: [],
    loading: false
  },
  getters: {
    businessDetails: state => state.businessDetails,
    loading: state => state.loading
  },
  mutations: {
    SET_LIST (state, payload) {
      state.businessDetails = [ ...payload ]
    },
    UPDATE_LIST (state, businessDetail) {
      const flag = state.businessDetails.findIndex(detail => {
        return (Number(businessDetail.id) === Number(detail.id))
      })
      state.businessDetails[flag] = { ...businessDetail }
      state.businessDetails = [ ...state.businessDetails ]
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    }
  },
  actions: {
    async getBusinessDetailsList ({ commit, dispatch, state, getters }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.getBusinessDetailsList()
        if (response.status === 200) {
          commit('SET_LIST', response.data)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async newDocumentList ({ commit, dispatch, state, getters }, payload) {
      try {
        const response = await repository.newDocumentListFile(payload.businessDetailsId, { document: payload.newDocument })
        if (response.status === 200) {
          commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async deleteDocumentListFile ({ commit, dispatch, state, getters }, payload) {
      try {
        const response = await repository.deleteDocumentListFile(payload.businessDetailsId, payload.document.id)
        if (response.status === 200) {
          dispatch('companies/updateCompanyList', response.data, { root: true })
          // commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    },
    async deleteLink ({ commit, dispatch, state, getters }, payload) {
      try {
        const response = await repository.deleteLink(payload.businessDetailsId, payload.link.id)
        if (response.status === 200) {
          dispatch('companies/updateCompanyList', response.data, { root: true })
          // commit('UPDATE_LIST', response.data)
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message[0], type: 'error', show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
