<template>
  <div
    class="flex flex-col justify-center"
    :class="minified ? subtitle ? 'py-1' : 'py-3' : 'py-4'"
    @click="$emit('click', $event)"
  >
    <div class="flex flex-col">
      <div class="flex items-center">
        <CompanyAvatar
          :company="company"
          :size="minified ? 45 : 75"
          :minified="minified"
          :default-class=" minified ? 'w-12 h-12' : 'w-20 h-20'"
        />
        <Title
          class="lg:mr-4 flex flex-col"
          :size="minified ? 'text-lg leading-5' : 'text-xl lg:text-3xl'"
        >
          <span>{{ company.name }}</span>
          <template v-if="subtitle">
            <span
              v-if="subtitle"
              class="text-gray-500 dark:text-custom-purple-200"
              :class="minified ? 'text-sm font-semibold' : 'text-lg'"
            >{{ subtitle }}</span>
          </template>
          <template v-else>
            <div
              v-if="!minified || !subtitle"
              class="sm:flex font-semibold items-center text-gray-500"
              :class="minified ? 'text-sm' : 'text-base'"
            >
              <div v-if="!minified">
                {{ companyDetails && companyDetails.exchange_from_user ? companyDetails.exchange_from_user.name :
                  companyDetails && companyDetails.exchange ? companyDetails.exchange.name : '' }}
              </div>
              <div
                v-if="!minified && (company.exchange && company.exchange.name)"
                class="w-6 flex h-2 -pt-1 justify-center"
              >
                <div class="text-3xl dots-margin">
                  •
                </div>
              </div>
              <div class="sm:pr-2">
                {{ company.pics && company.pics.name ? company.pics.name: '' }}
              </div>
              <div
                v-if="(company.pics && company.pics.name)"
                class="w-6 hidden sm:block flex mt-1 h-2 -pt-1 justify-center"
              >
                <div class="text-3xl dots-margin">
                  •
                </div>
              </div>

              <div class="flex">
                <CountryFlag
                  class="flex mt-1 flex-shrink-0"
                  :company="company"
                  :size="'w-4 h-4'"
                />
                <div class="whitespace-nowrap">
                  {{ company.all_followers }} Followers
                </div>
              </div>
            </div>
          </template>
        </Title>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompanyName',
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    companyDetails: {
      type: Object,
      default: () => {}
    },
    subtitle: {
      type: String,
      default: null
    },
    minified: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
 .dots-margin {
  margin-top: -15px;
 }
</style>
