<template>
  <div class="lg:space-y-10 space-y-5 pr-10">
    <div class="font-medium sm:text-2xl text-xl">
      Hey growth company!
    </div>
    <div class="xl:text-6xl xl:leading-tight sm:leading-tight leading-tight sm:text-5xl text-4xl font-extrabold  ">
      Millions of <span class="text-custom-orange-400">investors</span> beyond borders are waiting
    </div>
    <div class="text-custom-purple-200 font-medium sm:text-2xl text-xl ">
      Claim your company profile and start your growth journey. Right Now!
    </div>
    <div class="text-custom-purple-200 font-medium sm:text-2xl text-xl">
      Eucaps Premium for <span class="text-custom-orange-400">€750/month</span> (€1250/month).
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserTextSection'

}
</script>
