<template>
  <div class="lg:w-1/2 w-full flex flex-col h-full bg-white">
    <div class="h-full flex flex-col items-center lg:justify-center justify-start p-10">
      <div class="lg:hidden pb-20 block">
        <slot name="contentSlot" />
      </div>
      <div v-if="loading">
        <CircleLoader />
      </div>
      <div v-else>
        <div
          v-if="!showEmailVerification"
          class="flex flex-col space-y-5"
        >
          <div class=" max-w-xs">
            <ErrorMessage
              v-if="alreadyRegistered"
              class="text-sm"
            >
              <p>{{ $t('Email or username is already taken. If this is your account, shift to login or reset your password. If not, please try another username.') }}</p>
            </ErrorMessage>
          </div>
          <UpdateLoader v-if="loadingRegistration" />
          <template v-else>
            <BaseInput
              id="username"
              class="placeholder-custom-purple-500 h-14  placeholder-shown: font-semibold "
              placeholder="Username"
              shadow="shadow-md"
              :value="username"
              required
              @input="$emit('username', $event)"
            />
            <BaseInput
              id="email"
              class="placeholder-custom-purple-500 h-14 placeholder-shown: font-semibold "
              placeholder="Email"
              shadow="shadow-md"
              :value="email"
              required
              @input="$emit('email', $event)"
            />
            <BaseInput
              id="password"
              class="placeholder-custom-purple-500 h-14 placeholder-shown: font-semibold"
              placeholder="Password"
              shadow="shadow-md"
              type="password"
              :value="password"
              required
              @input="$emit('password', $event)"
            />
            <div class="relative">
              <BaseButton
                id="country"
                class="rounded-md w-full border-2 pl-3 shadow-md border-gray-300 h-14"
                @keyup.tab.native="$emit('handle-country-click', true)"
                @click="$emit('handle-country-click', !showOptions)"
              >
                <span class="flex flex-start items-center font-semibold text-custom-purple-500 text-shadow-none">
                  <CountryFlag
                    v-if="country && country.name"
                    tag="code"
                    :company="country"
                  />
                  <p>{{ country && country.name ? country.name : $t('Select Country') }}</p>
                </span>
              </BaseButton>
              <div
                v-show="showOptions"
                class="absolute pb-8 w-full z-10 bg-white border-2 flex flex-col space-y-3 mt-1 max-h-52 overflow-y-scroll"
              >
                <div class="flex flex-col relative z-60">
                  <div class="relative my-2 px-2">
                    <div
                      v-if="filterCountry !== ''"
                      class="absolute inset-y-0 right-0 cursor-pointer px-4 flex items-center justify-center"
                      @click="$emit('filter-countries', '')"
                    >
                      <Icon
                        name="x"
                        class="text-lg"
                      />
                    </div>
                    <BaseInput
                      class="w-full"
                      :value="filterCountry"
                      :placeholder="$t('Filter countries')"
                      @input="$emit('filter-countries', $event.target.value)"
                    />
                  </div>
                  <button
                    v-for="countryInstance in countryList"
                    :key="countryInstance.code"
                    class=" font-normal text-shadow-none p-2  text-custom-purple-500 flex items-center hover:bg-blue-500 hover:text-white "
                    @click="$emit('handle-country', countryInstance)"
                  >
                    <CountryFlag
                      tag="code"
                      :company="countryInstance"
                    />
                    <div>{{ countryInstance.name }}</div>
                  </button>
                </div>
                <div
                  class="fixed inset-0 z-50"
                  @click="$emit('handle-country-click', false)"
                />
              </div>
            </div>
          </template>
          <div class="text-custom-purple-500 font-medium text-sm">
            <div class="flex mt-4">
              <input
                :id="'checkbox'"
                name="oiasjdoihfeoiuhwoiuahfeiuwehfi2"
                type="checkbox"
                :checked="terms"
                required
                class="mr-2 text-sm"
                autocomplete="off"
                @change="$emit('terms')"
              >
              <p
                :for="'checkbox'"
                class="w-full inline-block"
              >
                {{ $t('I hereby accept') }}  <span
                  class="text-custom-orange-400 font-medium cursor-pointer"
                  @click="$emit('show-terms')"
                >{{ $t('terms and conditions') }}</span>
              </p>
            </div>
          </div>
          <ActiveButton
            class="bg-custom-orange-400 h-12 rounded-xl disabled:opacity-50 "
            :disabled="!terms || !password || !email || !username || !country"
            @click="$emit('on-submit')"
          >
            <span class="text-white">
              Register
            </span>
          </ActiveButton>
          <div />
          <!-- <div class="inline-flex h-14 md:w-96 w-80">
          <button class=" border-2 rounded-tl-lg rounded-bl-lg w-1/4">
            <img
              class="inline-flex px-7"
              src="/svg/Google-logo.svg"
            >
          </button>
          <button class="border-t-2 border-b-2 w-1/4">
            <img
              class="inline-flex px-7"
              src="/svg/Facebook-logo.svg"
            >
          </button>
          <button class="border-2 border-r-0 w-1/4">
            <img
              class="inline-flex px-7"
              src="/svg/Apple-logo.svg"
            >
          </button>
          <button class="border-2 rounded-tr-lg rounded-br-lg w-1/4 ">
            <img
              class="inline-flex px-6"
              src="/svg/Twitter-logo.svg"
            >
          </button>
        </div> -->
          <div
            class="cursor-pointer"
            @click="$emit('redirect-login', $event)"
          >
            <span class="text-center text-custom-purple-500 font-medium text-base">
              Do you already have an account?
              <button
                class="font-bold"
              >
                Sign in
              </button>
            </span>
          </div>
        </div>
        <div v-else-if="showEmailVerification">
          <EmailVerificationContainer :user-type="user" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmailVerificationContainer from '@/modules/emailVerification'
export default {
  name: 'SignupFormSection',
  components: {
    EmailVerificationContainer
  },
  props: {
    username: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    },
    terms: {
      type: Boolean,
      default: false
    },
    loadingRegistration: {
      type: Boolean,
      default: false
    },
    filterCountry: {
      type: String,
      default: ''
    },
    alreadyRegistered: {
      type: Boolean,
      default: false
    },
    user: {
      type: String,
      default: ''
    },
    country: {
      type: Object,
      default: () => {}
    },
    countryList: {
      type: Array,
      default: () => []
    },
    showOptions: {
      type: Boolean,
      default: false
    },
    showEmailVerification: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
