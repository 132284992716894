<template>
  <div class="cursor-pointer">
    <CompanyName
      :company="theCompany"
      :company-details="theCompanyDetails"
      :minified="minified"
      :subtitle="subtitle"
      @click="$emit('click', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CompanyName from './_components/CompanyName'

export default {
  name: 'CompanyNameModule',
  components: {
    CompanyName
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    companyDetails: {
      type: Object,
      default: () => {}
    },
    subtitle: {
      type: String,
      default: ''
    },
    minified: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      selectedCompany: 'companies/selectedCompany',
      selectedCompanyDetails: 'companies/companyDetails'
    }),
    theCompany () {
      if (this.company) {
        return this.company
      }

      return this.selectedCompany
    },
    theCompanyDetails () {
      if (this.companyDetails) {
        return this.companyDetails
      }

      return this.selectedCompanyDetails
    }
  }
}
</script>
