<template>
  <div
    class="flex items-center justify-between px-2 mb-4 font-extrabold text-custom-light-header dark:text-custom-purple-100"
    :class="secondary ? 'text-sm' : ' text-xl'"
  >
    <div class="inline-block">
      <slot />
    </div>
    <button
      v-if="showButton"
      :disabled="locked"
      class="float-right text-shadow-none focus:outline-none flex"
      @click="$emit('click')"
    >
      <Icon
        name="plus"
        bold
        :class="secondary ? 'text-xl' : ' text-2xl'"
      />
    </button>
  </div>
</template>

<script>

export default {
  name: 'TitleWithAddButton',
  props: {
    secondary: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    showButton: {
      type: Boolean,
      default: true
    }
  }
}
</script>
