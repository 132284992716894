<template>
  <div
    class="absolute bg-custom-orange-400 rounded-md text-xs text-white block w-40 py-1 px-2 z-70"
    :class="[position, 'carrot-'+carrot, closable ? 'cursor-pointer' : '']"
    @click="$emit('close')"
  >
    <Icon
      v-if="closable"
      name="x"
      class="absolute top-0 right-1 text-white text-lg"
    />
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Tooltip',
  props: {
    position: {
      type: String,
      default: '-top-6 right-0'
    },
    carrot: {
      type: String,
      default: 'right'
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.carrot-right::after {
  content: "";
    position: absolute;
    top: 100%;
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #F15B29 transparent transparent transparent;
}
.carrot-top-right::after {
  content: "";
    position: absolute;
    top: -25%;
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #F15B29 transparent ;
}
.carrot-left::after {
  content: "";
    position: absolute;
    top: 100%;
    left: 5%;
    margin-left: 3px;
    border-width: 5px;
    border-style: solid;
    border-color: #F15B29 transparent transparent transparent;
}
</style>
