import { RepositoryFactory } from '@/api/repositoryFactory'
import router from '@/js/router'
const repository = RepositoryFactory.get('incidents')
export default {
  state: {
    loading: false,
    incidents: [],
    incident: {},
    responsibilityLoading: false,
    selectedAction: '',
    selectedActionMessage: '',
    alert: {
      title: '',
      create: false,
      body: '',
      type: '',
      showConfirmButton: false
    }
  },
  getters: {
    loading: state => state.loading,
    incidents: state => state.incidents,
    incident: state => state.incident,
    responsibilityLoading: state => state.responsibilityLoading,
    alert: state => state.alert,
    selectedAction: state => state.selectedAction,
    selectedActionMessage: state => state.selectedActionMessage
  },
  mutations: {
    SET_INCIDENTS (state, incidents) {
      state.incidents = incidents
    },
    SET_INCIDENT (state, incident) {
      state.incident = incident
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_RESPONSIBLE (state, stockUser) {
      state.incident.responsible = stockUser
    },
    SET_RESPONSIBILITY_LOADING (state, boolean) {
      state.responsibilityLoading = boolean
    },
    SET_ALERT (state, payload) {
      state.alert.title = payload.title
      state.alert.body = payload.body
      state.alert.create = payload.create
      state.alert.type = payload.type
      state.alert.showConfirmButton = payload.showConfirmButton
    },
    RESET_ALERT (state) {
      state.alert = {
        title: '',
        create: false,
        body: '',
        type: '',
        showConfirmButton: false
      }
    },
    SET_SELECTED_ACTION (state, payload) {
      state.selectedAction = payload
    },
    SET_SELECTED_ACTION_MESSAGE (state, payload) {
      state.selectedActionMessage = payload
    }
  },
  actions: {
    async fetch ({ commit }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetch()
        console.log(response)
        commit('SET_LOADING', false)
        if ((response.status === 200)) {
          commit('SET_INCIDENTS', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        console.log(e)
        if (e.response.status === 405) {
          router.back()
        }
      }
    },
    async fetchIncident ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchIncident(payload)
        commit('SET_LOADING', false)
        if ((response.status === 200)) {
          dispatch('setIncident', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        console.log(e)
        if (e.response.status === 405) {
          router.back()
        }
      }
    },

    setIncident ({ commit, dispatch }, payload) {
      commit('SET_INCIDENT', payload)
      if (payload.action) {
        dispatch('setSelectedAction', payload.action)
      }
    },
    async takeResponsibility ({ commit, dispatch }, payload) {
      commit('SET_RESPONSIBILITY_LOADING', true)
      const response = await repository.takeResponsibilityForIncident(payload.incidentId)
      commit('SET_RESPONSIBILITY_LOADING', false)
      if (response.status === 200 && response.data) {
        dispatch('setIncident', response.data)
      }
    },
    resetAlert ({ commit }) {
      commit('RESET_ALERT')
      commit('SET_LOADING', false)
    },
    setAlert ({ commit }, payload) {
      commit('SET_ALERT', payload)
    },
    setSelectedAction ({ commit }, payload) {
      commit('SET_SELECTED_ACTION', payload)
    },
    setSelectedActionMessage ({ commit }, payload) {
      commit('SET_SELECTED_ACTION_MESSAGE', payload)
    },
    async setActionToUser ({ commit, dispatch, state }) {
      commit('SET_LOADING', true)
      const response = await repository.setActionToUser(state.incident.id, { 'action': state.selectedAction, 'actionMessage': state.selectedActionMessage })
      commit('SET_LOADING', false)
      if (response.status === 200 && response.data) {
        dispatch('setIncident', response.data)
        dispatch('setAlert', {
          title: '',
          create: false,
          body: '',
          type: '',
          showConfirmButton: false
        })
      }
    }
  },
  namespaced: true
}
