import { i18n } from '@/js/language/'

export default {
  state: {
    snack: {
      text: null,
      show: false,
      time: 5000,
      type: 'info',
      autoclose: true
    }
  },
  getters: {
    snack: state => state.snack
  },
  mutations: {
    SET_SNACKBAR (state, options) {
      state.snack = { ...state.snack, ...options }
    },
    CLOSE_SNACKBAR (state, boolean) {
      state.snack = { ...state.snack, show: boolean }
    }
  },
  actions: {
    startTimer ({ commit, state }) {
      if (state.snack.autoclose) {
        setTimeout(() => {
          commit('CLOSE_SNACKBAR', false)
        }, state.snack.time)
      }
    },
    snack ({ commit, state, dispatch }, options) {
      if (options.hasOwnProperty('e')) {
        if (options.e.hasOwnProperty('response')) {
          console.log('SNACK ERROR', options.e.response.status)
          if (options.e.response.status === 403 || options.e.response.status === '403') {
            dispatch('users/setUserProfile', options.e.response.data, { root: true })
            commit('SET_SNACKBAR', { text: i18n.t('Your profile is locked. please contact for support.'), type: 'error', show: true, autoclose: true })
            return
          }
          if (options.e.response.status === 401) {
            // dispatch('users/logout', null, { root: true })
          } else if (options.e.response.status === 422) {
            if (options.e.response.data.errors) {
              commit('SET_SNACKBAR', { text: i18n.t(options.e.response.data.errors[Object.keys(options.e.response.data.errors)[0]][0]), type: 'error', show: true, autoclose: true })
            } else {
              commit('SET_SNACKBAR', { text: i18n.t('Please add all required data'), type: 'error', show: true, autoclose: true })
            }
            dispatch('startTimer')
          } else if (options.e.response.status === 404) {
            commit('SET_SNACKBAR', { text: i18n.t(options.e.response.data.message), type: 'error', show: true, autoclose: true })
            dispatch('startTimer')
          } else if (options.e.response.message) {
            commit('SET_SNACKBAR', { text: i18n.t(options.e.response.data.message), type: 'error', show: true, autoclose: true })
            dispatch('startTimer')
          } else {
            commit('SET_SNACKBAR', { text: i18n.t('An error occurred. Please contact support if it remains'), type: 'error', show: true, autoclose: true })
            dispatch('startTimer')
          }
        } else {
          if (options.e.toString().indexOf('401') > -1) {
            // dispatch('users/logout', null, { root: true })
            dispatch('startTimer')
            return
          }
          if (options.e.toString().indexOf('422') > -1) {
            commit('SET_SNACKBAR', { text: i18n.t('Please add all required data'), type: 'error', show: true, autoclose: true })
            dispatch('startTimer')
            return
          }

          if (options.e.toString().indexOf('404') > -1) {
            commit('SET_SNACKBAR', { text: i18n.t(options.e.response.data.message), type: 'error', show: true, autoclose: true })
            dispatch('startTimer')
            return
          }
        }

        console.log(options)

        // commit('SET_SNACKBAR', { text: i18n.t('An error occurred. Please contact support if it remains'), type: 'error', show: true, autoclose: true })
        dispatch('startTimer')
        return
      }

      commit('SET_SNACKBAR', options)
      dispatch('startTimer')
    },
    close ({ commit }) {
      commit('CLOSE_SNACKBAR', false)
    }
  },
  namespaced: true
}
