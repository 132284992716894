<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/sv'

export default DatePicker
</script>

<style lang="scss">
.mx-datepicker {
  @apply w-full;
  @apply rounded-lg;
}

.mx-input {
  @apply border-gray-300 dark:border-custom-purple-300;
  @apply px-4;
  @apply py-6;
  @apply text-sm;
  @apply text-black;
  @apply rounded-lg;
}
.dark {
  .mx-input {
    @apply bg-custom-purple-500;
    @apply text-white;
    @apply placeholder-custom-purple-200;
  }

  .mx-icon-calendar {
    @apply text-custom-purple-200;
  }
}

.mx-calendar-content .cell.active {
  @apply bg-primary;
}
.mx-calendar-content .cell.in-range {
  @apply bg-primary;
}

.mx-datepicker-main.dark {
  @apply bg-custom-purple-400 border-custom-purple-600;

  .mx-calendar-content .cell.active {
    @apply bg-custom-purple-300;
  }
  .mx-calendar-content .cell.disabled {
    @apply bg-custom-purple-600;
    @apply text-gray-500;
  }
  .mx-btn {
    @apply text-custom-purple-200;
  }
}
</style>
