import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('questions')
export default {
  state: {
    questions: [],
    questionDocuments: [],
    questionDocument: {},
    loading: false,
    responsibilityLoading: false,
    localQuestionChange: false
  },
  getters: {
    questions: state => state.questions,
    loading: state => state.loading,
    responsibilityLoading: state => state.responsibilityLoading,
    localQuestionChange: state => state.localQuestionChange,
    questionDocuments: state => state.questionDocuments,
    questionDocument: state => state.questionDocument
  },
  mutations: {
    SET_QUESTIONS (state, payload) {
      state.questions = [...payload]
    },
    APPEND_NEW_QUESTION (state, stockUserId) {
      state.questions.push({ question_text: '', answer_text: '', stock_user_id: stockUserId })
    },
    SET_QUESTION (state, payload) {
      let questions = state.questions
      let question = questions[payload.index]
      console.log(question)
      console.log(payload)
      question = { ...question, question_text: payload.event }
      questions[payload.index] = question
      state.questions = [...questions]
    },
    SET_ANSWER (state, payload) {
      let questions = state.questions
      const question = { ...payload.question }
      questions[payload.index] = question
      state.questions = [...questions]
    },
    SET_QUESTION_DOCUMENTS (state, payload) {
      state.questionDocuments = payload
    },
    SET_QUESTION_DOCUMENT (state, payload) {
      state.questionDocument = payload
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_RESPONSIBILITY_LOADING (state, boolean) {
      state.responsibilityLoading = boolean
    },
    SET_RESPONSIBLE (state, stockUser) {
      state.questionDocument.responsible = stockUser
    },
    QUESTION_CHANGE (state, boolean) {
      state.localQuestionChange = boolean
    }
  },
  actions: {
    async fetchQuestionDocuments ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchQuestionDocuments(payload)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_QUESTION_DOCUMENTS', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        console.log('Error : ' + e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchQuestions ({ commit, rootState, dispatch }, payload) {
      commit('SET_QUESTIONS', [])

      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchQuestions(payload.company_id, payload.question_document_id)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_QUESTIONS', response.data.questions)
          commit('SET_QUESTION_DOCUMENT', response.data.document)
          commit('APPEND_NEW_QUESTION', rootState.users.user.profile_id)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        console.log('Error : ' + e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    setQuestion ({ commit, state, rootState }, payload) {
      if (state.questions[payload.index].question_text !== payload.event) {
        console.log('setQuestion', state.questions[payload.index].answer_text + ' = ' + payload.event)

        commit('QUESTION_CHANGE', true)
      }
      commit('SET_QUESTION', payload)
      if (payload.index === state.questions.length - 1 && payload.event !== '') {
        commit('APPEND_NEW_QUESTION', rootState.users.user.profile_id)
      }
    },
    toggleAnswer ({ commit }, payload) {
      commit('SET_ANSWER', payload)
    },
    setAnswer ({ commit, state }, payload) {
      if (state.questions[payload.index].answer_text !== payload.answer_text) {
        console.log(payload)
        console.log('setAnswer', state.questions[payload.index].answer_text + ' = ' + payload.answer_text)
        commit('QUESTION_CHANGE', true)
      }

      payload.question.answer_text = payload.answer_text
      commit('SET_ANSWER', payload)
    },
    async takeResponsibility ({ commit, state }, payload) {
      console.log(payload)
      commit('SET_RESPONSIBILITY_LOADING', true)
      const response = await repository.takeResponsibilityForQuestions(payload.companyId, payload.questionDocumentId)
      commit('SET_RESPONSIBILITY_LOADING', false)
      if (response.status === 200 && response.data) {
        commit('SET_RESPONSIBLE', payload.user.profile)
      }
    },
    async createQuestionDocuments ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.createQuestionDocuments(payload.company_id, payload)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) dispatch('fetchQuestionDocuments', payload.company_id)
      } catch (e) {
        commit('SET_LOADING', false)
        console.log('Error : ' + e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async createQuestions ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.createQuestions(payload.company_id, payload.question_document_id, { questions: payload.questions })
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('QUESTION_CHANGE', false)
          dispatch('fetchQuestions', { company_id: payload.company_id, question_document_id: payload.question_document_id })
        }
      } catch (e) {
        commit('SET_LOADING', false)
        console.log('Error : ' + e)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
