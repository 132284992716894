<template>
  <div
    class="flex flex-shrink-0 justify-center items-center mr-2 relative"
    :class="defaultClass"
  >
    <CompanyLogoContainer
      v-if="company && company.logo"
      :size="size"
      :company="company"
      :big="!minified"
      :class="defaultClass"
    />
    <AvatarInitials
      v-else
      :name="company.name"
      :class="defaultClass"
      :size="size"
      rounded="rounded-xl"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CompanyLogoContainer from '@/modules/companyLogo'
export default {
  name: 'CompanyAvatar',
  components: {
    CompanyLogoContainer
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    defaultClass: {
      type: String,
      default: 'w-10 h-10'
    },
    size: {
      type: Number,
      default: 40
    },
    minified: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user'
    })
  }
}
</script>

<style lang="scss">
  .star-margin {
    padding-top: 2px;
  }
</style>
