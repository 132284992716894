<template>
  <div v-if="notification.notifiable">
    <Notification
      :pivot="notification.pivot"
      @remove="$emit('remove', { id: notification.id })"
    >
      <div
        class="cursor-pointer"
        @click="$parent.redirectCompany({company: notification.notifiable.company, notificationId: notification.id, page: 'companyInsiderTrade'})"
      >
        <AvatarSlot
          :company="notification.notifiable.company"
        >
          <div class="text-xs font-medium mr-6">
            <span class="font-bold">{{ $t('Insider trade: ') }} </span>
            <span>{{ notification.notifiable.insider_trade.person }} - {{ notification.notifiable.insider_trade.type }}</span>
            <span
              v-if="notification.notifiable.company"
              class="whitespace-nowrap"
            >
              {{ $t('in') }}
              <RouterLink
                class="font-bold"
                :to="{name: 'companyOverview', params: {id: notification.notifiable.company.slug_url}}"
              > {{ notification.notifiable.company.name }}</RouterLink>
            </span>
            <div>{{ time }}</div>
          </div>
        </AvatarSlot>
      </div>
    </Notification>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationNewInsiderTrade',
  components: {
    Notification
  },
  props: {
    time: { type: String, default: '' },
    notification: { type: Object, default: () => {} }
  }
}
</script>
