<template>
  <div
    class="relative block overflow-hidden"
    :class="padding"
  >
    <video
      class="w-full"
      :controls="controls"
      preload="metadata"
      :poster="thumbnail"
    >
      <source :src="src">
    </video>
  </div>
</template>

<script>
export default {
  name: 'BaseVideo',
  props: {
    src: { type: String, default: '' },
    padding: { type: String, default: 'pt-4' },
    controls: { type: Boolean, default: true },
    thumbnail: { type: String, default: '' }

  }
}
</script>
