import { post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/verifications`

const authenticate = async (data) => post(`${url}/sweden/init`, data)
const verify = async (data) => post(`${url}/sweden/verify`, data)

export default {
  authenticate,
  verify
}
