<template>
  <BaseCard
    class="cursor-pointer"
    :background="(pivot.read === 0) ? 'bg-custom-blue-500 rounded-none dark:bg-custom-purple-800' : 'bg-white rounded-none dark:bg-custom-purple-600'"
    :border="(pivot.read === 0) ? 'border-b border-blue-200 dark:border-custom-purple-400' : 'border-gray-100 dark:border-custom-purple-600'"
    :shadow="(pivot.read === 1) ? 'shadow-sm' : 'shadow-sm dark:shadow-lg-dark'"
  >
    <template #body>
      <div class="w-full flex justify-between items-center">
        <div class="block flex flex-1 justify-between relative">
          <button
            class="absolute -top-2 -right-2 z-20 p-2 text-sm float-right focus:outline-none"
            @click="$emit('remove')"
          >
            <FontAwesomeIcon icon="times" />
          </button>
          <slot />
        </div>
      </div>
    </template>
  </BaseCard>
</template>
<script>
export default {
  name: 'Notification',
  props: {
    pivot: { type: Object, default: () => {} }
  }
}
</script>
