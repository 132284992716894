<template>
  <Container
    h-auto
    wide
    padding="p-0"
  >
    <FrontHeader
      :open="open"
      :loaded="loaded"
      @toggle-navbar="toggleNavbar"
    />
  </Container>
</template>

<script>
import FrontHeader from './_components/FrontHeader'

export default {
  name: 'FrontHeaderModule',
  components: {
    FrontHeader
  },
  data () {
    return {
      open: false,
      loaded: false
    }
  },
  methods: {
    toggleNavbar () {
      this.open = !this.open
      this.loaded = true
    }
  }
}
</script>
