import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('notepad')

export default {
  state: {
    loading: false,
    notes: []
  },
  getters: {
    notes: (state) => state.notes,
    loading: (state) => state.loading
  },
  mutations: {
    SET_NOTES (state, payload) {
      state.notes = [...payload]
    },
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    CREATE_NOTE (state, payload) {
      state.notes.push(payload)
    },
    UPDATE_NOTE (state, payload) {
      const noteIndex = state.notes.findIndex(note => Number(note.id) === Number(payload.id))
      if (noteIndex > -1) {
        state.notes[noteIndex] = payload
        state.notes = [...state.notes]
      }
    },
    DELETE_NOTE (state, id) {
      const noteIndex = state.notes.findIndex(note => Number(note.id) === Number(id))
      if (noteIndex > -1) {
        state.notes.splice(noteIndex, 1)
      }
    }
  },
  actions: {
    async fetch ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetch(payload)
        commit('SET_NOTES', response.data)
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'NOTEPAD DATA NOT FETCH', e }, { root: true })
        console.log(e)
      }
    },
    async createNote ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.create(payload)
        commit('CREATE_NOTE', response.data)
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'NOTE NOT CREATED', e }, { root: true })
        console.log(e)
      }
    },
    async updateNote ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.update(payload.id, { text: payload.text })
        commit('UPDATE_NOTE', response.data)
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'NOTE NOT UPDATED', e }, { root: true })
        console.log(e)
      }
    },
    async deleteNote ({ commit, dispatch }, id) {
      try {
        commit('SET_LOADING', true)
        await repository.remove(id)
        commit('DELETE_NOTE', id)
        commit('SET_LOADING', false)
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('alert/setError', { message: 'NOTE NOT DELETED', e }, { root: true })
        console.log(e)
      }
    }
  },
  namespaced: true
}
