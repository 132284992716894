import { Quill } from 'vue2-editor'

var Keyboard = Quill.import('modules/keyboard')

class DisableKeyboard extends Keyboard {
 static DEFAULTS = {
   ...Keyboard.DEFAULTS,
   bindings: {
     ...Keyboard.DEFAULTS.bindings,
     'list autofill': undefined
   }
 }
}

export default DisableKeyboard
