<template>
  <div class="z-60">
    <div
      class="flex flex-col justify-center w-full"
    >
      <div class="flex justify-center">
        <label class="w-10 text-center flex flex-col items-center justify-center">
          <Icon
            class="flex items-center justify-center w-8 h-8 text-lg font-semibold text-custom-light-header dark:text-custom-purple-100 text-white rounded-full"
            name="upload"
            @click="$emit('toggle')"
          />
          <input
            type="file"
            accept="image/*,video/mp4,audio/*,.doc,.docx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.pdf,.txt,.pptx,.ppt, .csv"
            multiple="multiple"
            class="hidden"
            @change="$emit('on-media-change', $event)"
          >
        </label>
        <label class="w-10 text-center flex flex-col items-center justify-center">
          <Icon
            class="flex items-center justify-center w-8 h-8 text-lg font-semibold text-custom-light-header dark:text-custom-purple-100 text-white rounded-full"
            name="at"
            @click.native="$emit('on-user-tag', $event)"
          />
        </label>
        <label
          v-if="companyAttachament"
          class="w-10 text-center flex flex-col items-center justify-center"
        >
          <Icon
            class="flex items-center justify-center w-8 h-8 text-lg font-semibold text-custom-light-header dark:text-custom-purple-100 text-white rounded-full"
            name="dollar"
            @click.native="$emit('on-company-tag', $event)"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttachMediaMenuMinified',
  props: {
    companyAttachament: { type: Boolean, default: true }
  }
}
</script>
