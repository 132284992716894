<template>
  <div class="relative">
    <Bubble
      v-if="!hideRemoveButton"
      small
      background="bg-white"
      color="text-custom-orange-400 absolute -top-1 -right-1 z-20"
      @click.native="$emit('remove-attachment',payload)"
    >
      <Icon
        name="x-circle"
        class="text-xl"
      />
    </Bubble>
    <BaseCard
      padding="pr-2 py-1"
      :background="background"
      border="border dark:border-custom-purple-400"
      shadow="shadow-none"
      class="w-full"
    >
      <template #body>
        <div
          class="w-full flex items-center text-2xs relative"
        >
          <p class="font-semibold p-1 pl-3">
            <Icon
              name="paperclip"
              class="text-xl"
            />
          </p>
          <div
            v-if="payload.linkable_type === 'App\\Models\\CompanyTrigger' || payload.linkable.linkable_type === 'App\\Models\\CompanyTrigger'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ (payload.linkable) ? payload.linkable.linkable.company.name : payload.company.name }} > Trigger
            </p>
            <p class="font-semibold">
              {{ payload.linkable.title || payload.title }}
            </p>
          </div>
          <div
            v-if="payload.linkable_type === 'App\\Models\\InsiderTrade'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ payload.linkable.company.name }} > {{ $t('Insider trades') }}
            </p>
            <div>
              <p
                class="text-3xs"
              >
                ({{ toDate(payload.linkable.publish_date) }})
              </p>
              <p class="font-semibold">
                {{ payload.linkable.person }} - {{ payload.linkable.type }} <template v-if="payload.linkable.status === 'Reviderad'">
                  (Reviderad)
                </template>
              </p>
              <p
                class="text-3xs font-semibold"
              >
                ({{ payload.linkable.occupation.indexOf('Annan') > -1 ? 'Annan' : payload.linkable.occupation }})
              </p>

              <p>
                {{ payload.linkable.price }} {{ payload.linkable.currency }} /
                {{ parseFloat(payload.linkable.volume).toLocaleString('en-US', {maximumFractionDigits: 2}).replace(/,/g, ' ') }} {{ payload.linkable.volume_unit }}
              </p>
            </div>
          </div>
          <div
            v-if="payload.linkable_type === 'App\\Models\\CompanyAsset' || payload.linkable.linkable_type === 'App\\Models\\CompanyAsset'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ (payload.linkable) ? payload.linkable.linkable.company.name : payload.company.name }} > Asset
            </p>
            <p class="font-semibold">
              {{ payload.linkable.name || payload.name }}
            </p>
            <p>{{ payload.linkable.description || payload.description }}</p>
          </div>
          <div
            v-if="payload.linkable_type === 'App\\Models\\CompanyBoardMember' || payload.linkable.linkable_type === 'App\\Models\\CompanyBoardMember'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ (payload.linkable) ? payload.linkable.linkable.company.name : payload.company.name }} > Board Member
            </p>
            <p class="font-semibold">
              {{ payload.linkable.full_name || payload.full_name }}
            </p>
            <p>{{ payload.linkable.title || payload.title }}</p>
          </div>
          <div
            v-if="payload.linkable_type === 'App\\Models\\CompanyContact' || payload.linkable.linkable_type === 'App\\Models\\CompanyContact'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ (payload.linkable) ? payload.linkable.linkable.company.name : payload.company.name }} > Contact
            </p>
            <p class="font-semibold">
              {{ payload.linkable.full_name || payload.full_name }}
            </p>
            <p>{{ payload.linkable.title || payload.title }}</p>
          </div>
          <div
            v-if="payload.linkable_type === 'App\\Models\\CompanyPressRelease' || payload.linkable.linkable_type === 'App\\Models\\CompanyPressRelease'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ (payload.linkable) ? payload.linkable.linkable.company.name : payload.company.name }} > Press Release
            </p>
            <p class="font-semibold">
              {{ payload.linkable.headline || payload.headline }}
            </p>
            <p class="">
              {{ diffFromNow(payload.linkable.external_timestamp) }}
            </p>
            <!-- <p :class="'text-2xs'">
              <span v-if="payload.linkable.content.length > 50"><span v-html="payload.linkable.content.slice(0, 50)" /></span>
              <span v-else><span v-html="payload.linkable.content" /></span>
            </p> -->
          </div>
          <div
            v-if="payload.linkable_type === 'App\\Models\\Media'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ (payload.linkable) ? payload.linkable.company.name : payload.company.name }} > Media
            </p>
            <Thumbnail
              v-if="payload.linkable.mediable.image"
              :src="payload.linkable.mediable.media_gallery[0].src"
            />
            <BaseAudio
              v-if="payload.linkable.mediable.audio"
              :src="payload.linkable.mediable.media_gallery[0].src"
            />
            <BaseDocument
              v-if="payload.linkable.mediable.document"
              :src="payload.linkable.mediable.media_gallery[0].src"
              :type="payload.linkable.mediable.media_gallery[0].type"
              :doc-name="payload.linkable.mediable.media_gallery[0].filename"
            />
            <BaseVideo
              v-if="payload.linkable.mediable.video"
              :controls="false"
              :src="payload.linkable.mediable.media_gallery[0].src"
            />
          </div>
        </div>
      </template>
    </BaseCard>
  </div>
</template>

<script>
import { format } from 'date-fns'
import time from '@/js/helpers/time'

export default {
  name: 'AttachmentList',
  props: {
    payload: { type: Object, default: () => {} },
    hideRemoveButton: { type: Boolean, default: false },
    showCompanyName: { type: Boolean, default: false },
    background: { type: String, default: 'bg-white rounded rounded-lg dark:bg-custom-purple-600' }
  },
  methods: {
    toDate (datetime) {
      return format(new Date(datetime), 'yyyy-MM-dd kk:mm')
    },
    diffFromNow (datetime) {
      if (datetime) {
        return time.diffFromNow(datetime)
      }
    }
  }
}
</script>
