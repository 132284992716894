import {
  RepositoryFactory
} from '@/api/repositoryFactory'
import mentions from '@/js/helpers/mentions'
import { i18n } from '../js/language'
const repository = RepositoryFactory.get('posts')

export default {
  state: {
    type: 'general-feed',
    loading: false,
    loadingNewPost: false,
    addPostLoading: false,
    pollLoading: false,
    selectedPost: '',
    nextPageUrl: {},
    posts: {},
    loadingPostMenu: false,
    editableId: null,
    selectedPostId: '',
    loadingSponsored: false
  },
  getters: {
    loadingSponsored: state => state.loadingSponsored,
    sponsoredPosts: state => state.posts['sponsored'],
    posts: (state, rootState, rootGetters) => {
      if (!state.posts || !state.posts[state.type]) {
        return []
      }
      let posts = [...state.posts[state.type]]
      // if (rootGetters.filters.filters && rootGetters.filters.filters.types) {
      // posts = posts.filter(post => rootGetters.filters.filters.types.includes(post.postable_type))
      // }
      // if (rootGetters.filters.filters.companiesOnly) {
      //   posts = posts.filter(post => (post.company))
      // }
      if (rootGetters.filters.filters.company_id) {
        posts = posts.filter(post => post.company && post.company.id === rootGetters.filters.filters.company_id)
      }
      if (rootGetters.filters.filters.stock_user_id) {
        posts = posts.filter(post => post.stock_user_id === rootGetters.filters.filters.stock_user_id)
      }
      return posts
    },
    allPost: state => state.posts[state.type],
    pinnedPost: state => state.posts['pinned'],
    postsWithoutTypes: state => state.posts,
    // nextPageUrl: state => state.nextPageUrl,
    nextPageUrl (state, getters) {
      return state.nextPageUrl[state.type]
    },
    hasPosts: (state, getters) => {
      return getters.posts.length > 0
    },
    loading: state => state.loading,
    type: state => state.type,
    loadingNewPost: state => state.loadingNewPost,
    addPostLoading: state => state.addPostLoading,
    pollLoading: state => state.pollLoading,
    loadingPostMenu: state => state.loadingPostMenu,
    editableId: state => state.editableId
  },
  mutations: {
    RESET_POST_TYPE (state) {
      state.posts[state.type] = [...[]]
    },
    SET_POSTS (state, payload) {
      payload.filter(post => {
        if (post.postable_type === 'App\\Models\\Share') {
          if (post.postable.shareable.postable && (typeof post.postable.shareable.postable.message === 'string' || post.postable.shareable.postable.message instanceof String)) {
            post.postable.shareable.postable.message = mentions.parse(post.postable.shareable.postable.message, post.postable.shareable.message.mentions)
          }
        }
        if (post.postable && post.postable.mediable && (typeof post.postable.mediable.message === 'string' || post.postable.mediable.message instanceof String) && post.postable.mediable.message.match(mentions.MATCHING)) {
          post.postable.mediable.message = mentions.parse(post.postable.mediable.message, post.postable.message.mentions)
        }
        if (post.postable && post.postable.message && (typeof post.postable.message === 'string' || post.postable.message instanceof String) && post.postable.message.match(mentions.MATCHING)) {
          post.postable.message = mentions.parse(post.postable.message, post.message.mentions)
        }
      })
      const type = (payload.type) ? payload.type : state.type
      state.posts = { ...state.posts, [type]: [...payload] }
      state.posts = { ...state.posts }
    },
    SET_SPONSORED_POSTS (state, payload) {
      payload.filter(post => {
        if (post.postable_type === 'App\\Models\\Share') {
          if (post.postable.shareable.postable && (typeof post.postable.shareable.postable.message === 'string' || post.postable.shareable.postable.message instanceof String)) {
            post.postable.shareable.postable.message = mentions.parse(post.postable.shareable.postable.message, post.postable.shareable.message.mentions)
          }
        }
        if (post.postable && post.postable.mediable && (typeof post.postable.mediable.message === 'string' || post.postable.mediable.message instanceof String) && post.postable.mediable.message.match(mentions.MATCHING)) {
          post.postable.mediable.message = mentions.parse(post.postable.mediable.message, post.postable.message.mentions)
        }
        if (post.postable && post.postable.message && (typeof post.postable.message === 'string' || post.postable.message instanceof String) && post.postable.message.match(mentions.MATCHING)) {
          post.postable.message = mentions.parse(post.postable.message, post.message.mentions)
        }
      })
      state.posts = { ...state.posts, 'sponsored': [...payload] }
      state.posts = { ...state.posts }
    },
    APPEND_SPONSORED_POSTS (state, payload) {
      if (payload.post) {
        payload = payload.post
      }
      payload.filter(post => {
        if (post.postable !== undefined) {
          if (post.postable_type === 'App\\Models\\Share') {
            if (post.postable.shareable.postable && (typeof post.postable.shareable.postable.message === 'string' || post.postable.shareable.postable.message instanceof String)) {
              post.postable.shareable.postable.message = mentions.parse(post.postable.shareable.postable.message, post.postable.shareable.message.mentions)
            }
          } else if (post.postable_type === 'App\\Models\\Media') {
            if (post.postable.mediable.message && (typeof post.postable.mediable.message === 'string' || post.postable.mediable.message instanceof String) && post.postable.mediable.message.match(mentions.MATCHING)) {
              post.postable.mediable.message = mentions.parse(post.postable.mediable.message, post.postable.message.mentions)
            }
          } else if (post.postable && post.postable.message && (typeof post.postable.message === 'string' || post.postable.message instanceof String) && post.postable.message.match(mentions.MATCHING)) {
            post.postable.message = mentions.parse(post.postable.message, post.message.mentions)
          }
        } else {
          return true
        }
      })

      payload.find(response => {
        const flag = state.posts[state.type].findIndex(post => {
          if (post.advertise_id !== undefined) {
            return false
          } else {
            return Number(response.id) === Number(post.id)
          }
        })
        if (flag > -1) {
          state.posts['sponsored'][flag] = { ...response }
        } else {
          state.posts['sponsored'].push(response)
        }
      })

      state.posts = { ...state.posts }
    },
    SET_PINNED_POSTS (state, post) {
      if (post.postable && post.postable.mediable && (typeof post.postable.mediable.message === 'string' || post.postable.mediable.message instanceof String) && post.postable.mediable.message.match(mentions.MATCHING)) {
        post.postable.mediable.message = mentions.parse(post.postable.mediable.message, post.postable.message.mentions)
      }
      if (post.postable && post.postable.message && (typeof post.postable.message === 'string' || post.postable.message instanceof String) && post.postable.message.match(mentions.MATCHING)) {
        post.postable.message = mentions.parse(post.postable.message, post.message.mentions)
      }
      state.posts = { ...state.posts, 'pinned': [...post] }
      state.posts = { ...state.posts }
    },
    SET_EDITABLE_ID (state, id) {
      state.editableId = id
    },
    UPDATE_POST (state, payload) {
      let posts = [...state.posts[state.type]]
      let postIndex = posts.findIndex(p => p.id === payload.id)
      posts[postIndex] = payload
      state.posts[state.type] = [...posts]
      state.posts = { ...state.posts }

      if (state.posts['general-feed']) {
        let postsGeneral = [...state.posts['general-feed']]
        postIndex = posts.findIndex(p => p.id === payload.id)
        console.log('postIndex', postIndex)
        if (postIndex) {
          postsGeneral[postIndex] = payload
          state.posts['general-feed'] = [...postsGeneral]
          state.posts = { ...state.posts }
        }
      }
    },
    APPEND_POSTS (state, payload) {
      if (payload.post) {
        payload = payload.post
      }
      payload.filter(post => {
        if (post.postable !== undefined) {
          if (post.postable_type === 'App\\Models\\Share') {
            if (post.postable.shareable.postable && (typeof post.postable.shareable.postable.message === 'string' || post.postable.shareable.postable.message instanceof String)) {
              post.postable.shareable.postable.message = mentions.parse(post.postable.shareable.postable.message, post.postable.shareable.message.mentions)
            }
          } else if (post.postable_type === 'App\\Models\\Media') {
            if (post.postable.mediable.message && (typeof post.postable.mediable.message === 'string' || post.postable.mediable.message instanceof String) && post.postable.mediable.message.match(mentions.MATCHING)) {
              post.postable.mediable.message = mentions.parse(post.postable.mediable.message, post.postable.message.mentions)
            }
          } else if (post.postable && post.postable.message && (typeof post.postable.message === 'string' || post.postable.message instanceof String) && post.postable.message.match(mentions.MATCHING)) {
            post.postable.message = mentions.parse(post.postable.message, post.message.mentions)
          }
        } else {
          return true
        }
      })

      payload.find(response => {
        const flag = state.posts[state.type].findIndex(post => {
          if (post.advertise_id !== undefined) {
            return false
          } else {
            return Number(response.id) === Number(post.id)
          }
        })
        if (flag > -1) {
          state.posts[state.type][flag] = { ...response }
        } else {
          state.posts[state.type].push(response)
        }
      })

      state.posts = { ...state.posts }
    },
    SET_TYPE (state, type) {
      state.type = type
    },
    SET_LOADING_SPONSORED_POSTS (state, boolean) {
      state.loadingSponsored = boolean
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    ADD_POST_LOADING (state, boolean) {
      state.addPostLoading = boolean
    },
    SET_PAGE_URL (state, payload) {
      state.nextPageUrl = { ...state.nextPageUrl, [state.type]: payload }
    },
    SET_LOADING_NEW (state, boolean) {
      state.loadingNewPost = boolean
    },
    SET_LOADING_POST_MENU (state, boolean) {
      state.loadingPostMenu = boolean
    },
    SET_POLL_ANSWERS (state, payload) {
      const index = state.posts[state.type].findIndex(post => {
        return post.postable_id === payload.id
      })
      state.posts[state.type][index].postable.answers = payload.answers
    },
    SET_POLL_LOADING (state, boolean) {
      state.pollLoading = boolean
    },
    DELETE_POST (state, payload) {
      state.posts[state.type].splice(state.posts[state.type].findIndex(post => post.id === payload), 1)
    }
  },
  actions: {
    resetNextUrl ({ commit }) {
      commit('SET_PAGE_URL', null)
    },
    async fetch ({ commit, rootState, state, dispatch }, override = false) {
      if ((!state.posts || !state.posts[state.type] || state.posts[state.type].length === 0) || override) {
        commit('SET_PAGE_URL', null)
        commit('SET_POSTS', [])
        try {
          commit('SET_LOADING', true)
          const response = await repository.list({ ...rootState.filters.filters, filter: rootState.filters.sorting })
          commit('SET_LOADING', false)
          if ((response.status === 200) && response.data) {
            commit('SET_POSTS', response.data.data)
            commit('SET_PAGE_URL', response.data.next_page_url)
          }
        } catch (e) {
          commit('SET_LOADING', false)
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    async fetchPost ({ commit, dispatch, state }, payload) {
      try {
        // console.log('type omg', state.type)
        // if (state.posts && state.posts[state.type] && state.posts[state.type].findIndex(post => post.id === postId) > -1) {
        // commit('SET_LOADING', false)
        // } else {
        // commit('RESET_POST_TYPE')
        if (payload.checkState === true && state.posts && state.posts[state.type]) {
          let post = state.posts['general-feed'].find(post => { return post.id === payload.postId })

          commit('SET_POSTS', [post])
        } else {
          if (!payload.silent) {
            commit('SET_LOADING', true)
          }
          const response = await repository.fetchPost(payload.postId)
          if (response.status === 200 && response.data) {
            if (!payload.silent) {
              commit('SET_LOADING', false)
              commit('SET_POSTS', [response.data])
            } else {
              commit('UPDATE_POST', response.data)
            }
          }
        }
        // }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    // async fetchSponsoredPosts ({ commit, dispatch }) {
    //   try {
    //     console.log('inside store')
    //     commit('SET_LOADING_SPONSORED_POSTS', true)
    //     const response = await repository.fetchSponsoredPosts()
    //     if (response.status === 200 && response.data) {
    //       commit('SET_SPONSORED_POSTS', response.data.data)
    //     }
    //     commit('SET_LOADING_SPONSORED_POSTS', false)
    //   } catch (e) {
    //     commit('SET_LOADING_SPONSORED_POSTS', false)
    //     dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
    //   }
    // },
    async sponsoredPost ({ commit, dispatch, state }, postId) {
      try {
        console.log('inside store')
        // commit('SET_LOADING', true)
        const response = await repository.sponsoredPost(postId)
        // commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('APPEND_POSTS', [response.data])
          return true
        } else if (response.status === 201) {
          return false
        }
      } catch (e) {
        // commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async unPinPost ({ commit, dispatch, state }, postId) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.unpinPost(postId)
        if (response.status === 200 && response.data) {
          commit('SET_LOADING', false)
        }
        // }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchPinnedPost ({ commit, dispatch, state }) {
      try {
        if (state.posts && state.posts['pinned'] && state.posts['pinned'].findIndex(post => post.pinned)) {
        } else {
          // commit('RESET_POST_TYPE')
          const response = await repository.fetchPinnedPost()
          if (response.status === 200 && response.data) {
            commit('SET_PINNED_POSTS', response.data)
          }
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editPost ({ commit, dispatch }, payload) {
      try {
        commit('SET_EDITABLE_ID', payload.postId)
        const response = await repository.update(payload.postId, payload.data)
        commit('SET_EDITABLE_ID', null)

        if (response.status === 200) {
          commit('APPEND_POSTS', [response.data])

          dispatch('snackbar/snack', { text: i18n.$t('Post updated successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
        commit('ADD_POST_LOADING', false)
      } catch (e) {
        commit('ADD_POST_LOADING', false)
        commit('SET_EDITABLE_ID', null)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async savePost ({ commit, dispatch }, postId) {
      try {
        const response = await repository.savePost(postId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: i18n.$t('Post created successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    postLoading ({ commit }, boolean) {
      commit('ADD_POST_LOADING', boolean)
    },
    setType ({ commit }, type) {
      commit('SET_TYPE', type)
    },
    async refetch ({ commit, rootState, state, dispatch }) {
      try {
        if (!state.loading) {
          commit('ADD_POST_LOADING', true)
          const response = await repository.list({ ...rootState.filters.filters, filter: rootState.filters.sorting })
          commit('ADD_POST_LOADING', false)
          if ((response.status === 200) && response.data) {
            commit('SET_POSTS', response.data.data)
            commit('SET_PAGE_URL', response.data.next_page_url)
          }
        }
      } catch (e) {
        commit('ADD_POST_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async scroll ({ commit, getters, rootState, state, dispatch }) {
      // console.log('getters', getters)
      try {
        if (getters.nextPageUrl !== null && !state.loadingNewPost && !state.loading) {
          commit('SET_LOADING_NEW', true)
          const response = await repository.scroll(getters.nextPageUrl, { ...rootState.filters.filters, filter: rootState.filters.sorting })
          if ((response.status === 200) && response.data) {
            commit('APPEND_POSTS', response.data.data)
            commit('SET_PAGE_URL', response.data.next_page_url)
          }
          commit('SET_LOADING_NEW', false)
        }
      } catch (e) {
        commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async postFilter ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.filter(payload)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_POSTS', response.data.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    setAllowedTypes ({
      commit
    }, payload) {
      commit('SET_ALLOWED_TYPES', payload)
    },
    setFilter ({
      commit
    }, payload) {
      commit('SET_FILTER', payload)
    },
    async saveOption ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_POLL_LOADING', true)
        const response = await repository.saveOption(payload.post_poll_id, {
          options: JSON.stringify(payload.options)
        })
        if (response.status === 200) {
          commit('SET_POLL_ANSWERS', response.data)
          dispatch('snackbar/snack', { text: 'Options added successfully', type: 'success', show: true, autoclose: true }, { root: true })
          commit('SET_POLL_LOADING', false)
        }
      } catch (e) {
        commit('SET_POLL_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pollAnswer ({ commit, state, rootState, dispatch }, payload) {
      try {
        const stockUser = rootState.users.user.profile
        let removeIndex

        const type = (payload.type) ? payload.type : state.type

        const postIndex = state.posts[type].findIndex(post => {
          if (post.postable.answers) {
            return post.postable_id === payload.post_poll_id
          }
        })

        const pollIndex = state.posts[type][postIndex].postable.answers.findIndex(answer => {
          return answer.id === payload.answer_id
        })

        const answerIndex = state.posts[type][postIndex].postable.answers.findIndex(answer => {
          if (answer.voters) {
            removeIndex = answer.voters.findIndex(voter => {
              return voter.id === stockUser.id
            })
          }
          return (removeIndex > -1)
        })

        if (state.posts[type][postIndex].postable.answers[answerIndex] !== undefined) {
          state.posts[type][postIndex].postable.answers[answerIndex].voters.splice(removeIndex, 1)
        }

        state.posts[type][postIndex].postable.answers[pollIndex].voters.push({
          companies_count: stockUser.companies_count,
          created_at: stockUser.created_at,
          username: stockUser.username,
          full_name: stockUser.full_name,
          id: stockUser.id,
          image: stockUser.image,
          pivot: {
            poll_answer_id: payload.answer_id,
            stock_user_id: stockUser.id
          },
          posts_count: stockUser.posts_count,
          reputation: stockUser.reputation
        })

        const response = await repository.saveAnswer(payload.post_poll_id, payload.answer_id)
        if (response.status === 200) {
          // dispatch('snackbar/snack', { text: 'Answer saved successfully.', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareLike ({ commit, state, rootState, dispatch }, payload) {
      try {
        const parentPost = state.posts[state.type].find(timeline => timeline.id === payload.parent_id)
        const post = parentPost.postable.shareable

        const likeIndex = post.likes.findIndex(like => (like.stock_user_id === rootState.users.user.profile_id && like.reaction === payload.reaction))

        if (likeIndex > -1) {
          post.likes.splice(likeIndex, 1)
        } else {
          post.likes.push({
            likeable_id: payload.post_id,
            reaction: payload.reaction,
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile.id
          })
        }

        const response = await repository.postLike(payload.post_id, {
          reaction: payload.reaction
        })
        if (response.status === 204) {
          // dispatch('snackbar/snack', { text: 'Post added to liked list', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async like ({ commit, state, rootState, dispatch }, payload) {
      try {
        const type = (payload.type) ? payload.type : state.type

        const post = state.posts[type].find(timeline => Number(timeline.id) === Number(payload.post_id))

        const likeIndex = post.likes.findIndex(like => (Number(like.stock_user_id) === Number(rootState.users.user.profile_id) && like.reaction === payload.reaction))

        if (likeIndex > -1) {
          post.likes.splice(likeIndex, 1)
        } else {
          post.likes.push({
            likeable_id: payload.post_id,
            reaction: payload.reaction,
            stock_user: rootState.users.user.profile,
            stock_user_id: rootState.users.user.profile.id
          })
        }

        const response = await repository.postLike(payload.post_id, {
          reaction: payload.reaction
        })
        if (response.status === 204) {
          // const post = state.posts[type].find(timeline => Number(timeline.id) === Number(payload.post_id))
          // const likeIndex = post.likes.findIndex(like => (like.stock_user.id === rootState.users.user.profile_id && like.reaction === payload.reaction))

          // if (likeIndex > -1) {
          //   post.likes.splice(likeIndex, 1)
          //   console.log('liked, remove')
          // } else {
          //   post.likes.push({
          //     likeable_id: payload.post_id,
          //     reaction: payload.reaction,
          //     stock_user: rootState.users.user.profile,
          //     stock_user_id: rootState.users.user.profile.id
          //   })
          //   console.log('not liked, add')
          // }
          // dispatch('snackbar/snack', { text: 'Post added to liked list', type: 'success', show: true, autoclose: true }, { root: true })
        }

        state.posts = { ...state.posts }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithAll ({ commit, dispatch }, payload) {
      try {
        const response = await repository.shareWithAll(payload.post_id, {
          message: payload.message
        })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Post shared.', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async shareWithCompany ({ commit, dispatch }, payload) {
      try {
        const response = await repository.shareWithCompany(payload.post_id, payload.company_id, {
          message: payload.message
        })
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Post shared.', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pushPost ({ commit, dispatch }, post) {
      try {
        commit('APPEND_POSTS', [post])
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addPost ({ commit, state, dispatch }, payload) {
      try {
        commit('ADD_POST_LOADING', true)
        const response = await repository.store(payload)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Post created successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async report ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING_POST_MENU', true)
        const response = await repository.reportPost(payload)

        commit('SET_LOADING_POST_MENU', false)
        if (response.status === 200) {
          dispatch('snackbar/snack', { text: 'Message reported successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_LOADING_POST_MENU', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async delete ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_LOADING_POST_MENU', true)
        const response = await repository.deletePost(payload)

        commit('SET_LOADING_POST_MENU', false)
        if (response.status === 204) {
          commit('DELETE_POST', payload)
          dispatch('snackbar/snack', { text: 'Message deleted successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_LOADING_POST_MENU', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.posts = { ...{} }
    }
  },
  namespaced: true
}
