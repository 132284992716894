<template>
  <div
    class="flex font-semibold justify-end px-1 py-1 rounded-lg"
    :class="secondary ? 'bg-custom-purple-400 dark:bg-custom-purple-600' : 'bg-gray-200 dark:bg-custom-purple-400'"
  >
    <CurrencyBadge
      first
      :active="active === 'in-universe'"
      :secondary="secondary"
      @click="$emit('choose-universe', 'in-universe')"
    >
      Eucaps Universe
    </CurrencyBadge>
    <CurrencyBadge
      :active="active === 'all'"
      :secondary="secondary"
      @click="$emit('choose-universe', 'all')"
    >
      Global
    </CurrencyBadge>
  </div>
</template>

<script>

export default {
  name: 'UniverseMenu',
  props: {
    active: {
      type: String,
      default: 'in-universe'
    },
    secondary: {
      type: Boolean,
      default: false
    }
  }
}
</script>
