<template>
  <RouterLink
    :to="isLoggedIn ? {name: 'generalfeed'} : '/'"
    class="flex flex-col items-center relative"
  >
    <Logo class="mb-2" />
    <!-- <span class="absolute left-11 -bottom-2 text-white font-extrabold">BETA TEST</span> -->
  </RouterLink>
</template>

<script>

export default {
  name: 'SmallLogo',
  props: {
    color: { default: 'white', type: String },
    isLoggedIn: { default: false, type: Boolean }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  height: 1.875rem;
}
</style>
