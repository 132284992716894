<template>
  <div
    class="flex items-center bx"
    :class="extendedClass"
    @click="locked? false: $emit('click', $event)"
  />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: ''
    },
    solid: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'regular'
    },
    bold: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    extendedClass () {
      let extendedClass = ''
      let icon = 'bx'

      if (this.solid) {
        icon += 's'
      }
      if (this.type === 'logo') {
        icon += 'l'
      }

      if (this.bold) {
        extendedClass = 'font-bold'
      }

      return extendedClass + ' ' + icon + '-' + this.name
    }
  }
}
</script>
