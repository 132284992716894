var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full relative"},[_c('div',{staticClass:"absolute inset-0 bg-center bg-no-repeat",class:[_vm.type, _vm.backgroundColor, _vm.backgroundColor !== '' ? 'p-4' : ''],style:(_vm.backgroundColor ? '' : 'background-image: url(' + _vm.src + ')')},[_c('div',{staticClass:"w-full h-full bg-center bg-no-repeat",class:[_vm.type],style:(_vm.backgroundColor ? 'background-image: url(' + _vm.src + ')' : '')})]),_c('div',{class:{
      'aspect-ratio-none': _vm.ratio === 'none',
      'aspect-ratio-square': _vm.ratio === 'square',
      'aspect-ratio-16/9': _vm.ratio === '16/9',
      'aspect-ratio-16/7': _vm.ratio === '16/7',
      'aspect-ratio-16/5': _vm.ratio === '16/5',
      'aspect-ratio-16/4': _vm.ratio === '16/4',
      'aspect-ratio-16/3': _vm.ratio === '16/3',
      'aspect-ratio-4/3': _vm.ratio === '4/3',
      'aspect-ratio-6/3': _vm.ratio === '6/3',
      'aspect-ratio-4/6': _vm.ratio === '4/6',
      'aspect-ratio-21/9': _vm.ratio === '21/9'
    }})])}
var staticRenderFns = []

export { render, staticRenderFns }