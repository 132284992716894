import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('companies')

export default {
  state: {
    documents: [],
    loading: false,
    newLoading: false
  },
  getters: {
    loading: state => state.loading,
    documents: state => state.documents,
    newLoading: state => state.newLoading
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LIST (state, payload) {
      state.documents = payload
    },
    SET_NEW_LOADING (state, boolean) {
      state.newLoading = boolean
    },
    PUSH_DOCUMENTS (state, payload) {
      state.documents = state.documents.push(payload)
    }
  },
  actions: {
    async fetch ({ commit, state, dispatch, rootState }, companyUrl) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.documentList(companyUrl)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
      }
    },
    async save ({ commit, rootState, dispatch }, documentId) {
      try {
        const response = await repository.saveDocument(rootState.companies.selectedCompanyId, documentId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Document added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async refetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_NEW_LOADING', true)
        const response = await repository.documentList(rootState.companies.selectedCompanySN)
        commit('SET_NEW_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_NEW_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pushDocuments ({ commit, state }, payload) {
      commit('PUSH_DOCUMENTS', [payload])
    },
    async addDocument ({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.addDocument(payload.companyUrl, payload.formData)
        if (response.status === 200 && response.data) {
          commit('SET_LIST', response.data)
          commit('SET_LOADING', false)
          // dispatch('snackbar/snack', { text: 'Document added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.documents = {}
    }
  },
  namespaced: true
}
