<template>
  <button
    class="
    buttonClass
    bg-custom-purple-200
    py-15
    flex
    flex-col
    items-center
    justify-around
    border
    "
    @click="$emit('click', $event)"
  >
    <img
      class="svgClass"
      :src="src"
    >
    <div class="text-2xl text-center mt-8">
      <h1>{{ catagory }}</h1>
      <p class="text-sm font-normal">
        {{ number }} stocks
      </p>
    </div>
  </button>
</template>

<script>
export default {
  name: 'CatagoryBox',
  props: {
    src: { type: String, default: '' },
    number: { type: String, default: '' },
    catagory: { type: String, default: '' }
  }
}
</script>
<style scoped>
.buttonClass {
  transition: 0.7s;
}
.buttonClass:hover {
  background-color: #ddcae6;
  z-index: 30;
  transform: scale(1.2);
}
.svgClass {
  transition: 1s;
}
.svgClass:hover {
  filter: invert(39%) sepia(73%) saturate(1725%) hue-rotate(347deg) brightness(99%) contrast(90%);
  transform: rotate(360deg);
}
</style>
