import { RepositoryFactory } from '@/api/repositoryFactory'
import { Capacitor } from '@capacitor/core'
import { isIphone } from '../js/helpers/mobile'
const repository = RepositoryFactory.get('creditCheck')

export default {
  state: {
    loading: false,
    downloadLoading: false,
    selectedCompanyReport: null,
    selectedCompaniesToBuyCreditReport: [],
    myCreditReportList: []
  },
  getters: {
    loading: (state) => state.loading,
    downloadLoading: (state) => state.downloadLoading,
    selectedCompanyReport: (state) => state.selectedCompanyReport,
    selectedCompaniesToBuyCreditReport: (state) => state.selectedCompaniesToBuyCreditReport,
    selectedCompaniesIdToBuyCreditReport: (state) => state.selectedCompaniesToBuyCreditReport.map((company) => company.id),
    myCreditReportList: (state) => state.myCreditReportList
  },
  mutations: {
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_DOWNLOAD_LOADING (state, payload) {
      state.downloadLoading = payload
    },
    SET_SELECTED_COMPANY_REPORT (state, payload) {
      state.selectedCompanyReport = payload
    },
    SET_SELECTED_COMPANIES_TO_BUY_CREDIT_REPORT (state, payload) {
      state.selectedCompaniesToBuyCreditReport = payload
    },
    SET_MY_CREDIT_REPORT_LIST (state, payload) {
      state.myCreditReportList = payload
    }
  },
  actions: {
    setCompaniesToBuyCreditReport ({ commit }, payload) {
      commit('SET_SELECTED_COMPANIES_TO_BUY_CREDIT_REPORT', payload)
    },
    async fetchSelectedCompanyReport ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.getCompanyCreditReport(payload)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data !== null) {
          commit('SET_SELECTED_COMPANY_REPORT', response.data)
        } else {
          commit('SET_SELECTED_COMPANY_REPORT', null)
        }
      } catch (err) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    },
    async downloadReport ({ commit, dispatch, rootGetters }, payload) {
      try {
        commit('SET_DOWNLOAD_LOADING', true)
        const response = await repository.getCompanyCreditReportPdf({
          correlationId: payload.correlationId,
          isPremium: payload.isPremium
        })
        commit('SET_DOWNLOAD_LOADING', false)
        if (response.data) {
          const href = response.data.link

          const link = document.createElement('a')
          link.href = href

          const filenameOfPdf = (payload.subtitle) ? payload.fileName + '-' + payload.subtitle : payload.fileName

          if (((Capacitor.getPlatform() === 'ios') || isIphone())) {
            link.download = 'Credit Report ' + filenameOfPdf + '.pdf'
            link.click()
            link.remove()
          } else if (rootGetters['app/isNative']) {
            window.open(href)
          } else {
            window.location.href = href
          }
        }
      } catch (err) {
        commit('SET_DOWNLOAD_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    },
    async getCreditReportCpp ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.buyCreditReport(payload)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          if (response.data.checkoutSessionId && response.data.stripeKey && !response.data.correlationId) {
            await window.Stripe(response.data.stripeKey).redirectToCheckout({ sessionId: response.data.checkoutSessionId })
          } else if (response.data.correlationId && !response.data.checkoutSessionId && !response.data.stripeKey) {
            await dispatch('downloadReport', {
              correlationId: response.data.correlationId,
              isPremium: response.data.isPremium,
              fileName: payload.fileName
            })
            dispatch('snackbar/snack', { type: 'success', text: 'CREDIT REPORT DOWNLOADED SUCCESSFULLY', show: true, autoclose: true }, { root: true })
            dispatch('fetchSelectedCompanyReport', { companyId: payload.companyId })
          }
        } else if (response.status === 204) {
          dispatch('snackbar/snack', { type: 'info', text: 'CREDIT REPORT NOT AVAILABLE', show: true, autoclose: true }, { root: true })
        }
      } catch (err) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    },
    async getCreditReportMarketplace ({ commit, dispatch, rootGetters }, payload) {
      try {
        commit('SET_LOADING', true)
        const data = {
          companyId: payload.companies[0],
          returnUrl: 'marketplace',
          isNative: rootGetters['app/isNative']
        }
        const response = await repository.buyCreditReport(data)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          if (response.data.checkoutSessionId && response.data.stripeKey && !response.data.correlationId) {
            await window.Stripe(response.data.stripeKey).redirectToCheckout({ sessionId: response.data.checkoutSessionId })
          } else if (response.data.correlationId && !response.data.checkoutSessionId && !response.data.stripeKey) {
            await dispatch('downloadReport', { correlationId: response.data.correlationId, isPremium: response.data.isPremium })
            dispatch('snackbar/snack', { type: 'success', text: 'CREDIT REPORT DOWNLOADED SUCCESSFULLY', show: true, autoclose: true }, { root: true })
          }
        } else if (response.status === 201) {
          // dispatch('snackbar/snack', { type: 'info', text: response.data, show: true, autoclose: true }, { root: true })
          await dispatch('downloadReport', { correlationId: response.data.correlationId, isPremium: response.data.isPremium, fileName: response.data.fileName, subtitle: response.data.date })
          dispatch('snackbar/snack', { type: 'success', text: 'CREDIT REPORT DOWNLOADED SUCCESSFULLY', show: true, autoclose: true }, { root: true })
        } else if (response.status === 204) {
          dispatch('snackbar/snack', { type: 'info', text: 'CREDIT REPORT NOT AVAILABLE', show: true, autoclose: true }, { root: true })
        }
      } catch (err) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    },
    async validateCheckoutSession ({ commit, dispatch }, payload) {
      console.log(payload)
      try {
        commit('SET_LOADING', true)
        const data = {
          session_id: payload.sessionId
        }
        const response = await repository.validateCheckoutSession(data)
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          if (response.data.success === true) {
            dispatch('snackbar/snack', { type: 'success', text: 'CREDIT REPORT PURCHASED SUCCESSFULLY', show: true, autoclose: true }, { root: true })
            if (payload.companyId) {
              dispatch('fetchSelectedCompanyReport', { companyId: payload.companyId })
            }
            dispatch('downloadReport', { correlationId: response.data.correlationId })
          } else {
            dispatch('snackbar/snack', { type: 'error', text: 'CREDIT REPORT PURCHASE FAILED', show: true, autoclose: true }, { root: true })
          }
        }
      } catch (err) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    },
    async getMyCreditReportList ({ commit, dispatch }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.getMyCreditReportList()
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_MY_CREDIT_REPORT_LIST', response.data)
        }
      } catch (err) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: err, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
