import { get } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/messages`

const list = async () => get(`${url}`)
const fetchMessages = async (id) => get(`${process.env.VUE_APP_API_URL}/users/${id}/messages`)
const scroll = async (link, data) => get(`${link}`, data)

export default {
  list,
  fetchMessages,
  scroll
}
