import { RepositoryFactory } from '@/api/repositoryFactory'
import router from '@/js/router'
import { i18n } from '@/js/language/'

const repository = RepositoryFactory.get('advertises')
export default {
  state: {
    advertisers: [],
    loading: false,
    selectedAdvertiserId: null
  },
  getters: {
    loading: state => state.loading,
    advertisers: state => state.advertisers,
    selectedAdvertiserId: state => state.selectedAdvertiserId,
    selectedAdvertiser (state) {
      return state.advertisers.find(advertiser => {
        return Number(advertiser.id) === Number(state.selectedAdvertiserId)
      })
    },
    selectedAdvertise (state) {
      let advertiser = state.advertisers.find(advertiser => {
        return Number(advertiser.id) === Number(state.selectedAdvertiserId)
      })

      if (advertiser !== undefined && advertiser.advertises !== undefined && advertiser.advertises.length > 0) {
        return advertiser.advertises.find(advertise => {
          return Number(advertise.id) === Number(state.selectedAdvertiseId)
        })
      } else {
        return {}
      }
    }

  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_ADVERTISERS (state, advertisers) {
      state.advertisers = advertisers
    },
    SELECT_ADVERTISER_ID (state, advertiserId) {
      state.selectedAdvertiserId = advertiserId
    },
    SELECT_ADVERTISE_ID (state, advertiseId) {
      state.selectedAdvertiseId = advertiseId
    }
  },
  actions: {
    async fetch ({
      commit, dispatch
    }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchAll()
        if ((response.status === 200) && response.data) {
          commit('SET_ADVERTISERS', response.data.data)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteAdvertiser ({
      commit, dispatch
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.deleteAdvertiser(payload)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: i18n.t('Advertiser deleted successfully'), type: 'success', show: true, autoclose: true }, { root: true })
          router.push({ name: 'advertisers' })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addAdvertiser ({
      commit, dispatch
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.addAdvertiser(payload)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: i18n.t('Advertiser added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
          router.push({ name: 'advertisers' })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editAdvertiser ({
      commit, dispatch
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.editAdvertiser(payload)

        if (response.status === 200) {
          dispatch('fetch')
          dispatch('snackbar/snack', { text: i18n.t('Advertiser edited successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },

    async addAdvertise ({
      commit, dispatch
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.addAdvertise(payload)

        if (response.status === 204) {
          dispatch('snackbar/snack', { text: i18n.t('Advertise added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
          router.push({ name: 'advertiser', params: { id: payload.advertiserId } })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editAdvertise ({
      commit, dispatch
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.editAdvertise(payload)

        if (response.status === 204) {
          dispatch('fetch')
          dispatch('snackbar/snack', { text: i18n.t('Advertise edited successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteAdvertise ({
      commit, dispatch, state
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.deleteAdvertise(payload)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: i18n.t('Advertise deleted successfully'), type: 'success', show: true, autoclose: true }, { root: true })
          router.push({ name: 'advertiser', params: { id: state.selectedAdvertiserId } })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editAdvertiseRecord ({
      commit, dispatch
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.editAdvertiseRecord(payload)

        if (response.status === 204) {
          dispatch('fetch')
          dispatch('snackbar/snack', { text: i18n.t('Advertise Record Updated Successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },

    setSelectAdvertiserId ({ commit }, advertiserId) {
      commit('SELECT_ADVERTISER_ID', advertiserId)
    },
    setSelectAdvertiseId ({ commit }, advertiseId) {
      commit('SELECT_ADVERTISE_ID', advertiseId)
    },
    setLoading ({ commit }, boolean) {
      commit('SET_LOADING', boolean)
    }

  },
  namespaced: true
}
