<template>
  <div
    class="bg-red-100 rounded-lg my-4 text-red-700 border border-red-700 flex items-center"
    :class="padding"
  >
    <FontAwesomeIcon
      icon="exclamation-circle"
      class="text-2xl mr-4"
    /> <div><slot /></div>
  </div>
</template>

<script>

export default {
  name: 'ErrorMessage',
  props: {
    padding: {
      type: String,
      default: 'p-4'
    }
  }
}
</script>
