<template>
  <BaseCard class="rounded-none z-10 overflow-auto shadow-none">
    <template #body>
      <AvatarSlot :stock-user="profile">
        <!-- LANGUAGE -->
        <BaseInput
          :value="message"
          :disabled="disable"
          class="flex-shrink flex-grow rounded-lg text-xs inline-flex"
          :placeholder="placeholder"
          @submit="$emit('submit')"
          @input="$emit('message', $event)"
        />
        <slot />
      </AvatarSlot>
    </template>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AvatarInput',
  props: {
    message: { type: String, default: '' },
    placeholder: { type: String, default: 'Write here' },
    disable: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({
      profile: 'users/profile'
    })
  }
}
</script>
