import { get } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const fetch = async (companySN) => get(`${url}/companies/${companySN}/holders`)
const fetchHolder = async (holderId) => get(`${url}/holders/${holderId}`)
const fetchHolderShares = async (holderId) => get(`${url}/holders/${holderId}/holder-shares`)

export default {
  fetch,
  fetchHolder,
  fetchHolderShares
}
