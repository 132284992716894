import { RepositoryFactory } from '@/api/repositoryFactory'
import { i18n } from '../js/language'
const devcodeID = RepositoryFactory.get('devcode')
const userRepository = RepositoryFactory.get('users')
export default {
  state: {
    loading: false,
    loadingContact: false,
    loadingRequested: false,
    user: null,
    activeTheme: 'light',
    otpVerified: false,
    joinRequested: false,
    salesContacted: false
  },
  getters: {
    activeTheme: state => state.activeTheme,
    user: state => state.user,
    loading: state => state.loading,
    loadingContact: state => state.loadingContact,
    loadingRequested: state => state.loadingRequested,
    otpVerified: state => state.otpVerified,
    joinRequested: state => state.joinRequested,
    salesContacted: state => state.salesContacted
  },
  mutations: {
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_CONTACT_LOADING (state, payload) {
      state.loadingContact = payload
    },
    SET_REQUEST_LOADING (state, payload) {
      state.loadingRequested = payload
    },
    SALES_CONTACTED (state, payload) {
      state.salesContacted = payload
    },
    SET_REQUESTED (state, payload) {
      state.joinRequested = payload
    },
    SET_PROFILE (state, payload) {
      state.user = { ...payload }
    },
    SET_OTPVERIFIED (state, payload) {
      state.otpVerified = payload
    }
  },
  actions: {
    resetPhoneForm ({ commit }) {
      commit('SALES_CONTACTED', false)
    },
    resetJoinRequest ({ commit }) {
      commit('SET_REQUESTED', false)
    },
    async iframe ({ commit, state }, payload) {
      commit('SET_LOADING', true)
      const response = await devcodeID.devcode(payload)
      if (response.status === 200 && response.data) {
        commit('SET_LOADING', false)
        return response.data
      }
    },
    async requestJoin ({ commit, dispatch }, payload) {
      try {
        commit('SET_REQUEST_LOADING', true)
        const response = await userRepository.requestJoin(payload)
        if (response.status === 200 && response.data) {
          commit('SET_REQUESTED', true)
          commit('SET_REQUEST_LOADING', false)
          return response.data
        }
      } catch (e) {
        commit('SET_REQUEST_LOADING', false)
        dispatch('snackbar/snack', { text: i18n.t('Could not send a join request, please try again later'), type: 'error', show: true, autoclose: false }, { root: true })
      }
    },
    async iframeResponse ({ commit, state }, payload) {
      commit('SET_LOADING', true)
      const response = await devcodeID.auth(payload)
      commit('SET_LOADING', false)
      return response
    },
    async otpVerification ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await userRepository.verifyOTP(payload)
        console.log(response)
        if (response.status === 200) {
          commit('SET_LOADING', false)
          if (response.data.status === 'success') {
            commit('SET_OTPVERIFIED', true)
            return 'success'
          } else {
            return response.data
          }
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { text: i18n.t('OTP does not match. Try'), type: 'error', show: true, autoclose: false }, { root: true })
      }
    },
    async sendOTP ({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await userRepository.sendOTP(payload)
        if (response.status === 200) {
          commit('SET_LOADING', false)
          dispatch('snackbar/snack', { text: i18n.t('Code generated successfully. please check out email for the code'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_LOADING', false)

        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async contactSales ({ commit, dispatch }, payload) {
      try {
        commit('SET_CONTACT_LOADING', true)
        const response = await userRepository.contactSales(payload)
        if (response.status === 200) {
          commit('SALES_CONTACTED', true)
          commit('SET_CONTACT_LOADING', false)
          dispatch('snackbar/snack', { text: i18n.t('Code generated successfully. please check out email for the code'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_LOADING', false)

        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async sendPDF ({ commit, dispatch, state, rootState }, payload) {
      try {
        commit('SET_LOADING', true)

        const response = await userRepository.sendPDF(payload)
        if (response.status === 200) {
          commit('SET_LOADING', false)
          return 'success'
        } else {
          console.log(response)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        if (Number(e.response.status) === 422) {
          dispatch('snackbar/snack', { text: i18n.t('Email does not exists in our record. please enter correct email'), type: 'error', show: true, autoclose: false }, { root: true })
        } else {
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    }
  },
  namespaced: true
}
