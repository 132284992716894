<template>
  <ModalContainer
    v-if="showBecomePremium"
    :hide-close="true"
    full-size
    @close="becomePremiumSeen"
  >
    <BecomePremiumModal
      @close="becomePremiumSeen"
      @confirm="confirm"
      @cancel="becomePremiumSeen"
    >
      <slot />
    </BecomePremiumModal>
  </ModalContainer>
</template>
<script>
import { mapGetters } from 'vuex'
import BecomePremiumModal from './_components/BecomePremiumModal.vue'
import ModalContainer from '@/modules/modal'

export default {
  name: 'BecomePremiumModalModule',
  components: {
    BecomePremiumModal,
    ModalContainer
  },

  computed: {
    ...mapGetters({
      showBecomePremium: 'users/showBecomePremium'
    })
  },
  methods: {
    becomePremiumSeen () {
      this.$store.dispatch('users/setShowBecomePremium', false)
    },
    confirm () {
      this.$router.push({ name: 'premium' })
      this.becomePremiumSeen()
    }
  }
}
</script>
