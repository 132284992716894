<template>
  <div
    class="py-1 px-2 rounded-md cursor-pointer"
    :class="[colors, bold ? 'font-bold' : '', first ? '' : 'ml-1']"
    @click="$emit('click', $event)"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'CurrencyBadge',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    first: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colors () {
      if (this.secondary) {
        if (this.active) {
          return 'text-custom-purple-500 bg-gray-100 dark:bg-custom-purple-600 hover:bg-white hover:text-custom-purple-400'
        } else {
          return 'bg-transparent text-white hover:bg-white hover:text-custom-purple-400'
        }
      } else {
        if (this.active) {
          return 'bg-white dark:bg-custom-purple-500 dark:hover:bg-custom-purple-450 dark:text-custom-purple-100 hover:bg-white shadow-sm dark:shadow-none dark:hover:bg-custom-purple-450 font-bold'
        } else {
          return 'bg-gray-200  dark:bg-custom-purple-400 hover:bg-white hover:shadow-sm dark:text-custom-purple-100 dark:hover:shadow-none dark:hover:bg-custom-purple-450 dark:hover:text-white'
        }
      }
    }
  }
}
</script>
