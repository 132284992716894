<template>
  <CompanyFollow
    :is-followed="internalIsFollower"
    :text="text"
    @follow="follow"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import CompanyFollow from './_components/CompanyFollow'

export default {
  name: 'CompanyFollowModule',
  components: {
    CompanyFollow
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    text: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      internalIsFollower: false
    }
  },
  computed: {
    ...mapGetters({
      selectedCompany: 'companies/selectedCompany',
      user: 'users/user'
    }),
    usedCompany () {
      if (this.company) {
        return this.company
      }

      return this.selectedCompany
    }
  },
  mounted () {
    this.internalIsFollower = this.usedCompany.is_follower
  },
  methods: {
    follow () {
      if (this.user === undefined || this.user.profile === undefined || this.user.profile_type === 'App\\Models\\GuestUser') {
        this.$router.push({ name: 'login' })
        return
      }
      this.$store.dispatch('companies/follow', this.usedCompany.id)

      this.internalIsFollower = !this.internalIsFollower
    }
  }
}
</script>
