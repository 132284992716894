<template>
  <ModalContainer
    v-if="showMyEucapsMenu"
    full-size
    right
    :padding="false"
    @toggle="$emit('toggle-my-eucaps-menu')"
    @on-back-button="$emit('toggle-my-eucaps-menu')"
  >
    <div>
      <Title bold="font-bold p-3 dark:text-custom-purple-100">
        {{ $t('Eucaps Shortcuts') }}
      </Title>
      <div class="flex flex-col">
        <RouterLink
          v-if="!isGuest && !isVerified"
          :to="{name:'onboarding'}"
          class="px-4 font-medium text-white h-14 cursor-pointer flex items-center z-30 bg-custom-orange-400 hover:bg-custom-orange-300"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <Icon
            name="id-card"
            class="mr-2 text-white text-lg"
            solid
          />
          <span class="flex items-center font-medium">{{ $t('Verify your identity here') }}</span>
          <Icon
            name="chevron-right"
            class="ml-2 text-white text-lg"
            solid
          />
        </RouterLink>
        <RouterLink
          class="flex items-center font-medium border-t dark:border-custom-purple-600 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500"
          :to="{name: 'myEucapsOverview'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <Icon
            name="user-circle"
            solid
            class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
          />
          <span>My Home</span>
        </RouterLink>
        <RouterLink
          class="flex items-center font-medium p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500"
          :to="{name: 'premium'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <img
            src="/img/crown.png"
            class="premium-icon mr-2.5 h-4 w-4"
          >
          <span>Premium</span>
        </RouterLink>
        <RouterLink
          class="flex items-center font-medium p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500 relative"
          :to="{name: 'chats'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <Bubble
            v-if="unreadMessages > 0"
            small
            background="bg-custom-orange-400"
            color="text-white"
            font-size="text-3xs"
            class="absolute rounded-full top-4 left-1 font-semibold"
          >
            {{ unreadMessages }}
          </Bubble>
          <Icon
            name="chat"
            class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
            solid
          />
          <span>Chat</span>
        </RouterLink>
        <RouterLink
          class="flex items-center font-medium p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500"
          :to="{name: 'members'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <Icon
            name="user-circle"
            class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
            solid
          />
          <span>Members</span>
        </RouterLink>
        <RouterLink
          class="flex items-center font-medium border-b dark:border-custom-purple-600 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500"
          :to="{name: 'support'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <Icon
            name="help-circle"
            class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
            solid
          />
          <span>Support</span>
        </RouterLink>
        <RouterLink
          class="flex items-center font-medium border-b dark:border-custom-purple-600 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500"
          :to="{name: 'myEucapsSettings'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <Icon
            name="cog"
            class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
            solid
          />
          <span>My Settings</span>
        </RouterLink>
        <div
          class="cursor-pointer flex items-center font-medium border-b  dark:border-custom-purple-600 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500"
          @click="logout"
        >
          <Icon
            name="log-out-circle"
            class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
          />
          <span>{{ $t('Log out') }}</span>
        </div>
      </div>
    </div>
    <div>
      <Title bold="font-bold mt-8 p-3 dark:text-custom-purple-100">
        {{ $t('Eucaps') }}
      </Title>
      <div class="flex flex-col">
        <RouterLink
          class="p-4 border-t dark:border-custom-purple-600 cursor-pointer text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500 font-medium"
          :to="{name: 'home'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <span
            class="inline-block"
            :class="(type === 'home') ? 'border-b-2 border-custom-orange-400':''"
          >
            Home
          </span>
        </RouterLink>
        <RouterLink
          class="p-4 cursor-pointer text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500 font-medium"
          :to="{name: 'heyInvestor'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <span
            class="inline-block"
            :class="(type === 'heyInvestor') ? 'border-b-2 border-custom-orange-400':''"
          >
            Hey Investor
          </span>
        </RouterLink>
        <RouterLink
          class="p-4 cursor-pointer text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500 font-medium"
          :to="{name: 'heyCompany'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <span
            class="inline-block"
            :class="(type === 'heyCompany') ? 'border-b-2 border-custom-orange-400':''"
          >
            Hey Company
          </span>
        </RouterLink>
        <RouterLink
          class="p-4 border-b  dark:border-custom-purple-600 cursor-pointer text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500 font-medium"
          :to="{name: 'heyEucaps'}"
          @click.native="$emit('toggle-my-eucaps-menu')"
        >
          <span
            class="inline-block"
            :class="(type === 'heyEucaps') ? 'border-b-2 border-custom-orange-400':''"
          >
            Hey Eucaps
          </span>
        </RouterLink>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from '@/modules/modal'
import { mapGetters } from 'vuex'

import usersMixin from '@/mixins/usersMixin'

export default {
  name: 'CompanySettingsModalModule',
  components: {
    ModalContainer
  },
  mixins: [
    usersMixin
  ],
  props: {
    showMyEucapsMenu: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      type: 'myEucapsOverview'
    }
  },
  computed: {
    ...mapGetters({
      company: 'companies/selectedCompany',
      allMessages: 'chats/allMessages',
      generalChats: 'chats/generalChats',
      user: 'users/user',
      myEucapsMenu: 'app/myEucapsMenu'
    }),
    unreadMessages () {
      if (!this.allMessages) return 0
      let unreadMessageArray = 0
      Object.values(this.allMessages).filter(chat => {
        chat.filter(message => {
          if (this.generalChats.find(chat => Number(chat.id) === Number(message.chat_id))) {
            const data = message.seen_by.find(user => Number(user.id) === Number(this.user.profile.id) && user.pivot.seen === 0)
            if (data) {
              unreadMessageArray++
            }
          }
        })
      })
      return unreadMessageArray
    }
  },
  mounted () {
    this.type = this.$route.name
  },
  methods: {
    logout () {
      this.$store.dispatch('users/logout')
      this.$emit('toggle-my-eucaps-menu')
    }
  }
}
</script>

<style lang="scss" scoped>
.light .premium-icon {
  filter: invert(33%) sepia(4%) saturate(1765%) hue-rotate(176deg) brightness(95%) contrast(92%);
  }
  .dark .premium-icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(176deg) brightness(104%) contrast(106%);
  }
</style>
