import { format,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInWeeks
} from 'date-fns'

import { i18n } from '@/js/language/'

export const diffFromNow = (datetime) => {
  const now = new Date()
  const current = new Date(datetime)

  const seconds = differenceInSeconds(now, current)
  if (seconds < 60) {
    return seconds.toString() + i18n.t(' second(s) ago')
  }

  const minutes = differenceInMinutes(now, current)
  if (minutes < 60) return minutes.toString() + i18n.t(' minute(s) ago')

  const hours = differenceInHours(now, current)
  if (hours < 24) return hours.toString() + i18n.t(' hour(s) ago')

  const days = differenceInDays(now, current)
  if (days < 7) return days.toString() + i18n.t(' day(s) ago')

  const weeks = differenceInWeeks(now, current)
  if (weeks < 4) return weeks.toString() + i18n.t(' week(s) ago')

  return format(current, 'yyyy-MM-dd kk:mm')
}

export default { diffFromNow }
