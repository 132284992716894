import { RepositoryFactory } from '@/api/repositoryFactory'
import mentions from '@/js/helpers/mentions'
import { i18n } from '@/js/language'
const repository = RepositoryFactory.get('posts')
const repo = RepositoryFactory.get('companies')

export default {
  state: {
    comments: [],
    loading: false,
    nextPageUrl: '',
    loaded: false,
    scrollLoading: false,
    canScrollMore: false,
    commentMenuLoading: false
  },
  getters: {
    loading: state => state.loading,
    comments (state) {
      return state.comments
    },
    nextPageUrl: state => state.nextPageUrl,
    loaded: state => state.loaded,
    scrollLoading: state => state.scrollLoading,
    canScrollMore: state => state.canScrollMore,
    commentMenuLoading: state => state.commentMenuLoading

  },
  mutations: {

    SET_COMMENT_MENU_LOADING (state, boolean) {
      state.commentMenuLoading = boolean
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SCROLL_LOADING (state, boolean) {
      state.scrollLoading = boolean
    },
    CAN_SCROLL_MORE (state, boolean) {
      state.canScrollMore = boolean
    },
    SET_COMMENTS (state, payload) {
      payload.data.map(comment => {
        if (comment.text && comment.text.match(mentions.MATCHING)) {
          comment.text = mentions.parse(comment.text, comment.message.mentions)
        }
        if (comment.comments && comment.comments.length > 0) {
          comment.comments.map(subComment => {
            if (subComment.text && subComment.text.match(mentions.MATCHING)) {
              subComment.text = mentions.parse(subComment.text, subComment.message.mentions)
            }
          })
        }
      })

      payload.data.map(comment => {
        state.comments = { ...state.comments, [comment.id]: comment }
      })
    },
    PUSH_COMMENT (state, payload) {
      payload.filter(comment => {
        if (comment.text && comment.text.match(mentions.MATCHING)) {
          comment.text = mentions.parse(comment.text, comment.message.mentions)
        }
        if (comment.comments && comment.comments.length > 0) {
          comment.comments.filter(subComment => {
            if (subComment.text && subComment.text.match(mentions.MATCHING)) {
              subComment.text = mentions.parse(subComment.text, subComment.message.mentions)
            }
          })
        }
      })

      payload.find(comment => {
        const flag = Object.values(state.comments).find(data => {
          return (comment.id === data.id)
        })

        if (!flag || flag === undefined) {
          state.comments = { ...state.comments, [comment.id]: comment }
        }
      })

      state.comments = { ...state.comments }
    },
    NEXT_PAGE_URL (state, payload) {
      state.nextPageUrl = payload
    },
    SET_LOADED (state, boolean) {
      state.loaded = boolean
    },
    UPDATE_COMMENT (state, comment) {
      if (comment.text && comment.text.match(mentions.MATCHING)) {
        comment.text = mentions.parse(comment.text, comment.message.mentions)
      }
      state.comments[comment.id] = { ...comment }
      // state.comments = { ...state.comments }
    },
    UPDATE_SUB_COMMENT (state, payload) {
      if (payload.data.text && payload.data.text.match(mentions.MATCHING)) {
        payload.data.text = mentions.parse(payload.data.text, payload.data.message.mentions)
      }
      const index = state.comments[payload.parentId].comments.findIndex(comment => comment.id === payload.data.id)
      state.comments[payload.parentId].comments[index] = payload.data
      state.comments = { ...state.comments }
    },
    DELETE_COMMENT (state, commentId) {
      delete state.comments[commentId]
      state.comments = { ...state.comments }
    },
    DELETE_SUB_COMMENT (state, payload) {
      const index = state.comments[payload.commentId].comments.findIndex(comment => comment.id === payload.subCommentId)
      state.comments[payload.commentId].comments.splice(index, 1)
      state.comments = { ...state.comments }
    }
  },
  actions: {
    async fetch ({ commit, dispatch }, postId) {
      try {
        commit('SET_LOADING', true)
        commit('CAN_SCROLL_MORE', true)

        const response = await repository.commentList(postId)

        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('NEXT_PAGE_URL', response.data.next_page_url)
          commit('SET_COMMENTS', { data: response.data.data })
          commit('SET_LOADED', true)
        }
        return response
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchMediaComments ({ commit, rootState, dispatch }, mediaId) {
      try {
        commit('SET_LOADING', true)
        commit('CAN_SCROLL_MORE', true)

        const response = await repo.commentList(mediaId, rootState.companies.selectedCompanySN)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('NEXT_PAGE_URL', response.data.next_page_url)
          commit('SET_COMMENTS', { data: response.data.data })
          commit('SET_LOADED', true)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addMediaComment ({ commit, rootState, dispatch }, payload) {
      try {
        const response = await repo.addComment(payload.mediaId, rootState.companies.selectedCompanyId, payload.formData)
        if (response.status === 200) {
          commit('NEXT_PAGE_URL', response.data.next_page_url)
          commit('SET_COMMENTS', { data: response.data.data })
          dispatch('snackbar/snack', { text: i18n.t('Comment added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async scroll ({ commit, rootState, state, dispatch }, payload) {
      try {
        if (state.nextPageUrl === null) {
          commit('SCROLL_LOADING', false)
          commit('CAN_SCROLL_MORE', false)
        } else if (state.nextPageUrl !== null && !state.loading && !state.scrollLoading) {
          commit('SCROLL_LOADING', true)
          const response = await repository.scrollComment(state.nextPageUrl)
          commit('SCROLL_LOADING', false)

          if ((response.status === 200) && response.data && response.data.data) {
            commit('PUSH_COMMENT', response.data.data)
            // payload.infiniteLoader.loaded()
            commit('NEXT_PAGE_URL', response.data.next_page_url)
          }
        }
      } catch (e) {
        // commit('SET_LOADING_NEW', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addPressReleaseComment ({ commit, rootState, dispatch }, payload) {
      try {
        const companyId = (payload.companyId) ? payload.companyId : rootState.companies.selectedCompanyId
        const response = await repository.addPressReleaseComment(companyId, payload.pressReleaseId, { text: payload.message, mentions: payload.mentions })
        if (response.status === 200) {
          commit('NEXT_PAGE_URL', response.data.next_page_url)
          commit('SET_COMMENTS', { data: response.data.data })
          dispatch('snackbar/snack', { text: i18n.t('Comment added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchPressReleaseComment ({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_LOADING', true)
        const companyId = (payload.companyId) ? payload.companyId : rootState.companies.selectedCompanySN
        const response = await repository.pressReleaseCommentList(companyId, payload.pressReleaseId)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_COMMENTS', { data: response.data.data })
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchTriggerComment ({ commit, rootState, dispatch }, triggerId) {
      try {
        // console.log('FETCHING TRIGGER COMMENTS')
        commit('SET_LOADING', true)
        commit('CAN_SCROLL_MORE', true)
        const response = await repository.triggerCommentList(rootState.companies.selectedCompanySN, triggerId)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_COMMENTS', { data: response.data.data })
          commit('NEXT_PAGE_URL', response.data.next_page_url)
          commit('SET_COMMENTS', { data: response.data.data })
          commit('SET_LOADED', true)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addComment ({ commit, dispatch }, payload) {
      try {
        const response = await repository.addComment(payload.postId, payload.formData)
        if (response.status === 200) {
          commit('NEXT_PAGE_URL', response.data.next_page_url)
          commit('SET_COMMENTS', { data: response.data.data })
          dispatch('timeline/fetchPost', { checkState: false, silent: true, postId: payload.postId }, { root: true })

          dispatch('snackbar/snack', { text: i18n.t('Comment added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async updateComment ({ commit, dispatch }, payload) {
      try {
        // commit('SET_COMMENT_MENU_LOADING', true)
        const response = await repository.updateComment(payload.commentId, payload.formData)
        // commit('SET_COMMENT_MENU_LOADING', false)
        if (response.status === 200) {
          commit('UPDATE_COMMENT', response.data)
          dispatch('snackbar/snack', { text: 'Comment updated successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_COMMENT_MENU_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async updateSubComment ({ commit, dispatch }, payload) {
      try {
        commit('SET_COMMENT_MENU_LOADING', true)
        const response = await repository.updateComment(payload.commentId, { text: payload.message, mentions: payload.mentions })
        commit('SET_COMMENT_MENU_LOADING', false)
        if (response.status === 200) {
          commit('UPDATE_SUB_COMMENT', { parentId: payload.parentId, data: response.data })
          dispatch('snackbar/snack', { text: 'Comment updated successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_COMMENT_MENU_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addTriggerComment ({ commit, rootState, dispatch }, payload) {
      try {
        const response = await repository.addTriggerComment(rootState.companies.selectedCompanyId, payload.trigger_id, payload.formData)
        if (response.status === 200) {
          commit('NEXT_PAGE_URL', response.data.next_page_url)
          commit('SET_COMMENTS', { data: response.data.data })
          dispatch('triggers/updateTrigger', response.data.data[0].commentable, { root: true })
          dispatch('snackbar/snack', { text: i18n.t('Comment added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addTriggerSubComment ({ commit, dispatch, rootState }, payload) {
      try {
        const response = await repository.addTriggerSubComment(rootState.companies.selectedCompanyId, payload.triggerId, payload.commentId, payload.formData)
        if (response.status === 200) {
          commit('SET_COMMENTS', { data: [response.data.commentable] })
          dispatch('snackbar/snack', { text: i18n.t('Comment added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addPressReleaseSubComment ({ commit, dispatch, rootState }, payload) {
      try {
        const response = await repository.addPressReleaseSubComment(rootState.companies.selectedCompanyId, payload.pressReleaseId, payload.commentId, { text: payload.message, mentions: payload.mentions })
        if (response.status === 200) {
          commit('SET_COMMENTS', { data: [response.data.commentable] })
          dispatch('snackbar/snack', { text: i18n.t('Comment added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addSubComment ({ commit, dispatch }, payload) {
      try {
        const response = await repository.addSubComment(payload.postId, payload.commentId, payload.formData)
        if (response.status === 200) {
          let comment = response.data
          if (comment.likes === undefined) comment.likes = []
          commit('SET_COMMENTS', { data: [comment] })
          dispatch('snackbar/snack', { text: i18n.t('Comment added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addMediaSubComment ({ commit, dispatch, rootState }, payload) {
      try {
        const response = await repo.addSubComment(rootState.companies.selectedCompanyId, payload.commentId, payload.formData)
        if (response.status === 200) {
          commit('SET_COMMENTS', { data: [response.data.commentable] })
          dispatch('snackbar/snack', { text: i18n.t('Comment added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchMediaSubComment ({ commit, dispatch }, payload) {
      try {
        const response = await repo.fetchSubComment(payload.commentId)
        if (response.status === 200 && response.data) {
          commit('SET_COMMENTS', { data: response.data.data })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async fetchSubComment ({ commit, dispatch }, payload) {
      try {
        const response = await repository.fetchSubComment(payload.commentId)
        if (response.status === 200 && response.data) {
          commit('SET_COMMENTS', { data: response.data.data })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pushComment ({ commit }, payload) {
      await commit('PUSH_COMMENT', [payload])
    },
    async like ({ commit, state, rootState, dispatch }, payload) {
      try {
        const response = await repository.commentLike(payload.comment_id, { reaction: payload.reaction })
        if (response.status === 204) {
          const comment = state.comments[payload.comment_id]
          const likeIndex = comment.likes.findIndex(like => (like.stock_user_id === rootState.users.user.profile_id && like.reaction === payload.reaction))

          if (likeIndex > -1) {
            comment.likes.splice(likeIndex, 1)
          } else {
            comment.likes.push({
              likeable_id: payload.comment_id,
              reaction: payload.reaction,
              stock_user_id: rootState.users.user.profile_id,
              stock_user: rootState.users.user.profile
            })
          }
          dispatch('snackbar/snack', { text: 'Comment liked successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async report ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_COMMENT_MENU_LOADING', true)
        const response = await repository.reportComment(payload)

        commit('SET_COMMENT_MENU_LOADING', false)
        if (response.status === 200) {
          dispatch('snackbar/snack', { text: 'Comment reported successfully', type: 'success', show: true, autoclose: true }, { root: true })
        } else {
          dispatch('snackbar/snack', { text: 'Comment already reported', type: 'info', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_COMMENT_MENU_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteSubComment ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_COMMENT_MENU_LOADING', true)
        const response = await repository.deleteComment(payload.subCommentId)

        commit('SET_COMMENT_MENU_LOADING', false)
        if (response.status === 204) {
          commit('DELETE_SUB_COMMENT', payload)
          dispatch('snackbar/snack', { text: 'Comment deleted successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_COMMENT_MENU_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async delete ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_COMMENT_MENU_LOADING', true)
        const response = await repository.deleteComment(payload)

        commit('SET_COMMENT_MENU_LOADING', false)
        if (response.status === 204) {
          commit('DELETE_COMMENT', payload)
          dispatch('snackbar/snack', { text: 'Comment deleted successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        commit('SET_COMMENT_MENU_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.comments = {}
    }
  },
  namespaced: true
}
