import Vue from 'vue'
import Router from 'vue-router'
import auth from './helpers/auth'
import store from './store'
// import Home from '@/views/Home'
// import Login from '@/views/Login'
// import Signup from '@/views/Signup'
// import Onboarding from '@/views/Onboarding'
// import Verification from '@/views/Verification'
// import GeneralFeed from '@/views/GeneralFeed'
// import SearchAddCompany from '@/views/SearchAddCompany'
// import CompanyPage from '@/views/CompanyPage'
// import Profile from '@/views/Profile'
// import ProfileMenu from '@/views/ProfileMenu'
// import EditProfile from '@/views/EditProfile'
// import ProfileSettings from '@/views/ProfileSettings'
// import MyCompany from '@/views/MyCompany'
// import InsiderTrade from '@/views/InsiderTrade'
// import Verify from '@/views/Verify'
// import Privacy from '@/views/Privacy'
// import Terms from '@/views/Terms'
// import Followers from '@/views/Followers'
// import Followings from '@/views/Followings'
// import Notifications from '@/views/Notifications'
// import OtherUserProfile from '@/views/OtherUserProfile'
// import Members from '@/views/Members'
// import Chats from '@/views/Chats'
// import Chat from '@/views/Chat'
// import Rules from '@/views/Rules'
// import Support from '@/views/Support'
// // import CompanyDiscussion from '@/views/CompanyDiscussion'
// import CompanyChat from '@/views/CompanyChat'
// import CompanyOverview from '@/views/CompanyOverview'
// import CompanyFinancials from '@/views/CompanyFinancials'
// import CompanyHolders from '@/views/CompanyHolders'
// import CompanyAnalysis from '@/views/CompanyAnalysis'
// import CompanyDiscussion from '@/views/CompanyDiscussion'

// import CompanyDetails from '@/views/CompanyDetails'
// import CompanyIPO from '@/views/CompanyIPO'
// import CompanyPrivate from '@/views/CompanyPrivate'
// import CompanyTriggers from '@/views/CompanyTriggers'
// import CompanyEstimates from '@/views/CompanyEstimates'
// import CompanyAssets from '@/views/CompanyAssets'
// import CompanyCalendar from '@/views/CompanyCalendar'
// import CompanyDocuments from '@/views/CompanyDocuments'
// import CompanyContacts from '@/views/CompanyContacts'
// import CompanyBoard from '@/views/CompanyBoard'
// import CompanyBrokerStats from '@/views/CompanyBrokerStats'
// import CompanyMedia from '@/views/CompanyMedia'
// import CompanyMembers from '@/views/CompanyMembers'
// import UserFlow from '@/views/UserFlow'
// import EmailVerification from '@/views/EmailVerification'
// import UserMedia from '@/views/UserMedia'
// import UserCompany from '@/views/UserCompany'
// import CompanyPressRelease from '@/views/CompanyPressRelease'
// import CompanyPressReleases from '@/views/CompanyPressReleases'
// import News from '@/views/News'
// import ProfileFavorites from '@/views/ProfileFavorites'
// import Premium from '@/views/Premium'
// import Refresh from '@/views/Refresh'
// import HighScore from '@/views/HighScore'
// import Posts from '@/views/Post'
// import Lists from '@/views/Lists'
// import Comment from '@/views/Comment'
// import Saved from '@/views/Saved'
// import CompanyOwners from '@/views/CompanyOwners'
// import CompanySettings from '@/views/CompanySettings'
// import CompanyQuestionDocuments from '@/views/CompanyQuestionDocuments'
// import HelpCenter from '@/views/HelpCenter'
// import CompanyQuestions from '@/views/CompanyQuestions'
// import Achievements from '@/views/Achievements'
import eventBus from './eventbus'
// import ForgotPassword from '@/views/ForgotPassword'
// import Freja from '@/views/Freja'
// import Lead from '@/views/Lead'
// import Incidents from '@/views/Incidents'
// import Incident from '@/views/Incident'
// import Advertisers from '@/views/Advertisers'
// import AddAdvertiser from '@/views/AddAdvertiser'
// import AddAdvertise from '@/views/AddAdvertise'
// import Advertiser from '@/views/Advertiser'
// import Advertise from '@/views/Advertise'
// import BrokerStats from '@/views/BrokerStats'

// import ListScreener from '@/views/ListScreener'

// import MyEucaps from '@/views/MyEucaps'
// import UserLists from '@/views/UserLists'
// import FeaturedVideos from '@/views/FeaturedVideos'
// import BlogCreate from '@/views/BlogCreate'
// import BlogEdit from '@/views/BlogEdit'
// import AllBlogs from '@/views/AllBlogs'
// import SingleBlog from '@/views/SingleBlog'
// import CompanyUsers from '@/views/CompanyUsers'
// import Explore from '@/views/Explore'
// import CompanyScreener from '@/views/CompanyScreener'
// import Compare from '@/views/Compare'
// import HeyInvestor from '@/views/HeyInvestor'
// import HeyCompany from '@/views/HeyCompany'
// import Subscribe from '@/views/Subscribe'
// import HeyEucaps from '@/views/HeyEucaps'
// import HolderShares from '@/views/HolderShares'

// import MyAdmin from '@/views/MyAdmin'
// import AdminOverview from '@/views/AdminOverview'
// import AdminPoll from '@/views/AdminPoll'

// import ProfileOverview from '@/views/ProfileOverview'
// import Marketplace from '@/views/Marketplace'

import findApp from '@/js/helpers/findApp'
import { isMobile } from './helpers/mobile'
import VueMeta from 'vue-meta'

Vue.use(Router)
Vue.use(VueMeta)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (to.name === 'generalfeed') {
      if (store.getters['timeline/postsWithoutTypes'] && store.getters['timeline/postsWithoutTypes']['general-feed'] && store.getters['timeline/postsWithoutTypes']['general-feed'].length === 0) {
        store.dispatch('timeline/setType', 'general-feed')
        return { x: 0, y: 0 }
      } else {
        return savedPosition
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    {
      path: '/index.html',
      name: 'home',
      component: () => import('@/views/Home'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    {
      path: '/hey-company',
      name: 'heyCompany',
      component: () => import('@/views/HeyCompany'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    {
      path: '/hey-eucaps',
      name: 'heyEucaps',
      component: () => import('@/views/HeyEucaps'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    {
      path: '/hey-investor',
      name: 'heyInvestor',
      component: () => import('@/views/HeyInvestor'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import('@/views/Subscribe'),
      meta: {
        slide: 0,
        needLogin: true
      }
    },
    {
      path: '/followers/:id',
      name: 'followers',
      component: () => import('@/views/Followers')
    },
    {
      path: '/followings/:id',
      name: 'followings',
      component: () => import('@/views/Followings')
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import('@/views/ForgotPassword'),
      meta: {
        slide: 0,
        layout: 'SignInLayout',
        needLogin: false
      }
    },
    {
      path: '/freja',
      name: 'freja',
      component: () => import('@/views/Freja'),
      meta: {
        slide: 0,
        needLogin: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login'),
      meta: {
        slide: 0,
        layout: 'SignInLayout',
        needLogin: false
      }
    },
    {
      path: '/r/:lead',
      name: 'lead',
      component: () => import('@/views/Lead'),
      meta: {
        slide: 0,
        needLogin: false
      }
    },
    {
      path: '/l/:leadredirect',
      name: 'lead',
      component: () => import('@/views/Lead'),
      meta: {
        slide: 0,
        needLogin: false
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/Signup'),
      meta: {
        slide: 0,
        layout: 'SignInLayout',
        needLogin: false
      }
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: () => import('@/views/Onboarding'),
      meta: {
        needLogin: false,
        layout: 'SignInLayout'
      }
    },
    {
      path: '/onboarding-company',
      name: 'onboarding-company',
      component: () => import('@/views/Onboarding'),
      meta: {
        needLogin: false,
        layout: 'SignInLayout'
      }
    },
    {
      path: '/verification',
      name: 'Verification',
      component: () => import('@/views/Verification'),
      meta: {
        needLogin: false,
        layout: 'SignInLayout'
      }
    },
    {
      path: '/email-verification',
      name: 'emailverification',
      component: () => import('@/views/EmailVerification'),
      meta: {
        slide: 0,
        needLogin: false
      }
    },
    {
      path: '/searchcompany',
      name: 'searchaddcompany',
      component: () => import('@/views/SearchAddCompany'),
      meta: {
        slide: 2,
        mainSwipe: true
      }
    },
    {
      path: '/members',
      name: 'members',
      component: () => import('@/views/Members'),
      meta: {
        slide: 2,
        mainSwipe: true
      }
    },
    {
      path: '/members/followings',
      name: 'memberFollowings',
      component: () => import('@/views/Members'),
      meta: {
        slide: 2,
        mainSwipe: true
      }
    },
    {
      path: '/profilemenu',
      name: 'profilemenu',
      component: () => import('@/views/ProfileMenu'),
      meta: {
        slide: 5,
        mainSwipe: true
      }
    },
    {
      path: '/featuredvideos',
      name: 'featuredVideos',
      component: () => import('@/views/FeaturedVideos')
    },
    {
      path: '/editprofile',
      redirect: { name: 'myEucapsSettings' },
      name: 'editprofile',
      component: () => import('@/views/EditProfile')
    },
    {
      path: '/verify',
      name: 'verify',
      component: () => import('@/views/Verify')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/Privacy'),
      meta: {
        needLogin: false
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/views/Terms'),
      meta: {
        needLogin: false
      }
    },
    {
      path: '/company-users',
      name: 'companyUsers',
      component: () => import('@/views/CompanyUsers')
    },
    {
      path: '/rules',
      name: 'rules',
      component: () => import('@/views/Rules')
    },
    {
      path: '/premium',
      name: 'premium',
      component: () => import('@/views/Premium')
    },
    {
      path: '/holder-shares/:id',
      name: 'holdershares',
      component: () => import('@/views/HolderShares')
    },
    {
      path: '/profile',
      redirect: { name: 'myEucapsOverview' },
      name: 'profile',
      component: () => import('@/views/Profile'),
      meta: {
        slide: 5
      }
    },
    // {
    //   path: '/profile/lists',
    //   name: 'usersLists',
    //   component: () => import('@/views/UsersLists')
    // },
    {
      path: '/posts/:id',
      name: 'posts',
      component: () => import('@/views/Post')
    },
    // {
    //   path: '/company-screener',
    //   name: 'companyScreener',
    //   component: () => import('@/views/CompanyScreener')
    // },
    // {
    //   path: '/list-screener',
    //   name: 'listScreener',
    //   component: () => import('@/views/ListScreener')
    // },
    {
      path: '/compare',
      name: 'compare',
      component: () => import('@/views/Compare')
    },
    {
      path: '/comment/:id',
      name: 'comment',
      component: () => import('@/views/Comment')
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/Notifications'),
      meta: {
        slide: 4,
        mainSwipe: true
      }
    },
    {
      path: '/otheruser/:id',
      name: 'otherUserProfile',
      component: () => import('@/views/OtherUserProfile'),
      meta: {
        slide: 6
      },
      children: [
        {
          path: '/otheruser/:id/flow',
          name: 'userFlow',
          component: () => import('@/views/UserFlow')
        },
        {
          path: '/otheruser/:id/company',
          name: 'userCompany',
          component: () => import('@/views/UserCompany')
        },
        {
          path: '/otheruser/:id/media',
          name: 'userMedia',
          component: () => import('@/views/UserMedia')
        }
      ]
    },
    {
      path: '/chats',
      name: 'chats',
      component: () => import('@/views/Chats'),
      meta: {
        slide: 3,
        mainSwipe: true
      }
    },
    {
      path: '/chats/:id',
      name: 'chat',
      component: () => import('@/views/Chat'),
      meta: {
        slide: 3
      }
    },
    {
      path: '/refresh',
      name: 'refresh',
      component: () => import('@/views/Refresh')
    },
    {
      path: '/highscore',
      name: 'highscore',
      component: () => import('@/views/HighScore')
    },
    {
      path: '/saved',
      name: 'saved',
      component: () => import('@/views/Saved')
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('@/views/Support')
    },
    {
      path: '/helpcenter',
      name: 'help-center',
      component: () => import('@/views/HelpCenter')
    },
    {
      path: '/achievements',
      name: 'achievements',
      component: () => import('@/views/Achievements')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/News')
    },
    {
      path: '/incidents',
      name: 'incidents',
      component: () => import('@/views/Incidents')
    },
    {
      path: '/incidents/:id',
      name: 'incident',
      component: () => import('@/views/Incident')
    },
    {
      path: '/advertisers',
      name: 'advertisers',
      component: () => import('@/views/Advertisers')
    },
    {
      path: '/addadvertiser',
      name: 'addAdvertiser',
      component: () => import('@/views/AddAdvertiser')
    },
    {
      path: '/:advertiserId/addadvertise',
      name: 'addAdvertise',
      component: () => import('@/views/AddAdvertise')
    },
    {
      path: '/:id/advertiser',
      name: 'advertiser',
      component: () => import('@/views/Advertiser')
    },
    {
      path: '/:advertiserId/:id/advertise',
      name: 'advertise',
      component: () => import('@/views/Advertise')
    },
    {
      path: '/talk',
      name: 'generalfeed',
      component: () => import('@/views/GeneralFeed')
    },
    {
      path: '/lists/:id',
      name: 'singleList',
      component: () => import('@/views/Lists')
    },
    {
      path: '/explore',
      name: 'explore',
      component: () => import('@/views/Explore'),
      children: [
        {
          path: '',
          name: 'companyScreener',
          component: () => import('@/views/CompanyScreener')
        },
        {
          path: '/explore/news',
          name: 'newsScreener',
          component: () => import('@/views/News')
        },
        {
          path: '/explore/lists',
          name: 'listScreener',
          component: () => import('@/views/ListScreener')
        },
        {
          path: '/explore/insiders',
          name: 'insider',
          component: () => import('@/views/InsiderTrade')
        },
        {
          path: '/explore/brokerstats',
          name: 'brokerstats',
          component: () => import('@/views/BrokerStats')
        }
      ]
    },
    {
      path: '/my-admin',
      name: 'myAdmin',
      component: () => import('@/views/MyAdmin'),
      children: [
        {
          path: '/my-admin/overview',
          name: 'myAdminOverview',
          component: () => import('@/views/AdminOverview')
        },
        {
          path: '/my-admin/polls',
          name: 'myAdminPolls',
          component: () => import('@/views/AdminPoll')
        }
      ]
    },
    {
      path: '/my-eucaps',
      name: 'myEucaps',
      component: () => import('@/views/MyEucaps'),
      children: [
        {
          path: '/my-eucaps/overview',
          name: 'myEucapsOverview',
          component: () => import('@/views/ProfileOverview')
        },
        {
          path: '/my-eucaps/lists',
          name: 'myEucapsLists',
          component: () => import('@/views/UserLists')
        },
        {
          path: '/my-eucaps/favorites',
          name: 'myEucapsFavorites',
          component: () => import('@/views/ProfileFavorites')
        },
        {
          path: '/my-eucaps/settings',
          name: 'myEucapsSettings',
          component: () => import('@/views/ProfileSettings')
        },
        {
          path: '/my-eucaps/my-company/:id?',
          name: 'myEucapsMyCompany',
          component: () => import('@/views/MyCompany')
        },
        {
          path: '/my-eucaps/marketplace',
          name: 'myEucapsMarketplace',
          component: () => import('@/views/Marketplace')
        }
      ]
    },
    {
      path: '/articles',
      name: 'blogs',
      component: () => import('@/views/AllBlogs')
    },
    {
      path: '/articles/create',
      name: 'BlogCreate',
      component: () => import('@/views/BlogCreate')
    },
    {
      path: '/articles/:name/edit',
      name: 'BlogEdit',
      component: () => import('@/views/BlogEdit')
    },

    {
      path: '/articles/:name',
      name: 'SingleBlog',
      component: () => import('@/views/SingleBlog')
    },
    {
      path: '/allowed-routes',
      name: 'AllowedRoutes',
      component: () => import('@/views/AllowedRoutes')
    },
    {
      path: '/:id',
      name: 'CompanyPage',
      component: () => import('@/views/CompanyPage'),
      meta: {
        slide: 2
      },
      children: [
        {
          path: '/:id',
          name: 'companyOverview',
          component: () => import('@/views/CompanyOverview')
        },
        {
          path: '/:id/media/:media_id?',
          name: 'companyMedia',
          component: () => import('@/views/CompanyMedia')
        },
        {
          path: '/:id/details',
          name: 'companyDetails',
          component: () => import('@/views/CompanyDetails')
        },
        {
          path: '/:id/chat/:room',
          name: 'companyChat',
          component: () => import('@/views/CompanyChat')
        },
        {
          path: '/:id/triggers',
          name: 'companyTriggers',
          component: () => import('@/views/CompanyTriggers')
        },
        {
          path: '/:id/estimates',
          name: 'companyEstimates',
          component: () => import('@/views/CompanyEstimates')
        },
        {
          path: '/:id/ipo',
          name: 'companyIPO',
          component: () => import('@/views/CompanyIPO')
        },
        {
          path: '/:id/private',
          name: 'companyPrivate',
          component: () => import('@/views/CompanyPrivate')
        },
        {
          path: '/:id/assets',
          name: 'companyAssets',
          component: () => import('@/views/CompanyAssets')
        },
        {
          path: '/:id/calendar',
          name: 'companyCalendar',
          component: () => import('@/views/CompanyCalendar')
        },
        {
          path: '/:id/documents',
          name: 'companyDocuments',
          component: () => import('@/views/CompanyDocuments')
        },
        {
          path: '/:id/contacts',
          name: 'companyContacts',
          component: () => import('@/views/CompanyContacts')
        },
        {
          path: '/:id/board',
          name: 'companyBoard',
          component: () => import('@/views/CompanyBoard')
        },
        {
          path: '/:id/brokerstats',
          name: 'companyBrokerStats',
          component: () => import('@/views/CompanyBrokerStats')
        },
        {
          path: '/:id/owner',
          name: 'companyOwners',
          component: () => import('@/views/CompanyOwners')
        },
        {
          path: '/:id/members',
          name: 'companyMembers',
          component: () => import('@/views/CompanyMembers')
        },
        {
          path: '/:id/documents',
          name: 'companyQuestionDocuments',
          component: () => import('@/views/CompanyQuestionDocuments')
        },
        {
          path: '/:id/companysettings',
          name: 'companySettings',
          component: () => import('@/views/CompanySettings')
        },
        {
          path: '/:id/documents/:questionDocumentId/questions',
          name: 'companyQuestions',
          component: () => import('@/views/CompanyQuestions')
        },
        {
          path: '/:id/pm',
          name: 'companyPressReleases',
          component: () => import('@/views/CompanyPressReleases')
        },
        {
          path: '/:id/pm/:pressReleaseId',
          name: 'companyPressRelease',
          component: () => import('@/views/CompanyPressRelease')
        },
        {
          path: '/:id/insiders',
          name: 'companyInsiderTrade',
          component: () => import('@/views/InsiderTrade')
        },
        {
          path: '/:id/financials',
          name: 'companyFinancials',
          component: () => import('@/views/CompanyFinancials')
        },
        {
          path: '/:id/holders',
          name: 'companyHolders',
          component: () => import('@/views/CompanyHolders')
        },
        {
          path: '/:id/analysis',
          name: 'companyAnalysis',
          component: () => import('@/views/CompanyAnalysis')
        },
        {
          path: '/:id/discussion',
          name: 'companyDiscussion',
          component: () => import('@/views/CompanyDiscussion')
        },
        {
          path: '/:id/events',
          name: 'companyEvents',
          component: () => import('@/views/CompanyEvents')
        }
      ]
    }

  ]
})

window.addEventListener('popstate', () => {
  // console.log('POPSTATE YEAH')
  eventBus.$emit('on-go-back')
  // store.dispatch('modals/removeLatestModal')
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('todos/reset')
  // store.dispatch('app/useMenu', true)

  let lead = to.params.lead
  let leadRedirect = to.params.leadredirect
  if (lead || leadRedirect) {
    const ifrm = document.createElement('iframe')
    ifrm.style.display = 'none'
    ifrm.setAttribute('id', 'go')
    document.body.appendChild(ifrm)
    await store.dispatch('app/lead', { lead: lead || leadRedirect, platform_key: store.getters['app/platformKey'] })

    if (leadRedirect) {
      window.location.href = 'https://get.eucaps.com'
    } else {
      if (isMobile()) {
        findApp.loadAppByUri('axyer://', 'https://get.eucaps.com', 'axyer://generalfeed')
      } else {
        next({ name: 'generalfeed' })
      }
    }

    return
  }

  if (to.fullPath.indexOf('/' + store.getters['app/fixedHash']) > -1) {
    if (to.matched.length === 0) {
      const path = to.fullPath.split('/' + store.getters['app/fixedHash'])[0]
      next(path)
    } else {
      next({ name: to.matched[0].name })
    }
  }

  const user = store.getters['users/user']
  const isGuestUser = user.hasOwnProperty('profile_type') && user.profile_type.indexOf('GuestUser') > -1

  const tryToAccessUserProtectedUrlWithoutAccess =
    to.matched.some((m) => !m.meta.hasOwnProperty('needLogin') || m.meta.needLogin) && (isGuestUser) && store.getters['app/isNative']

  if (tryToAccessUserProtectedUrlWithoutAccess) {
    auth.clearToken()
    next({ name: 'login' })
  }

  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') ? localStorage.isLoggedIn : false)

  if (isLoggedIn) {
    // console.log('IS LOGGED IN ROUTER')
    store.dispatch('users/fetch')
    store.dispatch('users/visited', to.fullPath)
    store.dispatch('socket/connect')
  } else if (auth.isAuthenticated()) {
    // console.log('IS AUTHENTICATED')
    localStorage.isLoggedIn = true
    store.dispatch('users/fetch')
    store.dispatch('users/visited', to.fullPath)
    store.dispatch('socket/connect')
  } else if (auth.hasDSecret()) {
    // console.log('IS D SECRET')
    store.dispatch('users/fetch')
    store.dispatch('users/visited', to.fullPath)
    store.dispatch('socket/connect')
  } else {
    store.dispatch('users/visited', to.fullPath)
  }

  // if (to.name === 'home') {
  //   next({ name: 'generalfeed' })
  // } else {
  //   next()
  // }

  next()
})

export default router
