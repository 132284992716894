import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('likes')

export default {
  state: {
    loading: false,
    likes: []
  },
  getters: {
    loading: state => state.loading,
    likes: state => state.likes
  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LIKES (state, likes) {
      state.likes = [...likes]
    },
    RESET_LIKES (state) {
      state.likes = []
    }
  },
  actions: {
    async likes ({ commit }, payload) {
      commit('RESET_LIKES')
      try {
        commit('SET_LOADING', true)
        const response = await repository.likes(payload)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIKES', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
      }
    }
  },
  namespaced: true
}
