import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('companies')

export default {
  state: {
    selectedCompanyId: null,
    companyEvents: {},
    loading: false,
    newLoading: false,
    alert: {
      title: '',
      create: false,
      body: '',
      type: '',
      showConfirmButton: false
    }
  },
  getters: {
    loading: state => state.loading,
    events (state) {
      return state.companyEvents[state.selectedCompanyId]
    },
    newLoading: state => state.newLoading,
    alert: state => state.alert
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LIST (state, payload) {
      state.companyEvents = { ...state.companyEvents, [state.selectedCompanyId]: payload }
    },
    SET_NEW_LOADING (state, boolean) {
      state.newLoading = boolean
    },
    SET_ALERT (state, payload) {
      state.alert.title = payload.title
      state.alert.body = payload.body
      state.alert.create = payload.create
      state.alert.type = payload.type
      state.alert.showConfirmButton = payload.showConfirmButton
    },
    RESET_ALERT (state) {
      state.alert = {
        title: '',
        create: false,
        body: '',
        type: '',
        showConfirmButton: false
      }
    },
    DELETE_EVENT (state, id) {
      const index = state.companyEvents[state.selectedCompanyId].findIndex(event => event.id === id)
      state.companyEvents[state.selectedCompanyId].splice(index, 1)
      state.companyEvents = { ...state.companyEvents }
    }
  },
  actions: {
    async fetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_LOADING', true)
        const response = await repository.eventList(rootState.companies.selectedCompanySN)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async refetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_NEW_LOADING', true)
        const response = await repository.eventList(rootState.companies.selectedCompanySN)
        commit('SET_NEW_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_NEW_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteRequest ({ state, rootState, dispatch }, eventId) {
      try {
        const response = await repository.eventDeleteRequest(rootState.companies.selectedCompanyId, eventId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Delete request added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    resetAlert ({ commit }) {
      commit('RESET_ALERT')
    },
    setAlert ({ commit }, payload) {
      commit('SET_ALERT', payload)
    },
    async delete ({ state, rootState, dispatch, commit }, eventId) {
      try {
        const response = await repository.eventDelete(rootState.companies.selectedCompanyId, eventId)
        if (response.status === 204) {
          commit('DELETE_EVENT', eventId)
          // dispatch('snackbar/snack', { text: 'Delete request added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addEvent ({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_NEW_LOADING', true)
        const response = await repository.addEvent(rootState.companies.selectedCompanyId, payload)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Event added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.companyEvents = {}
    }
  },
  namespaced: true
}
