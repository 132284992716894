<template>
  <ModalContainer
    popup
    only-back-with-toggle
    @toggle="$emit('toggle')"
  >
    <div>
      <div class="flex items-center">
        <div
          v-if="loadingProfileImage"
          class="w-full flex flex-col items-center justify-center"
        >
          <UpdateLoader />
          <div>{{ progress }} %</div>
        </div>
        <div
          v-show="!loadingProfileImage"
          class="w-full"
        >
          <img
            ref="image"
            class="w-42 h-42"
            :src="src"
            alt="user image"
          >
        </div>
      </div>
      <p class="mt-4">
        <PositiveButton
          class="rounded-full text-xs focus:outline-none"
          @click="$emit('upload-image',{destination, blob})"
        >
          {{ $t('Upload') }}
        </PositiveButton>
        <BaseButton
          class="rounded-full text-xs focus:outline-none"
          @click="$emit('toggle')"
        >
          {{ $t('Cancel') }}
        </BaseButton>
      </p>
    </div>
  </ModalContainer>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'
import ModalContainer from '@/modules/modal'

export default {
  name: 'Cropper',
  components: {
    ModalContainer
  },
  props: {
    loadingProfileImage: { type: Boolean, default: false },
    src: { type: String, default: null },
    progress: { type: Number, default: 0 }
  },
  data () {
    return {
      cropper: {},
      destination: null,
      blob: null,
      image: {},
      cropperTimer: null
    }
  },
  mounted () {
    const self = this
    setTimeout(() => {
      self.image = this.$refs.image
      self.cropper = new Cropper(self.image, {
        aspectRatio: 1,
        async crop () {
          clearTimeout(self.cropperTimer)
          self.cropperTimer = setTimeout(async () => {
            const canvas = self.cropper.getCroppedCanvas({
              maxWidth: 800,
              maxHeight: 800
            })
            self.destination = canvas.toDataURL('image/png')

            const data = await fetch(self.destination)
            const blob = await data.blob()

            self.blob = blob
          }, 600)
        }
      })
    }, 1)
  }
}
</script>
