<template>
  <ModalContainer
    v-if="warnings.length > 0"
    :hide-close="true"
    full-size
  >
    <WarningModal
      :warnings="warnings"
      @close="warningSeen"
    />
  </ModalContainer>
</template>
<script>
import { mapGetters } from 'vuex'
import WarningModal from './_components/WarningModal.vue'
import ModalContainer from '@/modules/modal'

export default {
  name: 'Warning',
  components: {
    WarningModal,
    ModalContainer
  },
  computed: {
    ...mapGetters({
      user: 'users/profile'
    }),
    warnings () {
      if (!this.user || !this.user.warnings) return []
      //   let warningList = []
      //   this.user.warnings.filter(warning => {
      //     if (warningList.length > 0) {
      //       let data = warningList.findIndex(data => data.type === warning.type)
      //       console.log(data)
      //       if (data > -1) {
      //         warningList[data].count += 1
      //       } else {
      //         warningList.push({ type: warning.type, count: 1 })
      //       }
      //     } else {
      //       warningList.push({ type: warning.type, count: 1 })
      //     }
      //   })
      //   return warningList
      return this.user.warnings
    }
  },
  methods: {
    warningSeen () {
      let list = []
      this.user.warnings.filter(warning => {
        list.push(warning.id)
      })
      this.$store.dispatch('users/warningSeen', { warning_list: list })
    }
  }
}
</script>
