import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('comparables')
export default {
  state: {
    showCompare: false,
    loading: false,
    comparables: [],
    compareList: {}
  },
  getters: {
    showCompare: state => state.showCompare,
    comparables: state => state.comparables,
    compareList: state => state.compareList,
    loading: state => state.loading
  },
  mutations: {
    SET_SHOW_COMPARE (state, payload) {
      state.showCompare = payload
    },
    SET_LOADING_COMPARE (state, payload) {
      state.loading = payload
    },
    SET_COMPARABLES (state, payload) {
      state.comparables = [...payload]
    },
    SET_COMPARE_LIST (state, payload) {
      state.compareList = { ...payload }
    }
  },
  actions: {
    async setShowCompare ({ state, commit }, payload) {
      commit('SET_SHOW_COMPARE', payload)
    },
    toggleCompare ({ state, commit }, companyId) {
      const comparables = [...state.comparables]
      const exist = comparables.findIndex(compare => Number(compare) === Number(companyId))

      if (exist > -1) {
        comparables.splice(exist, 1)
      } else {
        comparables.push(companyId)
      }

      commit('SET_COMPARABLES', comparables)
    },
    async compare ({ state, commit, rootGetters, dispatch }, companyIds) {
      try {
        // console.log('compare', state.loading)
        // console.log('companyIds', companyIds)
        if (!state.loading) {
          const currency = rootGetters['screeners/selectedCurrency']
          const periodCode = rootGetters['financials/selectedPeriodCode']

          commit('SET_LOADING_COMPARE', true)
          const response = await repository.compare({ comparables: companyIds, currency, period: periodCode })
          if ((response.status === 200) && response.data) {
            commit('SET_COMPARE_LIST', response.data)
          }
          commit('SET_LOADING_COMPARE', false)
        }
      } catch (e) {
        commit('SET_LOADING_COMPARE', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    }
  },
  namespaced: true
}
