const COMPANY_FOLLOWERS = 'companyFollowers'
const COMPANY_DISCUSSIONS = 'companyDiscussions'

const USER_FOLLOWERS = 'userFollowers'
const USER_ENGAGED = 'userEngaged'
const USER_REPUTATION = 'userReputation'
export const ALL_TYPES = {
  COMPANY_FOLLOWERS: COMPANY_FOLLOWERS,
  COMPANY_DISCUSSIONS: COMPANY_DISCUSSIONS,
  USER_FOLLOWERS: USER_FOLLOWERS,
  USER_ENGAGED: USER_ENGAGED,
  USER_REPUTATION: USER_REPUTATION
}

export { COMPANY_FOLLOWERS, COMPANY_DISCUSSIONS, USER_FOLLOWERS, USER_ENGAGED, USER_REPUTATION }

export default { COMPANY_FOLLOWERS, COMPANY_DISCUSSIONS, USER_FOLLOWERS, USER_ENGAGED, USER_REPUTATION, ALL_TYPES }
