import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('companies')

export default {
  state: {
    selectedCompanyId: null,
    companyBoardMembers: {},
    loading: false,
    newLoading: false,
    editableId: null,
    alert: {
      title: '',
      create: false,
      body: '',
      type: '',
      showConfirmButton: false
    }
  },
  getters: {
    loading: state => state.loading,
    editableId: state => state.editableId,
    boardMembers (state) {
      return state.companyBoardMembers[state.selectedCompanyId]
    },
    newLoading: state => state.newLoading,
    alert: state => state.alert
  },
  mutations: {
    SELECT_COMPANY (state, companyId) {
      state.selectedCompanyId = companyId
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_NEW_LOADING (state, boolean) {
      state.newLoading = boolean
    },
    SET_LIST (state, payload) {
      state.companyBoardMembers = { ...state.companyBoardMembers, [state.selectedCompanyId]: payload }
    },
    APPEND_MEMBERS (state, payload) {
      let members = state.companyBoardMembers[state.selectedCompanyId]
      payload.find(response => {
        const flag = members.findIndex(asset => {
          return (response.id === asset.id)
        })
        if (flag > -1) {
          members[flag] = response
        } else {
          members.push(response)
        }
      })
      state.companyBoardMembers = { ...state.companyBoardMembers }
    },
    SET_EDITABLE_ID (state, id) {
      state.editableId = id
    },
    PUSH_BOARD_MEMBERS (state, payload) {
      state.companyBoardMembers = { ...state.companyBoardMembers, [payload[0].company.id]: payload }
    },
    SET_ALERT (state, payload) {
      state.alert.title = payload.title
      state.alert.body = payload.body
      state.alert.create = payload.create
      state.alert.type = payload.type
      state.alert.showConfirmButton = payload.showConfirmButton
    },
    RESET_ALERT (state) {
      state.alert = {
        title: '',
        create: false,
        body: '',
        type: '',
        showConfirmButton: false
      }
    },
    DELETE_MEMBER (state, id) {
      const index = state.companyBoardMembers[state.selectedCompanyId].findIndex(member => member.id === id)
      state.companyBoardMembers[state.selectedCompanyId].splice(index, 1)
      state.companyBoardMembers = { ...state.companyBoardMembers }
    }
  },
  actions: {
    async fetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_LOADING', true)
        const response = await repository.boardMemberList(rootState.companies.selectedCompanySN)
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async pushBoardMembers ({ commit, state }, payload) {
      commit('PUSH_BOARD_MEMBERS', [payload])
    },
    async refetch ({ commit, rootState, dispatch }) {
      try {
        commit('SELECT_COMPANY', rootState.companies.selectedCompanyId)
        commit('SET_NEW_LOADING', true)
        const response = await repository.boardMemberList(rootState.companies.selectedCompanySN)
        commit('SET_NEW_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_NEW_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async deleteRequest ({ state, rootState, dispatch }, contactId) {
      try {
        const response = await repository.boardMemberDeleteRequest(rootState.companies.selectedCompanyId, contactId)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Delete request added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    resetAlert ({ commit }) {
      commit('RESET_ALERT')
    },
    setAlert ({ commit }, payload) {
      commit('SET_ALERT', payload)
    },
    async delete ({ state, rootState, dispatch, commit }, boardMemberId) {
      try {
        const response = await repository.boardMemberDelete(rootState.companies.selectedCompanyId, boardMemberId)
        if (response.status === 204) {
          commit('DELETE_MEMBER', boardMemberId)
          // dispatch('snackbar/snack', { text: 'Delete request added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editBoardMember ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_EDITABLE_ID', payload.boardMemberId)
        const response = await repository.editBoardMember(state.selectedCompanyId, payload.boardMemberId, payload)
        if (response.status === 200) {
          commit('APPEND_MEMBERS', [response.data])
          commit('SET_EDITABLE_ID', null)
        }
      } catch (e) {
        commit('SET_EDITABLE_ID', null)
        dispatch('snackbar/snack', { text: e.response.data.message, type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async addBoardMember ({ commit, state, dispatch }, payload) {
      try {
        commit('SET_NEW_LOADING', true)
        const response = await repository.addBoardMember(state.selectedCompanyId, payload)
        if (response.status === 204) {
          dispatch('snackbar/snack', { text: 'Board member added successfully', type: 'success', show: true, autoclose: true }, { root: true })
        }
      } catch (e) {
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    reset ({ state }) {
      state.companyBoardMembers = {}
    }
  },
  namespaced: true
}
