// import router from '@/js/router'

import { mapGetters } from 'vuex'

export default {
  mounted () {
    // console.log('mounted oms')
    // console.log('random', this.random(30))
    // Router.push({ name: routeName, hash: '#toHash' })
  },
  data () {
    return {
      modalHashId: '',
      visibleUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      activeModals: 'modals/active'
    }),
    visible () {
      return this.activeModals.filter(activeUrls => activeUrls === this.visibleUrl).length > 0
    }
  },
  methods: {
    pushRandomHashUrl (useUniqueUrl = false) {
      if (useUniqueUrl) {
        this.visibleUrl = useUniqueUrl
      } else {
        this.modalHashId = this.random(30)
        this.visibleUrl = this.$route.path + '/' + this.modalHashId
      }

      this.$store.dispatch('modals/newModal', this.visibleUrl)
      history.pushState({}, null, this.visibleUrl)
    },
    resetRandomHashUrl () {
      if (this.visible) {
        history.go(-1)
      }
    },
    random (size = 12, characters = 'a-z,A-Z,0-9') {
      let charactersArray = characters.split(',')
      let characterSet = ''
      let password = ''

      if (charactersArray.indexOf('a-z') >= 0) {
        characterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        characterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        characterSet += '0123456789'
      }
      if (charactersArray.indexOf('#') >= 0) {
        characterSet += '![]{}()%&*$#^<>~@|'
      }

      for (let i = 0; i < size; i++) {
        password += characterSet.charAt(Math.floor(Math.random() * characterSet.length))
      }

      return password
    }
  }
}
