import { get, post, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/polls`

const fetch = async (data) => get(`${url}`, data)
const getNextPoll = async (data) => get(`${url}/next`, data)
const create = async (data) => post(`${url}`, data)
const deletePoll = async (data) => destroy(`${url}/${data.id}`)
const answer = async (data) => post(`${url}/${data.id}/answer`, { answer: data.answer, company_id: data.company_id })

export default {
  fetch,
  getNextPoll,
  create,
  deletePoll,
  answer
}
