<template>
  <div
    class="animated bounceIn flex items-center justify-center rounded-full text-center dark:text-custom-purple-100"
    :class="[
      {'w-4 h-4': small},
      {'w-8 h-8': !small},
      background,
      fontSize,
      color
    ]"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Bubble',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: 'bg-gray-200'
    },
    color: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: 'text-xs'
    }
  }
}
</script>
