<template>
  <div
    class="flex font-semibold justify-end px-1 py-1 rounded-lg"
    :class="secondary ? 'bg-custom-purple-400 dark:bg-custom-purple-600' : 'bg-gray-200 dark:bg-custom-purple-400'"
  >
    <CurrencyBadge
      first
      :active="active === 'LOCAL'"
      :secondary="secondary"
      @click="$emit('choose-currency', 'LOCAL')"
    >
      LOCAL
    </CurrencyBadge>
    <CurrencyBadge
      :active="active === 'SEK'"
      :secondary="secondary"
      @click="$emit('choose-currency', 'SEK')"
    >
      SEK
    </CurrencyBadge>
    <CurrencyBadge
      :active="active === 'EUR'"
      :secondary="secondary"
      @click="$emit('choose-currency', 'EUR')"
    >
      EUR
    </CurrencyBadge>
  </div>
</template>

<script>

export default {
  name: 'CurrencyMenu',
  props: {
    active: {
      type: String,
      default: 'LOCAL'
    },
    secondary: {
      type: Boolean,
      default: false
    }
  }
}
</script>
