import { RepositoryFactory } from '@/api/repositoryFactory'

import { i18n } from '@/js/language/'

const repository = RepositoryFactory.get('allowedRoutes')

export default {
  state: {
    allowedRoutes: [],
    loading: false
  },
  getters: {
    loading: state => state.loading,
    allowedRoutes: state => state.allowedRoutes

  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_ALLOWED_ROUTES (state, allowedRoutes) {
      state.allowedRoutes = allowedRoutes
    }
  },
  actions: {
    async fetchAll ({
      commit, dispatch
    }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.fetchAll()
        if ((response.status === 200) && response.data) {
          commit('SET_ALLOWED_ROUTES', response.data)
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.error(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },

    async addAllowedRoute ({
      commit, dispatch
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.createAllowedRoute(payload)
        if (response.status === 200) {
          dispatch('snackbar/snack', { text: i18n.t('Allowed Route added successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },
    async editAllowedRoute ({
      commit, dispatch
    }, payload) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.editAllowedRoute(payload)

        if (response.status === 200) {
          dispatch('fetchAll')
          dispatch('snackbar/snack', { text: i18n.t('Allowed Route edited successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.error(e)
        commit('SET_LOADING', false)

        if ('response' in e && e.response.status === 403) {
          dispatch('snackbar/snack', { type: 'error', text: i18n.t('Allowed Route already exist'), show: true, autoclose: true }, { root: true })
        } else {
          dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
        }
      }
    },
    async deleteAllowedRoute ({
      commit, dispatch
    }, id) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.deleteAllowedRoute(id)

        if (response.status === 204) {
          dispatch('fetchAll')
          dispatch('snackbar/snack', { text: i18n.t('Allowed Route deleted successfully'), type: 'success', show: true, autoclose: true }, { root: true })
        }
        commit('SET_LOADING', false)
      } catch (e) {
        console.log(e)
        commit('SET_LOADING', false)
        dispatch('snackbar/snack', { type: 'error', e: e, show: true, autoclose: true }, { root: true })
      }
    },

    setLoading ({ commit }, boolean) {
      commit('SET_LOADING', boolean)
    }

  },
  namespaced: true
}
