// import { allTypes } from '@/enums/postTypes'
import { isMobile } from '@/js/helpers/mobile'

export default {
  state: {
    sorting: 'relevance',
    filters: {
      by_general: 1,
      by_companies: 1,
      by_followers: 1,
      by_pr: 1,
      by_users: 1,
      // by_follower: 1,
      companiesOnly: false,
      // types: allTypes,
      company_id: null,
      stock_user_id: null
    },
    toggleFilterSection: !isMobile()
  },
  getters: {
    filters: state => state.filters,
    toggleFilterSection: state => state.toggleFilterSection,
    sorting: state => state.sorting
  },
  mutations: {
    INIT_FILTERS (state) {
      if (localStorage.filterVersion !== '4') {
        // console.log('RENSA OMS')
        localStorage.removeItem('filters')
        localStorage.filterVersion = '4'
      }

      if (localStorage.filters) {
        state.filters = { ...JSON.parse(localStorage.filters) }
      }
    },
    SET_FILTERS (state, payload) {
      if (payload.override) {
        state.filters = { ...payload }
      } else {
        state.filters = { ...state.filters, ...payload }
      }
    },
    SET_SORTING (state, payload) {
      state.sorting = payload
    },
    TOGGLE_FILTER_SECTION_MENU (state, payload) {
      state.toggleFilterSection = payload
    },
    SET_LOCALSTORAGE (state, payload) {
      localStorage.filters = JSON.stringify(payload)
    }
  },
  actions: {
    async init ({ commit }) {
      commit('INIT_FILTERS')
    },
    async setFilter ({ commit }, payload) {
      commit('SET_FILTERS', payload)
    },
    async toggleFilterSection ({ commit }, payload) {
      commit('TOGGLE_FILTER_SECTION_MENU', payload)
    },
    async setSorting ({ commit }, payload) {
      commit('SET_SORTING', payload)
    },
    async setLocalStorage ({ commit }, payload) {
      commit('SET_LOCALSTORAGE', payload)
    }
  },
  namespaced: true
}
