import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'users/user'
    }),
    followedCompanyIds () {
      if (!this.user || !this.user.profile) {
        return 0
      }
      return this.user.profile.followed_company_ids
    }
  }
}
