<template>
  <div
    class="bg-red-200 text-custom-purple-500 rounded-xl text-xs py-1 px-3"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'NegativeBadge',
  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>
