<template>
  <div v-if="notification.notifiable">
    <Notification
      :pivot="notification.pivot"
      @remove="$emit('remove', { id: notification.id })"
    >
      <AvatarSlot
        :stock-user="user"
      >
        <div
          class="text-xs mr-6"
          @click="$parent.redirectCompany({ page: 'companyTriggers', company: notification.notifiable.company_trigger.linkable.company, notificationId: notification.id})"
        >
          <span class="font-bold">
            <RouterLink :to="{name: 'userFlow', params: {id: notification.notifiable.stock_user.id}}">{{ notification.notifiable.stock_user.username }}</RouterLink>
          </span>
          <span v-if="notification.total>1"> {{ $t('and') }}  {{ notification.total - 1 }} {{ $t('people') }}</span>
          {{ $t('liked your comment on trigger') }}
          <span
            v-if="notification.notifiable.company_trigger.linkable.company"
            class="whitespace-nowrap"
          >
            {{ $t('for') }}
            <RouterLink
              class="font-bold"
              :to="{name: 'companyOverview', params: {id: notification.notifiable.company_trigger.linkable.company.slug_url}}"
            > {{ notification.notifiable.company_trigger.linkable.company.name }}</RouterLink>
          </span>
          <div>{{ time }}</div>
        </div>
      </AvatarSlot>
    </Notification>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationTriggerLikeComment',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  }
}
</script>
